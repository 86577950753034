import { Button, Chip, FormControl, Container, Grid, List, ListItem, IconButton, Alert, AlertProps, Snackbar, TextField, Typography, useMediaQuery, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogActions, DialogTitle, Table } from "@mui/material"
import { Stack, Box, Backdrop, TableBody } from "@mui/material";
import { useCallback, useEffect, useState } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"
import { RootState, actions } from "../../store"
import { useAppDispatch, useAppSelector, useIsTabActive } from "../../store/hooks"
import { SubComponentName, TableComponents, rowActionsFromStatus } from "../../utils/constants"
import { ApiError } from "../../utils/errorHandler"
import "./BuildCycle.css";
import BuildCycleAPI from "../../apis/buildcycle"
import { BuildCycleDetail } from "../../interfaces/Buildcycle"
import { EditBRC } from "./BuildCycleEditPage";
import { CreatePromotions } from "./CreatePromotions";
import { HeaderAction, RowAction } from "../../interfaces/Data";

export const BuildCycleDetailsPage = () => {
    const headerActions: HeaderAction[] = useAppSelector((state: RootState) => state.common.headerActions[TableComponents.BuildCycle]);
    const rowActions: RowAction[] = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.BuildCycle]);
    const userData = useAppSelector((state: RootState) => state.common.userData);
    const [reload, setreload] = useState<{ randomNumber: number }>({ randomNumber: 0 });
    const [showAddPromotions, setAddPromotions] = useState<boolean>(false);
    const [buildCycleDetails, setBuildCycleDetails] = useState<BuildCycleDetail | null>(null);
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const isTabVisible = useIsTabActive();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { deployments, common } = actions;
    const [showCreate, setShowCreate] = useState<boolean>(true);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [canCreate, setCanCreate] = useState<boolean>(true);
    const [canEdit, setCanEdit] = useState<boolean>(true);
    const handleCloseSnackbar = () => setSnackbar(null);
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);

    useEffect(() => {
        if (rowActions.length === 0) {
            return;
        }
        setCanEdit(rowActions.indexOf(RowAction.EDIT_ROW) > -1);
    }, [rowActions])

    useEffect(() => {
        if (!isTabVisible) {
            return;
        }
        dispatch(common.setShowLoader(true));
        (async () => {
            const splits = window.location.pathname.split("/");
            const id = splits[splits.length - 1];
            const res = await BuildCycleAPI.getBuildCycleDetail(id);
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setBuildCycleDetails(res.data);
        })();
        console.log(reload.randomNumber);
        dispatch(common.setShowLoader(false));
    }, [reload, isTabVisible]);



    if (!buildCycleDetails) {
        return <Container>Resource Not Found</Container>
    }


    return <Container maxWidth={false} className="deployment-details-content" sx={{ overflowY: "scroll" }}>
        {(canEdit) && <Container maxWidth={false} className="button-container">
            {canEdit && <Button variant="contained" onClick={() => setShowEdit(true)}>Edit Schedule</Button>}
            <span style={{ margin: '0 8px' }}></span>
            {canEdit && <Button variant="contained" onClick={() => setAddPromotions(true)}>Add New Column</Button>}
            <span style={{ margin: '0 8px' }}></span>
        </Container>}

        <Container maxWidth={false} sx={{ padding: 0, margin: 0, paddingRight: '2px' }}>
            <Grid container spacing={0}>
                <Grid item xs={isLargeScreen ? 6 : 12}>
                    <List className="list">
                        <ListItem className="list-item">
                            <div className="field-name">Build Release Cycle:</div>
                            <div className="field-value"><Chip label={buildCycleDetails.cyclename}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Created At:</div>
                            <div className="field-value"><Chip label={`${new Date(buildCycleDetails.createdAt).toDateString()} ${new Date(buildCycleDetails.createdAt).toLocaleTimeString()}`}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Created By:</div>
                            <div className="field-value"><Chip label={buildCycleDetails.createdBy}></Chip></div>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>

        <Container className="build-cycle" maxWidth={false}>
            <TableContainer sx={{ width: "90vw", height: "80vh", overflow: "auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Build Cycle</TableCell>
                            <TableCell>Environment</TableCell>
                            <TableCell>In-time</TableCell>
                            <TableCell>Out-time</TableCell>
                            <TableCell>Restricted Check-in Time</TableCell>
                            <TableCell>SAAS Migration Start Time</TableCell>
                            {buildCycleDetails.buildcycle.length > 0 &&
                                Object.keys(buildCycleDetails.buildcycle[0].promotions).map((key) => (
                                    <TableCell key={key}>{key}</TableCell>
                                ))}
                            {buildCycleDetails.buildcycle.length > 0 &&
                                Object.keys(buildCycleDetails.buildcycle[0].Qualitycheckpoints).map((key) => (
                                    <TableCell key={key}>{key}</TableCell>
                                ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {buildCycleDetails.buildcycle.map((envCycle, index) => (
                            <TableRow key={index} >
                                <TableCell className="buildname">{buildCycleDetails.cyclename}</TableCell>
                                <TableCell>{envCycle.env}</TableCell>
                                <TableCell>{envCycle.intime ? envCycle.intime.toString().split('T')[0] : 'N/A'}</TableCell>
                                <TableCell>{envCycle.outtime ? envCycle.outtime.toString().split('T')[0] : 'N/A'}</TableCell>
                                <TableCell>{envCycle.restrictedCheckintime ? envCycle.restrictedCheckintime.toString().split('T')[0] : 'N/A'}</TableCell>
                                <TableCell>{envCycle.saasMigrationstartTime ? envCycle.saasMigrationstartTime.toString().split('T')[0] : 'N/A'}</TableCell>
                                {Object.keys(envCycle.promotions).map((key) => (
                                    <TableCell key={key}>{envCycle.promotions[key] ? envCycle.promotions[key]?.toString().split('T')[0] : 'N/A'}</TableCell>
                                ))}
                                {Object.keys(envCycle.Qualitycheckpoints).map((key) => (
                                    <TableCell key={key}>{envCycle.Qualitycheckpoints[key] ? envCycle.Qualitycheckpoints[key]?.toString().split('T')[0] : 'N/A'}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>

        {showAddPromotions && <CreatePromotions deploymentObj={buildCycleDetails} showModal={showAddPromotions} handleClose={() => { setAddPromotions(false); setreload((prevState) => ({ randomNumber: prevState.randomNumber + 1 })); }}></CreatePromotions>}
        {showEdit && <EditBRC deploymentObj={buildCycleDetails} showModal={showEdit} handleClose={() => { setShowEdit(false); console.log(reload.randomNumber); setreload((prevState) => ({ randomNumber: prevState.randomNumber + 1 })); }}></EditBRC>}
    </Container>

}