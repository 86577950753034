import { Autocomplete, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, MenuItem, Stack, TextareaAutosize, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { DeploymentsAPI } from "../../apis";
import ConfigsAPI from "../../apis/config";
import { Select, TextField } from "../../components/Wrapper";
import { Environment } from "../../interfaces/Common";
import { Deployment, DeploymentStatus, DeploymentType } from "../../interfaces/Deployments";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";
import "./Deployments.css";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../components/Wrapper/Inputs/InputFormGrid";

export const CreateDeploymentPage = (props: {
    handleClose: Function,
    showModal: boolean,
    // handleReload: Function
}) => {
    const [deploymentObj, setDeploymentObj] = useState<Deployment>({
        module: "",
        services: [],
        targetEnvironment: Environment.PREQA,
        tickets: [],
        deploymentType: DeploymentType.BUGBUILD,
        status: DeploymentStatus.Raised
    });

    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [moduleOptions, setModuleOptions] = useState<string[]>([]);
    const [servicesOptions, setServicesOptions] = useState<string[]>([]);
    const [filteredServicesOptions, setFilteredServicesOptions] = useState<string[]>([]);
    const [branchMaps, setBranchMaps] = useState<{ [key in Environment]?: string }>({});
    const [scrTargetMap, setSrcTargetMap] = useState<{ [key in Environment]?: Environment[] }>({});
    const [targetOptions, setTargetOptions] = useState<Environment[]>([]);
    const [srcOptions, setSrcOptions] = useState<Environment[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const dispatch = useAppDispatch();
    const { common } = actions;
    const removedEnvs: Environment[] = [Environment.QA_DEV];

    useEffect(() => {
        (async () => {
            const res = await ConfigsAPI.getModules();
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setModuleOptions(res.data);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if (deploymentObj.module) {
                const res = await ConfigsAPI.getModuleServices({ module: deploymentObj.module });
                if (res instanceof ApiError) {
                    dispatch(common.processApiError(res));
                    return;
                }

                setServicesOptions(res.data);
                setDeploymentObj({
                    ...deploymentObj,
                    services: []
                })
            } else {
                setServicesOptions([]);
            }
        })()
    }, [deploymentObj.module])

    useEffect(() => {
        if (searchText) {
            setFilteredServicesOptions(servicesOptions.filter(val => {
                return val.toLowerCase().includes(searchText.toLowerCase())
            }))
        } else {
            setFilteredServicesOptions(servicesOptions);
        }
    }, [servicesOptions, searchText])

    useEffect(() => {
        (async () => {
            const res = await ConfigsAPI.getBranchNames();
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setBranchMaps(res.data);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            const res = await ConfigsAPI.getSrcTargetMap();
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            const envs: Environment[] = Object.keys(res.data) as Environment[];
            setSrcTargetMap(res.data);
            setSrcOptions(envs.filter(val => !removedEnvs.includes(val)));
        })()
    }, [])

    useEffect(() => {
        if ([DeploymentType.BUGPROMOTION, DeploymentType.BUILDPROMOTION].indexOf(deploymentObj.deploymentType) === -1) {
            setTargetOptions(srcOptions.filter(val => !removedEnvs.includes(val)));
            return;
        }

        if (deploymentObj.sourceEnvironment) {
            const targetOptionsVal = scrTargetMap[deploymentObj.sourceEnvironment] || [];
            setTargetOptions(targetOptionsVal.filter(val => !removedEnvs.includes(val)));
        } else {
            setTargetOptions([])
        }
    }, [srcOptions, deploymentObj.sourceEnvironment, deploymentObj.deploymentType])

    useEffect(() => {
        if (deploymentObj.module && deploymentObj.deploymentType
            && deploymentObj.services.length > 0 && deploymentObj.targetEnvironment
            && deploymentObj.tickets.length > 0) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [deploymentObj])

    useEffect(() => {
        if (deploymentObj.targetEnvironment) {
            setDeploymentObj({
                ...deploymentObj,
                branchName: branchMaps[deploymentObj.targetEnvironment]
            })
        }
    }, [deploymentObj.targetEnvironment, branchMaps])



    const handleCreateConfirm = async () => {

        if (deploymentObj.deploymentType === DeploymentType.BUGBUILD || deploymentObj.deploymentType === DeploymentType.BUILD) {
            setDeploymentObj({
                ...deploymentObj,
                sourceEnvironment: undefined,
            });
        } else {
            setDeploymentObj({
                ...deploymentObj,
                branchName: undefined,
            });
        }

        dispatch(common.setShowLoader(true));
        const res = await DeploymentsAPI.createDeployment(deploymentObj);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setShowConfirm(false);
        setDeploymentObj({
            module: "",
            services: [],
            targetEnvironment: Environment.PREQA,
            tickets: [],
            deploymentType: DeploymentType.BUGBUILD,
            status: DeploymentStatus.Raised
        });
        dispatch(common.setSnackbar({ children: "Successfully raised a deployment request.", severity: "success" }));
        props.handleClose();
        dispatch(common.setShowLoader(false));
    }


    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (value === "") {
            // If the user selects an empty value, clear the source environment field
            setDeploymentObj({
                ...deploymentObj,
                [name]: name === "tickets" ? [] : "",
            });
        } else {

            setDeploymentObj({
                ...deploymentObj,
                [name]: name === "tickets" ? value.split(",") : value,
            });
        }
    };
    const handleClose = () => {
        props.handleClose()
    }

    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }

    return <ConfirmDialog
        yesText="Create"
        noText="Cancel"
        title="Create Deployment Request"
        open={props.showModal}
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        disableYes={!canSubmit}
    >
        <Container maxWidth={false} className="create-deployment-list">
            <Grid container justifyContent={"center"} rowGap={1} alignItems={"center"}>
                <InputFormGrid
                    formFields={[{
                        fieldName: "Module",
                        name: "module",
                        options: moduleOptions,
                        type: FormFieldType.CUSTOM_SELECT,
                        value: deploymentObj.module
                    }, {
                        fieldName: "Services",
                        name: "services",
                        options: filteredServicesOptions,
                        type: FormFieldType.CUSTOM_AUTOCOMPLETE,
                        searchText: searchText,
                        onSearchTextChange: (newVal: string) => setSearchText(newVal),
                        value: deploymentObj.services,
                        muiltiple: true
                    }, {
                        fieldName: "Build Type",
                        name: "deploymentType",
                        options: Object.values(DeploymentType),
                        type: FormFieldType.CUSTOM_SELECT,
                        value: deploymentObj.deploymentType
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
                {[DeploymentType.BUGBUILD, DeploymentType.BUILD].indexOf(deploymentObj.deploymentType) === -1 && <InputFormGrid
                    formFields={[{
                        fieldName: "Source Environment",
                        name: "sourceEnvironment",
                        options: srcOptions,
                        type: FormFieldType.CUSTOM_SELECT,
                        value: deploymentObj.sourceEnvironment
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>}
                <InputFormGrid
                    formFields={[{
                        fieldName: "Target Environment",
                        name: "targetEnvironment",
                        options: targetOptions,
                        type: FormFieldType.CUSTOM_SELECT,
                        value: deploymentObj.targetEnvironment
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
                {[DeploymentType.BUILDPROMOTION, DeploymentType.BUGPROMOTION].indexOf(deploymentObj.deploymentType) === -1 && <InputFormGrid
                    formFields={[{
                        fieldName: "Branch",
                        name: "branchName",
                        value: deploymentObj.branchName,
                        disabled: true
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>}
                <InputFormGrid
                    formFields={[{
                        fieldName: "Tickets",
                        name: "tickets",
                        value: deploymentObj.tickets.join(","),
                        type: FormFieldType.CUSTOM_TEXT_AREA
                    }]}
                    commonSx={{width: "200px"}}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
            </Grid>
            {!showConfirm ? <></> : <ConfirmDialog
                open={showConfirm}
                yesText="Yes"
                noText="No"
                title="Confirmation"
                handleNo={() => setShowConfirm(false)}
                handleYes={() => handleCreateConfirm()}
            >
                {`Are you sure, you want to create a deployment request for the service(s) ${deploymentObj.services}?`}
            </ConfirmDialog>}
        </Container>
    </ConfirmDialog>
}