import { AlertColor } from '@mui/material';
import type { PayloadAction, Slice } from "@reduxjs/toolkit";
import { createSlice } from '@reduxjs/toolkit';
import { Breadcrumb } from '../../interfaces/Common';
import { HeaderAction, RowAction } from '../../interfaces/Data';
import { SidebarItem } from '../../interfaces/Sidebar';
import { ComponentName, SubComponentName, TableComponents, commonSidebarItems, subComponentsMap } from '../../utils/constants';
import { ApiError } from '../../utils/errorHandler';
import { onlyUnique } from '../../utils/objectUtils';

export interface CommonState {
    isAuthenticated: boolean;
    authError?: string;
    auth: {
        unauthorizedError: boolean,
        refreshed: boolean
    },
    userData: {
        role: string[];
        userId: string;
        email: string,
        name: string
    };
    sidebarItems: SidebarItem[],
    breadCrumbs: Breadcrumb[];
    snackbar: { children: string, severity: AlertColor } | null;
    showModals: {
        loader: boolean
    },
    subComponents: {[K: string]: string[]},
    headerActions: {[K: string]: HeaderAction[]},
    rowActions: {[K: string]: RowAction[]},
    specialPermissions: string[]
};

export interface Pagination {
    totalPages: number;
    pageNumber: number;
    limit: number;
    totalResults: number;
    currentResults: number;
}

const getInitialState = (): CommonState => {
    const initialState: CommonState = {
        isAuthenticated: false,
        auth: {
            unauthorizedError: false,
            refreshed: false
        },    
        userData: {
            role: [],
            userId: "",
            email: "",
            name: ""
        },
        sidebarItems: [],
        breadCrumbs: [],
        snackbar: null,
        showModals: {
            loader: false
        },
        subComponents: {
            [ComponentName.Admin]: [],
            [ComponentName.Comparator]: [],
            [ComponentName.Scheduler]: [],
            [ComponentName.Configs]: [],
        },
        headerActions: {
            [TableComponents.Deployments]: [],
            [TableComponents.ScheduleRequests]: [],
            [TableComponents.ScheduleLambdas]: [],
            [TableComponents.Accounts]: [],
            [TableComponents.GitRepos]: [],
            [TableComponents.DBAccess]: [],
            [SubComponentName.DefaultSchedules]: [],
            [TableComponents.Holidays]: [],
            [SubComponentName.NSLProperties]: [],
            [SubComponentName.KafkaTopics]: [],
            [SubComponentName.TestsLogs]: [],
            [TableComponents.BuildCycle]: [],
        },
        rowActions: {
            [TableComponents.Deployments]: [],
            [TableComponents.ScheduleRequests]: [],
            [TableComponents.ScheduleLambdas]: [],
            [TableComponents.Accounts]: [],
            [TableComponents.GitRepos]: [],
            [TableComponents.DBAccess]: [],
            [SubComponentName.DefaultSchedules]: [],
            [SubComponentName.Branches]: [],
            [SubComponentName.Services]: [],
            [SubComponentName.KafkaEP]: [],
            [SubComponentName.TaggingPipelines]: [],
            [SubComponentName.EnvSourceTarget]: [],
            [SubComponentName.DatabaseSGs]: [],
            [TableComponents.Holidays]: [],
            [TableComponents.TestsLogs]: [],
            [TableComponents.BuildCycle]: [],
        },
        specialPermissions: []
    }

    return initialState;
}

const commonReducer = {
    setAuth(state: CommonState, action: PayloadAction<{
        unauthorizedError: boolean,
        refreshed: boolean,
    }>) {
        state.auth.refreshed = action.payload.refreshed;
        state.auth.unauthorizedError = action.payload.unauthorizedError;
    },
    setAccessData(state: CommonState, action: PayloadAction<{
        accessToken: string,
        userId: string,
        role: string[]
    }>) {
        localStorage.setItem("accessToken", action.payload.accessToken);
        localStorage.setItem("userId", action.payload.userId);
        localStorage.setItem("role", action.payload.role.toString());
        state.userData.userId = action.payload.userId;
        state.userData.role = action.payload.role;
        state.isAuthenticated = true;
    },
    setUserData(state: CommonState, action: PayloadAction<{
        name: string,
        email: string
    }>) {
        state.userData.name = action.payload.name;
        state.userData.email = action.payload.email;
    },
    processApiError: (state: CommonState, action: PayloadAction<ApiError>) => {
        state.snackbar = { children: action.payload.message, severity: "error" };
        if (action.payload.code === 401) {
            state.auth.unauthorizedError = true;
            state.auth.refreshed = false;
        }
    },
    setPermissions: (state: CommonState, action: PayloadAction<{ permissions: string[] }>) => {
        let componentNames: string[] = [];
        const permissions = action.payload.permissions;
        let items: SidebarItem[] = []
        for (let item of commonSidebarItems) {
            let subComponentsList = subComponentsMap[item.name as ComponentName];
            if (subComponentsList.length === 0 && permissions.includes(`${item.displayName}.Read`)) {
                items.push(item);
                componentNames.push(item.name);
            } else if (subComponentsList.length > 0) {
                let filteredSubcomponents = subComponentsList.filter(comp => {
                    return permissions.includes(`${comp}.Read`);
                })
                if (filteredSubcomponents.length > 0) {
                    items.push(item);
                    state.subComponents[item.name] = filteredSubcomponents;
                    componentNames = componentNames.concat(filteredSubcomponents);
                }
            }
        }
        

        const specialPermissions = permissions.filter(perm => {
            return perm.includes("All") || perm.includes("Trigger")
        });
        state.specialPermissions = specialPermissions;

        for (const name of componentNames) {
            let headerActions: HeaderAction[] = [HeaderAction.SELECT_ALL];
            let rowActions: RowAction[] = [RowAction.CHECKBOX];
            headerActions = headerActions.concat(Object.values(HeaderAction).filter(action => {
                let item = commonSidebarItems.find(obj => obj.name === name);
                if (item) {
                    return permissions.includes(`${item.displayName}.${action.split("All")[0]}`);
                }
                return permissions.includes(`${name}.${action.split("All")[0]}`);
            }));
            rowActions = rowActions.concat(Object.values(RowAction).filter(action => {
                let item = commonSidebarItems.find(obj => obj.name === name);
                if (item) {
                    return permissions.includes(`${item.displayName}.${action}`);
                }
                return permissions.includes(`${name}.${action}`);
            }));

            if (name in state.headerActions) {
                state.headerActions[name] = headerActions.filter(onlyUnique);
            }

            if (name in state.rowActions) {
                state.rowActions[name] = rowActions.filter(onlyUnique);
            }
        }

        state.sidebarItems = items;
    },
    setBreadCrumbs: (state: CommonState, action: PayloadAction<{ displayName: string, name: string, iconName?: string }[]>) => {
        state.breadCrumbs = action.payload;
        localStorage.setItem("breadcrumbs", JSON.stringify(action.payload));
    },
    setSnackbar: (state: CommonState, action: PayloadAction<{ children: string, severity: AlertColor } | null>) => {
        state.snackbar = action.payload;
    },
    setShowLoader: (state: CommonState, action: PayloadAction<boolean>) => {
        state.showModals.loader = action.payload;
    },
}

export const commonSlice: Slice<CommonState, typeof commonReducer> = createSlice({
    name: 'common',
    initialState: getInitialState(),
    reducers: commonReducer,
})