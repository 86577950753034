import { useState, useEffect } from "react";
import ConfigsAPI from "../../apis/config";
import { ApiError } from "../../utils/errorHandler";
import { Dropdown } from "./Dropdown";
import { AlertColor } from "@mui/material";
import { ROLE } from "../../interfaces/Common";
import { TestsLogsAPI, UserAPI } from "../../apis";
import DBAccessAPI from "../../apis/dbaccess";
import { useAppDispatch } from "../../store/hooks";
import { actions } from "../../store";

export type DataFilterProps = {
    name: string;
    label: string;
    value: string | string[] | undefined;
    placeholder?: string;
    id?: string;
    multiple?: boolean;
    onChange: (e: any) => void,
}

export const ModuleFilter = (props: DataFilterProps) => {
    const [moduleOptions, setModuleOptions] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await ConfigsAPI.getModules();
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setModuleOptions(res.data);
        })()
    }, [])

    return <Dropdown
        {...props}
        options={[...moduleOptions]}
    ></Dropdown>
}

export interface ServiceFilterProps extends DataFilterProps {
    module?: string | string[]
}

export const ServiceFilter = (props: ServiceFilterProps) => {
    const [servicesOptions, setServicesOptions] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            let modules = props.module;
            if (!modules) {
                modules = [];
            }
            const res = await ConfigsAPI.getModuleServices({ module: modules });
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setServicesOptions(res.data);
        })()
    }, [props.module])

    return <Dropdown
        {...props}
        options={[...servicesOptions]}
    ></Dropdown>
}

export interface UserFilterProps extends DataFilterProps {
    role?: ROLE | ROLE[],
    searchField?: string
}

export const UserFilter = (props: UserFilterProps) => {
    const [userOptions, setUserOptions] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await UserAPI.getUserFilter({
                role: props.role,
                searchText: searchText,
                searchField: props.searchField || "name"
            });
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setUserOptions(res.data);
        })()
    }, [props.role, searchText, props.searchField])

    const handleSearchTextChange = (e: any) => {
        const {name, value} = e.target;
        setSearchText(value);
    }

    return <Dropdown
        {...props}
        options={[...userOptions]}
        onSearchTextChange={handleSearchTextChange}
    ></Dropdown>
}

export interface LogsFilterProps extends DataFilterProps {
    searchField?: string
}

export const LogsFilter = (props: LogsFilterProps) => {
    const [filterOptions, setFilterOptions] = useState<string[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await TestsLogsAPI.getFilters({
                searchText: searchText,
                searchField: props.searchField || "buildId"
            });
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setFilterOptions(res.data);
        })()
    }, [searchText, props.searchField])

    const handleSearchTextChange = (e: any) => {
        const {name, value} = e.target;
        setSearchText(value);
    }

    return <Dropdown
        {...props}
        options={[...filterOptions]}
        onSearchTextChange={handleSearchTextChange}
    ></Dropdown>
}

export const EnvFilter = (props: DataFilterProps) => {
    const [envOptions, setenvOptions] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await DBAccessAPI.getenvNames();
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setenvOptions(res.data);
        })()
    }, [])

    return <Dropdown
        {...props}
        options={[...envOptions]}
    ></Dropdown>
}