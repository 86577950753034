
import axios from 'axios';
import { ApiError, AxiosErrorHandler } from '../utils/errorHandler';
import api from "./axiosInstance";
import urls from './urls';

export default class  AuthAPI {
    public static async accessCheck(payload: any): Promise<{
        accessToken: string,
        userId: string,
        role: string[]
    } | ApiError>{
        try {
            const accessToken = localStorage.getItem("accessToken");
            const response = await axios.post(urls.accessCheck, {}, {
                withCredentials: true,
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                },
            });
            const userId = response.data?.data?.userId;
            const role = response.data?.data?.role;
            if (userId && accessToken) {
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("userId", userId);
                localStorage.setItem("role", role.toString());
                return {
                    accessToken,
                    userId,
                    role: role
                }    
            } else {
                return new ApiError(500, "Error in fetching token");
            }
        } catch (error) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("role");
            return AxiosErrorHandler(error, "accessCheck");
        }
    }

    public static async MSLogin(payload: any): Promise<{
        accessToken: string,
        userId: string,
        role: string[]
    } | ApiError> {
        const body = {...payload}
        try {
            const response = await axios.post(urls.msLogin, body, { withCredentials: true });
            const accessToken = response.headers["authorization"];
            const userId = response.data?.data?.userId;
            const role = response.data?.data?.role;
            if (userId && accessToken) {
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("userId", userId);
                localStorage.setItem("role", role.toString());
                return {
                    accessToken,
                    userId,
                    role: role
                }    
            } else {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userId");
                return new ApiError(500, "Error in fetching token");
            }
        } catch (error) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userId");
            return AxiosErrorHandler(error, "MSLogin");
        }
    }

    public static async refresh() {
        const body = { userId: localStorage.getItem("userId") };
        try {
            const response = await axios.post(urls.refresh, body, {withCredentials: true});
            const accessToken = response.headers["authorization"];
            const userId = response.data?.data?.userId;
            if (userId && accessToken) {
                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("userId", userId);
                return {
                    accessToken,
                    userId
                }    
            } else {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("userId");
                return {
                    err: "Error in fetching accessToken"
                }
            }
        } catch (error) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userId");
            return AxiosErrorHandler(error, "refresh");
        }
    }

    public static async logout() {
        try {
            const response = await api.post(urls.logout,{});
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userId");
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error, "logout");
        }
    }
}