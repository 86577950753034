import { useMediaQuery, Dialog, DialogTitle, DialogContent, Tabs, Tab, Container, DialogActions, Button, Box, Typography } from "@mui/material";
import { useState } from "react";
import { ComparisonData } from "../../../interfaces/Comparator"

export const PropertiesDiff = ({
    comparisonData,
    diffPayload
}: {
    diffPayload: {
        env1: string,
        env2: string
    },
    comparisonData: ComparisonData
}) => {
    const [tab, setTab] = useState(0);
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const renderKeyValues = (sameValuesObj: { [k: string]: string }) => {
        const rows = Object.keys(sameValuesObj).map(key => {
            return <div className="simple-row">
                <div>{`${key}=${sameValuesObj[key]}`}</div>
            </div>
        })
        return <div className="show-area">
            {rows}
        </div>
    }

    const renderDiffValues = (diffValuesObj: { [k: string]: string[] }) => {
        const rows = Object.keys(diffValuesObj).map(key => {
            return <>
                <div className="simple-row">{`${key}=${diffValuesObj[key][0]}`}</div>
                <div className="diff-row">{`${key}=${diffValuesObj[key][1]}`}</div>
            </>
        })
        return <div className="show-area">
            {rows}
        </div>
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    function tabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        },
        '& .MuiDialogContent-root': {
            width: '100vw',
            margin: 0,
            padding: 0
        }
    }


    return <Container maxWidth={false} className="diff-container">
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                    <Tab label="Changed Properties" {...tabProps(0)} />
                    <Tab label="Same Properties" {...tabProps(1)} />
                    <Tab label={`Missing Properties`}  {...tabProps(2)} />
                    <Tab label={`New Properties`}  {...tabProps(3)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={tab} index={0}>
                <Container maxWidth={false}>{renderDiffValues(comparisonData.commonKeyDifferentValues)}</Container>
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={1}>
                <Container maxWidth={false}>{renderKeyValues(comparisonData.commonKeyValues)}</Container>
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={2}>
                <Container maxWidth={false}>{renderKeyValues(comparisonData.uniqueKeysFile1)}</Container>
            </CustomTabPanel>
            <CustomTabPanel value={tab} index={3}>
                <Container maxWidth={false}>{renderKeyValues(comparisonData.uniqueKeysFile2)}</Container>
            </CustomTabPanel>
        </Box>
    </Container>
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}