import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Sidebar } from '../../components';
import { SidebarItem } from '../../interfaces/Sidebar';
import { RootState, actions } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

const SidebarContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { common } = actions;
  const menuItems = useAppSelector((state: RootState) => state.common.sidebarItems);

  const handleClick = (e: React.MouseEvent<HTMLElement>, item: SidebarItem) => {
    dispatch(common.setBreadCrumbs([{name: item.name, displayName: item.displayName, iconName: item.iconName}]));
    navigate(`/dashboard/${item.name}`);
  }

  return <Sidebar
    items={menuItems}
    handleClick={handleClick}
  ></Sidebar>
}

export default SidebarContainer;