import { IconButton } from '@mui/material';
import Icon from '@mui/material/Icon';
import { CustomIconProps } from './interfaces';

const CustomIcon = (props: CustomIconProps) => {
    const baseClassMap = {
        "outlined": "material-icons-outlined",
        "filled": "material-icons-filled",
        "sharp": "material-icons-sharp",
        "two-tone": "material-icons-two-tone",
        "rounded": "material-icons-rounded",
    }
    const scaleMap: {[k: string]: number} = {
        "x-small": 0.7,
        "large": 1.3,
        "small": 1
    }
    const iconType = props.iconType || "outlined";
    const size = props.size || "small";
    if (props.iscustom === "false") {
        switch(props.containerType) {
            case "button":
                return (<button className='icon-button' >
                        <Icon sx={{padding: 0, margin: 0, "cursor": "pointer"}} {...props} style={{transform: `scale(${scaleMap[size]})`}}
                        baseClassName={baseClassMap[iconType]}>{props.name}</Icon>
                </button>);
            default:
                return (<Icon sx={{padding: 0, margin: 0, "cursor": "pointer"}} {...props} style={{transform: `scale(${scaleMap[size]})`}}
                        baseClassName={baseClassMap[iconType]}>{props.name}</Icon>);

        }
    } else {
        return (<span>Not Found</span>)
    }
}

export default CustomIcon;

