import { Container, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { DeploymentsAPI } from '../../apis';
import { TableWrapper } from '../../components/DataTables/TableWrapper';
import { Pagination, SortOrder } from '../../interfaces/Common';
import { HeaderAction, RowAction } from '../../interfaces/Data';
import { Deployment, DeploymentDetail, DeploymentFilters } from '../../interfaces/Deployments';
import { RootState, actions } from '../../store';
import { useAppDispatch, useAppSelector, useIsTabActive } from '../../store/hooks';
import { TableComponents } from '../../utils/constants';
import { getNewHeaderActions } from '../../utils/deploymentUtils';
import { ApiError } from '../../utils/errorHandler';
// import { ConfirmDialog } from './ConfirmDialog';
import { CreateDeploymentPage } from './CreateDeploymentPage';
import { DeploymentDetailsPanel } from './DeploymentDetailsPanel';
import "./Deployments.css";
import { DeploymentsFilters } from './DeploymentsFilters';
import { ConfirmDialog } from '../../components/Dialogs/ConfirmDialog';
import { CustomTextArea } from '../../components/Wrapper/Inputs/CutomTextField';
import { EditDeployment } from './EditDeployment';

export const Deployments = () => {
  const columns = [
    { field: '_id', displayName: 'ID', width: "50px", isSortField: true },
    { field: 'status', displayName: 'Status', width: "150px", isSortField: true },
    { field: 'module', displayName: 'Module', width: "100px", isSortField: true },
    { field: 'services', displayName: 'Services', width: "100px", isSortField: true },
    { field: 'deploymentType', displayName: 'Type', width: "100px", isSortField: true },
    { field: 'sourceEnvironment', displayName: 'Source', width: "100px", isSortField: true },
    { field: 'targetEnvironment', displayName: 'Target', width: "100px", isSortField: true },
    { field: 'branchName', displayName: 'Branch', width: "100px", isSortField: true },
    { field: 'tickets', displayName: 'Tickets', width: "100px" },
    { field: 'createdBy', displayName: 'Raised By', width: "100px", isSortField: true },
    { field: 'createdAt', displayName: 'Raised On', width: "100px", isSortField: true }
  ];

  // Redux States
  const defaultHeaderActions = useAppSelector((state: RootState) => state.common.headerActions[TableComponents.Deployments]);
  const rowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.Deployments]);
  const rows: DeploymentDetail[] = useAppSelector((state: RootState) => state.deployments.deployments);
  const selections: { [k: string]: boolean } = useAppSelector((state: RootState) => state.deployments.selections);
  const filters: DeploymentFilters = useAppSelector((state: RootState) => state.deployments.filters);
  const pagination: Pagination = useAppSelector((state: RootState) => state.deployments.pagination);
  const reload: {randomNumber: number} = useAppSelector((state: RootState) => state.deployments.reload);
// Component States
  const [showPane, setShowPane] = useState<boolean>(false);
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  const [actionClicked, setActionClicked] = useState<RowAction | HeaderAction | null>(null);
  const [rowClicked, setRowClicked] = useState<Deployment | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [headerActions, setHeaderActions] = useState<HeaderAction[]>([]);
  const [comment, setComment] = useState<string>("");
  const isTabVisible = useIsTabActive();
  const dispatch = useAppDispatch();
  const { deployments, common } = actions;

  useEffect(() => {
    if (defaultHeaderActions.length === 0) {
      return;
    }

    const ids = Object.keys(selections);
    if (ids.length === 0) {
      setHeaderActions(defaultHeaderActions);
      return;
    }
    
    const selectedStatuses = rows.filter(row => ids.indexOf(row._id.toString()) > -1).map(row => row.status);    
    const newHeaderActions: HeaderAction[] = getNewHeaderActions(selectedStatuses, defaultHeaderActions);
    setHeaderActions(newHeaderActions);
  }, [selections, defaultHeaderActions]);

  useEffect(() => {
    (async () => {
      if (!isTabVisible) {
        return;
      }
      const res =  await DeploymentsAPI.getDeployments({
        limit: pagination.limit,
        pageNumber: pagination.pageNumber
      }, {
        ...filters
      });

      if (res instanceof ApiError) {
        return;
      }
      if (res && res.data) {
        dispatch(deployments.setDeployments(res.data.results));
        dispatch(deployments.setResultCounts({
          totalPages: res.data.totalPages,
          totalResults: res.data.totalResults,
          currentResults: res.data.currentResults
        }))
      }
    })()
  }, [pagination.limit, pagination.pageNumber, filters, reload.randomNumber, isTabVisible]);

  const handleConfirm = async () => {
    dispatch(common.setShowLoader(true));
    let res;
    switch (actionClicked) {
      case HeaderAction.APPROVE:
        if (Object.keys(selections).length > 0) {
          res = await DeploymentsAPI.bulkApprove({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.APPROVE:
        if (rowClicked?._id) {
          res = await DeploymentsAPI.bulkApprove({ ids: [`${rowClicked?._id}`] });
        }
        break;
      case HeaderAction.REVIEW:
        if (Object.keys(selections).length > 0) {
          res = await DeploymentsAPI.bulkReview({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.REVIEW:
        if (rowClicked?._id) {
          res = await DeploymentsAPI.bulkReview({ ids: [`${rowClicked?._id}`] });
        }
        break;
      case HeaderAction.REJECT:
        if (Object.keys(selections).length > 0) {
          res = await DeploymentsAPI.bulkReject({ ids: Object.keys(selections), comment: comment });
        }
        break;
      case RowAction.REJECT:
        if (rowClicked?._id) {
          res = await DeploymentsAPI.bulkReject({ ids: [`${rowClicked?._id}`] , comment: comment});
        }
        break;
      case RowAction.REBUILD:
        if (rowClicked?._id) {
          res = await DeploymentsAPI.bulkRebuild({ ids: [`${rowClicked?._id}`] , comment: comment});
        }
        break;  
      case HeaderAction.REBUILD:
          if (Object.keys(selections).length > 0) {
            console.log("comment is", comment)
            res = await DeploymentsAPI.bulkRebuild({ ids: Object.keys(selections), comment: comment });
          }
          break;  
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          res = await DeploymentsAPI.bulkRemove({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.DELETE_ROW:
        if (rowClicked) {
          res = await DeploymentsAPI.bulkRemove({ ids: [`${rowClicked?._id}`]});
        }
        break;
      case HeaderAction.DOWNLOAD_SELECTED:
        let filtersObj: any = {...filters}
        if (Object.keys(selections).length > 0) {
          filtersObj._id = Object.keys(selections);
        }

        res = await DeploymentsAPI.downloadDeployments(filtersObj);
        if (res instanceof ApiError) {
          dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
        } else {
          const href = URL.createObjectURL(res);
          const link = document.createElement('a');
          link.href = href;
          link.setAttribute('download', 'Depoyments.xlsx');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        }

        break;
    }
    if (res instanceof ApiError) {
      dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
    }

    setActionClicked(null);
    setRowClicked(null);
    dispatch(common.setShowLoader(false));
    dispatch(deployments.reloadData());
    setShowConfirm(false);
  }

  const handleSort = (field: string, order: SortOrder) => {
    dispatch(deployments.setFilters({...filters, sortBy: field, sortOrder: order}));
  }

  const handleHeaderAction = async (action: HeaderAction) => {
    setActionClicked(action);
    switch (action) {
      case HeaderAction.APPROVE:
      case HeaderAction.REVIEW:
      case HeaderAction.REJECT:
      case HeaderAction.MULTI_DELETE:
      case HeaderAction.REBUILD:
        if (Object.keys(selections).length > 0) {
          setShowConfirm(true)
        }
        break;
      case HeaderAction.ADD_NEW:
        // setRenderNewRow(true);
        setShowCreateForm(true);
        break;
      case HeaderAction.SELECT_ALL:
        dispatch(deployments.selectAll())
        break;
      case HeaderAction.UNSELECT:
        dispatch(deployments.unselectAll())
        break;
      case HeaderAction.DOWNLOAD_SELECTED:
          setShowConfirm(true);
          break;
      default:
        console.log(action);

    }
  }

  const handleRowAction = async (action: RowAction, row: DeploymentDetail) => {
    setActionClicked(action);
    switch (action) {
      case RowAction.DELETE_ROW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.APPROVE:
          setShowConfirm(true);
          setRowClicked(row);
          break;
      case RowAction.REJECT:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.REVIEW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.REBUILD:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.CHECKBOX:
        if (row._id && selections[row._id]) {
          dispatch(deployments.unselectRow({ _id: row._id }));
        } else if (row._id) {
          dispatch(deployments.selectRow({ _id: row._id }));
        }
        break;
      case RowAction.EDIT_ROW:
        setShowEditForm(true);
        setRowClicked(row);
        break;
      default:
        console.log(action);
    }
  }

  const handleRowClick = (row: Deployment) => {
    if (row._id && selections[row._id]) {
      dispatch(deployments.unselectRow({_id: row._id}));
    } else if (row._id) {
      dispatch(deployments.selectRow({_id: row._id}));
    }
  }

  const handleIdClick = (row: Deployment) => {
    setShowPane(true);
    setRowClicked(row);
  }

  const handleRowDoubleClick = (row: Deployment) => {
    const path = `${window.location}/${row._id}`;
    const a = document.createElement("a");
    a.href = path;
    a.target = "_blank";
    a.click();
    a.remove();
  }

  const getDialogText = () => {
    let str = "action";
    switch(actionClicked) {
      case RowAction.APPROVE:
      case HeaderAction.APPROVE:
        str = "Approve";
        break;
      case RowAction.REVIEW:
      case HeaderAction.REVIEW:
        str = "Review";
        break;
      case RowAction.REJECT:
      case HeaderAction.REJECT:
        str = "Reject";
        break;
      case RowAction.DELETE_ROW:
      case HeaderAction.MULTI_DELETE:
        str = "Delete";
        break;
    }
    if (rowClicked) {
      return `Are you sure, you want to ${str} deployment ${rowClicked._id} ?`
    } else {
      return `Are you sure, you want to ${str} deployments ${Object.keys(selections).join(", ")} ?`
    }
  }

  return (
    <Container maxWidth={false} className='table-view'>
      <DeploymentsFilters></DeploymentsFilters>
      <TableWrapper<DeploymentDetail>
          componentName={TableComponents.Deployments}
          columns={columns}
          rows={rows}
          selections={selections}
          headerActions={headerActions}
          handleHeaderAction={handleHeaderAction}
          handleLimitChange={(limit: number) => dispatch(deployments.setPagination({ pageNumber: pagination.pageNumber, limit: limit}))}
          handlePageChange={(pageNumber: number) => dispatch(deployments.setPagination({ limit: pagination.limit, pageNumber: pageNumber}))}
          pagination={pagination}
          rowActions={rowActions}
          handleRowAction={handleRowAction}
          rowClickFn={handleRowClick}
          rowDbClickFn={handleRowDoubleClick}
          idClickFn={handleIdClick}
          isStatusActions={true}
          handleSort={handleSort}
          sorting={{field: filters.sortBy, order: filters.sortOrder }}
      ></TableWrapper>
      <ConfirmDialog
        open={showConfirm}
        handleNo={() => {
          setRowClicked(null);
          setShowConfirm(false);
        }}
        handleYes={handleConfirm}
        title='Deployments Actions Confirmation'
      >
        <div>
          <span>{getDialogText()}</span>
          {actionClicked === HeaderAction.REJECT && <div style={{padding: "0 20px"}}>
                <CustomTextArea
                    autoFocus
                    id="comment"
                    fieldName="Comments"
                    name="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />    
            </div>}
        </div>
      </ConfirmDialog>
        {rowClicked && showPane && <DeploymentDetailsPanel
          deployment={rowClicked}
          handleClose={() => {
            setRowClicked(null);
            setShowPane(false);
          }}
        ></DeploymentDetailsPanel>}
      {showCreateForm && <CreateDeploymentPage showModal={showCreateForm} handleClose={() => setShowCreateForm(false)}></CreateDeploymentPage>}
      {showEditForm && rowClicked && <EditDeployment deploymentObj={rowClicked} showModal={showEditForm} handleClose={() => {
        setRowClicked(null);
        setShowEditForm(false)
      }}></EditDeployment>}
    </Container>
  );
}
