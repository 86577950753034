import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination } from '../../interfaces/Common';
import { Holiday, HolidayFilters } from '../../interfaces/Scheduler';

export interface HolidaysState {
    holidays: Holiday[],
    pagination: Pagination,
    filters: HolidayFilters,
    selections: {[k:string]: boolean},
    reload: {
        randomNumber: number
    }
};


const getInitialState = (): HolidaysState => {
    const initialState: HolidaysState = {
        holidays: [],
        pagination: {
            pageNumber: 1,
            limit: 10,
            currentResults: 0,
            totalPages: 0,
            totalResults: 0        
        },
        filters: {},
        selections: {},
        reload: {
            randomNumber: 0
        }
    }

    return initialState;
}

const holidaysReducer = {
    setHolidays: (state: HolidaysState,
        action: PayloadAction<Holiday[]>) => {
            state.holidays = action.payload;
    },
    setResultCounts: (state: HolidaysState, action: PayloadAction<{
        totalPages: number,
        currentResults: number,
        totalResults: number
    }>) => {
        state.pagination.currentResults = action.payload.currentResults;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalResults = action.payload.totalResults;
    },
    setPagination: (state: HolidaysState, action: PayloadAction<{limit: number, pageNumber: number}>) => {
        state.pagination.pageNumber = action.payload.pageNumber;
        state.pagination.limit = action.payload.limit;
    },
    reloadData: (state: HolidaysState) => {
        state.reload.randomNumber = Math.random();
    },
    setFilters: (state: HolidaysState, action: PayloadAction<HolidayFilters>) => {
        state.filters = action.payload;
    },
    selectRow: (state: HolidaysState, action: PayloadAction<{_id: string}>) => {
        state.selections[action.payload._id] = true;
    },
    setSelections: (state: HolidaysState, action: PayloadAction<{[k:string]: boolean}>) => {
        state.selections = action.payload;
    },
    unselectRow: (state: HolidaysState, action: PayloadAction<{_id: string}>) => {
        delete state.selections[action.payload._id];
    },
    selectAll: (state: HolidaysState) => {
        for (const row of state.holidays) {
            if (row._id) {
                state.selections[row._id] = true;
            }
        }
    },
    unselectAll: (state: HolidaysState) => {
        state.selections = {}
    },
}

export const holidaysSlice: Slice<HolidaysState, typeof holidaysReducer> = createSlice({
    name: 'holidays',
    initialState: getInitialState(),
    reducers: holidaysReducer,
})