import { Add, Remove, Save, SettingsBackupRestore } from '@mui/icons-material';
import { Autocomplete, Box, Button, Chip, Container, Dialog, DialogActions, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { AdminAPI } from '../../apis';
import { TextField } from '../../components/Wrapper';
import { actions } from '../../store';
import { useAppDispatch } from '../../store/hooks';
import { ApiError } from '../../utils/errorHandler';
import "./Admin.css";


export const Permissions = () => {
    const [permissionsMap, setPermissionsMap] = useState<{[k: string]: string[]}>({});
    const [allPermissionsMap, setAllPermissionsMap] = useState<{[k: string]: string[]}>({});
    const [reset, setReset] = useState<number>(0);
    const [showSave, setShowSave] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
      dispatch(common.setShowLoader(true));
      (async () => {
        const res = await AdminAPI.getPermissions({});
        if (res instanceof ApiError) {
          dispatch(common.processApiError(res));
          return;
        }
        let permissionsRes = res.data.sort();
        setPermissionsMap(getPermissionsMap(permissionsRes));
        setAllPermissionsMap(getPermissionsMap(permissionsRes));
      })()
      dispatch(common.setShowLoader(false));
    }, [reset])

    

    const handleCateggoryAdd = (category: string) => {
        setPermissionsMap({
          [category]: [],
          ...permissionsMap
        });
        setShowSave(true);      
    }

    const handleCategoryDelete = (key: string) => {
      let permissionsMapCopy = {...permissionsMap};
      delete permissionsMapCopy[key];
      setPermissionsMap(permissionsMapCopy);
      setShowSave(true);
    }

    const handlePermissionAdd = (key: string, permission: string) => {
        let permissionsMapCopy = {...permissionsMap};
        let actions = [...permissionsMapCopy[key]];
        actions.push(permission);
        permissionsMapCopy[key] = actions;
        setPermissionsMap(permissionsMapCopy);
        setShowSave(true);
    }

    const handlePermissionDelete = (key: string, permission: string) => {
      let permissionsMapCopy = {...permissionsMap};
      let actions = [...permissionsMapCopy[key]];
      let index = actions.indexOf(permission);
      if (index > -1) {
        actions.splice(index, 1);
      }
      permissionsMapCopy[key] = actions;
      setPermissionsMap(permissionsMapCopy);
      setShowSave(true);
    }

    const handleSaveClick = () => {
      setShowConfirm(true);
    }


    const handleConfirm = async () => {
      dispatch(common.setShowLoader(true));
      const payload = getPermissionsPayload(permissionsMap);

      const res = await AdminAPI.updatePermissions({...payload});

      if (res instanceof ApiError) {
        dispatch(common.processApiError(res));
        return;
        dispatch(common.setShowLoader(false));
      }

      dispatch(common.setSnackbar({children: res.message, severity: "success"}));
      setShowConfirm(false);
      setShowSave(false);
      dispatch(common.setShowLoader(false));
    }

    const handleReset = () => {
      setReset(Math.random());
    }

    const renderPermissions = () => {
      if (permissionsMap) {
        let permissions: any[] = [];
        for (let key of Object.keys(permissionsMap)) {
          permissions.push(<CategoryItem
            category={key}
            permissions={permissionsMap[key]}
            allPermissions={allPermissionsMap[key] || []}
            handleDeletePermission={handlePermissionDelete}
            handleDeleteCategory={handleCategoryDelete}
            handleAddPermission={handlePermissionAdd}
          ></CategoryItem>)
        }
        return permissions;
      } else {
        return []
      }
    }

    const renderCategoriesDropdown = () => {
      let categories = Object.keys(permissionsMap);
      let allCategories = Object.keys(allPermissionsMap);
      let options = allCategories.filter(category => categories.indexOf(category) === -1);
      return <Box className='category-container' sx={{justifyContent: "flex-start!important"}}>
        <Box className='category' sx={{borderRight: "none!important"}}>
          <Autocomplete
              openOnFocus
              options={options}
              disableCloseOnSelect
              renderTags={() => <></>}
              getOptionLabel={(option: string) => {
                return option;
              }}
              // value={props.value}
              onChange={(event: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
                if (newValue) {
                  handleCateggoryAdd(newValue);
                }
              }}
              style={{ width: 150 }}
              renderInput={(params) => <TextField variant='outlined' onKeyDown={(e: any) => {
                if (e.key === "Enter" && e.target.value) {
                  handleCateggoryAdd(e.target.value);
                }
              }} {...params}
              sx={{'& .MuiAutocomplete-input': {
                minWidth: '120px!important'
              }}}/>}
            />
        </Box>
      </Box>
    }

    return (<Container maxWidth={false} className='roles-container'>
      <Container className='data-container'>
        <Box className='heading-container'>
          <Box className='buttons-wrapper'>
            <IconButton onClick={handleSaveClick} disabled={!showSave}><Save color={showSave ? "primary": "disabled"}></Save></IconButton>
            <IconButton onClick={handleReset}><SettingsBackupRestore color="primary"></SettingsBackupRestore>
            </IconButton>
          </Box>
        </Box>
        <Box className='perms-container'>
          {renderCategoriesDropdown()}
          <Table>        
            <TableHead>
              <TableRow>
                <TableCell sx={{textAlign: "center", borderRight: "1px solid rgba(224, 224, 224, 1)"}}>Category</TableCell>
                <TableCell>Permissions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderPermissions()}
            </TableBody>
          </Table>
        </Box>
      </Container>
      <Dialog
          maxWidth="xs"
          open={!!(showConfirm)}
      >
          <DialogTitle>{`Are you sure, you want to update the permissions list?`}</DialogTitle>
          <DialogActions>
              <Button onClick={() => setShowConfirm(false)}>
                  No
              </Button>
              <Button onClick={handleConfirm}>Yes</Button>
          </DialogActions>
      </Dialog>
  </Container>
  );
}

export const CategoryItem = (props: {
  category: string,
  permissions: string[],
  allPermissions: string[],
  handleDeletePermission: (category: string, permission: string) => void,
  handleDeleteCategory: (category: string) => void,
  handleAddPermission: (category: string, permission: string) => void,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (props.permissions.length === 0) {
      setOptions(props.allPermissions);
      return;
    }

    const permissionsLeft = props.allPermissions.filter(permission => {
      return props.permissions.indexOf(permission) === -1
    });
    setOptions(permissionsLeft);
  }, [props.allPermissions, props.permissions])

  return <TableRow>
    <TableCell sx={{textAlign: "center", borderRight: "1px solid rgba(224, 224, 224, 1)"}}>
      <Chip
        onDelete={() => props.handleDeleteCategory(props.category)}
        color='primary'
        label={props.category}
      ></Chip>
    </TableCell>
    <TableCell>
        {props.permissions.map((perm, index) => {
          return <Chip
            sx={{margin: "2px"}}
            label={perm}
            key={index}
            onDelete={() => props.handleDeletePermission(props.category, perm)}
          ></Chip>
        })}
        {showDropdown ? 
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2px"}}>
            <Autocomplete
              openOnFocus
              options={options}
              disableCloseOnSelect
              renderTags={() => <></>}
              getOptionLabel={(option: string) => {
                return option;
              }}
              // value={props.value}
              onChange={(event: React.SyntheticEvent<Element, Event>, newValue: string | null) => {
              if (newValue) {
                  props.handleAddPermission(props.category, newValue);
                }
              }}
              style={{ width: 150 }}
              renderInput={(params) => <TextField onKeyDown={(e: any) => {
                if (e.key === "Enter" && e.target.value) {
                  props.handleAddPermission(props.category, e.target.value);
                }
              }}
              {...params}
              sx={{'& .MuiAutocomplete-input': {
                minWidth: '120px!important'
              }}}/>}
            />
            <IconButton onClick={() => setShowDropdown(false)}><Remove></Remove></IconButton>
          </Box> : <IconButton onClick={() => setShowDropdown(true)}><Add></Add></IconButton>}
    </TableCell>
  </TableRow>
}

export const getPermissionsPayload = (permissionsMap: { [k: string]: string[] }) => {
  const payload: {permissions: string[]} = {
    permissions: []
  }

  for (let category of Object.keys(permissionsMap)) {
    let permissions = permissionsMap[category];
    if (permissions.length === 0) {
      payload.permissions.push(`${category}`);
      continue;
    }
    for (let perm of permissions) {
      if (!perm) {
        continue;
      }
      payload.permissions.push(`${category}.${perm}`);
    }
  }
  return payload;
}

export const getPermissionsMap = (permissions: string[]) => {
  const maps: {[k: string]: string[]} = {};
  for (let permission of permissions) {
    let splits = permission.split(".");
    let action = splits.pop();
    if (splits.length === 0) {
      maps[permission] = [permission];
      continue;
    }

    let category = splits.join(".");
    if (!maps[category]) {
      maps[category] = []
    }
    if (action) {
      maps[category].push(action);
    }
  }
  return maps;
}

