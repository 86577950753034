import { Container, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../components/Wrapper/Inputs/InputFormGrid";
import { newCycle } from "../../interfaces/Buildcycle";
import BuildCycleAPI from "../../apis/buildcycle";
import "./BuildCycle.css";

export const CreateBuildCyclePage = (props: {
    handleClose: Function,
    showModal: boolean,
    // handleReload: Function
}) => {
    const [buildCycleObj, setbuildCycleObj] = useState<newCycle>({
        cyclename: "",
        promotionNumber: 1,
        qualityCheckpointNumber: 1,

    });

    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const dispatch = useAppDispatch();
    const { common } = actions;


    useEffect(() => {
        if ((buildCycleObj.cyclename && buildCycleObj.promotionNumber && buildCycleObj.qualityCheckpointNumber)) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [buildCycleObj])


    const handleCreateConfirm = async () => {

        dispatch(common.setShowLoader(true));
        const res = await BuildCycleAPI.createBuildCycle(buildCycleObj);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setShowConfirm(false);
        dispatch(common.setSnackbar({ children: "Successfully Created a BRC", severity: "success" }));
        props.handleClose();
        dispatch(common.setShowLoader(false));
    }


    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setbuildCycleObj({
            ...buildCycleObj,
            [name]: value,
        });
    };

    const handleClose = () => {
        props.handleClose()
    }

    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }

    return <ConfirmDialog
        yesText="Create"
        noText="Cancel"
        title="Create Build Release Cycle"
        open={props.showModal}
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        disableYes={!canSubmit}
    >
        <Container maxWidth={false} className="create-deployment-list">
            <Grid container justifyContent={"center"} rowGap={1} alignItems={"center"}>
                <InputFormGrid
                    formFields={[{
                        fieldName: "Release Cycle Name",
                        name: "cyclename",
                        value: buildCycleObj.cyclename
                    }, {
                        fieldName: "Number of Promotions",
                        name: "promotionNumber",
                        value: buildCycleObj.promotionNumber
                    }, {
                        fieldName: "Number of CheckPoints",
                        name: "qualityCheckpointNumber",
                        value: buildCycleObj.qualityCheckpointNumber
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
            </Grid>
            {!showConfirm ? <></> : <ConfirmDialog
                open={showConfirm}
                yesText="Yes"
                noText="No"
                title="Confirmation"
                handleNo={() => setShowConfirm(false)}
                handleYes={() => handleCreateConfirm()}
            >
                {`Are you sure, you want to create ${buildCycleObj.cyclename}?`}
            </ConfirmDialog>}
        </Container>
    </ConfirmDialog>
}