import urls from "./urls";
import api from "./axiosInstance";
import { ApiDataRes, ApiMsgRes, Environment, ListApiRes } from "../interfaces/Common";
import { ConfigObj, Deployment, DeploymentDetail } from "../interfaces/Deployments";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import { AxiosResponse } from "axios";

export default class DeploymentsAPI {
    public static async getDeployments(pagination: {
        limit: number,
        pageNumber: number
    }, filters: any): Promise<ListApiRes<DeploymentDetail> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    if (Array.isArray(filters[key])) {
                        filtersObj[key] = filters[key].filter((filter: any) => filter !== ""); 
                    } else {
                        filtersObj[key] = filters[key]
                    }
                }
            }
            const response: AxiosResponse<ListApiRes<DeploymentDetail>> = await api.get(urls.deployments, {
                params: {
                    ...pagination,
                    ...filtersObj
                }
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async downloadDeployments(filters: any): Promise<Blob | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    if (Array.isArray(filters[key])) {
                        filtersObj[key] = filters[key].filter((filter: any) => filter !== ""); 
                    } else {
                        filtersObj[key] = filters[key]
                    }
                }
            }
            const response: AxiosResponse<Blob> = await api.get(urls.downloadDeployments, {
                params: {
                    ...filtersObj
                },
                responseType: 'blob'
            });
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async getDeploymentDetail(id: string): Promise<ApiDataRes<DeploymentDetail> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<DeploymentDetail>> = await api.get(`${urls.deployments}/${id}`);
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async createDeployment(payload: Deployment): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(urls.deployments, {
                ...payload
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async updateDeployment(payload: Deployment): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.deployments}/${payload._id}`, {
                sourceEnvironment: payload.sourceEnvironment,
                targetEnvironment: payload.targetEnvironment,
                tickets: payload.tickets,
                branchName: payload.branchName
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async deleteDeployment(payload: Deployment): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.delete(`${urls.deployments}/${payload._id}`);
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkApprove(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.deployments}/status/approve`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async bulkReview(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.deployments}/status/review`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async bulkReject(payload: {ids: string[],comment: string}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.deployments}/status/reject`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkRebuild(payload: {ids: string[],comment: string}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.deployments}/status/rebuild`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkRemove(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.deployments}/status/remove`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }
}