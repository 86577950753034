import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination } from '../../interfaces/Common';
import { ScheduleDetail, SchedulerFilters } from '../../interfaces/Scheduler';

export interface SchedulerState {
    schedules: ScheduleDetail[],
    pagination: Pagination,
    filters: SchedulerFilters,
    selections: {[k:string]: boolean},
    reload: {
        randomNumber: number
    },
    reloadDefault: {
        randomNumber: number
    },
    reloadStartStop: {
        randomNumber: number
    }
};


const getInitialState = (): SchedulerState => {
    const initialState: SchedulerState = {
        schedules: [],
        pagination: {
            pageNumber: 1,
            limit: 10,
            currentResults: 0,
            totalPages: 0,
            totalResults: 0        
        },
        filters: {},
        selections: {},
        reload: {
            randomNumber: 0
        },
        reloadDefault: {
            randomNumber: 0
        },
        reloadStartStop: {
            randomNumber: 0
        }
    }

    return initialState;
}

const schedulerReducer = {
    setSchedules: (state: SchedulerState,
        action: PayloadAction<ScheduleDetail[]>) => {
            state.schedules = action.payload;
    },
    setResultCounts: (state: SchedulerState, action: PayloadAction<{
        totalPages: number,
        currentResults: number,
        totalResults: number
    }>) => {
        state.pagination.currentResults = action.payload.currentResults;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalResults = action.payload.totalResults;
    },
    setPagination: (state: SchedulerState, action: PayloadAction<{limit: number, pageNumber: number}>) => {
        state.pagination.pageNumber = action.payload.pageNumber;
        state.pagination.limit = action.payload.limit;
    },
    reloadData: (state: SchedulerState) => {
        state.reload.randomNumber = Math.random();
    },
    reloadDefaultScheduleData: (state: SchedulerState) => {
        state.reloadDefault.randomNumber = Math.random();
    },
    reloadStartStopData: (state: SchedulerState) => {
        state.reloadStartStop.randomNumber = Math.random();
    },
    setFilters: (state: SchedulerState, action: PayloadAction<SchedulerFilters>) => {
        state.filters = action.payload;
    },
    selectRow: (state: SchedulerState, action: PayloadAction<{_id: string}>) => {
        state.selections[action.payload._id] = true;
    },
    setSelections: (state: SchedulerState, action: PayloadAction<{[k:string]: boolean}>) => {
        state.selections = action.payload;
    },
    unselectRow: (state: SchedulerState, action: PayloadAction<{_id: string}>) => {
        delete state.selections[action.payload._id];
    },
    selectAll: (state: SchedulerState) => {
        for (const row of state.schedules) {
            if (row._id) {
                state.selections[row._id] = true;
            }
        }
    },
    unselectAll: (state: SchedulerState) => {
        state.selections = {}
    },
}

export const schedulerSlice: Slice<SchedulerState, typeof schedulerReducer> = createSlice({
    name: 'scheduler',
    initialState: getInitialState(),
    reducers: schedulerReducer,
})