import { Container, useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NotificationsMenu from "../Notifications/NotificationsContainer";
import AccountMenu from "../Profile/ProfileContainer";
import "./MainContainer";

export const HeaderBar = () => {
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const navigate = useNavigate();
    return <Container maxWidth={false} className="header-bar-container">
        <img onClick={() => {
            if (!isLargeScreen) {
                navigate("/dashboard/home");
            }
        }} style={{width: '200px', height: '40px', objectFit: 'cover'}} src={require("../../assets/logos/brane-logo.png")}></img>
        <div className="title-wrapper">Devops Web Application</div>
        <div className="icon-section">
            <div className="icon-wrapper">
                <NotificationsMenu></NotificationsMenu>
            </div>
            <div className="icon-wrapper">
                <AccountMenu></AccountMenu>
            </div>
        </div>
    </Container>
}