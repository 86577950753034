import { Container, Stack } from "@mui/material";
import { Dropdown } from "../../../components/Filters/Dropdown";
import { Environment } from "../../../interfaces/Common";
import { RunStatus, HolidayFilters } from "../../../interfaces/Scheduler";
import { RootState, actions } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import "./Holidays.css";


export const HolidaysFilterGrid = () => {
    const filters: HolidayFilters = useAppSelector((state: RootState) => state.holidays.filters);
    const dispatch = useAppDispatch();
    const { holidays } = actions;

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        dispatch(holidays.setFilters({
            ...filters,
            [name]: ""
        }))
    };

    return <Container maxWidth={false} className="filters-container">
        <Stack direction="row" spacing={2}>
            <Dropdown
                key={"env"}
                id="env-simple-select"
                multiple
                value={filters.name}
                label="Name"
                name="name"
                onChange={handleInputChange}
                options={Object.values(Environment)}
            ></Dropdown>
        </Stack>
    </Container>
}
