import { Container, Stack, FormControl, Button, Box, IconButton, AlertProps, Alert, Snackbar, Dialog, DialogActions, DialogTitle, Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { UserAPI } from "../../apis";
import { ApiError } from "../../utils/errorHandler";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataObjectSharp, EditSharp, ListAlt } from "@mui/icons-material";
import ConfigsAPI from "../../apis/config";
import { ModuleName } from "../../interfaces/Common";
import "./Configs.css";
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { SubComponentName } from "../../utils/constants";
import { RowAction } from "../../interfaces/Data";

export const TaggingPipelineMap = () => {
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const permissions = useAppSelector((state: RootState) => state.common.rowActions[SubComponentName.TaggingPipelines]);
    const [taggingPipelineMap, setTaggingPipelineMap] = useState<{[key in ModuleName]?: string[]}>({});
    const [editString, setEditString] = useState<string>("");
    const columns: GridColDef[] = [
        { field: 'module', headerName: 'ModuleName', minWidth: 200 },
        {
            field: 'pipelines',
            headerName: "Pipelines",
            minWidth: 200,
            editable: false
        },
    ];
    const [viewMode, setViewMode] = useState<string>("Json");
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSave, setCanSave] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            dispatch(common.setShowLoader(true));
            const res = await ConfigsAPI.getTaggingPipelines();
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                dispatch(common.setShowLoader(false));
                return;
            }
            setTaggingPipelineMap(res.data);
            setEditString(JSON.stringify(res.data, null, 2));
            dispatch(common.setShowLoader(false));
        })()
    }, []);

    useEffect(() => {
        setCanEdit(permissions.includes(RowAction.EDIT_ROW));
    }, [permissions])

    useEffect(() => {
        try {
            let json = JSON.parse(editString);
            setCanSave(true);
        } catch (error) {
            setCanSave(false);
        }
    }, [editString]);

    

    const handleSave = async () => {
        const val = JSON.parse(editString);
        let res = await ConfigsAPI.updateTaggingPipelines(val);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setTaggingPipelineMap(val);
        dispatch(common.setSnackbar({children: res.message, severity: "success"}));
        setViewMode("Json");
    }
    
    const renderTable = () => {
        const rows = Object.keys(taggingPipelineMap).map((key: string) => {
            return {
                id: key,
                module: key,
                pipelines: taggingPipelineMap[key as ModuleName],
            }
        })
        return (
            <Box sx={{ height: "60vh" }}>
              <DataGrid
                rows={rows}
                columns={columns}
              />
            </Box>
          );    
    }

    const handleChange = (newVal: string) => {
        setEditString(newVal);
    }

    const renderJson = () => {
        return <textarea 
            onChange={(e: any) => {
                handleChange(e.target.value);
            }}
            disabled={viewMode !== "Edit"}
            spellCheck={false}
            style={{width: "50vw", height: "50vh"}} value={editString}></textarea>
    }

    return <Container>
        <Stack direction={"row"} spacing={2}>
            {canEdit && <IconButton color={viewMode === "Edit" ? "primary": "default"} onClick={() => setViewMode("Edit")}><EditSharp></EditSharp></IconButton>}
            <IconButton color={viewMode === "Json" ? "primary": "default"} onClick={() => setViewMode("Json")}><DataObjectSharp></DataObjectSharp></IconButton>
            <IconButton color={viewMode === "List" ? "primary": "default"} onClick={() => setViewMode("List")}><ListAlt></ListAlt></IconButton>
        </Stack>
        <Container>{viewMode === "List"? renderTable(): renderJson()}</Container>
        {viewMode === "Edit" && <FormControl sx={{width: "50vw"}}><Button variant="contained" disabled={!canSave} onClick={() => handleSave()}>Save</Button></FormControl>}
        <Dialog
            maxWidth="xs"
            open={!!(showConfirm)}
        >
            <DialogTitle>{`Are you sure, you want to update?`}</DialogTitle>
            <DialogActions>
                <Button onClick={() => setShowConfirm(false)}>
                    No
                </Button>
                <Button onClick={() => handleSave()}>Yes</Button>
            </DialogActions>
        </Dialog>
    </Container>
}