import { Close } from "@mui/icons-material"
import { Alert, Box, Button, Chip, Container, Grid, IconButton, List, ListItem, Paper, Snackbar, TextField, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { DeploymentsAPI } from "../../apis"
import { RowActions } from "../../components/DataTables/TableBodyWrapper"
import { HeaderAction, RowAction } from "../../interfaces/Data"
import { Deployment, DeploymentDetail, StatusLogType } from "../../interfaces/Deployments"
import { RootState, actions } from "../../store"
import { useAppDispatch, useAppSelector, useIsTabActive } from "../../store/hooks"
import { TableComponents, rowActionsFromStatus } from "../../utils/constants"
import { getActionsFromStatus, getChipColor } from "../../utils/deploymentUtils"
import { ApiError } from "../../utils/errorHandler"
import "./Deployments.css"
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog"
import { EditDeployment } from "./EditDeployment"

export const DeploymentDetailsPanel = (props: {
    deployment: Deployment,
    handleClose: () => void
}) => {
    const defaultRowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.Deployments]);
    const specialPermissions = useAppSelector((state: RootState) => state.common.specialPermissions);
    const userData = useAppSelector((state: RootState) => state.common.userData);
    const reload: {randomNumber: number} = useAppSelector((state: RootState) => state.deployments.reload);
    const [deploymentDetails, setDeploymentDetails] = useState<DeploymentDetail | null>(null);
    const [rowActions, setRowActions] = useState<RowAction[]>([]);
    const [actionClicked, setActionClicked] = useState<HeaderAction | RowAction | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [rejectionComment, setRejectionComment] = useState<string>("");
    const isTabVisible = useIsTabActive();
    const dispatch = useAppDispatch();
    const { deployments, common } = actions;
    
    useEffect(() => {
        if (!isTabVisible) {
            return;
        }
        (async () => {
            const id = props.deployment._id;
            if (!id) {
                return;
            }

            const res = await DeploymentsAPI.getDeploymentDetail(id);
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setDeploymentDetails(res.data);
        })();
    }, [reload, props.deployment._id, isTabVisible]);

    useEffect(() => {
        if (!(deploymentDetails && defaultRowActions.length > 0 && userData.name && specialPermissions.length > 0)) {
            return;
        }

        const rowActionsFiltered = getActionsFromStatus({
            rowActions: defaultRowActions,
            row: deploymentDetails,
            specialPermissions: specialPermissions,
            componentName: TableComponents.Deployments,
            personalData: userData
        })
        setRowActions(rowActionsFiltered)
    }, [defaultRowActions, deploymentDetails, specialPermissions, userData])

    const renderLogs = useCallback(() => {
        return <List>
        {deploymentDetails?.statusLog.map((log, index) => {
                if (log.type === StatusLogType.USER_ACTION) {
                    return <ListItem>
                        <div>
                            <Chip sx={getChipColor(log.status)} label={log.status}></Chip> by {log.updatedBy} At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} {log.remarks ? `, Comment: ${log.remarks}` : ''}
                        </div>
                    </ListItem>
                } else {
                    return <ListItem>
                        <div>
                            {log.url ? <a href={log.url}><b>{log.remarks}</b></a> : <b>{log.remarks}</b>} At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} <Chip sx={getChipColor(log.status)} label={log.status}></Chip>
                        </div>
                    </ListItem>
                }
            })}
        </List>
    }, [deploymentDetails?.statusLog]);

    const handleConfirm = async () => {
        if (!deploymentDetails) {
            return;
        }

        dispatch(common.setShowLoader(true));
        let res;
        switch (actionClicked) {
            case RowAction.APPROVE:
                res = await DeploymentsAPI.bulkApprove({ ids: [deploymentDetails._id.toString()] });
                break;
            case RowAction.REVIEW:
                res = await DeploymentsAPI.bulkReview({ ids: [deploymentDetails._id.toString()] });
                break;
            case RowAction.REJECT:
                res = await DeploymentsAPI.bulkReject({ ids: [deploymentDetails._id.toString()], comment: rejectionComment });
                break;
            case RowAction.DELETE_ROW:
                res = await DeploymentsAPI.bulkRemove({ ids: [`${deploymentDetails?._id}`]});
                break;
        }
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
        }

        setActionClicked(null);
        dispatch(common.setShowLoader(false));
        setShowConfirmModal(false);
        dispatch(deployments.reloadData());
    }

    const handleActionClick = (action: RowAction) => {
        if (action === RowAction.EDIT_ROW) {
            setActionClicked(action);
            setShowEditForm(true);
            return;
        }

        setActionClicked(action);
        setShowConfirmModal(true);
    }

    const handleDetailsPageClick = () => {
        if (deploymentDetails) {
            const path = `${window.location}/${deploymentDetails._id}`;
            const a = document.createElement("a");
            a.href = path;
            a.target = "_blank";
            a.click();
            a.remove();
        }
    }

    const renderActions = () => {
        if (!deploymentDetails) {
            return <></>
        }

        const statusActions = rowActionsFromStatus[deploymentDetails.status];
        const filteredActions = statusActions.filter(action => {
            return action !== RowAction.CHECKBOX && rowActions.indexOf(action) > -1
        })
        
        return <RowActions
            sx={{backgroundColor: "transparent!important"}}
            actions={filteredActions}
            handleAction={handleActionClick}
        ></RowActions>
    }

    const getValue = (field: string, value: any) => {
        return <span>{value}</span>
    }

    if (!deploymentDetails) {
        return <Container>Resource Not Found</Container>
    }

    return <Paper className="deployment-details-panel" elevation={3} sx={{overflowY: "scroll"}}>
        <Box className="ticket-panel-header">
            <div className="title" onClick={() => handleDetailsPageClick()}>{`Deployment Request ${deploymentDetails._id}`}</div>
            <div className="icon-wrapper">
                <IconButton onClick={() => props.handleClose()} sx={{height: "20px", width: "20px"}}><Close sx={{color: "white"}}></Close></IconButton>
            </div>
        </Box>
        <Box sx={{padding: "10px 0", ...getChipColor(deploymentDetails.status)}}>{deploymentDetails.status}</Box>
        <Container maxWidth={false} className="actions-container">
            {renderActions()}
        </Container>
        <Container maxWidth={false} sx={{height: "70vh!important", overflowY: "scroll"}}>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <List className="list">
                        <ListItem className="list-item">
                            <div className="field-name">Requested At:</div>
                            <div className="field-value">{getValue("date", `${new Date(deploymentDetails.createdAt).toLocaleTimeString()}`)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested On:</div>
                            <div className="field-value">{getValue("date", `${new Date(deploymentDetails.createdAt).toLocaleDateString()}`)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested By:</div>
                            <div className="field-value">{getValue("createdBy", deploymentDetails.createdBy)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Build Type:</div>
                            <div className="field-value">{getValue("deploymentType", deploymentDetails.deploymentType)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Module:</div>
                            <div className="field-value">{getValue("module", deploymentDetails.module)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Services:</div>
                            <div className="field-value">{getValue("services", deploymentDetails.services)}</div>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List className="list">
                    <ListItem className="list-item">
                            <div className="field-name">Source Env:</div>
                            <div className="field-value">{getValue("sourceEnvironment", deploymentDetails.sourceEnvironment)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Target Env:</div>
                            <div className="field-value">{getValue("targetEnvironment", deploymentDetails.targetEnvironment)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Branch:</div>
                            <div className="field-value">{getValue("branchName", deploymentDetails.branchName)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Tag:</div>
                            <div className="field-value">{getValue("tag", deploymentDetails.metadata?.tag)}</div>
                        </ListItem>
                        {deploymentDetails.tickets && deploymentDetails.tickets.length > 0 && <ListItem className="list-item">
                            <div className="field-name">Bug Tickets:</div>
                            <div className="field-value">{getValue("tickets", deploymentDetails.tickets.join(","))}</div>
                        </ListItem>}
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Logs</Typography>
                    {renderLogs()}
                </Grid>
            </Grid>
        </Container>
        <ConfirmDialog
            title="Confirm Deployment Change"
            open={!!showConfirmModal}
            yesText="Confirm"
            noText="Cancel"
            handleNo={() => setShowConfirmModal(false)}
            handleYes={handleConfirm}
        >
            <span>{`Are you sure, you want to ${actionClicked}?`}</span>
            {actionClicked === HeaderAction.REJECT ? <div style={{ padding: "0 20px" }}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e: any) => setRejectionComment(e.target.value)}
                />
            </div> : <></>}
        </ConfirmDialog>
        {showEditForm && deploymentDetails && <EditDeployment deploymentObj={deploymentDetails} showModal={showEditForm} handleClose={() => {
            setShowEditForm(false)
        }}></EditDeployment>}
    </Paper>
}