import { Socket, io } from 'socket.io-client';
import urls from './apis/urls';
    
// export const getSocket = () => {
//     const accessToken = localStorage.getItem("accessToken");
//     const socket = io(process.env.API_ENDPOINT || "http://localhost:4000", {
//         auth: {
//             accessToken: accessToken || ""
//         }
//     });
//     return socket;
// }

export class SocketManager {
    private static socket: Socket | undefined;

    constructor() {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
            const socket = io(process.env.API_ENDPOINT || "http://localhost:4000", {
                auth: {
                    accessToken: accessToken || ""
                }
            });
            SocketManager.socket = socket;    
        }
    }

    public static getSocket() {
        if (this.socket) {
            return this.socket;
        } else {
            const accessToken = localStorage.getItem("accessToken");
            if (accessToken) {
                const socket = io(process.env.API_ENDPOINT || "http://localhost:4000", {
                    auth: {
                        accessToken: accessToken || ""
                    }
                });
                this.socket = socket;
            }    
        }
        return this.socket;
    }
}