import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import CustomIcon from '../../components/Icons';
import "./Navigation.css";
import { SidebarItem } from '../../interfaces/Sidebar';
import { useMediaQuery } from '@mui/material';

const Sidebar = (props: {
  items: SidebarItem[],
  handleClick: (e: React.MouseEvent<HTMLElement>, item: SidebarItem) => void
}) => {
  const isLargeScreen = useMediaQuery('(min-width:1200px)');
  const getSidebarItems = (): any[] => {
    const items = [];
    let breadcrumbs = JSON.parse(localStorage.getItem("breadcrumbs") || "[]");

    for (let i = 0; i < props.items.length; i++) {
      items.push(<MenuItem key={`sidebar_item_${i}`}
        onClick={(e) => { props.handleClick(e, props.items[i]) }}
        className={breadcrumbs[0].name === props.items[i].name ? "sidebar-menu-item selected" : 'sidebar-menu-item'}>
        {props.items[i].iconName ? <ListItemIcon>
          <CustomIcon
            name={props.items[i].iconName || ""}
            iscustom="false"
            className="sidebar-icon"
            fontSize={props.items[i].iconSize || 'small'}
            sx={isLargeScreen ? {} : { fontSize: "60px" }}
          ></CustomIcon>
        </ListItemIcon> : <></>}
        <span className="sidebar-menu-item-text">{props.items[i].displayName}</span>
      </MenuItem>)
    }
    items.push(<MenuItem key={`sidebar_item_static`}
      onClick={(e) => {
        const a = document.createElement("a");
        a.href = "https://platformhealth.nslhub.com/";
        a.target = "_blank";
        a.click();
      }}
      className={'sidebar-menu-item'}>
      <ListItemIcon>
        <CustomIcon
          name={"health_and_safety"}
          iscustom="false"
          className="sidebar-icon"
          fontSize={'large'}
          sx={isLargeScreen ? {} : { fontSize: "60px" }}
        ></CustomIcon>
      </ListItemIcon>
      <span className="sidebar-menu-item-text">{"Health"}</span>
    </MenuItem>)
    return items;
  }

  return (
    <MenuList className="sidebar-menu">
      {getSidebarItems()}
    </MenuList>
  );
}

export default Sidebar;
