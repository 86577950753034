import { AlertColor, SnackbarProps } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { ComponentName, TableComponents, commonSidebarItems } from '../../utils/constants';
import { HeaderAction, RowAction } from '../../interfaces/Data';
import { SidebarItem } from '../../interfaces/Sidebar';

export interface PermissionsState {
    menuItems: {
        [ComponentName.Comparator]: string[],
        [ComponentName.Scheduler]: string[],
        [ComponentName.Configs]: string[],
    },
    headerActions: {
        [TableComponents.Deployments]: HeaderAction[],
        [TableComponents.ScheduleRequests]: HeaderAction[],
        [TableComponents.ScheduleLambdas]: HeaderAction[],
        [TableComponents.Accounts]: HeaderAction[],
        [TableComponents.Gitlab]: HeaderAction[],
        [TableComponents.BuildCycle]: HeaderAction[],
    },
    rowActions: {
        [TableComponents.Deployments]: RowAction[],
        [TableComponents.ScheduleRequests]: RowAction[],
        [TableComponents.ScheduleLambdas]: RowAction[],
        [TableComponents.Accounts]: RowAction[],
        [TableComponents.Gitlab]: RowAction[],
        [TableComponents.BuildCycle]: RowAction[],
    }
};

const getInitialState = (): PermissionsState => {
    const initialState: PermissionsState = {
        menuItems: {
            [ComponentName.Comparator]: [],
            [ComponentName.Scheduler]: [],
            [ComponentName.Configs]: [],
        },
        headerActions: {
            [TableComponents.Deployments]: [],
            [TableComponents.ScheduleRequests]: [],
            [TableComponents.ScheduleLambdas]: [],
            [TableComponents.Accounts]: [],
            [TableComponents.Gitlab]: [],
            [TableComponents.BuildCycle]: [],
        },
        rowActions: {
            [TableComponents.Deployments]: [],
            [TableComponents.ScheduleRequests]: [],
            [TableComponents.ScheduleLambdas]: [],
            [TableComponents.Accounts]: [],
            [TableComponents.Gitlab]: [],
            [TableComponents.BuildCycle]: [],
        }
    }

    return initialState;
}

const permissionsReducer = {
    setMenuItems: (state: PermissionsState, action: PayloadAction<{
        [ComponentName.Comparator]: [],
        [ComponentName.Scheduler]: [],
        [ComponentName.Configs]: [],
    }>) => {
            state.menuItems = action.payload;
    },
}

export const permissionsSlice: Slice<PermissionsState, typeof permissionsReducer> = createSlice({
    name: 'permissions',
    initialState: getInitialState(),
    reducers: permissionsReducer,
})