import { useEffect, useState } from "react";
import { ConfirmDialog } from "../../../components/Dialogs/ConfirmDialog";
import { Grid } from "@mui/material";
import { InputFormGrid, FormFieldType } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { ComparatorAPI } from "../../../apis";
import { ApiError } from "../../../utils/errorHandler";
import { useAppDispatch } from "../../../store/hooks";
import { actions } from "../../../store";
import { Environment } from "../../../interfaces/Common";

export const RecordPropertiesSnapshots = ({
    showRecordModal,
    setShowRecordModal,
}: {
    showRecordModal: boolean,
    setShowRecordModal: (show: boolean) => void,
}) => {
    const [showRecordConfirm, setShowRecordConfirm] = useState<boolean>(false);
    const [snapshotPayload, setSnapshotPayload] = useState<{
        serviceNames: string[],
        env: string,
        tags: string[]
    }>({ serviceNames: [], env: Environment.QA3, tags: [] });
    const [serviceNameOptions, setServiceNameOptions] = useState<string[]>([]);
    const [envOptions, setEnvOptions] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await ComparatorAPI.getPropertiesFilters("serviceName");
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setServiceNameOptions(res.data)

            const res2 = await ComparatorAPI.getPropertiesFilters("env");
            if (res2 instanceof ApiError) {
                dispatch(common.processApiError(res2));
                return;
            }
            setEnvOptions(res2.data)
        })()
    }, [])

    const handleRecordSnapshots = () => {
        setShowRecordConfirm(true);
    }

    const handleRecordConfirm = async () => {
        dispatch(common.setShowLoader(true));
        const res = await ComparatorAPI.createPropertiesSnapshots({
            envs: [snapshotPayload.env],
            serviceNames: snapshotPayload.serviceNames,
            tags: snapshotPayload.tags
        });
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            dispatch(common.setShowLoader(false));
            return;
        }
        dispatch(common.setShowLoader(false));
        setSnapshotPayload({ serviceNames: [], tags: [], env: Environment.QA3 });
        setShowRecordConfirm(false);
        setShowRecordModal(false);
    }

    const handleServiceNamesSelect = (e: any) => {
        const { name, value } = e.target;
                        
        let selectedReposCopy = [...snapshotPayload.serviceNames];
        let isAdded = value.includes("All");
        let isPresent = selectedReposCopy.includes("All");

        if (!isPresent && isAdded) {
            setSnapshotPayload({
                ...snapshotPayload,
                serviceNames: ["All", ...serviceNameOptions]
            });
            return;
        }

        if (isPresent && !isAdded) {
            setSnapshotPayload({
                ...snapshotPayload,
                serviceNames: []
            });
            return;
        }

        if (value.length !== serviceNameOptions.length+1 && isAdded) {
            value.splice(0,1);
        }
        setSnapshotPayload({ ...snapshotPayload, serviceNames: value });
    }

    const handleEnvSelect = (e: any) => {
        const { name, value } = e.target;
        setSnapshotPayload({
            ...snapshotPayload,
            env: value
        });
        return;
    }

    return <ConfirmDialog
        open={showRecordModal}
        title="Record Snapshots"
        yesText="Record"
        noText="Cancel"
        handleYes={handleRecordSnapshots}
        handleNo={() => setShowRecordModal(false)}
        disableYes={snapshotPayload.serviceNames.length === 0}
    >
        <Grid container rowGap={1}>
            <Grid container item xs={12}>
                <InputFormGrid
                    formFields={[
                        {
                            fieldName: "Environment",
                            name: "env",
                            type: FormFieldType.CUSTOM_SELECT,
                            value: snapshotPayload.env,
                            options: envOptions,
                            sx: {width: "150px"}
                        },
                        {
                            fieldName: "Services",
                            name: "serviceNames",
                            type: FormFieldType.COSTANTS_AUTOCOMPLETE,
                            value: snapshotPayload.serviceNames,
                            options: ["All", ...serviceNameOptions],
                            muiltiple: true
                        },
                        {
                            fieldName: "Tags",
                            name: "tags",
                            value: snapshotPayload.tags.join(","),
                        },
                    ]}
                    onChange={(e: any) => {
                        const {name, value} = e.target;
                        if (name === "serviceNames") {
                            handleServiceNamesSelect(e)
                        } else if (name === "env") {
                            handleEnvSelect(e)
                        } else {
                            setSnapshotPayload({...snapshotPayload, "tags": value.split(",")})
                        }
                    }}
                ></InputFormGrid>
            </Grid>
        </Grid>
        {showRecordConfirm && <ConfirmDialog
            open={showRecordConfirm}
            title="Confirmation"
            yesText="Yes"
            noText="No"
            handleYes={handleRecordConfirm}
            handleNo={() => setShowRecordConfirm(false)}
        >
            {`Are you sure you want to record the properties snapshots for the selected RepoNames?`}
        </ConfirmDialog>}
    </ConfirmDialog>
}