import { Container, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { SchedulerAPI } from '../../../apis';
import { TableWrapper } from '../../../components/DataTables/TableWrapper';
import { ConfirmDialog } from '../../../components/Dialogs/ConfirmDialog';
import { Pagination, SortOrder } from '../../../interfaces/Common';
import { HeaderAction, RowAction } from '../../../interfaces/Data';
import { Holiday, HolidayFilters } from '../../../interfaces/Scheduler';
import { RootState, actions } from '../../../store';
import { useAppDispatch, useAppSelector, useIsTabActive } from '../../../store/hooks';
import { TableComponents } from '../../../utils/constants';
import { ApiError } from '../../../utils/errorHandler';
import { CreateHoliday } from './CreateHoliday';
import "./Holidays.css";
import { HolidaysFilterGrid } from './HolidaysFilterGrid';
import { EditHoliday } from './EditHoliday';


export const Holidays = () => {
  const columns = [
    { field: 'name', displayName: 'Name', },
    { field: 'dates', displayName: 'Dates' },
    { field: 'envs', displayName: 'Run Environments' },
  ];
  const rows: Holiday[] = useAppSelector((state: RootState) => state.holidays.holidays);
  const headerActions = useAppSelector((state: RootState) => state.common.headerActions[TableComponents.Holidays]);
  const rowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.Holidays]);
  const selections: { [k: string]: boolean } = useAppSelector((state: RootState) => state.holidays.selections);
  const filters: HolidayFilters = useAppSelector((state: RootState) => state.holidays.filters);
  const pagination: Pagination = useAppSelector((state: RootState) => state.holidays.pagination);
  const reload: {randomNumber: number} = useAppSelector((state: RootState) => state.holidays.reload);
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [actionClicked, setActionClicked] = useState<RowAction | HeaderAction | null>(null);
  const [rowClicked, setRowClicked] = useState<Holiday | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false);
  
  const [comment, setComment] = useState<string>("");
  const isTabVisible = useIsTabActive();
  const dispatch = useAppDispatch();
  const { holidays, common } = actions;

  useEffect(() => {
    if (!isTabVisible) {
      return;
    }

    (async () => {
      const res = await SchedulerAPI.getHolidays({
        pageNumber: pagination.pageNumber,
        limit: pagination.limit
      },{...filters});

      if (res instanceof ApiError) {
        dispatch(common.setSnackbar({children: res.message, severity: "error"}));
        return;
      }

      dispatch(holidays.setHolidays(res.data.results));
      dispatch(holidays.setResultCounts({
        totalPages: res.data.totalPages,
        totalResults: res.data.totalResults,
        currentResults: res.data.currentResults
      }))
    })()
  }, [pagination.limit, pagination.pageNumber, filters, reload.randomNumber, isTabVisible]);

  const handleConfirm = async () => {
    setShowConfirm(true);
    let res;
    switch (actionClicked) {
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          res = await SchedulerAPI.bulkRemoveHolidays({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.DELETE_ROW:
        if (rowClicked && rowClicked._id) {
          res = await SchedulerAPI.bulkRemoveHolidays({ids: [rowClicked._id]});
        }
        break;
    }

    if (res instanceof ApiError) {
      dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
    }

    setActionClicked(null);
    setRowClicked(null);
    setShowConfirm(false);
    setShowConfirm(false);
  }

  const handleHeaderAction = async (action: HeaderAction) => {
    setActionClicked(action);
    switch (action) {
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          setShowConfirm(true)
        }
        break;
      case HeaderAction.ADD_NEW:
        // setRenderNewRow(true);
        setShowCreateForm(true);
        break;
      case HeaderAction.SELECT_ALL:
        dispatch(holidays.selectAll())
        break;
      case HeaderAction.UNSELECT:
        dispatch(holidays.unselectAll())
        break;
      default:
        console.log(action);

    }
  }

  const handleRowAction = async (action: RowAction, row: Holiday) => {
    setActionClicked(action);
    switch (action) {
      case RowAction.DELETE_ROW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.APPROVE:
          setShowConfirm(true);
          setRowClicked(row);
          break;
      case RowAction.REJECT:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.REVIEW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.CHECKBOX:
        if (row._id && selections[row._id]) {
          dispatch(holidays.unselectRow({ _id: row._id }));
        } else if (row._id) {
          dispatch(holidays.selectRow({ _id: row._id }));
        }
        break;
      case RowAction.EDIT_ROW:
        setRowClicked(row);
        setShowEditForm(true);
        break;
      default:
        console.log(action);
    }
  }

  const handleRowClick = (row: Holiday) => {
    if (row._id && selections[row._id]) {
      dispatch(holidays.unselectRow({_id: row._id}));
    } else if (row._id) {
      dispatch(holidays.selectRow({_id: row._id}));
    }
  }

  return (
    <Container maxWidth={false} className='table-view'>
      <HolidaysFilterGrid></HolidaysFilterGrid>
      <TableWrapper<Holiday>
          componentName={TableComponents.Holidays}
          columns={columns}
          rows={rows}
          selections={selections}
          headerActions={headerActions}
          handleHeaderAction={handleHeaderAction}
          handleLimitChange={(limit: number) => dispatch(holidays.setPagination({ pageNumber: pagination.pageNumber, limit: limit}))}
          handlePageChange={(pageNumber: number) => dispatch(holidays.setPagination({ limit: pagination.limit, pageNumber: pageNumber}))}
          pagination={pagination}
          rowActions={rowActions}
          handleRowAction={handleRowAction}
          rowClickFn={handleRowClick}
          isStatusActions={false}
      ></TableWrapper>
      <ConfirmDialog
            open={showConfirm}
            handleNo={() => setShowConfirm(false)}
            handleYes={handleConfirm}
            title='Schedule Updated Confirmation'
        >
            {actionClicked === HeaderAction.REJECT ? <div style={{ padding: "0 20px" }}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e) => setComment(e.target.value)}
                />
            </div> : <>{`Are you sure, you want to ${actionClicked}?`}</>}
        </ConfirmDialog>
      {showCreateForm && <CreateHoliday showModal={showCreateForm} handleClose={() => setShowCreateForm(false)}></CreateHoliday>}
      {showEditForm && rowClicked && <EditHoliday row={rowClicked} showModal={showEditForm} handleClose={() => setShowEditForm(false)}></EditHoliday>}
    </Container>
  );
}
