import { Close } from "@mui/icons-material";
import { Alert, Box, Button, Chip, Container, Grid, IconButton, List, ListItem, Paper, Snackbar, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import GitlabAPI from "../../apis/gitlab";
import { RowActions } from "../../components/DataTables/TableBodyWrapper";
import { HeaderAction, RowAction } from "../../interfaces/Data";
import { StatusLogType } from "../../interfaces/Deployments";
import { GitDetail } from '../../interfaces/Gitlab';
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector, useIsTabActive } from "../../store/hooks";
import { TableComponents, rowActionsFromStatus } from "../../utils/constants";
import { getActionsFromStatus, getChipColor } from "../../utils/deploymentUtils";
import { ApiError } from "../../utils/errorHandler";
import "./Gitlab.css";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
export const GitlabDetailsPanel = (props: {
    gitlabparam: GitDetail,
    handleClose: () => void
}) => {
    const defaultRowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.Deployments]);
    const specialPermissions = useAppSelector((state: RootState) => state.common.specialPermissions);
    const userData = useAppSelector((state: RootState) => state.common.userData);
    const reload: { randomNumber: number } = useAppSelector((state: RootState) => state.gitlab.reload);
    const [gitlabDetails, setGitlabDetails] = useState<GitDetail | null>(null);
    const [actionClicked, setActionClicked] = useState<HeaderAction | RowAction | null>(null);    
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);    
    const [rowActions, setRowActions] = useState<RowAction[]>([]);
    const [rejectionComment, setRejectionComment] = useState<string>("");
    const isTabVisible = useIsTabActive();
    const dispatch = useAppDispatch();
    const { gitlab, common } = actions;

    useEffect(() => {
        if (!(gitlabDetails && defaultRowActions.length > 0 && userData.name && specialPermissions.length > 0)) {
            return;
        }

        const rowActionsFiltered = getActionsFromStatus({
            rowActions: defaultRowActions,
            row: gitlabDetails,
            specialPermissions: specialPermissions,
            componentName: TableComponents.Deployments,
            personalData: userData
        })
        setRowActions(rowActionsFiltered)
    }, [defaultRowActions, gitlabDetails, specialPermissions, userData])

    useEffect(() => {
        if (!isTabVisible) {
            return;
        }
        (async () => {
            const id = props.gitlabparam._id;
            if (!id) {
                return;
            }

            const res = await GitlabAPI.getRepoDetail(id);
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setGitlabDetails(res.data);
        })();
    }, [reload, props.gitlabparam._id, isTabVisible]);

    
    const renderLogs = () => {
        if (!gitlabDetails?.statusLog) {
            return;
        }
        return <List>
            {gitlabDetails.statusLog.map((log: any, index) => {
                if (log.type === StatusLogType.USER_ACTION) {
                    let byString = `by ${log.updatedBy}`;
                    return <ListItem key={index}>
                        <div>
                            <Chip sx={getChipColor(log.status)} label={log.status}></Chip> {byString} At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} {log.remarks ? `, Comment: ${log.remarks}` : ''}
                        </div>
                    </ListItem>
                } else {
                    return <ListItem key={index}>
                        <div>
                            {log.link ? <a href={log.link}><b>{log.remarks}</b></a> : <b>{log.remarks}</b>}  At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} <Chip sx={getChipColor(log.status)} label={log.status}></Chip>
                        </div>
                    </ListItem>
                }

            })}
        </List>
    };

    const handleConfirm = async () => {
        if (!gitlabDetails) {
            return;
        }

        dispatch(common.setShowLoader(true));
        let res;
        switch (actionClicked) {
            case RowAction.APPROVE:
                res = await GitlabAPI.bulkApprove({ ids: [gitlabDetails._id.toString()] });
                break;
            case RowAction.REVIEW:
                res = await GitlabAPI.bulkReview({ ids: [gitlabDetails._id.toString()] });
                break;
            case RowAction.REJECT:
                res = await GitlabAPI.bulkReject({ ids: [gitlabDetails._id.toString()], comment: rejectionComment });
                break;
            case RowAction.DELETE_ROW:
                res = await GitlabAPI.deleteGitrepo(gitlabDetails);
                break;
        }
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
        }

        setActionClicked(null);
        dispatch(common.setShowLoader(false));
        setShowConfirmModal(false);
        dispatch(gitlab.reloadData());
    }

    const handleActionClick = (action: RowAction) => {
        if (action === RowAction.EDIT_ROW && gitlabDetails?._id) {
            const path = `${window.location}/${gitlabDetails._id}?mode=edit`;
            const a = document.createElement("a");
            a.href = path;
            a.target = "_blank";
            a.click();
            a.remove();
            return;
        }

        setActionClicked(action);
        setShowConfirmModal(true);
    }

    const handleDetailsPageClick = () => {
        if (gitlabDetails) {
            const path = `${window.location}/${gitlabDetails._id}`;
            const a = document.createElement("a");
            a.href = path;
            a.target = "_blank";
            a.click();
            a.remove();
        }
    }

    const renderActions = () => {
        if (!gitlabDetails) {
            return <></>
        }

        const statusActions = rowActionsFromStatus[gitlabDetails.status];
        const filteredActions = statusActions.filter(action => {
            return action !== RowAction.CHECKBOX && rowActions.indexOf(action) > -1
        })

        return <RowActions
            sx={{ backgroundColor: "transparent!important" }}
            actions={filteredActions}
            handleAction={handleActionClick}
        ></RowActions>
    }

    const getValue = (field: string, value: any) => {
        return <span>{value}</span>
    }

    if (!gitlabDetails) {
        return <Container>Resource Not Found</Container>
    }

    return <Paper className="deployment-details-panel" elevation={3} sx={{ overflowY: "scroll" }}>
        <Box className="ticket-panel-header">
            <div className="title" onClick={() => handleDetailsPageClick()}>{`Git Repo Access Request ${gitlabDetails._id}`}</div>
            <div className="icon-wrapper">
                <IconButton onClick={() => props.handleClose()} sx={{height: "20px", width: "20px"}}><Close sx={{color: "white"}}></Close></IconButton>
            </div>
        </Box>
        <Box sx={{ padding: "10px 0", ...getChipColor(gitlabDetails.status) }}>{gitlabDetails.status}</Box>
        <Container maxWidth={false} className="actions-container">
            {renderActions()}
        </Container>
        <Container maxWidth={false} sx={{ height: "70vh!important", overflowY: "scroll" }}>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <List className="list">
                        <ListItem className="list-item">
                            <div className="field-name">Requested At:</div>
                            <div className="field-value">{getValue("date", `${new Date(gitlabDetails.createdAt).toLocaleTimeString()}`)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested On:</div>
                            <div className="field-value">{getValue("date", `${new Date(gitlabDetails.createdAt).toLocaleDateString()}`)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested By:</div>
                            <div className="field-value">{getValue("createdBy", gitlabDetails.createdBy)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Project Name:</div>
                            <div className="field-value">{getValue("deploymentType", gitlabDetails?.repoName)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">GroupName:</div>
                            <div className="field-value">{getValue("roupName", gitlabDetails?.groupName)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Description:</div>
                            <div className="field-value">{getValue("description", gitlabDetails?.description)}</div>
                        </ListItem>
                    </List>
                </Grid>
                {gitlabDetails.createGroup &&
                    < Grid item xs={6}>
                        <List className="list">
                            <ListItem className="list-item">
                                <div className="field-name">Group Maintainers:</div>
                                <div className="field-value">{getValue("groupmaintainers", (gitlabDetails?.groupOwners)?.join(', '))}</div>
                            </ListItem>
                            <ListItem className="list-item">
                                <div className="field-name">Group Owners:</div>
                                <div className="field-value">{getValue("groupowmers", (gitlabDetails?.groupMaintainers)?.join(', '))}</div>
                            </ListItem>

                        </List>
                    </Grid>}
                <Grid item xs={12}>
                    <Typography>Logs</Typography>
                    {renderLogs()}
                </Grid>
            </Grid>
        </Container>
        <ConfirmDialog
            open={!!showConfirmModal}
            title='Confirm Repository Updates'
            yesText='Confirm'
            noText='Cancel'
            handleNo={() => setShowConfirmModal(false)}
            handleYes={handleConfirm}
        >
            <span>{`Are you sure, you want to ${actionClicked}?`}</span>
            {actionClicked === 'Delete' ? <div style={{ padding: "0 20px" }}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e: any) => setRejectionComment(e.target.value)}
                />
            </div> : <></>}
        </ConfirmDialog>
    </Paper>
}