import { useEffect, useState } from 'react';
import { Container, AlertProps, Alert, Snackbar, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector, useIsTabActive } from '../../../store/hooks';
import { RootState, actions } from '../../../store';
import { SchedulerAPI, UserAPI } from '../../../apis';
import { Pagination, SortOrder } from '../../../interfaces/Common';
import "./ScheduleRequests.css";
import { HeaderAction, RowAction } from '../../../interfaces/Data';
import { AuthState } from '../../../store/reducers/authReducer';
import { TableComponents } from '../../../utils/constants';
import { ApiError } from '../../../utils/errorHandler';
import { CreateSchedulePage } from './CreateSchedulePage';
import { TableWrapper } from '../../../components/DataTables/TableWrapper';
import { ScheduleDetailsPanel } from './ScheduleDetailsPanel';
import { ScheduleDetail, SchedulerFilters, ScheduleStatus } from '../../../interfaces/Scheduler';
import { SchedulerFilterGrid } from './SchdulerFilters';
import { ConfirmDialog } from '../../../components/Dialogs/ConfirmDialog';
import { getNewHeaderActions } from '../../../utils/deploymentUtils';

export const Scheduler = () => {
  const columns = [
    { field: '_id', displayName: 'ID', width: "50px", isSortField: true },
    { field: 'status', displayName: 'Status', width: "100px", isSortField: true },
    { field: 'env', displayName: 'Environment', width: "100px", isSortField: true },
    { field: 'runType', displayName: 'Run Type', width: "100px", isSortField: true },
    { field: 'schedules', displayName: 'Schedule', width: "200px" },
    { field: 'createdBy', displayName: 'Raised By', width: "100px", isSortField: true },
    { field: 'reason', displayName: 'Reason', width: "100px" },
    { field: 'createdAt', displayName: 'Raised On', width: "100px", isSortField: true }
  ];
  const permissions = useAppSelector((state: RootState) => state.auth.permissions);
  const rows: ScheduleDetail[] = useAppSelector((state: RootState) => state.scheduler.schedules);
  const defaultHeaderActions = useAppSelector((state: RootState) => state.common.headerActions[TableComponents.ScheduleRequests]);
  const rowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.ScheduleRequests]);
  const selections: { [k: string]: boolean } = useAppSelector((state: RootState) => state.scheduler.selections);
  const filters: SchedulerFilters = useAppSelector((state: RootState) => state.scheduler.filters);
  const pagination: Pagination = useAppSelector((state: RootState) => state.scheduler.pagination);
  const reload: {randomNumber: number} = useAppSelector((state: RootState) => state.scheduler.reload);
  const [showPane, setShowPane] = useState<boolean>(false);
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [actionClicked, setActionClicked] = useState<RowAction | HeaderAction | null>(null);
  const [rowClicked, setRowClicked] = useState<ScheduleDetail | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false); 
  const [headerActions, setHeaderActions] = useState<HeaderAction[]>([]);
  const [comment, setComment] = useState<string>("");
  const isTabVisible = useIsTabActive();
  const dispatch = useAppDispatch();
  const { scheduler, common } = actions;

  useEffect(() => {
    if (defaultHeaderActions.length === 0) {
      return;
    }

    const ids = Object.keys(selections);
    if (ids.length === 0) {
      setHeaderActions(defaultHeaderActions);
      return;
    }
    
    const selectedStatuses = rows.filter(row => ids.indexOf(row._id.toString()) > -1).map(row => row.status);    
    const newHeaderActions: HeaderAction[] = getNewHeaderActions(selectedStatuses, defaultHeaderActions);
    setHeaderActions(newHeaderActions);
  }, [selections, defaultHeaderActions]);

  useEffect(() => {
    if (!isTabVisible) {
      return;
    }

    SchedulerAPI.getScheduleRequests({
      limit: pagination.limit,
      pageNumber: pagination.pageNumber
    }, {
      ...filters
    }).then((res) => {
      if (res instanceof ApiError) {
        return;
      }
      if (res && res.data) {
        dispatch(scheduler.setSchedules(res.data.results));
        dispatch(scheduler.setResultCounts({
          totalPages: res.data.totalPages,
          totalResults: res.data.totalResults,
          currentResults: res.data.currentResults
        }))
      }
    })
  }, [pagination.limit, pagination.pageNumber, filters, reload.randomNumber, isTabVisible]);

  useEffect(() => {
    if (defaultHeaderActions.length === 0) {
      return;
    }

    const ids = Object.keys(selections);
    if (ids.length === 0) {
      setHeaderActions(defaultHeaderActions);
      return;
    }
    
    const selectedStatuses = rows.filter(row => ids.indexOf(row._id.toString()) > -1).map(row => row.status);    
    const newHeaderActions: HeaderAction[] = [];

    for (let i = 0; i < defaultHeaderActions.length; i++) {
      switch (defaultHeaderActions[i]) {
        case HeaderAction.APPROVE:
        case HeaderAction.REJECT:
        case HeaderAction.MULTI_DELETE:
          let isValid = true;
          for (let i = 0; i < selectedStatuses.length; i++) {
            if ([ScheduleStatus.Raised].indexOf(selectedStatuses[i]) === -1) {
              isValid = false;
              break;
            }
          }
          if (isValid) {
            newHeaderActions.push(defaultHeaderActions[i]);
          }
          break;
        default:
          newHeaderActions.push(defaultHeaderActions[i])
      }
    }
    setHeaderActions(newHeaderActions);
  }, [selections, defaultHeaderActions]);

  

  const handleConfirm = async () => {
    setShowConfirm(true);
    let res;
    switch (actionClicked) {
      case HeaderAction.APPROVE:
        if (Object.keys(selections).length > 0) {
          res = await SchedulerAPI.bulkApprove({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.APPROVE:
        if (rowClicked?._id) {
          res = await SchedulerAPI.bulkApprove({ ids: [`${rowClicked?._id}`] });
        }
        break;
      case HeaderAction.REJECT:
        if (Object.keys(selections).length > 0) {
          console.log("comment is", comment)
          res = await SchedulerAPI.bulkReject({ ids: Object.keys(selections), comment: comment });
        }
        break;
      case RowAction.REJECT:
        if (rowClicked?._id) {
          res = await SchedulerAPI.bulkReject({ ids: [`${rowClicked?._id}`] , comment: comment});
        }
        break;
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          res = await SchedulerAPI.bulkRemove({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.DELETE_ROW:
        if (rowClicked) {
          res = await SchedulerAPI.deleteSchedule(rowClicked);
        }
        break;
    }

    if (res instanceof ApiError) {
      dispatch(common.processApiError(res));
    }

    setActionClicked(null);
    setRowClicked(null);
    setShowConfirm(false);
    setShowConfirm(false);
  }

  const handleHeaderAction = async (action: HeaderAction) => {
    setActionClicked(action);
    switch (action) {
      case HeaderAction.APPROVE:
      case HeaderAction.REVIEW:
      case HeaderAction.REJECT:
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          setShowConfirm(true)
        }
        break;
      case HeaderAction.ADD_NEW:
        // setRenderNewRow(true);
        setShowCreateForm(true);
        break;
      case HeaderAction.SELECT_ALL:
        dispatch(scheduler.selectAll())
        break;
      case HeaderAction.UNSELECT:
        dispatch(scheduler.unselectAll())
        break;
      default:
        console.log(action);

    }
  }

  const handleRowAction = async (action: RowAction, row: ScheduleDetail) => {
    setActionClicked(action);
    switch (action) {
      case RowAction.DELETE_ROW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.APPROVE:
          setShowConfirm(true);
          setRowClicked(row);
          break;
      case RowAction.REJECT:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.REVIEW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.CHECKBOX:
        if (row._id && selections[row._id]) {
          dispatch(scheduler.unselectRow({ _id: row._id }));
        } else if (row._id) {
          dispatch(scheduler.selectRow({ _id: row._id }));
        }
        break;
      case RowAction.EDIT_ROW:
        const ind2 = rows.findIndex(rowObj => row._id === rowObj._id);
        if (ind2 > -1) {
          const path = `${window.location}/${row._id}?mode=edit`;
          const a = document.createElement("a");
          a.href = path;
          a.target = "_blank";
          a.click();
          a.remove();
        }
        break;
      default:
        console.log(action);
    }
  }

  const handleSort = (field: string, order: SortOrder) => {
    dispatch(scheduler.setFilters({...filters, sortBy: field, sortOrder: order}));
  }

  const handleRowClick = (row: ScheduleDetail) => {
    if (row._id && selections[row._id]) {
      dispatch(scheduler.unselectRow({_id: row._id}));
    } else if (row._id) {
      dispatch(scheduler.selectRow({_id: row._id}));
    }
  }

  const handleIdClick = (row: ScheduleDetail) => {
    setShowPane(true);
    setRowClicked(row);
  }

  const handleRowDoubleClick = (row: ScheduleDetail) => {
    const path = `${window.location}/${row._id}`;
    const a = document.createElement("a");
    a.href = path;
    a.target = "_blank";
    a.click();
    a.remove();
  }

  return (
    <Container maxWidth={false} className='table-view'>
      <SchedulerFilterGrid></SchedulerFilterGrid>
      <TableWrapper<ScheduleDetail>
          componentName={TableComponents.ScheduleRequests}
          columns={columns}
          rows={rows}
          selections={selections}
          headerActions={headerActions}
          handleHeaderAction={handleHeaderAction}
          handleLimitChange={(limit: number) => dispatch(scheduler.setPagination({ pageNumber: pagination.pageNumber, limit: limit}))}
          handlePageChange={(pageNumber: number) => dispatch(scheduler.setPagination({ limit: pagination.limit, pageNumber: pageNumber}))}
          pagination={pagination}
          rowActions={rowActions}
          handleRowAction={handleRowAction}
          rowClickFn={handleRowClick}
          rowDbClickFn={handleRowDoubleClick}
          idClickFn={handleIdClick}
          isStatusActions={true}
          handleSort={handleSort}
          sorting={{field: filters.sortBy, order: filters.sortOrder }}
      ></TableWrapper>
      <ConfirmDialog
        open={!!showConfirm}
        handleNo={() => setShowConfirm(false)}
        yesText='Confirm'
        noText='Cancel'
        handleYes={handleConfirm}
        title='Confirm Schedule Update'
        >
          <div>{`Are you sure, you want to ${actionClicked}?`}</div>
          {actionClicked === HeaderAction.REJECT ? <div style={{padding: "0 20px"}}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e) => setComment(e.target.value)}
                />    
            </div>: <></>}
        </ConfirmDialog>
        {rowClicked && showPane && <ScheduleDetailsPanel
          schedule={rowClicked}
          handleClose={() => {
            setRowClicked(null);
            setShowPane(false);
          }}
        ></ScheduleDetailsPanel>}
      {showCreateForm && <CreateSchedulePage showModal={showCreateForm} handleClose={() => setShowCreateForm(false)}></CreateSchedulePage>}
    </Container>
  );
}
