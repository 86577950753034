import { Environment, ROLE } from "../interfaces/Common";
import { HeaderAction, RowAction } from "../interfaces/Data";
import { DeploymentStatus } from "../interfaces/Deployments";
import { SidebarItem } from "../interfaces/Sidebar"

export const Colors = {
    baseColor: "#2f5e03"
}

export enum ComponentName {
    Admin = "admin",
    Accounts = "accounts",
    Configs = "configs",
    Comparator = "comparator",
    Deployments = "deployments",
    Scheduler = "scheduler",
    GitRepos = "gitRepos",
    DBAccess = "dBAccess",
    Reports = "reports",
    BuildCycle = "buildCycle"
}

export enum SubComponentName {
    Branches = "EnvBranches",
    Services = "ModuleServices",
    KafkaEP = "KafkaEndPoints",
    TaggingPipelines = "TaggingPipelines",
    EnvSourceTarget = "EnvSourceTarget",
    ScheduleLambdas = "ScheduleLambdas",
    ScheduleRequests = "ScheduleRequests",
    ScheduleCalendar = "ScheduleCalendar",
    DefaultSchedules = "DefaultSchedules",
    StartStop = "StartStop",
    NSLProperties = "PropertiesCompare",
    KafkaTopics = "TopicsCompare",
    ArgoImages = "ImagesCompare",
    TestsLogs = "TestsLogs",
    Permissions = "Permissions",
    Roles = "Roles",
    DatabaseSGs = "DatabaseSGs",
    Holidays="Holidays"
}

export const commonSidebarItems: SidebarItem[] = [{
    displayName: "Admin",
    name: ComponentName.Admin,
    iconName: "security",
    iconSize: "large"
}, {
    displayName: "Accounts",
    name: ComponentName.Accounts,
    iconName: "account_box",
    iconSize: "large"
}, {
    displayName: "Deployments",
    name: ComponentName.Deployments,
    iconName: "rocket_launch",
    iconSize: "large"
}, {
    displayName: "Scheduler",
    name: ComponentName.Scheduler,
    iconName: "alarm_add",
    iconSize: "large"
}, {
    displayName: "GitRepos",
    name: ComponentName.GitRepos,
    iconName: "create_new_folder",
    iconSize: "large"
}, {
    displayName: "DBAccess",
    name: ComponentName.DBAccess,
    iconName: "lock_person",
    iconSize: "large"
}, {
    displayName: "Comparator",
    name: ComponentName.Comparator,
    iconName: "difference",
    iconSize: "large"
}, {
    displayName: "Configs",
    name: ComponentName.Configs,
    iconName: "data_object",
    iconSize: "large"
}, {
    displayName: "BuildCycle",
    name: ComponentName.BuildCycle,
    iconName: "timeline",
    iconSize: "large"
}, {
    displayName: "Reports",
    name: ComponentName.Reports,
    iconName: "assessment",
    iconSize: "large"
},];

export const rowActionsFromStatus: { [k: string]: RowAction[] } = {
    [DeploymentStatus.Completed]: [RowAction.CHECKBOX],
    [DeploymentStatus.Raised]: [RowAction.CHECKBOX, RowAction.REVIEW, RowAction.APPROVE, RowAction.REJECT, RowAction.EDIT_ROW, RowAction.DELETE_ROW],
    [DeploymentStatus.Updated]: [RowAction.CHECKBOX, RowAction.REVIEW, RowAction.APPROVE, RowAction.REJECT, RowAction.EDIT_ROW, RowAction.DELETE_ROW],
    [DeploymentStatus.Reviewed]: [RowAction.CHECKBOX, RowAction.APPROVE, RowAction.REJECT],
    [DeploymentStatus.Failed]: [RowAction.CHECKBOX, RowAction.DELETE_ROW, RowAction.REBUILD, RowAction.EDIT_ROW],
    [DeploymentStatus.Rebuild]: [RowAction.CHECKBOX, RowAction.APPROVE, RowAction.REJECT, RowAction.REVIEW],
    [DeploymentStatus.Rejected]: [RowAction.CHECKBOX, RowAction.EDIT_ROW],
    [DeploymentStatus.InProgress]: [RowAction.CHECKBOX],
    [DeploymentStatus.Removed]: [RowAction.CHECKBOX],
    [DeploymentStatus.Approved]: [RowAction.CHECKBOX],
}

export const statusDropDown = () => {
    let values: DeploymentStatus[] = [];
    values = values.concat(Object.keys(DeploymentStatus).map((key) => {
        const value = DeploymentStatus[key as keyof typeof DeploymentStatus];
        return value
    }));
    return values;
}

export const breadcrumbIcons: any = {
    "admin": "security",
    "deployments": "rocket_launch",
    "reports": "assessment",
    "comparator": "difference",
    "scheduler": "alarm_add",
}

export const DEFAULT_SCHEDULE = {
    startTime: "08:00",
    stopTime: "22:00"
}

export const SpecialPermissions: any = {
    updateDeploymentsNonowner: "DeploymentsSpecial.UpdateNonOwner",
    deleteDeploymentsNonowner: "DeploymentsSpecial.DeleteNonOwner",
    deleteFailedDeployments: "DeploymentsSpecial.DeleteFailed",
    deleteSchedulerNonowner: "SchedulerSpecial.DeleteNonOwner",
    deploymentGroupNotifications: "GroupNotifications.Deployments",
    schedulerGroupNotifications: "GroupNotifications.Scheduler",
    startStopGroupNotifications: "GroupNotifications.StartStop",
    onlyIndividualNotifications: "Notifications.OnlyIndividual",
    triggerEnvStart: "StartEnv.Trigger",
    triggerEnvStop: "StopEnv.Trigger"
}


export enum TableComponents {
    Deployments = "deployments",
    Accounts = "accounts",
    GitRepos = "gitRepos",
    ScheduleRequests = "ScheduleRequests",
    ScheduleLambdas = "ScheduleLambdas",
    DBAccess = "dBAccess",
    Holidays="Holidays",
    TestsLogs="Tests Logs",
    BuildCycle = "buildCycle"
}

export const subComponentsMap = {
    [ComponentName.Admin]: [
        SubComponentName.Permissions,
        SubComponentName.Roles
    ],
    [ComponentName.Accounts]: [],
    [ComponentName.Configs]: [
        SubComponentName.Branches,
        SubComponentName.Services,
        SubComponentName.KafkaEP,
        SubComponentName.TaggingPipelines,
        SubComponentName.EnvSourceTarget,
        SubComponentName.DatabaseSGs,
    ],
    [ComponentName.Comparator]: [
        SubComponentName.NSLProperties,
        SubComponentName.KafkaTopics,
        SubComponentName.ArgoImages,
        SubComponentName.TestsLogs
    ],
    [ComponentName.Deployments]: [],
    [ComponentName.Scheduler]: [
        SubComponentName.ScheduleLambdas,
        SubComponentName.ScheduleRequests,
        SubComponentName.ScheduleCalendar,
        SubComponentName.DefaultSchedules,
        SubComponentName.StartStop,
        SubComponentName.Holidays,
    ],
    [ComponentName.GitRepos]: [],
    [ComponentName.DBAccess]: [],
    [ComponentName.Reports]: [],
    [ComponentName.BuildCycle]: []
}