import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { SchedulerAPI } from "../../../apis";
import { ConfirmDialog } from "../../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { Environment, SchedulingEnvironment } from "../../../interfaces/Common";
import { RunStatus, Holiday, HolidayType } from "../../../interfaces/Scheduler";
import { actions } from "../../../store";
import { useAppDispatch } from "../../../store/hooks";
import { ApiError } from "../../../utils/errorHandler";

export const CreateHoliday = (props: {
    handleClose: Function,
    showModal: boolean,
    // handleReload: Function
}) => {
    const [holidayObj, setHolidayObj] = useState<Holiday>({
        _id: "",
        name: "",
        dates: [],
        envs: []
    });
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common, holidays } = actions;

    useEffect(() => {
        if (holidayObj.name && holidayObj.dates.length > 0) {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    }, [holidayObj])

    const handleCreateConfirm = async () => {
        dispatch(common.setShowLoader(true));
        const res = await SchedulerAPI.createHoliday(holidayObj);
        if (res instanceof ApiError) {
            dispatch(common.setShowLoader(false));
            dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
            return;
        }
        setShowConfirm(false);
        setHolidayObj({
            _id: "",
            name: "",
            dates: [],
            envs: []
        });
        dispatch(common.setSnackbar({ children: res.message, severity: "success" }));
        props.handleClose();
        dispatch(common.setShowLoader(false));
        dispatch(holidays.reloadData());
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setHolidayObj({
            ...holidayObj,
            [name]: value,
        });
    };

    const handleClose = () => {
        props.handleClose()
    }

    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }

    return <ConfirmDialog
        yesText="Create"
        noText="Cancel"
        title="Create Holiday"
        open={props.showModal}
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        disableYes={!canSubmit}
        dialogSx={{"& .MuiPaper-root": {width: "50vw", height: "80vh"}}}
    >
        <Container maxWidth={false} className="create-deployment-list">
            <Grid container sx={{height: "50vh"}} justifyContent={"center"} rowGap={1} alignItems={"center"}>
            <InputFormGrid
                    formFields={[{
                        fieldName: "Name",
                        name: "name",
                        value: holidayObj.name,
                    }, {
                        fieldName: "Run Environments",
                        name: "envs",
                        value: holidayObj.envs,
                        type: FormFieldType.CUSTOM_SELECT,
                        muiltiple: true,
                        options: Object.values(SchedulingEnvironment)
                    }, {
                        fieldName: "Dates",
                        name: "dates",
                        value: holidayObj.dates,
                        type: FormFieldType.CUSTOM_MULTI_DATEPICKER
                    },]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
            </Grid>
            {!showConfirm ? <></> : <ConfirmDialog
                open={showConfirm}
                yesText="Yes"
                noText="No"
                title="Confirmation"
                handleNo={() => setShowConfirm(false)}
                handleYes={() => handleCreateConfirm()}
            >
                {`Are you sure, you want to create the holiday ${holidayObj.name}?`}
            </ConfirmDialog>}
        </Container>
    </ConfirmDialog>
}