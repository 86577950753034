import urls from "./urls";
import api from "./axiosInstance";
import { ApiDataRes, ApiMsgRes, Environment, ListApiRes, SchedulingEnvironment } from "../interfaces/Common";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import { AxiosResponse } from "axios";
import { ScheduleRequest, ScheduleDetail, DefaultSchedule, RunStatus, ScheduleTime, ScheduleType, RunLog, ScheduleLambda, CreateScheduleReq, ScheduleAggregation, Holiday } from "../interfaces/Scheduler";

export default class SchedulerAPI {
    public static async getScheduleRequests(pagination: {
        limit: number,
        pageNumber: number
    }, filters: any): Promise<ListApiRes<ScheduleDetail> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    filtersObj[key] = filters[key]
                }
            }
            const response: AxiosResponse<ListApiRes<ScheduleDetail>> = await api.get(urls.scheduler, {
                params: {
                    ...pagination,
                    ...filtersObj
                }
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async getEnvRunningStatus(filters: {envs: SchedulingEnvironment[]}): Promise<ApiDataRes<{[key in SchedulingEnvironment]?: RunLog}> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<{[key in SchedulingEnvironment]?: RunLog}>> = await api.get(urls.currentStatus, {
                params: {
                    envs: filters.envs
                }
            });
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async getEnvRunHistory(filters: {env: SchedulingEnvironment}): Promise<ApiDataRes<RunLog[]> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<RunLog[]>> = await api.get(`${urls.runHistory}/${filters.env}`);
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async startEnv(filters: {env: SchedulingEnvironment, reason: string}): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(`${urls.scheduler}/start/${filters.env}`, {reason: filters.reason});
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async stopEnv(filters: {env: SchedulingEnvironment, reason: string}): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(`${urls.scheduler}/stop/${filters.env}`, {reason: filters.reason});
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async getDefaultSchedules(pagination: {
        limit: number,
        pageNumber: number
    }, filters: any): Promise<ListApiRes<DefaultSchedule> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    filtersObj[key] = filters[key]
                }
            }
            const response: AxiosResponse<ListApiRes<DefaultSchedule>> = await api.get(urls.defaultSchedules, {
                params: {
                    ...pagination,
                    ...filtersObj
                }
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async getEnvCurrentSchedule(env: Environment): Promise<ListApiRes<ScheduleDetail> | ApiError> {
        try {
            const response: AxiosResponse<ListApiRes<ScheduleDetail>> = await api.get(urls.scheduler, {
                params: {
                   env: env,
                   limit: 100,
                   pageNumber: 1
                }
            });
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async getWeekSchedule(filters: {env: SchedulingEnvironment, scheduleType: ScheduleType}): Promise<ApiDataRes<{schedule: ScheduleAggregation[]}> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<{schedule: ScheduleAggregation[]}>> = await api.get(urls.weekSchedule, {
                params: {
                   env: filters.env,
                   scheduleType: filters.scheduleType
                }
            });
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async getScheduleDetail(id: string): Promise<ApiDataRes<ScheduleDetail> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<ScheduleDetail>> = await api.get(`${urls.scheduler}/${id}`);
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async createSchedule(payload: CreateScheduleReq): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(urls.scheduler, {
                ...payload
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async createDefaultSchedule(payload: DefaultSchedule): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(urls.defaultSchedules, {
                ...payload
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async updateSchedule(payload: ScheduleRequest): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.scheduler}/${payload._id}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async updateDefaultSchedule(payload: DefaultSchedule): Promise<ApiMsgRes | ApiError> {
        try {
            const response = await api.put(`${urls.defaultSchedules}/${payload.env}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async deleteSchedule(payload: ScheduleRequest): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.delete(`${urls.scheduler}/${payload._id}`);
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkApprove(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.scheduler}/status/approve`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async bulkReview(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.scheduler}/status/reviewed`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async bulkReject(payload: {ids: string[],comment: string}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.scheduler}/status/reject`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkRemove(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.scheduler}/status/remove`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkUpdateScheduleLambda(payload: {
        lambdas: ScheduleLambda[]
    }): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.lambdas}/bulk`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async updateScheduleLambda(payload: ScheduleLambda): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.lambdas}/${payload._id}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async getScheduleLambdas(pagination: {
        limit: number,
        pageNumber: number
    }, filters: any): Promise<ListApiRes<ScheduleLambda> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    filtersObj[key] = filters[key]
                }
            }

            const response: AxiosResponse<ListApiRes<ScheduleLambda>> = await api.get(urls.lambdas, {
                params: {
                    ...pagination,
                    ...filtersObj
                }
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async getLambdaNames(): Promise<ApiDataRes<string[]> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<string[]>> = await api.get(`${urls.lambdas}/name`);
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async bulkRemoveScheduleLambdas(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.scheduler}/lambdas/bulkDelete`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async createScheduleLambda(payload: ScheduleLambda): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(urls.lambdas, {
                ...payload
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async updateHoliday(payload: Holiday): Promise<ApiMsgRes | ApiError> {
        try {
            const response = await api.put(`${urls.holidays}/${payload._id}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async getHolidays(pagination: {
        limit: number,
        pageNumber: number
    }, filters: any): Promise<ListApiRes<Holiday> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    filtersObj[key] = filters[key]
                }
            }

            const response: AxiosResponse<ListApiRes<Holiday>> = await api.get(urls.holidays, {
                params: {
                    ...pagination,
                    ...filtersObj
                }
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async createHoliday(payload: Holiday): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(urls.holidays, {
                ...payload
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async deleteHoliday(payload: Holiday): Promise<ApiMsgRes | ApiError> {
        try {
            const response = await api.delete(`${urls.holidays}/${payload._id}`);
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkRemoveHolidays(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.holidays}/bulk`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }
}