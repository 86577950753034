import { Checkbox, CheckboxProps, SxProps } from "@mui/material";
import "./Inputs.css";

export interface CustomCheckboxProps {
    name: string,
    value: boolean,
    fieldName: string,
    sx?: SxProps,
    onChange: (e:any) => void,
    required?: boolean
}

export const CustomCheckbox = (props: CustomCheckboxProps) => {
	const sx: any = props.sx;
	const width = sx ? sx.width : "200px";
	return <div className="custom-checkbox" style={{width: width}}>
		<div className="field-name">
			<Checkbox
				checked={props.value}
				onClick={(e: any) => {props.onChange({target: {name: props.name, value: e.target.checked}});}}
				sx={{
					color: "var(--sidebar-bgc)",
					"&.Mui-checked": {
						color: "var(--sidebar-bgc)",
					},
				}}
			></Checkbox>
			<span>{props.fieldName}{props.required ? "*": ""}</span>
		</div>
	</div>;
};

export const StyledCheckbox = (props: CheckboxProps) => {
	return <Checkbox
		{...props}
		sx={{
			color: "var(--sidebar-bgc)",
			"&.Mui-checked": {
				color: "var(--sidebar-bgc)",
			},
		}}
	></Checkbox>;
            
};