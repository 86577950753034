import DatePicker, { DateObject } from "react-multi-date-picker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import "./Inputs.css";
import { useState } from "react";
import { SxProps } from "@mui/material";
import { bumpDate } from "../../../utils/dateUtils";
import DatePanel from "react-multi-date-picker/plugins/date_panel";


export const CustomRangeDatePicker = (props: {
    id?: string,
    value: any[],
    onChange: (e: any) => void,
    fieldName: string,
    required?: boolean,
    sx?: SxProps,
    name: string,
    format?: string,
    minDate?: Date,
    maxDate?: Date,
    maxLength?: number
}) => {
    const sx: any = props.sx;
    const width = sx ? sx.width : "200px";
    return <div className="custom-input" style={{ width: width }}>
        <div className="field-name"><span>{props.fieldName}{props.required ? "*" : ""}</span></div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
            <div className="startDate">
                <DatePicker
                    style={{width: width}}
                    value={props.value}
                    range
                    onChange={(newVals: DateObject[] | null) => {
                        props.onChange({
                            target: {
                                name: props.name,
                                value: (newVals || []).map(obj => obj.toDate())
                            }
                        })
                    }}
                    plugins={[
                        <DatePanel sort="date" />
                    ]}
                    minDate={props.minDate}
                    maxDate={props.maxDate ? props.maxDate : (props.minDate && props.maxLength) ? bumpDate(props.minDate, props.maxLength) : undefined}
                    calendarPosition="top-left"
                    format={props.format || "DD-MM-YYYY"}
                    // inputClass="multi-datepicker-input"
                />
            </div>
        </div>
    </div>
};