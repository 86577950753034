import { Select, SelectProps } from "@mui/material";
import "./Inputs.css";

export interface CustomSelectProps<T> extends SelectProps<T> {
    fieldName: string,
    sideLabel?: boolean
}

export const CustomSelect = <T,>(props: CustomSelectProps<T>) => {
    let sx: any = props.sx;
    let width = sx ? sx.width : "200px";
    let fieldWidth = props.sideLabel ? "60%" : width;
    return <div className={props.sideLabel ? "custom-input sidelabel" : "custom-input"} style={{ width: width }}>
        <div className="field-name"><span>{props.fieldName}</span></div>
        <Select
            size={props.size || "small"}
            {...props}
            sx={{ ...props.sx, width: fieldWidth }}
            style={{ height: '30px', }}
            MenuProps={{
                style: {
                    maxHeight: 400,
                },
            }}
        >{props.children}</Select>
    </div>
}
