import { Chip, Container, Grid, List, ListItem, TextField, Typography, useMediaQuery } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"
import { DeploymentsAPI } from "../../apis"
import { RowActions } from "../../components/DataTables/TableBodyWrapper"
import { HeaderAction, RowAction } from "../../interfaces/Data"
import { DeploymentDetail, DeploymentStatus, StatusLogType } from "../../interfaces/Deployments"
import { RootState, actions } from "../../store"
import { useAppDispatch, useAppSelector, useIsTabActive } from "../../store/hooks"
import { TableComponents, rowActionsFromStatus } from "../../utils/constants"
import { getActionsFromStatus, getChipColor } from "../../utils/deploymentUtils"
import { ApiError } from "../../utils/errorHandler"
import "./Deployments.css"
import { EditDeployment } from "./EditDeployment"
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog"

export const DeploymentDetailsPage = () => {
    const defaultRowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.Deployments]);
    const specialPermissions = useAppSelector((state: RootState) => state.common.specialPermissions);
    const userData = useAppSelector((state: RootState) => state.common.userData);
    const reload: {randomNumber: number} = useAppSelector((state: RootState) => state.deployments.reload);
    const [deploymentDetails, setDeploymentDetails] = useState<DeploymentDetail | null>(null);
    const [rowActions, setRowActions] = useState<RowAction[]>([]);
    const [actionClicked, setActionClicked] = useState<HeaderAction | RowAction | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [showEditForm, setShowEditForm] = useState<boolean>(false);
    const [rejectionComment, setRejectionComment] = useState<string>("");
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const isTabVisible = useIsTabActive();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { deployments, common } = actions;

    useEffect(() => {
        if (!isTabVisible) {
            return;
        }
        dispatch(common.setShowLoader(true));
        (async () => {
            const splits = window.location.pathname.split("/");
            const id = splits[splits.length - 1];
            const res = await DeploymentsAPI.getDeploymentDetail(id);
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setDeploymentDetails(res.data);
        })();
        dispatch(common.setShowLoader(false));
    }, [reload, isTabVisible]);

    useEffect(() => {
        if (!deploymentDetails) {
            return;
        }

        const rowActionsFiltered = getActionsFromStatus({
            rowActions: defaultRowActions,
            row: deploymentDetails,
            specialPermissions: specialPermissions,
            componentName: TableComponents.Deployments,
            personalData: userData
        })
        setRowActions(rowActionsFiltered)
    }, [defaultRowActions, deploymentDetails, specialPermissions])

    const renderLogs = useCallback(() => {
        return <List>
        {deploymentDetails?.statusLog.map((log, index) => {
                if (log.type === StatusLogType.USER_ACTION) {
                    return <ListItem>
                        <div>
                            <Chip sx={getChipColor(log.status)} label={log.status}></Chip> by {log.updatedBy} At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} {log.remarks ? `, Comment: ${log.remarks}` : ''}
                        </div>
                    </ListItem>
                } else {
                    
                    return <ListItem>                      
                        <div>
                            {log.url ? <a href={log.url}><b>{log.remarks}</b></a> : <b>{log.remarks}</b>} At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} <Chip sx={getChipColor(log.status)} label={log.status}></Chip>
                        </div>
                    </ListItem>
                }
            })}
        </List>
    }, [deploymentDetails?.statusLog]);

    const handleConfirm = async () => {
        dispatch(common.setShowLoader(true));
        if (!deploymentDetails) {
            return;
        }

        let res;
        switch (actionClicked) {
            case RowAction.APPROVE:
                res = await DeploymentsAPI.bulkApprove({ ids: [deploymentDetails._id.toString()] });
                break;
            case RowAction.REVIEW:
                res = await DeploymentsAPI.bulkReview({ ids: [deploymentDetails._id.toString()] });
                break;
            case RowAction.REJECT:
                res = await DeploymentsAPI.bulkReject({ ids: [deploymentDetails._id.toString()], comment: rejectionComment });
                break;
            case RowAction.DELETE_ROW:
                res = await DeploymentsAPI.bulkRemove({ ids: [`${deploymentDetails?._id}`]});
                break;
        }
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
        }

        setActionClicked(null);
        dispatch(common.setShowLoader(false));
        setShowConfirmModal(false);
        dispatch(deployments.reloadData());
    }

    const handleActionClick = (action: HeaderAction | RowAction) => {
        if (action === RowAction.EDIT_ROW) {
            setShowEditForm(true);
            return;
        }
        setActionClicked(action);
        setShowConfirmModal(true);
    }

    const renderActions = () => {
        if (!deploymentDetails) {
            return <></>
        }
        
        return <RowActions
            sx={{backgroundColor: "transparent!important"}}
            actions={rowActions}
            handleAction={handleActionClick}
        ></RowActions>
    }

    if (!deploymentDetails) {
        return <Container>Resource Not Found</Container>
    }

    return <Container className="deployment-details-content" maxWidth={false} sx={{overflowY: "scroll"}}>
        <Container maxWidth={false} className="actions-container">
            {renderActions()}
        </Container>
        <Container maxWidth={false} sx={{padding:0, margin:0, paddingRight: '2px'}}>
            <Grid container spacing={0}>
                <Grid item xs={isLargeScreen ? 6 : 12}>
                    <List className="list">
                        <ListItem className="list-item">
                            <div className="field-name">Request ID:</div>
                            <div className="field-value"><Chip label={deploymentDetails._id}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested At:</div>
                            <div className="field-value"><Chip label={`${new Date(deploymentDetails.createdAt).toDateString()} ${new Date(deploymentDetails.createdAt).toLocaleTimeString()}`}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested By:</div>
                            <div className="field-value"><Chip label={deploymentDetails.createdBy}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Status:</div>
                            <div className="field-value"><Chip label={deploymentDetails.status} sx={getChipColor(deploymentDetails.status)}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Module:</div>
                            <div className="field-value"><Chip label={deploymentDetails.module}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Services:</div>
                            <div className="field-value"><Chip label={deploymentDetails.services}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Build Type:</div>
                            <div className="field-value"><Chip label={deploymentDetails.deploymentType}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Source Env:</div>
                            <div className="field-value"><Chip label={deploymentDetails.sourceEnvironment}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Target Env:</div>
                            <div className="field-value"><Chip label={deploymentDetails.targetEnvironment}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Branch:</div>
                            <div className="field-value"><Chip label={deploymentDetails.branchName}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Tag:</div>
                            <div className="field-value"><Chip label={deploymentDetails.metadata?.tag}></Chip></div>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={isLargeScreen ? 6 : 12} sx={{height: "80vh!important", overflowY: "auto"}}>
                    <Typography>Logs</Typography>
                    {renderLogs()}
                </Grid>
            </Grid>
        </Container>
        <ConfirmDialog
            title="Confirm Deployment Change"
            open={!!showConfirmModal}
            yesText="Confirm"
            noText="Cancel"
            handleNo={() => setShowConfirmModal(false)}
            handleYes={handleConfirm}
        >
            <span>{`Are you sure, you want to ${actionClicked}?`}</span>
            {actionClicked === HeaderAction.REJECT ? <div style={{ padding: "0 20px" }}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e: any) => setRejectionComment(e.target.value)}
                />
            </div> : <></>}
        </ConfirmDialog>
            {showEditForm && deploymentDetails && <EditDeployment deploymentObj={deploymentDetails} showModal={showEditForm} handleClose={() => {
            setShowEditForm(false)
        }}></EditDeployment>}
    </Container>
}