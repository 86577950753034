import urls from "./urls";
import api from "./axiosInstance";
import { ApiDataRes, ApiMsgRes, ListApiRes, ROLE } from "../interfaces/Common";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import { AxiosResponse } from "axios";
import { Notification } from "../interfaces/Notifications";

export default class NotificationsAPI {
    public static async getNotifications(filters: {
        limit: number, pageNumber: number, isRead?: boolean
    }): Promise<ApiError | ListApiRes<Notification>> {
        try {

            const filtersObj: any = {
                limit: filters.limit,
                pageNumber: filters.pageNumber
            }
    
            if (filters.isRead !== undefined) {
                filtersObj.isRead = filters.isRead
            }
    
            const response: AxiosResponse<ListApiRes<Notification>> = await api.get(urls.notifications, {
                params: {
                    ...filtersObj
                }
            });
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async getCount(): Promise<ApiError | ApiDataRes<{all: number, unread: number}>> {
        try {
            const response: AxiosResponse<ApiDataRes<{all: number, unread: number}>> = await api.get(`${urls.notifications}/count`);
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async updateNotification(id: string, isRead: boolean): Promise<ApiError | ApiMsgRes> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.put(`${urls.notifications}/${id}`, {
                isRead: isRead
            });
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async removeNotification(id: string): Promise<ApiError | ApiMsgRes> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.delete(`${urls.notifications}/${id}`);
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }
}