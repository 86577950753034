import { Save, Cancel } from "@mui/icons-material";
import { TableRow, TableCell, IconButton, FormControl, Button, Dialog, DialogTitle, DialogActions, MenuItem, Autocomplete, Container, List, ListItem, Stack, TextareaAutosize, AlertProps, Alert, Snackbar, Backdrop, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { InputLabel, Select, TextField } from "../../components/Wrapper";
import { ApiError } from "../../utils/errorHandler";
import { Environment } from "../../interfaces/Common";
import ConfigsAPI from "../../apis/config";
import { dbaccess } from "../../interfaces/DbAccess";
import DBAccessAPI from "../../apis/dbaccess";
import { useAppDispatch } from "../../store/hooks";
import { actions } from "../../store";

export const EditRepo = (props: {
    handleCancel: () => void,
    handleSave: () => void,
    row: dbaccess
}) => {
    const [dbaccessObj, setDbaccessObj] = useState<dbaccess>({
        _id: props.row._id,
        envName:props.row.envName,
        dbName:props.row.dbName,
        rdpID:props.row.rdpID,
        description:props.row.description,
        // createGroup:props.row.createGroup,
        // groupName:props.row.groupName,
        // namespace:props.row.namespace,
        status:props.row.status
        // groupMaintainers:props.row.groupMaintainers,
        // groupOwners:props.row.groupOwners,
        // grouptype:props.row.createGroup
    })

    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [groupsname,setGroupsname]= useState<string[]>([]);
    const [maintainersname,setMaintainersname]= useState<string[]>([]);
    const [ownersname,setOwnersname]= useState<string[]>([]);
    const [type,setType]=useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchText, setSearchText] = useState('');
    const dispatch = useAppDispatch();
    const { common } = actions;
    
    useEffect(() => {
        if (dbaccessObj.envName && dbaccessObj.dbName && dbaccessObj.rdpID && dbaccessObj.description) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }

    }, [dbaccessObj])

    const handleCreateConfirm = async () => {
        dispatch(common.setShowLoader(true));
        await DBAccessAPI.updateDBAccess(dbaccessObj);
        setShowConfirm(false);
        setDbaccessObj({
            _id: props.row._id,
            envName:props.row.envName,
            dbName:props.row.dbName,
            rdpID:props.row.rdpID,
            description:props.row.description,
            status:props.row.status
        });
        dispatch(common.setShowLoader(false));
        props.handleSave();
    }
    
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (value === "") {
            // If the user selects an empty value, clear the source environment field
            setDbaccessObj({
                ...dbaccessObj,
                [name]: "",  // Make sure to update the specific field with an empty value
            });
        }
         else {
            setDbaccessObj({
                ...dbaccessObj,
                [name]: value,
                
            });
            
        }
    };
    const handleSearchTextChange = (identifier:any, event:any, value:any) => {
        setType(identifier);
        setSearchText(value);
        setPageNumber(1);
      };
    
    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }

    return  <Container maxWidth={false} className="edit-deployment">
                <Stack direction={"row"} spacing={2}>
                <Container maxWidth={false}>
                    <List className="list">
                        <ListItem className="list-item" key="envName">
                            <div className="field-name">Environment:</div>
                            <div className="field-value">
                            <TextField
                                disabled
                                id="envName"
                                value={dbaccessObj.envName}
                                name="envName"
                                variant="outlined" size="small"
                                onChange={handleInputChange}
                                >
                                </TextField>
                            </div>
                        </ListItem>
                        <ListItem className="list-item" key="dbName">
                            <div className="field-name">Database:</div>
                            <div className="field-value">
                                <TextField
                                disabled
                                id="dbName"
                                value={dbaccessObj.dbName}
                                name="dbName"
                                variant="outlined" size="small"
                                onChange={handleInputChange}
                                >
                                </TextField>
                            </div>
                        </ListItem>
                        <ListItem className="list-item" key="rdpID">
                            <div className="field-name">RDP IP</div>
                            <div className="field-value">
                            <TextField value={dbaccessObj.rdpID} onChange={handleInputChange} name="rdpID" variant="outlined" size="small"></TextField>
                            </div>
                        </ListItem>
                        <ListItem className="list-item" key="description">
                            <div className="field-name">Description</div>
                            <div className="field-value">
                            <TextareaAutosize value={dbaccessObj.description} onChange={handleInputChange} name="description" style={{minHeight: "50px", minWidth: "200px"}} />
                            </div>
                        </ListItem>
                    </List>
                    <Button disabled={!canSubmit} onClick={() => setShowConfirm(true)} fullWidth variant="contained">Update</Button>
                </Container>
            </Stack>
        <Dialog
            maxWidth="xs"
            open={!!(showConfirm)}
        >
            <DialogTitle>{`Are you sure, you want to create a request for ${dbaccessObj.envName}?`}</DialogTitle>
            <DialogActions>
                <Button onClick={() => setShowConfirm(false)}>
                    No
                </Button>
                <Button onClick={handleCreateConfirm}>Yes</Button>
            </DialogActions>
        </Dialog>
    </Container>
}