import { Box, Button, Container, Grid, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import KafkaAPI from "../../../apis/kafka";
import { FormFieldType, InputFormGrid } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { Environment } from "../../../interfaces/Common";
import { actions } from "../../../store";
import { useAppDispatch } from "../../../store/hooks";
import { ApiError } from "../../../utils/errorHandler";
import "../Comparator.css";
import { TopicsSnapshotComparator } from "./TopicsSnapshots";
import { AllTopicsSnapshotComparator } from "./AllTopicsSnapshots";

export const TopicsComparator = () => {
    const [viewToggle, setViewToggle] = useState<string>("snapshots");
    return <Container maxWidth={false} sx={{padding: "0!important"}} className="property-comparator">
        <div className="toggle-wrapper">
            <div className="view_toggle">
                <span
                    onClick={() => setViewToggle("comparison")}
                    className={`${viewToggle === "comparison" && "activeToggle"}`}
                >
                    Comparison
                </span>
                <span
                    onClick={() => setViewToggle("snapshots")}
                    className={`${viewToggle === "snapshots" && "activeToggle"}`}
                >
                    Env Snapshots
                </span>
                <span
                    onClick={() => setViewToggle("allEnvs")}
                    className={`${viewToggle === "allEnvs" && "activeToggle"}`}
                >
                    All Snapshots
                </span>
            </div>
        </div>
        {viewToggle === "comparison" && <DirectComparator></DirectComparator>}
        {viewToggle === "snapshots" && <TopicsSnapshotComparator></TopicsSnapshotComparator>}
        {viewToggle === "allEnvs" && <AllTopicsSnapshotComparator></AllTopicsSnapshotComparator>}
    </Container>
}

export const DirectComparator = () => {
    const [payload, setPayload] = useState<{ sourceEnv: string, targetEnv: string }>({
        sourceEnv: "",
        targetEnv: ""
    })
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const [comparisonData, setComparisonData] = useState<{
        common: string[],
        onlyInEnv1: string[],
        onlyInEnv2: string[]
    }>({
        common: [],
        onlyInEnv1: [],
        onlyInEnv2: []
    });
    const [topicsFetched, setTopicFetched] = useState<boolean>(false);
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const [tab, setTab] = useState<number>(0)
    const dispatch = useAppDispatch();
    const { common } = actions;


    useEffect(() => {
        if (topicsFetched) {
            setTopicFetched(false);
        }
    }, [payload])

    useEffect(() => {
        (async () => {
            if (payload.sourceEnv != "" && payload.targetEnv != "")
                setDisableSubmit(false);
        })()
    }, [payload])

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        if (value === "") {
            setPayload({
                ...payload,
                [name]: ""
            })
        } else {
            setPayload({
                ...payload,
                [name]: value
            })
        }
    }

    const handleListSubmit = async () => {
        dispatch(common.setShowLoader(true));
        const res = await KafkaAPI.getTopicsDiff(payload);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            dispatch(common.setShowLoader(false));
            return;
        }
        setComparisonData(res);
        setTopicFetched(true);
        dispatch(common.setShowLoader(false));
        setDisableSubmit(true);
    }

    const renderTable = (title: string, topicsList: string[]) => {
        return <TableContainer sx={{height: "50vh", overflow: "auto"}}>
            <Table stickyHeader>
                <TableHead>
                    <TableCell colSpan={2}>{title}</TableCell>
                </TableHead>
                <TableBody>
                    {topicsList.map((val: string, ind: number) => {
                        return <TableRow>
                            <TableCell>{ind+1}</TableCell>
                            <TableCell>{val}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    }

    return <Container maxWidth={false} sx={{padding: 0}} className="property-comparator">
        <Grid container columnGap={1}>
            <InputFormGrid
                formFields={[{
                    name: "sourceEnv",
                    fieldName: "Source Env",
                    value: payload.sourceEnv,
                    type: FormFieldType.CUSTOM_SELECT,
                    options: Object.values(Environment)
                }, {
                    name: "targetEnv",
                    fieldName: "Target Env",
                    value: payload.targetEnv,
                    type: FormFieldType.CUSTOM_SELECT,
                    options: Object.values(Environment)
                }]}
                onChange={handleInputChange}
            ></InputFormGrid>
            <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end", paddingBottom: "5px" }}>
                <Button className={disableSubmit ? "yes-button disabled" : "yes-button"} disabled={disableSubmit} onClick={handleListSubmit}>Submit</Button>
            </Grid>
        </Grid>
        {topicsFetched && 
            <Grid container className="topics-diff-container">
                <Grid xs={4} className="diff-grid">{renderTable(`Common Topics (${comparisonData.common.length})`, comparisonData.common)}</Grid>
                <Grid xs={4} className="diff-grid">{renderTable(`Missing Topics (${comparisonData.onlyInEnv1.length})`, comparisonData.onlyInEnv1)}</Grid>
                <Grid xs={4} className="diff-grid">{renderTable(`New Topics (${comparisonData.onlyInEnv2.length})`, comparisonData.onlyInEnv2)}</Grid>
            </Grid>}
    </Container>
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}