import { NavigateBefore, NavigateNext, SkipNext, SkipPrevious } from "@mui/icons-material";
import { IconButton, MenuItem, TableCell, TableFooter, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { Select } from "../Wrapper";
import "./Data.css";
export interface TablePaginationProps {
    pageNumber: number,
    limit: number,
    onPageChange: (pageNumber: number) => void,
    onLimitChange: (limit: number) => void,
    totalResults: number,
    currentResults: number,
    totalPages: number,
    showText?: boolean,
    showLimit?: boolean,
    width?: string,
    hideRpp?: boolean
}

export const TablePaginationWrapper = (props: TablePaginationProps) => {
    const [pages, setPages] = useState<{
        start: number,
        end: number,
        limit: number
    }>({start: 1, end: 5, limit: 5});

    useEffect(() => {
        if (props.totalPages < pages.limit) {
            setPages({
                ...pages,
                end: props.totalPages
            })
        } else {
            setPages({
                ...pages,
                end: 5
            })
        }
    }, [props.totalPages])

    const getShowingText = () => {
        if (props.totalResults === 0) {
            return ""
        }
        let start = props.limit * (props.pageNumber - 1) + 1;
        let end = start+props.currentResults-1;
        return `Showing ${start}-${end} of ${props.totalResults}`
    }

    const handleSkipClick = (direction: string) => {
        if (direction === "Previous") {
            setPages({
                ...pages,
                start: pages.start-pages.limit,
                end: pages.end - pages.limit < pages.limit ? pages.limit : pages.end - pages.limit
            })
            props.onPageChange(pages.start-pages.limit);
        } else {
            setPages({
                ...pages,
                start: pages.start+pages.limit,
                end: pages.end + pages.limit > props.totalPages ? props.totalPages : pages.end + pages.limit
            })
            props.onPageChange(pages.start+pages.limit);
        }
    }

    const handlePaginationClick = (direction: string) => {
        if (direction === "Previous") {
            if (props.pageNumber === pages.start) {
                setPages({
                    ...pages,
                    start: pages.start-pages.limit,
                    end: pages.end - pages.limit < pages.limit ? pages.limit : pages.end - pages.limit
                })    
            }
            props.onPageChange(props.pageNumber-1);
        } else {
            if (props.pageNumber === pages.end) {
                setPages({
                    ...pages,
                    start: pages.start+pages.limit,
                    end: pages.end + pages.limit > props.totalPages ? props.totalPages : pages.end + pages.limit
                })                    
            }
            props.onPageChange(props.pageNumber+1);
        }
    }

    const renderPageNumbers = () => {
        let pageNumbers = [];
        for (let i = pages.start; i <= pages.end; i++) {
            if (i === props.pageNumber) {
                pageNumbers.push(<div
                    className="pageNumber active"
                >{i}</div>)
            } else {
                pageNumbers.push(<div
                    onClick={() => props.onPageChange(i)}
                    className="pageNumber"
                >{i}</div>)
            }
        }

        return <div className="pageNumbers-container">
            <div className="pagination-prev2">
                <IconButton
                    onClick={() => handleSkipClick("Previous")}
                    disabled={pages.start === 1}
                    sx={{height: "30px", width: "30px"}}
                >
                    <SkipPrevious></SkipPrevious>        
                </IconButton>
            </div>
            <div className="pagination-prev">
                <IconButton
                    onClick={() => handlePaginationClick("Previous")}
                    disabled={props.pageNumber === 1}
                    sx={{height: "30px", width: "30px"}}
                >
                    <NavigateBefore></NavigateBefore>
                </IconButton>
            </div>
            <div className="pageNumbers-list">{pageNumbers}</div>
            <div className="pagination-next">
                <IconButton
                    onClick={() => handlePaginationClick("Next")}
                    disabled={props.totalPages===props.pageNumber}
                    sx={{height: "30px", width: "30px"}}
                >
                    <NavigateNext></NavigateNext>
                </IconButton>
            </div>
            <div className="pagination-next2">
                <IconButton
                    onClick={() => handleSkipClick("Next")}
                    disabled={props.totalPages <= pages.end}
                    sx={{height: "30px", width: "30px"}}
                >
                    <SkipNext></SkipNext>
                </IconButton></div>
        </div>
    }


    if (props.totalResults === 0) {
        return <></>
    }

    return <TableFooter className="table-footer">
        <TableRow>
            <TableCell sx={{padding: "0px",border: "none"}}>
                <div className="table-footer-row" style={props.width ? {width: props.width}: undefined}>
                    {props.showText && <div className="showing-text">{getShowingText()}</div>}
                    {props.showLimit && <div className="items-per-page">
                        {!props.hideRpp && <div className="text">Records per page</div>}
                        <div className="dropdown-wrapper">
                            <Select
                                value={props.limit}
                                onChange={(e: any) => {                            
                                    props.onLimitChange(e.target.value);
                                }}
                                sx={{width: "100px!important"}}
                            >
                                {[10, 20, 50, 100, 500].map(limit => {
                                    return <MenuItem value={limit}>{limit}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </div>}
                    {renderPageNumbers()}
                </div>
            </TableCell>
        </TableRow>
    </TableFooter>

}