import { Container, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { SchedulerAPI } from "../../../apis";
import { ConfirmDialog } from "../../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { Environment } from "../../../interfaces/Common";
import { RunStatus, ScheduleLambda } from "../../../interfaces/Scheduler";
import { actions } from "../../../store";
import { useAppDispatch } from "../../../store/hooks";
import { ApiError } from "../../../utils/errorHandler";

export const CreateScheduleLambda = (props: {
    handleClose: Function,
    showModal: boolean,
    // handleReload: Function
}) => {
    const [lambdaObj, setLambdaObj] = useState<ScheduleLambda>({
        _id: "",
        id: "",
        envs: [],
        triggerType: RunStatus.ON,
        lambdaName: "",
    });
    const [lambdaNameOptions, setLambdaNameOptions] = useState<string[]>([]);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common, lambdas } = actions;

    const envOptions = [
        Environment.PREMERGEDEV,
        Environment.PAAS3,
        Environment.PREQA,
        Environment.PREQA_DEV,
        Environment.QA3,
        Environment.QA3_DEV,
        Environment.CARNIVALSC,
        Environment.CARNIVALSC_DEV,
        Environment.PIONEER,
        Environment.PIONEER_DEV,
        Environment.MAESTRO,
    ]

    useEffect(() => {
        (async () => {
            const res = await SchedulerAPI.getLambdaNames();
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setLambdaNameOptions(res.data);
        })()
    }, [])

    useEffect(() => {
        if (lambdaObj.lambdaName && lambdaObj.envs.length > 0) {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    }, [lambdaObj])

    const handleCreateConfirm = async () => {
        dispatch(common.setShowLoader(true));
        const res = await SchedulerAPI.createScheduleLambda(lambdaObj);
        if (res instanceof ApiError) {
            dispatch(common.setShowLoader(false));
            dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
            return;
        }
        setShowConfirm(false);
        setLambdaObj({
            _id: "",
            id: "",
            envs: [],
            triggerType: RunStatus.ON,
            lambdaName: "",
        });
        dispatch(common.setSnackbar({ children: res.message, severity: "success" }));
        props.handleClose();
        dispatch(common.setShowLoader(false));
        dispatch(lambdas.reloadData());
    }


    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setLambdaObj({
            ...lambdaObj,
            [name]: value,
        });
    };

    const handleClose = () => {
        props.handleClose()
    }

    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }

    return <ConfirmDialog
        yesText="Create"
        noText="Cancel"
        title="Create Schedule Lambda"
        open={props.showModal}
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        disableYes={!canSubmit}
    >
        <Container maxWidth={false} className="create-deployment-list">
            <Grid container justifyContent={"center"} rowGap={1} alignItems={"center"}>
                <InputFormGrid
                    formFields={[{
                        fieldName: "Lambda Name",
                        name: "lambdaName",
                        options: lambdaNameOptions,
                        type: FormFieldType.CUSTOM_AUTOCOMPLETE,
                        value: lambdaObj.lambdaName
                    }, {
                        fieldName: "Trigger Type",
                        name: "triggerType",
                        options: Object.values(RunStatus),
                        type: FormFieldType.CUSTOM_SELECT,
                        value: lambdaObj.triggerType
                    }, {
                        fieldName: "Environments",
                        name: "envs",
                        options: envOptions,
                        type: FormFieldType.CUSTOM_SELECT,
                        value: lambdaObj.envs,
                        muiltiple: true
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
            </Grid>
            {!showConfirm ? <></> : <ConfirmDialog
                open={showConfirm}
                yesText="Yes"
                noText="No"
                title="Confirmation"
                handleNo={() => setShowConfirm(false)}
                handleYes={() => handleCreateConfirm()}
            >
                {`Are you sure, you want to create ${lambdaObj.lambdaName} for the envs ${lambdaObj.envs.join(", ")}?`}
            </ConfirmDialog>}
        </Container>
    </ConfirmDialog>
}