import { Grid, IconButton } from "@mui/material";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { SchedulerAPI } from "../../apis";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../components/Wrapper/Inputs/InputFormGrid";
import { SchedulingEnvironment } from "../../interfaces/Common";
import { Day } from "../../interfaces/Scheduler";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { setIntervalTime } from "../../utils/dateUtils";
import { ApiError } from "../../utils/errorHandler";
import "./ScheduleCalendar.css";
import { formField } from "pdfkit";
import { CustomTimeRangePicker } from "../../components/Wrapper/Inputs/CustomDatePicker";
import { Add, Cancel, Clear, Delete } from "@mui/icons-material";

export const CreateDefaultSchedulePage = (props: {
    handleClose: Function,
    showModal: boolean
}) => {
    const days = Object.values(Day);
    const [env, setEnv] = useState<SchedulingEnvironment>(SchedulingEnvironment.QA3);
    const [commonSchedules, setCommonSchedules] = useState<{ startTime: Date, stopTime: Date }[]>([
        {
            startTime: setIntervalTime(new Date(), 30),
            stopTime: setIntervalTime(new Date(), 30)
        }
    ]);
    const [selectedDays, setSelectedDays] = useState<Day[]>([])
    const [isStatic, setIsStatic] = useState<boolean>(false)
    const [schedules, setSchedules] = useState<{
        day: Day,
        index: number,
        startTime: Date, stopTime: Date
    }[]>([])
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common, scheduler } = actions;

    useEffect(() => {
        if (!(selectedDays.length > 0 && commonSchedules.length > 0)) {
            return;
        }

        let schedulesCopy: {
            day: Day,
            index: number,
            startTime: Date, stopTime: Date
        }[] = []
        for (let day of days) {
            if (!selectedDays.includes(day)) {
                continue;
            }

            for (let i = 0; i < commonSchedules.length; i++) {
                schedulesCopy.push({
                    day: day,
                    index: i,
                    startTime: commonSchedules[i].startTime,
                    stopTime: commonSchedules[i].stopTime
                })
            }
        }
        setSchedules(schedulesCopy);
    }, [commonSchedules, selectedDays])

    const handleCreateConfirm = async () => {
        const res = await SchedulerAPI.createDefaultSchedule({
            env: env,
            schedule: schedules,
            isStatic: isStatic
        });

        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setShowConfirm(false);
        setEnv(SchedulingEnvironment.QA3);
        dispatch(common.setSnackbar({ children: "Successfully created default schedule.", severity: "success" }));
        setSchedules([])
        props.handleClose();
        dispatch(scheduler.reloadDefaultScheduleData());
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleChangeCommonTime = (newValue: Date[] | null, index: number) => {
        if (!newValue) {
            return;
        }
        let commonCopy = cloneDeep(commonSchedules);
        commonCopy[index] = { startTime: newValue[0], stopTime: newValue[1] };
        setCommonSchedules(commonCopy);
    }

    const handleRemoveCommon = (index: number) => {
        const copy = cloneDeep(commonSchedules);
        copy.splice(index, 1);
        setCommonSchedules(copy);
    }

    const handleAddNewCommon = () => {
        if (commonSchedules.length >= 3) {
            return;
        }
        setCommonSchedules([
            ...commonSchedules,
            {startTime: setIntervalTime(new Date(), 30), stopTime: setIntervalTime(new Date(), 30)}
        ])
    }

    const renderCommonRow = (index: number) => {
        return <Grid item flexDirection={"column"}>
            <div className="common-schedule">
                <CustomTimeRangePicker
                    fieldName={`Common - ${index+1}`}
                    name={`common-${index}`}
                    format={"HH:mm A"}
                    value={[commonSchedules[index].startTime, commonSchedules[index].stopTime]}
                    onChange={(newValue: Date[] | null) => {
                        handleChangeCommonTime(newValue, index);
                    }}
                ></CustomTimeRangePicker>
                {index > 0 && <div className="clear-icon">
                    <IconButton
                        sx={{height: "18px", width: "18px"}}
                        onClick={() => handleRemoveCommon(index)}
                    >
                            <Clear sx={{fontSize: "18px"}}></Clear>
                    </IconButton></div>}
            </div>
        </Grid>
    }

    const handleChangeDayTime = (newValue: Date[] | null, day: Day, index: number) => {        
        if (!newValue) {
            return;
        }
        const copy = cloneDeep(schedules);
        copy[index].startTime = newValue[0];
        copy[index].stopTime = newValue[1];
        setSchedules(copy);
    }

    const handleRemoveDayTime = (day: Day, scheduleIndex: number) => {
        const copy = cloneDeep(schedules);
        copy.splice(scheduleIndex, 1)
        setSchedules(copy);
    }

    const handleAddNewDayTime = (day: Day, index: number) => {
        const copy = cloneDeep(schedules);
        let scheduleIndex = copy.findIndex(obj => obj.day === day && obj.index === index-1);
        copy.splice(scheduleIndex+1, 0, {
            day: day,
            index: index,
            startTime: commonSchedules[index-1].startTime,
            stopTime: commonSchedules[index-1].stopTime
        })
        setSchedules(copy);
    }

    const renderDayRow = (day: Day, index: number) => {
        const objIndex = schedules.findIndex(schedule => schedule.day === day && schedule.index === index);
        if (objIndex === -1) {
            return <></>
        }
        const obj = schedules[objIndex];

        return <Grid item flexDirection={"column"}>
            <div className="common-schedule">
                <CustomTimeRangePicker
                    fieldName={`${day} - ${index+1}`}
                    name={`${day}-${objIndex}`}
                    format={"HH:mm A"}
                    value={[obj.startTime, obj.stopTime]}
                    onChange={(newValue: Date[] | null) => {
                        handleChangeDayTime(newValue, day, objIndex);
                    }}
                ></CustomTimeRangePicker>
                {index > 0 && <div className="clear-icon">
                    <IconButton
                        sx={{height: "18px", width: "18px"}}
                        onClick={() => handleRemoveDayTime(day, objIndex)}
                    >
                            <Clear sx={{fontSize: "18px"}}></Clear>
                    </IconButton></div>}
            </div>
        </Grid>
    }

    return <ConfirmDialog
        open={!!props.showModal}
        title="Create Default Schedule"
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        yesText="Create"
        noText="Cancel"
    >
        <Grid container>
            <Grid container item columnGap={1} xs={3}>
                <InputFormGrid
                    formFields={[{
                        fieldName: "SchedulingEnvironment",
                        name: "env",
                        type: FormFieldType.CUSTOM_SELECT,
                        value: env,
                        options: Object.values(SchedulingEnvironment)
                    },{
                        fieldName: "Static Schedule",
                        name: "isStatic",
                        type: FormFieldType.CUSTOM_CHECKBOX,
                        value: isStatic,
                    }]}
                    onChange={(e: any) => {
                        const { name, value } = e.target;
                        if (name === "isStatic") {
                            setIsStatic(!isStatic)
                        } else {
                            setEnv(value);                            
                        }
                    }}
                    commonXs={12}
                ></InputFormGrid>
                <InputFormGrid
                    formFields={[
                        {
                            fieldName: "All",
                            name: "All",
                            type: FormFieldType.CUSTOM_CHECKBOX,
                            value: selectedDays.length === days.length
                        },
                        ...days.map(day => {
                            return {
                                fieldName: day,
                                name: day,
                                type: FormFieldType.CUSTOM_CHECKBOX,
                                value: selectedDays.includes(day)
                            }
                        })
                    ]}
                    onChange={(e: any) => {
                        const { name, value } = e.target;
                        if (name === "All") {
                            setSelectedDays(selectedDays.length < days.length ? [...days] : []);
                            return;
                        }

                        let selectedDaysCopy = [...selectedDays];
                        let ind = selectedDaysCopy.indexOf(name);
                        if (ind > -1 && !value) {
                            selectedDaysCopy.splice(ind, 1);
                        } else {
                            selectedDaysCopy.push(name);
                        }
                        setSelectedDays(selectedDaysCopy)
                    }}
                    commonXs={12}
                ></InputFormGrid>
            </Grid>
            <Grid container item columnGap={1} xs={8} justifyContent={"flex-start"}>
                <Grid container item xs={12} columnGap={1}>
                    {commonSchedules.map((obj, index) => {
                        return renderCommonRow(index);
                    })}
                    {commonSchedules.length < 3 && <Grid item>
                        <IconButton onClick={() => handleAddNewCommon()}><Add></Add></IconButton>
                    </Grid>}
                </Grid>
                {days.filter(day => selectedDays.includes(day)).map(day => {
                    let fileredSchedules = schedules.filter(obj => obj.day === day)
                    return <Grid container item xs={12} columnGap={1}>
                        {fileredSchedules.map((obj, index) => {
                            return renderDayRow(day, index);
                        })}
                        {fileredSchedules.length < 3 && <Grid item>
                            <IconButton onClick={() => handleAddNewDayTime(day, fileredSchedules.length)}><Add></Add></IconButton>
                        </Grid>}
                    </Grid>
                })}
            </Grid>
        </Grid>
        <ConfirmDialog
            open={!!(showConfirm)}
            yesText="Yes"
            noText="No"
            handleNo={() => setShowConfirm(false)}
            handleYes={() => handleCreateConfirm()}
            title="Confirmation"
        >
            {`Are you sure, you want to create a default schedule for ${env}?`}
        </ConfirmDialog>
    </ConfirmDialog>
}