import { AlertProps, Badge } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SocketManager } from '../../Socket';
import { NotificationsAPI, UserAPI } from '../../apis';
import CustomIcon from '../../components/Icons';
import { DeploymentStatus } from '../../interfaces/Deployments';
import { Notification } from '../../interfaces/Notifications';
import { JobStatus, ScheduleStatus } from '../../interfaces/Scheduler';
import { RootState, actions } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AuthState } from '../../store/reducers/authReducer';
import { SpecialPermissions } from '../../utils/constants';
import { ApiError } from '../../utils/errorHandler';
import { NotificationFlashMessage, Notifications } from './Notifications';

export default function NotificationsMenu() {
  const authState: AuthState = useAppSelector((state: RootState) => state.auth);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [unread, setUnread] = useState<boolean>(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [newNotification, setNewNotification] = useState<Notification | null>(null);
  const [newFlashNotification, setNewFlashNotification] = useState<Notification | null>(null);
  const [msgCounts, setMsgCounts] = useState<{
    all: number, unread: number
  }>({all: 0, unread: 0});
  const socket = SocketManager.getSocket();
  const [counts, setCounts] = useState<{
    totalResults: number;
    totalPages: number;
    currentResults: number;
  }>({
    totalResults: 0,
    totalPages: 0,
    currentResults: 0,
  })
  const initialPagination = {
    limit: 10, pageNumber: 1
  }
  const [pagination, setPagination] = useState<{limit: number, pageNumber: number}>(initialPagination);
  // const [reload, setReload] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null);
  const {  deployments, scheduler } = actions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      const res = await NotificationsAPI.getCount();
      if (res instanceof ApiError) {
        setMsgCounts({all:0, unread:0});
        return;
      }
      setMsgCounts(res.data);
    })()
  }, [])

  useEffect(() => {
    if (!open) {
      setPagination(initialPagination);
      setUnread(false);
    }
  }, [open])

  useEffect(() => {
    (async () => {
      let filters: any = {
        limit: pagination.limit, pageNumber: pagination.pageNumber
      }

      if (unread) {
        filters.isRead = false;
      }

      const res = await NotificationsAPI.getNotifications({...filters });
      if (res instanceof ApiError) {
        setNotifications([]);
        return;
      }
      setNotifications(res.data.results.map(result => result));
      setCounts({
        totalResults: res.data.totalResults,
        totalPages: res.data.totalPages,
        currentResults: res.data.currentResults,
      })
    })()
  }, [pagination, unread])

  useEffect(() => {
    (async () => {
      if (!socket || !authState.userId) {
        console.log("--------------------------returning no socket");
        return;
      }

      let uniquePrefix = `GroupNotifications.`;
      const res = await UserAPI.getUserPermissions({searchText: uniquePrefix});
      if (res instanceof ApiError) {
        // setSna
        return;
      }
      let permissions: string[] = res.data;

      let uniquePrefix2 = `Notifications.`;
      const res2 = await UserAPI.getUserPermissions({searchText: uniquePrefix2});
      if (res2 instanceof ApiError) {
        // setSna
        return;
      }
      let permissions2: string[] = res.data;

      if (permissions2.indexOf(SpecialPermissions.onlyIndividualNotifications) > -1) {
        socket.on(authState.userId, (notification: Notification) => {
            setNewNotification(notification); 
        });
      }

      for (let channel of permissions) {
        socket.on(channel, (notification: Notification) => {
          setNewFlashNotification(notification);
          if (permissions2.indexOf(SpecialPermissions.onlyIndividualNotifications) === -1) {
            setNewNotification(notification); 
          }
    
          if (channel === SpecialPermissions.deploymentGroupNotifications) {
            dispatch(deployments.reloadData())
          } else if (channel === SpecialPermissions.schedulerGroupNotifications) {
            dispatch(scheduler.reloadData())
          } else if (channel === SpecialPermissions.startStopGroupNotifications) {
            dispatch(scheduler.reloadStartStopData())
          }
        });
      }
    })()
  }, [authState.userId])

  useEffect(() => {
    if (newNotification) {
        const notificationsCopy = [newNotification, ...notifications];
        setNotifications(notificationsCopy);
        setMsgCounts({
          all: msgCounts.all+1,
          unread: msgCounts.unread+1
        })
    }
  }, [newNotification])

  useEffect(() => {
    if (newFlashNotification) {
        setSnackbar({
          children: (<NotificationFlashMessage notification={newFlashNotification}></NotificationFlashMessage>),
          severity: getSeverity(newFlashNotification)
        })
    }
  }, [newFlashNotification])

  

  const getSeverity = (notification: Notification) => {
    if (!(notification.msgType === "deployment" || notification.msgType === "scheduler" || notification.msgType === "start-stop")) {
        return "info";
    }
    let status = notification.msgType === "start-stop" ? notification.msgBody.data.jobStatus : notification.msgBody.data.status;
    switch(status) {
        case DeploymentStatus.Completed:
        case ScheduleStatus.Approved:
        case JobStatus.Completed:
          return "success";
        case DeploymentStatus.Removed:
        case ScheduleStatus.Removed:
            return "error";
        case DeploymentStatus.Failed:
        case JobStatus.Failed:
          return "error"
        case DeploymentStatus.Rejected:
        case ScheduleStatus.Rejected:
          return "warning";
        case DeploymentStatus.Raised:
        default:
            return "info";
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRead = async (id: string) => {
    const res = await NotificationsAPI.updateNotification(id, true);
    if (res instanceof ApiError) {
        console.log(res.message);
        return;
    }
    const notificationsCopy = [...notifications];
    const index = notificationsCopy.findIndex(notification => notification.msgId === id);
    if (index > -1) {
      notificationsCopy[index].isRead = true;
      setNotifications(notificationsCopy);
      setMsgCounts({
        all: msgCounts.all,
        unread: msgCounts.unread-1
      })
    }
  }

  const handleRemove = async (id: string, isRead: boolean) => {
    const res = await NotificationsAPI.removeNotification(id);
    if (res instanceof ApiError) {
        console.log(res.message);
        return;
    }
    setPagination({...pagination});
    setMsgCounts({
      all: msgCounts.all-1,
      unread: msgCounts.unread - (isRead ? 0 : 1)
    })
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleClick}
            size="small"
            aria-controls={open ? 'notifications-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Badge badgeContent={msgCounts.unread} color={"error"}>
              <CustomIcon
                  name={"notifications"}
                  iscustom="false"
                  className="sidebar-icon"
                  fontSize={"medium"}
              ></CustomIcon>
            </Badge>
          </IconButton>
        </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="notifications-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Notifications
          notifications={notifications}
          unread={unread}
          handleRead={handleRead}
          handleUnreadClick={(boolVal: boolean) => setUnread(boolVal)}
          handleRemove={handleRemove}
          totalCount={msgCounts.all}
          unreadCount={msgCounts.unread}
          handleLoadMore={() => {
            if (counts.totalResults > notifications.length) {
              let limit = pagination.limit+initialPagination.limit;
              setPagination({...pagination, limit: limit});  
            }
          }}
        ></Notifications>
      </Menu>
    </React.Fragment>
  );
}