import { Button, Container, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useEffect, useState } from 'react';
import { AdminAPI } from '../../apis';
import { TableWrapper } from '../../components/DataTables/TableWrapper';
import { User, UserFilters } from '../../interfaces/Accounts';
import { Pagination } from '../../interfaces/Common';
import { HeaderAction, RowAction } from '../../interfaces/Data';
import { RootState, actions } from '../../store';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ComponentName, TableComponents } from '../../utils/constants';
import { ApiError } from '../../utils/errorHandler';
import "./Accounts.css";
import { AccountsFilters } from './AccountsFilters';
import { ConfirmDialog } from '../../components/Dialogs/ConfirmDialog';


export const Accounts = () => {
  const columns = [
    { field: 'name', displayName: 'Name', },
    { field: 'email', displayName: 'Email', },
    { field: 'role', displayName: 'Role', },
  ];
  const rows: User[] = useAppSelector((state: RootState) => state.accounts.users);
  const headerActions: HeaderAction[] = useAppSelector((state: RootState) => state.common.headerActions[ComponentName.Accounts]);
  const rowActions: RowAction[] = useAppSelector((state: RootState) => state.common.rowActions[ComponentName.Accounts]);
  const filters: UserFilters = useAppSelector((state: RootState) => state.accounts.filters);
  const selections: { [k: string]: boolean } = useAppSelector((state: RootState) => state.accounts.selections);
  const pagination: Pagination = useAppSelector((state: RootState) => state.accounts.pagination);
  const reload: { randomNumber: number } = useAppSelector((state: RootState) => state.accounts.reload);
  const [actionClicked, setActionClicked] = useState<RowAction | HeaderAction | null>(null);
  const [rowClicked, setRowClicked] = useState<User>();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { accounts, common } = actions;

  useEffect(() => {
    (async () => {
      const res = await AdminAPI.getUsersList({
        limit: pagination.limit,
        pageNumber: pagination.pageNumber
      }, { ...filters });
      if (res instanceof ApiError) {
        dispatch(common.processApiError(res));
        return;
      }

      if (res && res.data) {
        dispatch(accounts.setUsers(res.data.results));
        dispatch(accounts.setResultCounts({
          totalPages: res.data.totalPages,
          totalResults: res.data.totalResults,
          currentResults: res.data.currentResults
        }))
      }

    })()
  }, [pagination.limit, pagination.pageNumber, filters, reload]);

  const handleConfirm = async () => {
    switch (actionClicked) {
      case HeaderAction.VERIFY:
        if (Object.keys(selections).length > 0) {
          await AdminAPI.bulkVerifyUser({ ids: Object.keys(selections) });
        }
        break;
      case HeaderAction.BLOCK:
        if (Object.keys(selections).length > 0) {
          await AdminAPI.bulkDBlockUsers({ ids: Object.keys(selections) });
        }
        break;
    }
    setActionClicked(null);
    setRowClicked(undefined);
    setShowConfirm(false);
    dispatch(accounts.reloadData());
  }

  const handleHeaderAction = async (action: HeaderAction) => {
    setActionClicked(action);
    switch (action) {
      case HeaderAction.VERIFY:
      case HeaderAction.BLOCK:
        if (Object.keys(selections).length > 0) {
          setShowConfirm(true)
        }
        break;
      case HeaderAction.SELECT_ALL:
        dispatch(accounts.selectAll())
        break;
      case HeaderAction.UNSELECT:
        dispatch(accounts.unselectAll())
        break;
      default:
        console.log(action);

    }
  }

  const handleRowAction = async (action: RowAction, row: User) => {
    setActionClicked(action);
    switch (action) {
      case RowAction.BLOCK:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.CHECKBOX:
        if (row._id && selections[row._id]) {
          dispatch(accounts.unselectRow({ _id: row._id }));
        } else if (row._id) {
          dispatch(accounts.selectRow({ _id: row._id }));
        }
        break;
      default:
        console.log(action);
    }
  }

  return (
    <Container maxWidth={false} className='table-view'>
      <AccountsFilters></AccountsFilters>
      <TableWrapper<User>
        componentName={TableComponents.Accounts}
        columns={columns}
        rows={rows}
        selections={selections}
        headerActions={headerActions}
        handleHeaderAction={handleHeaderAction}
        handleLimitChange={(limit: number) => dispatch(accounts.setPagination({ limit: limit, pageNumber: pagination.pageNumber }))}
        handlePageChange={(pageNumber: number) => dispatch(accounts.setPagination({ limit: pagination.limit, pageNumber: pageNumber }))}
        pagination={pagination}
        rowActions={rowActions}
        handleRowAction={handleRowAction}
        inlineEdit={true}
      ></TableWrapper>
      <ConfirmDialog
        yesText='Yes'
        noText='No'
        open={showConfirm}
        handleNo={() => setShowConfirm(false)}
        handleYes={() => handleConfirm()}
        title='Accounts Action Confirmation'
      >{`Are you sure, you want to proceed with the action?`}</ConfirmDialog>
    </Container>
  );
}
