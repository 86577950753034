import { Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { SchedulerAPI } from "../../apis";
import { HeaderAction, RowAction } from "../../interfaces/Data";
import { Day, DefaultSchedule, ScheduleTime } from "../../interfaces/Scheduler";
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { SubComponentName } from "../../utils/constants";
import { get24TimeStr } from "../../utils/dateUtils";
import { ApiError } from "../../utils/errorHandler";
import { CreateDefaultSchedulePage } from "./CreateDefaultSchedulePage";
import { EditDefaultSchedulePage } from "./EditDefaultSchedulePage";
import "./ScheduleCalendar.css";

export const DefaultSchedulePage = () => {
    const headerActions: HeaderAction[] = useAppSelector((state: RootState) => state.common.headerActions[SubComponentName.DefaultSchedules]);
    const rowActions: RowAction[] = useAppSelector((state: RootState) => state.common.rowActions[SubComponentName.DefaultSchedules]);
    const reload: number = useAppSelector((state: RootState) => state.scheduler.reloadDefault.randomNumber);
    const [defaultSchedules, setDefaultSchedules] = useState<DefaultSchedule[]>([]);
    const [showCreate, setShowCreate] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [canCreate, setCanCreate] = useState<boolean>(false);
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        if (headerActions.length === 0 && rowActions.length === 0) {
            return;
        }
        setCanCreate(headerActions.indexOf(HeaderAction.ADD_NEW) > -1);
        setCanEdit(rowActions.indexOf(RowAction.EDIT_ROW) > -1);
    }, [headerActions, rowActions])

    useEffect(() => {
        (async () => {
            const res = await SchedulerAPI.getDefaultSchedules({
                limit: 100, pageNumber: 1,
            }, {});
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setDefaultSchedules(res.data.results);
        })()
    }, [reload])

    const getSchedule = (objs: ScheduleTime[]) => {
        return <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            {objs.map(obj => {
                let startStr = get24TimeStr(new Date(obj.startTime));
                let stopStr = get24TimeStr(new Date(obj.stopTime));
                if (startStr === "00:00" && stopStr === "00:00") {
                    return <span>All Day</span>
                }
                if (stopStr === "00:00") {
                    stopStr = "24:00"
                }
                return <span>{`${startStr}-${stopStr}`}</span>
            })}            
        </div>
    }

    if (defaultSchedules.length === 0) {
        return <></>
    }

    return <Container maxWidth={false} className="default-schedule">
        {(canEdit || canCreate) && <Container maxWidth={false} className="button-container">
            {canCreate && <Button variant="contained" onClick={() => setShowCreate(true)}>Add Schedule</Button>}
            {canEdit && <Button variant="contained" onClick={() => setShowEdit(true)}>Edit Schedule</Button>}
        </Container>}
        <Container maxWidth={false} className="schedule-container">
            <TableContainer sx={{width: "90vw", height: "80vh", overflow: "auto"}}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="day"></TableCell>
                            {defaultSchedules.map(obj => {
                                return <TableCell className="schedule-block-env">{obj.env}</TableCell>
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.values(Day).map(day => {
                            return <TableRow>
                                <TableCell className="day">{day}</TableCell>
                                {defaultSchedules.map(obj => {
                                    let daySchedules = obj.schedule.filter(obj2 => obj2.day === day)
                                    return <TableCell>{daySchedules.length > 0 ? getSchedule(daySchedules): ""}</TableCell>
                                })}
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
        {showCreate && <CreateDefaultSchedulePage showModal={showCreate} handleClose={() => setShowCreate(false)}></CreateDefaultSchedulePage>}
        {showEdit && <EditDefaultSchedulePage showModal={showEdit} handleClose={() => setShowEdit(false)}></EditDefaultSchedulePage>}
    </Container>
}