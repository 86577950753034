import urls from "./urls";
import api from "./axiosInstance";
import { ApiDataRes, ApiMsgRes, Environment, ListApiRes } from "../interfaces/Common";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import { AxiosResponse } from "axios";
import { AllEnvsSnapshot, AllServicesSnapshot, ComparisonData, PropertiesSnapshotShortData, ReposRes, SnapshotShortData, TopicsSnapshot } from "../interfaces/Comparator";
import { ImageRes, CommonRes } from "../interfaces/Comparator";

export default class ComparatorAPI {
  public static async getPropertiesComparison(payload: {
    serviceName: string,
    env1: string;
    env2: string;
  }): Promise<ComparisonData | ApiError> {
    try {
      const response: AxiosResponse<{
        message: string;
        data: ComparisonData;
      }> = await api.post(urls.compareProperties, {
        ...payload,
      });
      return response.data.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getPropertiesFilters(fieldName: string, filters?: {
    serviceName?: string[];
    env?: string[],
    searchText?: string
  }): Promise<ApiDataRes<string[]> | ApiError> {
    try {
      const response: AxiosResponse<ApiDataRes<string[]>> = await api.get(
        `${urls.compareProperties}/filters/${fieldName}`,
        {
          params: filters
        }
      );
      return response.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async saveRepoStructure(): Promise<ApiMsgRes | ApiError> {
    try {
      const response: AxiosResponse<ApiMsgRes> = await api.post(
        `${urls.compareProperties}/structure`
      );
      return response.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getAllFilenames(): Promise<ReposRes | ApiError> {
    try {
      const response: AxiosResponse<ApiDataRes<ReposRes>> = await api.get(
        `${urls.compareProperties}/filenames`,
      );
      return response.data.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async createPropertiesSnapshots(payload: {
    serviceNames: string[],
    envs: string[],
    tags: string[]
  }): Promise<ApiMsgRes | ApiError> {
    try {
      const response: AxiosResponse<ApiMsgRes> = await api.post(urls.propertiesSnapshots, {
        ...payload
      });
      return response.data || [];
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getPropertiesSnapshots(pagination: {
    limit: number,
    pageNumber: number
  }, filters: any): Promise<ListApiRes<PropertiesSnapshotShortData> | ApiError> {
    try {
      const filtersObj: any = {};
      for (let key in filters) {
        if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
          if (Array.isArray(filters[key])) {
            filtersObj[key] = filters[key].filter((filter: any) => filter !== "");
          } else {
            filtersObj[key] = filters[key]
          }
        }
      }
      const response: AxiosResponse<ListApiRes<PropertiesSnapshotShortData>> = await api.get(urls.propertiesSnapshots, {
        params: {
          ...pagination,
          ...filtersObj
        }
      });
      return response.data || [];
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getAggregatedPropertiesSnapshots(pagination: {
    limit: number,
    pageNumber: number
  }, filters: any): Promise<ListApiRes<AllServicesSnapshot> | ApiError> {
    try {
      const filtersObj: any = {};
      for (let key in filters) {
        if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
          if (Array.isArray(filters[key])) {
            filtersObj[key] = filters[key].filter((filter: any) => filter !== "");
          } else {
            filtersObj[key] = filters[key]
          }
        }
      }
      const response: AxiosResponse<ListApiRes<AllServicesSnapshot>> = await api.get(`${urls.propertiesSnapshots}/aggregate`, {
        params: {
          ...pagination,
          ...filtersObj
        }
      });
      return response.data || [];
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getPropertiesSnapshotsComparison(payload: {
    id1: string;
    id2: string;
  }): Promise<ApiDataRes<ComparisonData> | ApiError> {
    try {
      const response: AxiosResponse<{
        message: string;
        data: ComparisonData;
      }> = await api.post(urls.propertiesSnapshotsDiff, {
        ...payload,
      });
      return response.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getAllPropertiesSnapshotsComparison(payload: {
    date1: Date;
    date2: Date;
  }): Promise<ApiDataRes<{
      env: string,
      diff: ComparisonData,
      snapshots: string[],
      serviceName: string
  }[]> | ApiError> {
    try {
      const response: AxiosResponse<{
        message: string;
        data: {
          env: string,
          diff: ComparisonData,
          snapshots: string[],
          serviceName: string,
      }[];
      }> = await api.post(urls.propertiesSnapshotsAllDiff, {
        ...payload,
      });
      return response.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async downloadAllPropertiesSnapshotsComparison(payload: {
    date1: Date;
    date2: Date;
  }): Promise<Blob | ApiError> {
    try {
      const response: AxiosResponse<Blob> = await api.post(`${urls.propertiesSnapshotsAllDiff}/download`, {
        ...payload,
      }, {
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getKafkaTopicsComparison(payload: {
    sourceEnv: string;
    targetEnv: string;
  }): Promise<string[] | ApiError> {
    try {
      const response: AxiosResponse<{
        message: string;
        data: string[];
      }> = await api.post(urls.kafkaTopicsCompare, {
        ...payload,
      });
      return response.data.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getTopicsSnapshots(pagination: {
    limit: number,
    pageNumber: number
  }, filters: any): Promise<ListApiRes<SnapshotShortData> | ApiError> {
    try {
      const filtersObj: any = {};
      for (let key in filters) {
        if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
          if (Array.isArray(filters[key])) {
            filtersObj[key] = filters[key].filter((filter: any) => filter !== "");
          } else {
            filtersObj[key] = filters[key]
          }
        }
      }
      const response: AxiosResponse<ListApiRes<SnapshotShortData>> = await api.get(urls.kafkaSnapshots, {
        params: {
          ...pagination,
          ...filtersObj
        }
      });
      return response.data || [];
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getAggregatedTopicsSnapshots(pagination: {
    limit: number,
    pageNumber: number
  }, filters: any): Promise<ListApiRes<AllEnvsSnapshot> | ApiError> {
    try {
      const filtersObj: any = {};
      for (let key in filters) {
        if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
          if (Array.isArray(filters[key])) {
            filtersObj[key] = filters[key].filter((filter: any) => filter !== "");
          } else {
            filtersObj[key] = filters[key]
          }
        }
      }
      const response: AxiosResponse<ListApiRes<AllEnvsSnapshot>> = await api.get(`${urls.kafkaSnapshots}/aggregate`, {
        params: {
          ...pagination,
          ...filtersObj
        }
      });
      return response.data || [];
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getTopicsFilters(fieldName: string, pagination: {
    limit: number,
    pageNumber: number
  }, filters: any): Promise<ApiDataRes<string[]> | ApiError> {
    try {
      const filtersObj: any = {};
      for (let key in filters) {
        if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
          if (Array.isArray(filters[key])) {
            filtersObj[key] = filters[key].filter((filter: any) => filter !== "");
          } else {
            filtersObj[key] = filters[key]
          }
        }
      }
      const response: AxiosResponse<ApiDataRes<string[]>> = await api.get(urls.kafkaFilters, {
        params: {
          searchField: fieldName,
          ...pagination,
          ...filtersObj
        }
      });
      return response.data || [];
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async createTopicsSnapshots(payload: {
    envs: Environment[],
    lastOverRide: boolean,
    tags: string[]
  }): Promise<ApiMsgRes | ApiError> {
    try {
      const response: AxiosResponse<ApiMsgRes> = await api.post(urls.kafkaSnapshots, {
        ...payload
      });
      return response.data || [];
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getTopicsSnapshotDetail(id: string): Promise<ApiDataRes<TopicsSnapshot> | ApiError> {
    try {
        const response: AxiosResponse<ApiDataRes<TopicsSnapshot>> = await api.get(`${urls.kafkaSnapshots}/${id}`);
        return response.data;
    } catch (error) {
        return AxiosErrorHandler(error);
        
    }
  }

  public static async getKafkaSnapshotsComparison(payload: {
    id1: string;
    id2: string;
  }): Promise<ApiDataRes<{ common: string[], onlyInEnv1: string[], onlyInEnv2: string[] }> | ApiError> {
    try {
      const response: AxiosResponse<{
        message: string;
        data: { common: string[], onlyInEnv1: string[], onlyInEnv2: string[] };
      }> = await api.post(urls.kafkaSnapshotsDiff, {
        ...payload,
      });
      return response.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getAllKafkaSnapshotsComparison(payload: {
    date1: Date;
    date2: Date;
  }): Promise<ApiDataRes<{
      env: string,
      diff: { common: string[], onlyInEnv1: string[], onlyInEnv2: string[] },
      snapshots: string[]
  }[]> | ApiError> {
    try {
      const response: AxiosResponse<{
        message: string;
        data: {
          env: string,
          diff: { common: string[], onlyInEnv1: string[], onlyInEnv2: string[] },
          snapshots: string[]
      }[];
      }> = await api.post(urls.kafkaSnapshotsAllDiff, {
        ...payload,
      });
      return response.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async downloadAllKafkaSnapshotsComparison(payload: {
    date1: Date;
    date2: Date;
  }): Promise<Blob | ApiError> {
    try {
      const response: AxiosResponse<Blob> = await api.post(`${urls.kafkaSnapshotsAllDiff}/download`, {
        ...payload,
      }, {
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }

  public static async getImages(payload: {
    servicename: string;
  }): Promise<CommonRes<ImageRes[]> | ApiError> {
    try {
      const response = await api.post(urls.ImageCompare, {
        ...payload,
      });
      return response.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }
}
