import { Autocomplete, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";
import "./BuildCycle.css";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../components/Wrapper/Inputs/InputFormGrid";
import BuildCycleAPI from "../../apis/buildcycle";
import { BuildCycleDetail } from "../../interfaces/Buildcycle";
import { updatedBuildCycle } from "../../interfaces/Buildcycle";

export const CreatePromotions = (props: {
    handleClose: Function,
    showModal: boolean,
    deploymentObj: BuildCycleDetail
    // handleReload: Function
}) => {
    const [buildCycleObj, setbuildCycleObj] = useState<updatedBuildCycle>({
        _id: props.deploymentObj._id,
        cyclename: props.deploymentObj.cyclename,
        buildcycle: props.deploymentObj.buildcycle,
        columnName: ""
    });

    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const dispatch = useAppDispatch();
    const { common } = actions;
    const [columnOptions, setColumnOptions] = useState<string[]>(["Promotion", "Quality Checkpoint"]);
    const [selectedOption, setSelectedOption] = useState<string>("Promotion");

    useEffect(() => {
        if ((buildCycleObj.columnName != "")) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }
    }, [buildCycleObj])


    const handleCreateConfirm = async () => {

        dispatch(common.setShowLoader(true));

        let res;
        if (selectedOption === "Promotion") {
            res = await BuildCycleAPI.addPromotionCloumn(buildCycleObj);
        }
        else if (selectedOption === "Quality Checkpoint") {
            res = await BuildCycleAPI.addQualityCloumn(buildCycleObj);
        }

        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setShowConfirm(false);
        // setbuildCycleObj({
        //     cyclename: ""
        // });
        dispatch(common.setSnackbar({ children: "Successfully added New Column", severity: "success" }));
        props.handleClose();
        dispatch(common.setShowLoader(false));
    }


    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setbuildCycleObj({
            ...buildCycleObj,
            [name]: value,

        });
    };

    const handleInputDropdown = (e: any) => {
        const { name, value } = e.target;
        setSelectedOption(value);
    }

    const handleClose = () => {
        props.handleClose()
    }

    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }

    return <ConfirmDialog
        yesText="Create"
        noText="Cancel"
        title="Add New Column to BRC"
        open={props.showModal}
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        disableYes={!canSubmit}
    >
        <Container maxWidth={false} className="create-deployment-list">
            <Grid container justifyContent={"center"} rowGap={1} alignItems={"center"}>
                <InputFormGrid
                    formFields={[{
                        fieldName: "Module",
                        name: "cloumn",
                        options: columnOptions,
                        type: FormFieldType.CUSTOM_SELECT,
                        value: selectedOption
                    }]}
                    onChange={handleInputDropdown}
                    commonXs={12}
                ></InputFormGrid>
                <InputFormGrid
                    formFields={[{
                        fieldName: "Column Name",
                        name: "columnName",
                        value: buildCycleObj.columnName
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
            </Grid>
            {!showConfirm ? <></> : <ConfirmDialog
                open={showConfirm}
                yesText="Yes"
                noText="No"
                title="Confirmation"
                handleNo={() => setShowConfirm(false)}
                handleYes={() => handleCreateConfirm()}
            >
                {`Are you sure, you want to add a column in ${buildCycleObj.cyclename}?`}
            </ConfirmDialog>}
        </Container>
    </ConfirmDialog>
}