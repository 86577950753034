import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hooks";
import { actions } from "../../store";
import { AuthAPI } from "../../apis";
import LogoutIcon from '@mui/icons-material/Logout';

export const  Logout = () => {
    const [showLogoutModal, setShowLogoutModal] = useState(false);
    const { auth } = actions;
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await AuthAPI.logout();
        setShowLogoutModal(false);
        dispatch(auth.setAuthStatus(false));
        navigate("/");
    }

    return <>
        <MenuItem onClick={() => setShowLogoutModal(true)}>
          <ListItemIcon>
            <LogoutIcon></LogoutIcon>
          </ListItemIcon>
          Logout
        </MenuItem>
        <Dialog
            maxWidth="xs"
            open={!!(showLogoutModal)}
        >
            <DialogTitle>{`Are you sure, you want to Logout?`}</DialogTitle>
            <DialogActions>
                <Button onClick={() => setShowLogoutModal(false)}>
                    No
                </Button>
                <Button onClick={handleLogout}>Yes</Button>
            </DialogActions>
        </Dialog>
    </>
}