export const getBreadcrumbName = (breadcrumb: string) => {
    const splits: any = [];
    if (breadcrumb.indexOf("_") > -1) {
        breadcrumb.split("_").forEach(str => {
            if (str) {
                const strSplits = str.split('');
                strSplits[0] = strSplits[0].toLocaleUpperCase();
                splits.push(strSplits.join(''));    
            }
        });    
    } else {
        const strSplits = breadcrumb.split('');
        strSplits[0] = strSplits[0].toLocaleUpperCase();
        splits.push(strSplits.join(''));
    }
    return splits.join(" ");
}

export const onlyUnique = (value: any, index: number, array: any[]) => {
    return array.indexOf(value) === index;
}

export const uppercaseStart = (val: string) => {
    if (!val) {
        return val;
    }
    const modStr = val[0].toUpperCase() + val.slice(1);
    return modStr;
}