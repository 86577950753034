const baseUrl = `${process.env.API_ENDPOINT}/devops-dashboards/api/v0`;
const authEndPoint = `${baseUrl}/auth`;
const adminEndPoint = `${baseUrl}/admin`;
const userEndPoint = `${baseUrl}/user`;
const rolesEndPoint = `${baseUrl}/roles`;
const deploymentsEndPoint = `${baseUrl}/deployments`;
const pipelinesEndPoint = `${baseUrl}/pipelines`;
const comparatorEndPoint = `${baseUrl}/comparator`;
const gitlabEndPOint=`${baseUrl}/gitrepo`
const dbaccessEndPOint = `${baseUrl}/dbaccess`
const configsEndpoint = `${baseUrl}/configs`;
const schedulerEndpoint = `${baseUrl}/scheduler`;
const notificationsEndpoint = `${baseUrl}/notifications`;
const testsLogsEndpoint = `${baseUrl}/automationTestsLogs`;
const buildcycleEndpoint = `${baseUrl}/buildcycle`

const urls = {
    baseUrl,
    login: `${authEndPoint}/login`,
    msLogin: `${authEndPoint}/mslogin`,
    refresh: `${authEndPoint}/refreshToken`,
    forgotPassword: `${authEndPoint}/forgotPassword`,
    resetPassword: `${authEndPoint}/resetPassword`,
    register: `${authEndPoint}/register`,
    logout: `${authEndPoint}/logout`,
    changePassword: `${authEndPoint}/changePassword`,
    accessCheck: `${authEndPoint}/check`,
    userRole: `${userEndPoint}/role`,
    userData: `${userEndPoint}/profile`,
    userFilter: `${userEndPoint}/filter`,
    userPermissions: `${userEndPoint}/permissions`,
    deployments: deploymentsEndPoint,
    downloadDeployments: `${deploymentsEndPoint}/download`,
    roles: `${rolesEndPoint}`,
    permissions: `${rolesEndPoint}/permissions`,
    users: `${adminEndPoint}/users`,
    bulkVerifyUsers: `${adminEndPoint}/userStatus/verify`,
    bulkBlockUsers: `${adminEndPoint}/userStatus/disable`,
    compareProperties: `${comparatorEndPoint}/properties`,
    propertiesSnapshots: `${comparatorEndPoint}/properties/snapshots`,
    propertiesSnapshotsDiff: `${comparatorEndPoint}/properties/snapshots/diff`,
    propertiesSnapshotsAllDiff: `${comparatorEndPoint}/properties/snapshots/allDiff`,
    kafkaTopicsCompare: `${comparatorEndPoint}/kafkaTopics`,
    kafkaSnapshots: `${comparatorEndPoint}/kafkaTopics/snapshots`,
    kafkaFilters: `${comparatorEndPoint}/kafkaTopics/snapshots/filters`,
    kafkaSnapshotsDiff: `${comparatorEndPoint}/kafkaTopics/snapshots/diff`,
    kafkaSnapshotsAllDiff: `${comparatorEndPoint}/kafkaTopics/snapshots/allDiff`,
    ImageCompare: `${comparatorEndPoint}/images/service`,
    pipelines: pipelinesEndPoint,
    configs: `${configsEndpoint}`,
    branchNamesMap: `${configsEndpoint}/envBranchMap`,
    srcTargetMap: `${configsEndpoint}/srcTargetMap`,
    taggingPipelines: `${configsEndpoint}/taggingPipelinesMap`,
    moduleServiceMap: `${configsEndpoint}/moduleServiceMap`,
    kafkaEndPointMap: `${configsEndpoint}/kafkaEndPointsMap`,
    dbAccess: `${configsEndpoint}/dbAccessMap`,
    scheduler: schedulerEndpoint,
    defaultSchedules: `${schedulerEndpoint}/default`,
    lambdas: `${schedulerEndpoint}/lambdas`,
    holidays: `${schedulerEndpoint}/holidays`,
    currentStatus: `${schedulerEndpoint}/runStatus`,
    runHistory: `${schedulerEndpoint}/runHistory`,
    weekSchedule: `${schedulerEndpoint}/weekSchedule`,
    notifications: notificationsEndpoint ,
    gitlabapi:`${gitlabEndPOint}`,
    dbaccessapi: `${dbaccessEndPOint}`,
    testsLogs: testsLogsEndpoint,
    buildCycle: `${buildcycleEndpoint}`,
}

export default urls;