import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, IconButton, SxProps } from "@mui/material"
import React from "react"
import "./ConfirmDialog.css";
import { Cancel } from "@mui/icons-material";

export interface ConfirmDialogProps extends DialogProps {
    title: string,
    handleNo: Function,
    children?: React.ReactElement | React.ReactElement[] | any,
    noText?: string,
    dialogSx?: SxProps
}

export const InfoDialog = (props: ConfirmDialogProps) => {
    const handleNo = () => {
        props.handleNo()
    }

    return <Dialog
        maxWidth={props.maxWidth || "xl"}
        open={props.open}
        className="confirm-dialog"
        fullScreen={props.fullScreen}
        sx={props.dialogSx}
    >
        <DialogTitle className="title-wrapper">
            <div className="title">
                <span>{props.title}</span>
            </div>
            <IconButton
                onClick={() => handleNo()}
                className="cancel-button"
            >
                <Cancel sx={{fontSize: "20px", color: "white"}}></Cancel>
            </IconButton>
        </DialogTitle>
        <DialogContent
            className="content"
            sx={props.sx}
        >{props.children}</DialogContent>
        <DialogActions className="actions">
            <Button className="no-button" onClick={handleNo}>{props.noText || "No"}</Button>
        </DialogActions>
    </Dialog>  
}