import { Container, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { TableWrapper } from '../../../components/DataTables/TableWrapper';
import { ConfirmDialog } from '../../../components/Dialogs/ConfirmDialog';
import { Pagination, SortOrder } from '../../../interfaces/Common';
import { HeaderAction, RowAction } from '../../../interfaces/Data';
import { AutomationTestsLog, AutomationTestsFilters } from '../../../interfaces/Comparator';
import { RootState, actions } from '../../../store';
import { useAppDispatch, useAppSelector, useIsTabActive } from '../../../store/hooks';
import { TableComponents } from '../../../utils/constants';
import { ApiError } from '../../../utils/errorHandler';
import "./TestsLogs.css";
import { AutomationTestsLogsFilterGrid } from './TestsLogsFilterGrid';
import { TestsLogsAPI } from '../../../apis';


export const AutomationTestsLogs = () => {
  const columns = [
    { field: 'buildId', displayName: 'Build Id', },
    { field: 'type', displayName: 'Type' },
    { field: 'status', displayName: 'Status' },
    { field: 'buildtime', displayName: 'Build Time' },
    { field: 'commonbranch', displayName: 'Common Branch' },
    { field: 'MR_ID', displayName: 'MR ID' },
    { field: 's3logslink', displayName: 'S3 Link' },
    { field: 'FailedStage', displayName: 'Failed Stage' },
    { field: 'ServiceName', displayName: 'Service Name' },
    { field: 'createdAt', displayName: 'Created On' },
  ];
  const rows: AutomationTestsLog[] = useAppSelector((state: RootState) => state.testsLogs.testsLogs);
  const headerActions = useAppSelector((state: RootState) => state.common.headerActions[TableComponents.TestsLogs]);
  const rowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.TestsLogs]);
  const selections: { [k: string]: boolean } = useAppSelector((state: RootState) => state.testsLogs.selections);
  const filters: AutomationTestsFilters = useAppSelector((state: RootState) => state.testsLogs.filters);
  const pagination: Pagination = useAppSelector((state: RootState) => state.testsLogs.pagination);
  const reload: {randomNumber: number} = useAppSelector((state: RootState) => state.testsLogs.reload);
  const [actionClicked, setActionClicked] = useState<RowAction | HeaderAction | null>(null);
  const [rowClicked, setRowClicked] = useState<AutomationTestsLog | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const isTabVisible = useIsTabActive();
  const dispatch = useAppDispatch();
  const { testsLogs, common } = actions;

  useEffect(() => {
    if (!isTabVisible) {
      return;
    }

    (async () => {
      const res = await TestsLogsAPI.getAutomationTestsLogs({
        pageNumber: pagination.pageNumber,
        limit: pagination.limit
      },{...filters});

      if (res instanceof ApiError) {
        dispatch(common.setSnackbar({children: res.message, severity: "error"}));
        return;
      }

      dispatch(testsLogs.setAutomationTestsLogs(res.data.results));
      dispatch(testsLogs.setResultCounts({
        totalPages: res.data.totalPages,
        totalResults: res.data.totalResults,
        currentResults: res.data.currentResults
      }))
    })()
  }, [pagination.limit, pagination.pageNumber, filters, reload.randomNumber, isTabVisible]);

  const handleConfirm = async () => {
    setShowConfirm(true);
    let res;
    switch (actionClicked) {
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          res = await TestsLogsAPI.bulkRemoveAutomationTestsLogs({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.DELETE_ROW:
        if (rowClicked && rowClicked._id) {
          res = await TestsLogsAPI.bulkRemoveAutomationTestsLogs({ids: [rowClicked._id]});
        }
        break;
    }

    if (res instanceof ApiError) {
      dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
    }

    setActionClicked(null);
    setRowClicked(null);
    setShowConfirm(false);
    setShowConfirm(false);
  }

  const handleHeaderAction = async (action: HeaderAction) => {
    setActionClicked(action);
    switch (action) {
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          setShowConfirm(true)
        }
        break;
      case HeaderAction.ADD_NEW:
        // setRenderNewRow(true);
        // setShowCreateForm(true);
        break;
      case HeaderAction.SELECT_ALL:
        dispatch(testsLogs.selectAll())
        break;
      case HeaderAction.UNSELECT:
        dispatch(testsLogs.unselectAll())
        break;
      default:
        console.log(action);

    }
  }

  const handleRowAction = async (action: RowAction, row: AutomationTestsLog) => {
    setActionClicked(action);
    switch (action) {
      case RowAction.DELETE_ROW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.APPROVE:
          setShowConfirm(true);
          setRowClicked(row);
          break;
      case RowAction.REJECT:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.REVIEW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.CHECKBOX:
        if (row._id && selections[row._id]) {
          dispatch(testsLogs.unselectRow({ _id: row._id }));
        } else if (row._id) {
          dispatch(testsLogs.selectRow({ _id: row._id }));
        }
        break;
      case RowAction.EDIT_ROW:
        setRowClicked(row);
        // setShowEditForm(true);
        break;
      default:
        console.log(action);
    }
  }

  const handleRowClick = (row: AutomationTestsLog) => {
    if (row._id && selections[row._id]) {
      dispatch(testsLogs.unselectRow({_id: row._id}));
    } else if (row._id) {
      dispatch(testsLogs.selectRow({_id: row._id}));
    }
  }

  return (
    <Container maxWidth={false} className='table-view'>
      <AutomationTestsLogsFilterGrid></AutomationTestsLogsFilterGrid>
      <TableWrapper<AutomationTestsLog>
          componentName={TableComponents.TestsLogs}
          columns={columns}
          rows={rows}
          selections={selections}
          headerActions={headerActions}
          handleHeaderAction={handleHeaderAction}
          handleLimitChange={(limit: number) => dispatch(testsLogs.setPagination({ pageNumber: pagination.pageNumber, limit: limit}))}
          handlePageChange={(pageNumber: number) => dispatch(testsLogs.setPagination({ limit: pagination.limit, pageNumber: pageNumber}))}
          pagination={pagination}
          rowActions={rowActions}
          handleRowAction={handleRowAction}
          rowClickFn={handleRowClick}
          isStatusActions={false}
      ></TableWrapper>
      <ConfirmDialog
            open={showConfirm}
            handleNo={() => setShowConfirm(false)}
            handleYes={handleConfirm}
            title='Schedule Updated Confirmation'
        >
            {`Are you sure, you want to ${actionClicked}?`}
        </ConfirmDialog>
    </Container>
  );
}
