import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserFilters } from '../../interfaces/Accounts';
import { Pagination } from '../../interfaces/Common';

export interface AccountsState {
    users: User[],
    pagination: Pagination,
    filters: UserFilters,
    selections: {[k:string]: boolean},
    reload: {
        randomNumber: number
    }
};


const getInitialState = (): AccountsState => {
    const initialState: AccountsState = {
        users: [],
        pagination: {
            pageNumber: 1,
            limit: 10,
            currentResults: 0,
            totalPages: 0,
            totalResults: 0        
        },
        filters: {},
        selections: {},
        reload: {
            randomNumber: 0
        }
    }

    return initialState;
}

const AccountsReducer = {
    setUsers: (state: AccountsState,
        action: PayloadAction<User[]>) => {
            state.users = action.payload;
    },
    setResultCounts: (state: AccountsState, action: PayloadAction<{
        totalPages: number,
        currentResults: number,
        totalResults: number
    }>) => {
        state.pagination.currentResults = action.payload.currentResults;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalResults = action.payload.totalResults;
    },
    setPagination: (state: AccountsState, action: PayloadAction<{limit: number, pageNumber: number}>) => {
        state.pagination.pageNumber = action.payload.pageNumber;
        state.pagination.limit = action.payload.limit;
    },
    reloadData: (state: AccountsState) => {
        state.reload.randomNumber = Math.random();
    },
    setFilters: (state: AccountsState, action: PayloadAction<UserFilters>) => {
        state.filters = action.payload;
    },
    selectRow: (state: AccountsState, action: PayloadAction<{_id: string}>) => {
        state.selections[action.payload._id] = true;
    },
    unselectRow: (state: AccountsState, action: PayloadAction<{_id: string}>) => {
        delete state.selections[action.payload._id];
    },
    selectAll: (state: AccountsState) => {
        for (const row of state.users) {
            if (row._id) {
                state.selections[row._id] = true;
            }
        }
    },
    unselectAll: (state: AccountsState) => {
        state.selections = {}
    },
}

export const accountsSlice: Slice<AccountsState, typeof AccountsReducer> = createSlice({
    name: 'accounts',
    initialState: getInitialState(),
    reducers: AccountsReducer,
})