import { IconButton, Container, TableCell, TableHead, TableRow, useMediaQuery } from "@mui/material"
import "./Data.css";
import { HeaderAction } from "../../interfaces/Data";
import CustomIcon from "../Icons";
import { AddBox, ArrowDownward, ArrowUpward, BlockSharp, CheckBox, CheckBoxOutlineBlank, Delete, Download, Grading, Replay, ThumbDown, ThumbUp, VerifiedUser } from "@mui/icons-material";
import { TableComponents } from "../../utils/constants";
import { SortOrder } from "../../interfaces/Common";


export const HeaderActions = (props: {
    actions: HeaderAction[],
    handleAction: (action: HeaderAction) => void
}) => {
    return <div className="table-actions-row">{props.actions.map((item, index) => {
        console.log(item);
        switch(item) {
            case HeaderAction.ADD_NEW:
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><AddBox></AddBox></IconButton>
            case HeaderAction.REVIEW: 
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><Grading></Grading></IconButton>
            case HeaderAction.APPROVE: 
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><ThumbUp></ThumbUp></IconButton>
            case HeaderAction.REJECT: 
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><ThumbDown></ThumbDown></IconButton>
            case HeaderAction.MULTI_DELETE: 
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><Delete></Delete></IconButton>
            case HeaderAction.REBUILD: 
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><Replay></Replay></IconButton>
            case HeaderAction.SELECT_ALL: 
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><CheckBoxOutlineBlank></CheckBoxOutlineBlank></IconButton>
            case HeaderAction.UNSELECT: 
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><CheckBox></CheckBox></IconButton>
            case HeaderAction.VERIFY: 
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><VerifiedUser></VerifiedUser></IconButton>
            case HeaderAction.BLOCK: 
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><BlockSharp></BlockSharp></IconButton>
            case HeaderAction.DOWNLOAD_SELECTED:
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><Download></Download></IconButton>
            default:
                return <IconButton key={index} sx={{color: "white"}} onClick={() => props.handleAction(item)}><CheckBoxOutlineBlank></CheckBoxOutlineBlank></IconButton>
        }
    })}</div>
}

export const TableHeaderWrapper = (props: {
    componentName: TableComponents,
    columns: {field: string, displayName: string, width?: string, isSortField?: boolean}[],
    showActions?: boolean,
    handleSort?: (field: string, order: SortOrder) => void,
    sorting?: {
        field?: string,
        order?: SortOrder
    }
}) => {

    const isLargeScreen = useMediaQuery('(min-width:1200px)');

    const getActionsWidth = () => {
        switch (props.componentName) {
            case TableComponents.Deployments:
                return "100px";
            default:
                return "100px";
        }
    }

    const getSortIcon = (item: {field: string, displayName: string, width?: string, isSortField?: boolean}) => {
        if (!item.isSortField || !props.sorting || (props.sorting && props.sorting.field !== item.field)) {
            return;
        }

        return props.sorting.order === SortOrder.ASC ? <ArrowDownward sx={{fontSize: 12}}></ArrowDownward> : <ArrowUpward sx={{fontSize: 12}}></ArrowUpward>
    }

    return <TableHead>
        <TableRow sx={{backgroundColor: "var(--table-heading-bgc)!important"}}>
            {props.showActions && <TableCell
                key={"actions"}
                sx={isLargeScreen ? {width: `${getActionsWidth()}`, position: 'sticky', left: 0, zIndex: 10} : {width: `${getActionsWidth()}`}}
                className="table-header-cell">Actions</TableCell>}
            {props.columns.map((item, index) => {
                let sx: any = item.width ? {minWidth: `${item.width}!important`}: {}
                if (item.isSortField) {
                    sx[":hover"] = {cursor: item.isSortField ? "pointer": "none"}
                }

                if (item.field === '_id') {
                    return <TableCell
                        key={index}
                        onClick={() => {
                            if (item.isSortField && props.handleSort) {
                                props.handleSort(item.field, props.sorting?.order === SortOrder.ASC ? SortOrder.DSC : SortOrder.ASC)
                            }
                        }}
                        sortDirection={props.sorting?.order === SortOrder.ASC ? "asc" : "desc"}
                        sx={isLargeScreen ? {...sx, position: 'sticky', backgroundColor: "white", left: 132, zIndex: 10} : {...sx, backgroundColor: "white"}}
                        className="table-header-cell">
                            {item.displayName}
                            {getSortIcon(item)}
                        </TableCell>
                }
                return <TableCell
                    key={index}
                    onClick={() => {
                        if (item.isSortField && props.handleSort) {
                            props.handleSort(item.field, props.sorting?.order === SortOrder.ASC ? SortOrder.DSC : SortOrder.ASC)
                        }
                    }}
                    sortDirection={props.sorting?.order === SortOrder.ASC ? "asc" : "desc"}
                    sx={{...sx}}
                    className="table-header-cell">
                        {item.displayName}
                        {getSortIcon(item)}
                    </TableCell>
            })}
        </TableRow>
    </TableHead>
}