import { Alert, Button, Chip, Dialog, DialogActions, DialogContent, Divider, List, ListItem, Snackbar } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import { useEffect, useState } from "react";
import { UserAPI } from "../../apis";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import "./Profile.css";
import { ApiError } from "../../utils/errorHandler";

export default function Profile() {
    const [showModal, setShowModal] = useState(false);
    
    const [personalData, setPersonalData] = useState<any>({});
    const dispatch = useAppDispatch();
    const { auth, common } = actions;

    useEffect(() => {
        (async () => {
            const res = await UserAPI.getUserProfile();
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setPersonalData(res);
            dispatch(auth.setUserData({name: res.name, email: res.email}));  
        })()
    }, [])
        

    return <>
        <MenuItem onClick={() => setShowModal(true)}>
          <ListItemIcon>
            <Avatar></Avatar>
          </ListItemIcon>
          Profile
        </MenuItem>
        <Dialog
            className="profile-dialog"
            open={!!(showModal)}
        >
            <DialogContent className="content">
                <Divider><Chip label="Profile"></Chip></Divider>
                <List>
                    <ListItem>
                        <div className="field"><span>Name</span></div>
                        <div className="value"><span>{personalData.name}</span></div>
                    </ListItem>
                    <ListItem>
                        <div className="field"><span>Email</span></div>
                        <div className="value"><span>{personalData.email}</span></div>
                    </ListItem>
                    <ListItem>
                        <div className="field"><span>Role</span></div>
                        <div className="value"><span>{personalData.role}</span></div>
                    </ListItem>
                </List>
                {/* <Divider><Chip label="Change Password"></Chip></Divider>
                <List>
                    <ListItem><TextField
                        value={changePasswordData.oldPassword} 
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CustomIcon onClick={() => setShowOldPassword(!showOldPassword)} iscustom="false" 
                                        name={showOldPassword ? "lock_open": "lock"}></CustomIcon>
                                </InputAdornment>
                            ),
                        }}
                        className="password-field" type={showOldPassword ? "text":  "password"} variant="outlined" size="small" name="oldPassword" label="Old Password" onChange={handleChangeText}></TextField></ListItem>
                    <ListItem><TextField
                        value={changePasswordData.newPassword} 
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CustomIcon onClick={() => setShowNewPassword(!showNewPassword)} iscustom="false" 
                                        name={showNewPassword ? "lock_open": "lock"}></CustomIcon>
                                </InputAdornment>
                            ),
                        }}
                        className="password-field" type={showNewPassword ? "text":  "password"} variant="outlined" size="small" name="newPassword" label="New Password" onChange={handleChangeText}></TextField></ListItem>
                    <ListItem><TextField
                        value={changePasswordData.confirmPassword} 
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <CustomIcon onClick={() => setShowConfirmPassword(!showConfirmPassword)} iscustom="false" 
                                        name={showConfirmPassword ? "lock_open": "lock"}></CustomIcon>
                                </InputAdornment>
                            ),
                        }}
                        className="password-field" type={showConfirmPassword ? "text":  "password"} variant="outlined" size="small" name="confirmPassword" label="Confirm Password" onChange={handleChangeText}></TextField></ListItem>
                    {error && <span className="error">{error}</span>}
                    <ListItem><Button disabled={isButtonDisabled} variant="contained" onClick={handleChangePassword}>Change</Button></ListItem>
                </List> */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    </>
}