import { ContentCopy } from "@mui/icons-material";
import { Chip, Grid, IconButton } from "@mui/material";

export interface DataField {
    name: string,
    fieldName?: string,
    value: any,
    showCopy?: boolean,
	isChip?: boolean,
	chipColor?: string
}

export const GridData = (props: {
    dataFields: DataField[],
    xs?: number,
}) => {
	const getValue = (val: any) => {
		if (Array.isArray(val)) {
			return val.join(", ");
		}
		return val;
	};

	return <>
		{props.dataFields.map(dataField => {
			return <Grid item xs={props.xs || 12}>
				<div className="data-field-container">
					<span className="field-name">{dataField.fieldName}</span>
					<div className="field-value">
						{dataField.isChip ?
						<Chip label={dataField.value} sx={{color: dataField.chipColor}}></Chip>
						: <span>{getValue(dataField.value)}</span>}
						{dataField.showCopy && dataField.value && <IconButton onClick={() => navigator.clipboard.writeText(dataField.value)}>
							<ContentCopy sx={{color: "grey", fontSize: "20px"}}></ContentCopy>
						</IconButton>}
					</div>
				</div>
			</Grid>;
		})}
	</>;
};