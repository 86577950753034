import axios from "axios";
import urls from "./urls";

const instance = axios.create({
    baseURL: urls.baseUrl,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("accessToken") || "";
        if (token) {
            config.headers!["Authorization"] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// instance.interceptors.response.use(
//     (res) => {
//         return res;
//     },
//     async (err) => {
//         const originalConfig = err.config;

//         if (originalConfig.url !== urls.login && err.response) {
//             // Access Token was expired
//             if (err.response.status === 401 && !originalConfig._retry) {
//                 originalConfig._retry = true;

//                 try {
//                     const rs = await axios.post(urls.refresh, {
//                         userId: localStorage.getItem("userId") || "",
//                     }, {withCredentials: true});

//                     const accessToken = rs.headers["authorization"];
//                     localStorage.setItem("accessToken", accessToken);
//                     return instance(originalConfig);
//                 } catch (_error) {
//                     return Promise.reject(_error);
//                 }
//             }
//         }

//         return Promise.reject(err);
//     }
// );

export default instance;