import { Box, Button, Checkbox, Chip, Container, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { ComparatorAPI } from "../../../apis";
import { InfoDialog } from "../../../components/Dialogs/InfoDialog";
import { FormFieldType, InputFormGrid } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { Environment, Pagination } from "../../../interfaces/Common";
import { SnapshotShortData, TopicsSnapshot } from "../../../interfaces/Comparator";
import { actions } from "../../../store";
import { useAppDispatch } from "../../../store/hooks";
import { getDateTimeStr } from "../../../utils/dateUtils";
import { ApiError } from "../../../utils/errorHandler";
import { Difference } from "@mui/icons-material";
import { TablePaginationWrapper } from "../../../components/DataTables/TablePaginationWrapper";

export const TopicsSnapshotComparator = () => {
    const [diffPayload, setDiffPayload] = useState<{
        snapshot1: SnapshotShortData | null,
        snapshot2: SnapshotShortData | null
    }>({ snapshot1: null, snapshot2: null });
    const [comparisonData, setComparisonData] = useState<{
        common: string[],
        onlyInEnv1: string[],
        onlyInEnv2: string[]
    }>({
        common: [],
        onlyInEnv1: [],
        onlyInEnv2: []
    });
    const [showViewDiff, setShowViewDiff] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common } = actions;

    const handleViewDiff = async () => {
        if (!(diffPayload.snapshot1 && diffPayload.snapshot2)) {
            return;
        }
        dispatch(common.setShowLoader(true));
        const res = await ComparatorAPI.getKafkaSnapshotsComparison({
            id1: `${diffPayload.snapshot1?._id}`,
            id2: `${diffPayload.snapshot2?._id}`
        })
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            dispatch(common.setShowLoader(false));
            return;
        }
        dispatch(common.setShowLoader(false));
        setShowViewDiff(true);
        setComparisonData(res.data);
    }

    const renderTable = (title: string, topicsList: string[]) => {
        return <TableContainer sx={{ height: "50vh", width: "100%", overflow: "auto" }}>
            <Table stickyHeader>
                <TableHead>
                    <TableCell colSpan={2}>{title}</TableCell>
                </TableHead>
                <TableBody>
                    {topicsList.map((val: string, ind: number) => {
                        return <TableRow>
                            <TableCell>{ind + 1}</TableCell>
                            <TableCell>{val}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    }

    return <Container maxWidth={false} className="property-comparator">
        <Grid container>
            <Grid container item columnGap={0.4}>
                <Grid container item xs={5.8} className="snapshots-table">
                    <SnapshotsTable
                        selected={diffPayload.snapshot1}
                        onSelect={(obj: SnapshotShortData) => {
                            if (diffPayload.snapshot1 && diffPayload.snapshot1._id === obj._id) {
                                setDiffPayload({ ...diffPayload, snapshot1: null })
                            } else {
                                setDiffPayload({ ...diffPayload, snapshot1: obj })
                            }
                        }}
                        key={1}
                        index={1}
                    ></SnapshotsTable>
                </Grid>
                <Grid container item  xs={5.8} className="snapshots-table">
                    <SnapshotsTable
                        selected={diffPayload.snapshot2}
                        onSelect={(obj: SnapshotShortData) => {
                            if (diffPayload.snapshot2 && diffPayload.snapshot2._id === obj._id) {
                                setDiffPayload({ ...diffPayload, snapshot2: null })
                            } else {
                                setDiffPayload({ ...diffPayload, snapshot2: obj })
                            }
                        }}
                        key={2}
                        index={2}
                    ></SnapshotsTable>
                </Grid>
            </Grid>
            {diffPayload.snapshot1 && diffPayload.snapshot2 && <div style={{backgroundColor: "white"}} className="compare-button"><IconButton
                sx={{height: "50px", width: "50px"}}
                onClick={handleViewDiff}
            >
                <Difference sx={{color: "var(--sidebar-bgc)", fontSize: "40px"}}></Difference>
            </IconButton></div>}
        </Grid>
        {showViewDiff && <InfoDialog
            open={showViewDiff}
            title="Snapshots Comparison"
            noText="Close"
            handleNo={() => {
                setShowViewDiff(false);
                setComparisonData({
                    common: [],
                    onlyInEnv1: [],
                    onlyInEnv2: []
                })
            }}
            dialogSx={{ "& .MuiPaper-root": { width: "100vw" } }}
        >
            {diffPayload.snapshot1 && diffPayload.snapshot2 &&
                <Container maxWidth={false} sx={{ padding: 0 }}>
                    <Box className="diff-title">
                        <div className="snapshot-name">
                            <span>{`Source - SNAPSHOT-${diffPayload.snapshot1._id}: ${diffPayload.snapshot1.env}`}</span>
                            <span>{getDateTimeStr(diffPayload.snapshot1.date)}</span>
                            <span>{diffPayload.snapshot1.tags.map(str => <Chip label={str}></Chip>)}</span>
                        </div>
                        <div className="snapshot-name">
                            <span>{`Target - SNAPSHOT-${diffPayload.snapshot2._id}: ${diffPayload.snapshot2.env}`}</span>
                            <span>{getDateTimeStr(diffPayload.snapshot2.date)}</span>
                            <span>{diffPayload.snapshot2.tags.map(str => <Chip label={str}></Chip>)}</span>
                        </div>
                    </Box>
                    <Grid container className="topics-diff-container">
                        <Grid xs={4} className="diff-grid">{renderTable(`Common Topics (${comparisonData.common.length})`, comparisonData.common)}</Grid>
                        <Grid xs={4} className="diff-grid">{renderTable(`Missing Topics (${comparisonData.onlyInEnv1.length})`, comparisonData.onlyInEnv1)}</Grid>
                        <Grid xs={4} className="diff-grid">{renderTable(`New Topics (${comparisonData.onlyInEnv2.length})`, comparisonData.onlyInEnv2)}</Grid>
                    </Grid>
                </Container>
            }
        </InfoDialog>}
    </Container>
}

export const SnapshotsTable = (props: {
    selected: SnapshotShortData | null,
    onSelect: (selected: SnapshotShortData) => void,
    index: number
}) => {
    const [filters, setFilters] = useState<{
        env: Environment,
        tags: string[],
        dates: Date[],
    }>({
        env: Environment.PAAS3,
        tags: [],
        dates: [],
    })
    const [pagination, setPagination] = useState<Pagination>({
        pageNumber: 1,
        limit: 10,
        currentResults: 0,
        totalPages: 0,
        totalResults: 0
    })
    const isLargeScreen = useMediaQuery('(min-width:1400px)');
    const [tagsOptions, setTagsOptions] = useState<string[]>([]);
    const [tagsSearchText, setTagsSearchText] = useState<string>("");
    const [rows, setRows] = useState<SnapshotShortData[]>([])
    const [rowData, setRowData] = useState<TopicsSnapshot | null>(null);
    const [showTopics, setShowTopics] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await ComparatorAPI.getTopicsFilters("tags", {
                pageNumber: 1, limit: 1000
            }, {searchText: tagsSearchText});
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setTagsOptions(res.data);
        })()
    }, [tagsSearchText])

    useEffect(() => {
        (async () => {
            dispatch(common.setShowLoader(true));
            const res = await ComparatorAPI.getTopicsSnapshots({
                pageNumber: pagination.pageNumber,
                limit: pagination.limit
            }, {
                env: filters.env,
                after: filters.dates ? filters.dates[0] : undefined,
                before: filters.dates ? filters.dates[1] : undefined,
                tags: filters.tags
            })
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                dispatch(common.setShowLoader(false));
                return;
            }
            setRows(res.data.results);
            setPagination({
                ...pagination,
                currentResults: res.data.currentResults,
                totalPages: res.data.totalPages,
                totalResults: res.data.totalResults
            })
            dispatch(common.setShowLoader(false));
        })()
    }, [filters, pagination.pageNumber, pagination.limit])

    const handleRowClick = async (obj: SnapshotShortData) => {
        const res = await ComparatorAPI.getTopicsSnapshotDetail(`${obj._id}`);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setRowData(res.data);
        setShowTopics(true);
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }

    return <>
        <Grid container item className="snapshot-filters-wrapper">
            <InputFormGrid
                formFields={[{
                    name: "env",
                    fieldName: "Environment",
                    value: filters.env,
                    type: FormFieldType.CUSTOM_SELECT,
                    options: Object.values(Environment)
                }, {
                    name: "dates",
                    fieldName: "Date Range",
                    value: filters.dates,
                    type: FormFieldType.CUSTOM_DATERANGE_PICKER,
                }, {
                    name: "tags",
                    fieldName: "Tags",
                    value: filters.tags,
                    type: FormFieldType.CUSTOM_SEARCH_SELECT,
                    searchText: tagsSearchText,
                    muiltiple: true,
                    onSearchTextChange(e) {
                        setTagsSearchText(e);
                    },
                    options: tagsOptions
                }]}
                onChange={handleInputChange}
                commonXs={4}
                commonSx={{width: "180px"}}
            ></InputFormGrid>
        </Grid>
        <Grid item xs={12} className="snapshots-title">{`${props.index === 1 ? "Source" : "Target"} Snapshots of Environment ${filters.env.toUpperCase()}`}</Grid>
        <Grid item xs={12}>
            <TableContainer sx={{ height: isLargeScreen ? "62vh": "55vh", overflow: "auto" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Tags</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(obj => {
                            return <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={props.selected ? props.selected._id === obj._id : false}
                                        onClick={(e: any) => {
                                            props.onSelect(obj)
                                        }}
                                        sx={{
                                            color: "var(--sidebar-bgc)",
                                            height: "20px!important",
                                            "&.Mui-checked": {
                                                color: "var(--sidebar-bgc)",
                                            },
                                            '& .MuiSvgIcon-root': { fontSize: 20 },
                                        }}
                                    ></Checkbox>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => handleRowClick(obj)}>{`${getDateTimeStr(obj.date)}`}</div>
                                </TableCell>
                                <TableCell>
                                    <div>{obj.tags.map(str => <Chip sx={{height: "20px"}} label={str}></Chip>)}</div>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePaginationWrapper
                limit={pagination.limit}
                pageNumber={pagination.pageNumber}
                currentResults={pagination.currentResults}
                totalResults={pagination.totalResults}
                totalPages={pagination.totalPages}
                onPageChange={(pageNumber: number) => {setPagination({...pagination,pageNumber: pageNumber})}}
                onLimitChange={(limit: number) => {setPagination({...pagination,limit: limit})}}
                width="45vw"
                showLimit={true}
                hideRpp={!isLargeScreen}
            ></TablePaginationWrapper>
        </Grid>
        {rowData && showTopics && <InfoDialog
            open={showTopics}
            noText="Close"
            handleNo={() => {
                setShowTopics(false);
                setRowData(null);
            }}
            title={`Kafka Topics for ${rowData?.env} on ${rowData?.date}`}
        >
            <TableContainer className="topics-container">
                <Table>
                    <TableBody>
                        {rowData.topics.map((topic, index) => {
                            return <TableRow>
                                <TableCell>{index+1}</TableCell>
                                <TableCell>{topic}</TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </InfoDialog>}
    </>
}
