import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Button, Container, FormControl, Grid, IconButton, InputLabel, MenuItem, Popover, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { SchedulerAPI } from "../../apis";
import { SchedulingEnvironment } from "../../interfaces/Common";
import { Day, ScheduleAggregation, ScheduleTime, ScheduleType } from "../../interfaces/Scheduler";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { copyDate, getCurrentWeek, getDateStr, getDayNumber, getDaysDiff, getNextWeek, getOverlapPercent, getPreviousWeek, getTimeSlots, getTimeSlotsInTimeRange, getWrokingDay } from "../../utils/dateUtils";
import { ApiError } from "../../utils/errorHandler";
import "./ScheduleCalendar.css";
import { FormFieldType, InputFormGrid } from "../../components/Wrapper/Inputs/InputFormGrid";
import { GridData } from "../../components/DataTables/GridData";
import { useNavigate } from "react-router-dom";

export const SchedulerCalendar = () => {
    const currentWeekObjs = getCurrentWeek(new Date());
    const [env, setEnv] = useState<SchedulingEnvironment>(SchedulingEnvironment.QA3);
    const [scheduleType, setScheduleType] = useState<ScheduleType>(ScheduleType.THIS_WEEK);
    const [currentWeek, setCurrentWeek] = useState<{
        firstDay: Date,
        lastDay: Date
    }>(currentWeekObjs);
    const dispatch = useAppDispatch();
    const { common } = actions;
    const [weekSchedule, setWeekSchedule] = useState<ScheduleAggregation[]>([]);
    const [hoveObj, setHoverObj] = useState<{
        reason: string, createdBy: string, _id: string, env: SchedulingEnvironment
    } | null>(null);
    const timeSlots = getTimeSlots();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);  
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            const res = await SchedulerAPI.getWeekSchedule({
                scheduleType: scheduleType,
                env: env
            });

            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setWeekSchedule(res.data.schedule);
        })()

    }, [scheduleType, env])

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, obj: any) => {
        setAnchorEl(event.currentTarget);
        setHoverObj(obj);
    };
  
    const handlePopoverClose = () => {
        setAnchorEl(null);
        setHoverObj(null);
    };      

    const handleInputChange = (e: any) => {
        const { value } = e.target;
        setEnv(value);
    };

    const handleNextWeekClick = () => {
        const nextWeek = getNextWeek(currentWeek.firstDay);
        setCurrentWeek(nextWeek);
        setScheduleType(ScheduleType.NEXT_WEEK)
    }

    const handlePreviousWeekClick = () => {
        const prevWeek = getPreviousWeek(currentWeek.firstDay);
        setCurrentWeek(prevWeek);
        setScheduleType(ScheduleType.THIS_WEEK)
    }

    const renderTimeCells = () => {
        return timeSlots.map(obj => {
            return <TableCell className="time-slot">
                <div>{`${obj.startTime.getHours() || "00"}:${obj.startTime.getMinutes() || "00"}`}</div>
                <div>{`${obj.stopTime.getHours() || "00"}:${obj.stopTime.getMinutes() || "00"}`}</div>
            </TableCell>
        })
    }

    const renderDaySlot = (day: Day) => {
        let filteredSchedules = weekSchedule.filter(obj => obj.day === day);        
        if (filteredSchedules.length === 0) {
            return timeSlots.map(obj => {
                return <TableCell className="empty-slot"></TableCell>
            })
        }

        let presentSlots: {
            slot: string, reason: string, createdBy: string, _id: string
        }[] = [];
        filteredSchedules.forEach(obj => {
            presentSlots = presentSlots.concat(getTimeSlotsInTimeRange(obj));
        })

        return timeSlots.map(obj => {
            let index = presentSlots.findIndex(obj2 => obj2.slot === obj.slot);
            return index > -1 ? <TableCell
                className={presentSlots[index]._id ? "filled-slot" : "filled-slot default"}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={(e: any) => handlePopoverOpen(e, presentSlots[index])}
                onMouseLeave={handlePopoverClose}      
            ></TableCell>
            : <TableCell className="empty-slot"></TableCell>
        })    
    }

    const renderSlots = () => {
        return Object.values(Day).map(day => {
            return <TableRow>
                <TableCell className="day-slot">{day}</TableCell>
                {renderDaySlot(day)}
            </TableRow>
        })
    }

    return <Container maxWidth={false} className="scheduler-calendar">
        <Grid container sx={{paddingLeft: "10px"}}>
            <InputFormGrid
                formFields={[{
                    name: "env",
                    fieldName: "SchedulingEnvironment",
                    value: env,
                    type: FormFieldType.CUSTOM_SELECT,
                    options: Object.values(SchedulingEnvironment)
                }]}
                onChange={handleInputChange}
            ></InputFormGrid>
            <Grid item>
                <Container maxWidth={false} className="week-display">
                    <IconButton sx={{minWidth: 40}} disabled={getDaysDiff(currentWeek.firstDay, new Date()) <= 0 } onClick={() => handlePreviousWeekClick()}>{getDaysDiff(currentWeek.firstDay, new Date()) > 0  && <ChevronLeft></ChevronLeft>}</IconButton>
                    {`${getDateStr(currentWeek.firstDay)} - ${getDateStr(currentWeek.lastDay)}`}
                    <IconButton disabled={getDaysDiff(currentWeek.lastDay, new Date()) >= 30} onClick={() => handleNextWeekClick()}>{getDaysDiff(currentWeek.lastDay, new Date()) < 30 && <ChevronRight></ChevronRight>}</IconButton>
                </Container>
            </Grid>
        </Grid>
        <Container maxWidth={false} className="date-times-container">
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className="day-slot">Time</TableCell>
                            {renderTimeCells()}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {renderSlots()}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Container maxWidth={false}>
            <Grid>
                <GridData
                    dataFields={[{
                        name: "_id",
                        fieldName: "Schedule ID",
                        value: hoveObj?._id
                    },{
                        name: "reason",
                        fieldName: "Reason",
                        value: hoveObj?.reason
                    },{
                        name: "createdBy",
                        fieldName: "Created By",
                        value: hoveObj?.createdBy
                    }]}
                ></GridData>
            </Grid>
          </Container>
        </Popover>
    </Container>
}