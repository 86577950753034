import urls from "./urls";
import api from "./axiosInstance";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import { AxiosResponse } from "axios";

export default class KafkaAPI {

  public static async getTopicsDiff(payload: { sourceEnv: string, targetEnv: string}):
  Promise<{ common: string[], onlyInEnv1: string[], onlyInEnv2: string[] } | ApiError> {
    try {
      const response: AxiosResponse<{
        message: string;
        data: { common: string[], onlyInEnv1: string[], onlyInEnv2: string[] };
      }> = await api.post(urls.kafkaTopicsCompare, {
        ...payload,
      });
      return response.data.data;
    } catch (error) {
      return AxiosErrorHandler(error);
    }
  }
}
