import { Container, Stack } from "@mui/material";
import { Dropdown } from "../../../components/Filters/Dropdown";
import { SchedulingEnvironment } from "../../../interfaces/Common";
import { RunStatus, ScheduleLambdaFilters } from "../../../interfaces/Scheduler";
import { RootState, actions } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import "./ScheduleLambdas.css";


export const ScheduleLambdasFilterGrid = () => {
    const filters: ScheduleLambdaFilters = useAppSelector((state: RootState) => state.lambdas.filters);
    const dispatch = useAppDispatch();
    const { lambdas } = actions;

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        dispatch(lambdas.setFilters({
            ...filters,
            [name]: ""
        }))
    };

    return <Container maxWidth={false} className="filters-container">
        <Stack direction="row" spacing={2}>
            <Dropdown
                key={"env"}
                id="env-simple-select"
                multiple
                value={filters.envs}
                label="Environment"
                name="env"
                onChange={handleInputChange}
                options={Object.values(SchedulingEnvironment)}
            ></Dropdown>
            <Dropdown
                key={"scheduleType"}
                multiple
                id="scheduleType-select"
                value={filters.triggerType}
                options={Object.values(RunStatus)}
                name="scheduleType"
                label="Schedule Type"
                onChange={handleInputChange}
            ></Dropdown>
        </Stack>
    </Container>
}
