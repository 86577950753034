import { PlayCircleOutline, StopCircle } from "@mui/icons-material";
import { Alert, Backdrop, Box, Button, Card, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, LinearProgress, Snackbar, TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SchedulerAPI } from "../../apis";
import { SchedulingEnvironment } from "../../interfaces/Common";
import { JobStatus, RunLog, RunStatus } from "../../interfaces/Scheduler";
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { SpecialPermissions } from "../../utils/constants";
import { getDayName, getHourMinString, getLapsedTime } from "../../utils/dateUtils";
import { ApiError } from "../../utils/errorHandler";
import "./Scheduler.css";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../components/Wrapper/Inputs/InputFormGrid";

export const StartStopEnv = () => {
    const permissions = useAppSelector((state: RootState) => state.common.specialPermissions);
    const reload: { randomNumber: number } = useAppSelector((state: RootState) => state.scheduler.reloadStartStop);
    const [selectedEnv, setSelectedEnv] = useState<SchedulingEnvironment>(SchedulingEnvironment.QA3);
    const [statusData, setStatusData] = useState<{[key in SchedulingEnvironment]?: RunLog}>({});
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");
    const [canStartEnv, setCanStartEnv] = useState<boolean>(false);
    const [canStopEnv, setCanStopEnv] = useState<boolean>(false);
    const [reset, setReset] = useState<boolean>(false);
    const [updateData, setUpdateData] = useState<{
        env: SchedulingEnvironment,
        status: RunStatus
    } | null>(null);
    const envOptions = [
        SchedulingEnvironment.PREMERGEDEV,
        SchedulingEnvironment.PAAS3,
        SchedulingEnvironment.QA3,
        SchedulingEnvironment.QA3_DEV,
        SchedulingEnvironment.CARNIVALSC,
        SchedulingEnvironment.CARNIVALSC_DEV,
        SchedulingEnvironment.PIONEER,
        SchedulingEnvironment.PIONEER_DEV,
        SchedulingEnvironment.JENKINS,
        SchedulingEnvironment.QA3_JENKINS,
    ];
    const dispatch = useAppDispatch();
    const { scheduler, common } = actions;

    useEffect(() => {
        if (permissions.length === 0) {
            return;
        }

        setCanStartEnv(permissions.indexOf(`${SpecialPermissions.triggerEnvStart}All`) > -1);
        setCanStopEnv(permissions.indexOf(`${SpecialPermissions.triggerEnvStop}All`) > -1);
    }, [permissions])
    
    useEffect(() => {
        (async () => {
            const res = await SchedulerAPI.getEnvRunningStatus({envs: envOptions});
            if (res instanceof ApiError) {
                console.log(res.message);
                return;                
            }
            setStatusData(res.data)
        })()        
    }, [reload.randomNumber])

    

    const handleRefresh = () => {
        dispatch(scheduler.reloadStartStopData());
    }

    const handleConfirm = async () => {
        if (!updateData) {
            return;
        }
        dispatch(common.setShowLoader(true));
        let res;
        if (updateData.status === RunStatus.OFF) {
            res = await SchedulerAPI.stopEnv({
                env: updateData.env,
                reason: reason
            });
        } else {
            res = await SchedulerAPI.startEnv({
                env: updateData.env,
                reason: reason
            });
        }
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
        }
        dispatch(common.setShowLoader(false));
        setShowConfirmModal(false);
    }

    const renderEnvs = () => {
        const cards: any[] = [];
        envOptions.forEach((val, index) => {
            let envStatusData = statusData[val];
            if (envStatusData) {
                cards.push(<EnvCard
                    isSelected={selectedEnv === val}
                    handleCardClick={(env: SchedulingEnvironment) => {setSelectedEnv(env)}}
                    env={val}
                    key={index}
                    statusData={envStatusData}
                    handleButtonClick={(env: SchedulingEnvironment, status: RunStatus) => {
                        setUpdateData({
                            env: env,
                            status: status
                        })
                        setShowConfirmModal(true);
                    }}
                    canStart={canStartEnv}
                    canStop={canStopEnv}
                />)    
            }
        })
        return cards;
    }

    return <Container maxWidth={false} className="start-stop-container">
        <Container maxWidth={false} className="envs-container">
            <Box sx={{width: "100%"}}><Button sx={{width: "200px"}} onClick={handleRefresh}>Refresh</Button></Box>
            {renderEnvs()}
        </Container>
        <EnvRunHistory env={selectedEnv}  refresh={reset}></EnvRunHistory>
        {showConfirmModal && <ConfirmDialog
            open={!!(showConfirmModal)}
            yesText="Yes"
            noText="No"
            handleNo={() => setShowConfirmModal(false)}
            handleYes={handleConfirm}
            disableYes={!reason}
            title="SchedulingEnvironment Start-Stop Trigger"
        >
            <Grid container>
                <Grid item xs={8}>
                    <div>{`Are you sure, you want to turn ${updateData?.status} the env ${updateData?.env}?`}</div>
                </Grid>
                <InputFormGrid
                    formFields={[{
                        name: "reason",
                        fieldName: 'Reason',
                        value: reason,
                        type: FormFieldType.CUSTOM_TEXT_AREA
                    }]}
                    onChange={(e: any) => {
                        const {name, value} = e.target;
                        setReason(value);
                    }}
                    commonXs={8}
                >
                </InputFormGrid>
            </Grid>
        </ConfirmDialog>}
    </Container>
}

export const EnvCard = (props: {
    env: SchedulingEnvironment,
    handleCardClick: (env: SchedulingEnvironment) => void,
    isSelected: boolean,
    statusData: RunLog,
    handleButtonClick: (env: SchedulingEnvironment, status: RunStatus) => void,
    canStart: boolean,
    canStop: boolean
}) => {
    const permissions = useAppSelector((state: RootState) => state.common.specialPermissions);
    const [canStartEnv, setCanStartEnv] = useState<boolean>(false);
    const [canStopEnv, setCanStopEnv] = useState<boolean>(false);

    useEffect(() => {
        if (permissions.length === 0) {
            return;
        }

        if (!props.canStart) {
            setCanStartEnv(permissions.indexOf(`${SpecialPermissions.triggerEnvStart}${props.env.toUpperCase()}`) > -1);    
        } else {
            setCanStartEnv(true);
        }

        if (!props.canStop) {
            setCanStopEnv(permissions.indexOf(`${SpecialPermissions.triggerEnvStop}${props.env.toUpperCase()}`) > -1);
        } else {
            setCanStopEnv(true);
        }
    }, [permissions, props.canStart, props.canStop])

    const buttonColor = (buttonName: string) => {
        if (buttonName === "play") {
            return props.statusData.status === RunStatus.ON || props.statusData.jobStatus === JobStatus.InProgress ? "grey" : "var(--sidebar-bgc)"
        } else if (buttonName === "stop") {
            return props.statusData.status === RunStatus.OFF || props.statusData.jobStatus === JobStatus.InProgress ? "grey" : "var(--sidebar-bgc)"
        } else {
            return "var(--sidebar-bgc)"
        }
    }

    return <Card onClick={ () => props.handleCardClick(props.env)}
        className={props.isSelected ? "env-item selected" : "env-item"}>
        <Box sx={{width: "100%", height: "90%", display: "flex", justifyContent: "center", alignItems: "center"}}>
            {props.statusData.jobStatus === JobStatus.InProgress
                ? <div className="log-time">{`Turning ${props.statusData.status}...`}</div> : <div className="log-time">{`${props.statusData.status} for ${getLapsedTime(props.statusData.createdAt)}`}</div>}
            <div className="env-name">{props.env}</div>
            {(canStartEnv || canStartEnv) && <div className="action-buttons">
                {canStartEnv && <IconButton
                    onClick={() => props.handleButtonClick(props.env, RunStatus.ON)}>
                        <PlayCircleOutline sx={{color: buttonColor("play"), fontSize: "35px"}}></PlayCircleOutline>
                </IconButton>}
                {canStopEnv && <IconButton
                    onClick={() => props.handleButtonClick(props.env, RunStatus.OFF)}>
                        <StopCircle sx={{color: buttonColor("stop"), fontSize: "35px"}}></StopCircle>
                </IconButton>}
            </div>}
        </Box>
        {props.statusData.jobStatus === JobStatus.InProgress && <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>}
    </Card>
}

export const EnvRunHistory = (props: {
    env: SchedulingEnvironment,
    refresh: boolean
}) => {
    const [runHistory, setRunHistory] = useState<RunLog[]>([]);
    const reload: { randomNumber: number } = useAppSelector((state: RootState) => state.scheduler.reloadStartStop);

    useEffect(() => {
        (async () => {
            const res = await SchedulerAPI.getEnvRunHistory({env: props.env});
            if (res instanceof ApiError) {
                console.log(res.message);
                setRunHistory([]);
                return;                
            }
            setRunHistory(res.data);
        })()
    }, [props.env, reload.randomNumber]);

    useEffect(() => {
        if (!props.refresh) {
            return;
        }

        (async () => {
            const res = await SchedulerAPI.getEnvRunHistory({env: props.env});
            if (res instanceof ApiError) {
                console.log(res.message);
                setRunHistory([]);
                return;                
            }
            setRunHistory(res.data);
        })()
    }, [props.refresh]);

    const renderRunHistory = () => {
        const daysMap: {[k: string]: RunLog[]} = {};
        for (let i = 0; i < runHistory.length; i++) {
            let date = new Date(runHistory[i].createdAt);
            let dateString = `${getDayName(date.getDay())} ${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
            if (!daysMap[dateString]) {
                daysMap[dateString] = []
            }
            daysMap[dateString].push(runHistory[i]);
        }
        let items: any = [];
        let days = Object.keys(daysMap);
        for (let i = 0; i < days.length; i++) {
            let dayString = days[i];
            let objs = daysMap[dayString];
            items.push(<Card key={i}>
                <Chip label={dayString}></Chip>
                <div className="history-container">
                    {objs.map(runLog => {
                        return <><div className="history-div">
                            <Chip color={runLog.status === RunStatus.ON ? "success" : "error"} label={runLog.status}></Chip>
                            <Box sx={{width:"100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-start"}}>
                                <div className="log-time">{`Triggered At ${getHourMinString(runLog.createdAt)} By ${runLog.createdBy}`}</div>
                                {runLog.updatedAt && <div className="log-time">{`Completed At ${getHourMinString(runLog.updatedAt)}`}</div>}
                                <div className="actioner">Reason: <TriggerReason createdBy={runLog.createdBy} reason={runLog.reason}></TriggerReason></div>
                            </Box>
                        </div>
                        {runLog.jobStatus === JobStatus.InProgress && <Box sx={{ width: '100%' }}>
                            <LinearProgress />
                        </Box>}
                        </>
                    })}
                </div>
            </Card>)
        }
        return items;
    }

    return <Container className="env-history">
        <div className="history-title">{`Run history of ${props.env}`}</div>
        {renderRunHistory()}
    </Container>
}

export const TriggerReason = (props: {
    reason: string,
    createdBy: string
}) => {
    if (props.createdBy !== "Scheduler") {
        return <span>{props.reason}</span>
    }

    if (props.reason === "Default Schedule") {
        return <span><a target="_blank" href={`${process.env.FRONTEND_URL}/dashboard/scheduler/default`}>Default Schedule</a></span>
    }
    let requests = props.reason.split("Requests: ")[1] || "";
    if (requests.length > 0) {
        let ids = requests.split(", ");
        return <span>Requests - {ids.map((id, index) => {
            return <a target="_blank" href={`${process.env.FRONTEND_URL}/dashboard/scheduler/requests/${id}`}>{index === ids.length-1 ? `${id}`: `${id}, `}</a>
        })}</span>
    }
    return <span>{props.reason}</span>
}