import { 
    Select as SelectBase,
    TextField as TextFieldBase,
    InputLabel as InputLabelBase,
    RadioGroup as RadioGroupBase,
    FormLabel as FormLabelBase,
    Autocomplete as AutocompleteBase,
    SelectProps, TextFieldProps, InputLabelProps, Checkbox, RadioGroupProps, FormLabelProps, AutocompleteProps
} from "@mui/material";
import CustomIcon from "../Icons";
import "./Wrapper.css";

export const Select = (props: SelectProps) => {
    return <SelectBase className="wrapper-select" {...props}/>
}

export const TextField = (props: TextFieldProps) => {
    return <TextFieldBase className="wrapper-text-field" {...props}/>
}

export const InputLabel = (props: InputLabelProps) => {
    return <InputLabelBase className="wrapper-input-label" {...props}/>
}

export const FormLabel = (props: FormLabelProps) => {
    return <FormLabelBase className="wrapper-form-label" {...props}/>
}

export const RadioGroup = (props: RadioGroupProps) => {
    return <RadioGroupBase className="wrapper-radio-group" {...props}>{props.children}</RadioGroupBase>
}

export const Autocomplete = (props: AutocompleteProps<{label: string, value: string}, true, true, false>) => {
    return <AutocompleteBase className="wrapper-autocomplete" {...props}
        ></AutocompleteBase>
}

export const AutocompleteOption = (props: {props:any, option: any, selected:boolean}) => {
    return <li {...props.props} style={{overflowX: "auto", padding: "0 0.1vw 0.1vh 0.1vw"}}>
    <Checkbox
        icon={<CustomIcon size="x-small" name="check_box_outline_blank" iscustom="false"></CustomIcon>}
        checkedIcon={<CustomIcon size="x-small" name="check_box" iscustom="false"></CustomIcon>}
        style={{ margin: 0, marginRight: 2, padding: 0 }}
        checked={props.selected}
    />
    <span style={{fontSize: "0.7rem", wordWrap: "break-word"}}>{props.option.displayValue}</span>
    </li>
}