import { Close } from "@mui/icons-material";
import { Box, Button, Chip, Container, Grid, IconButton, List, ListItem, Paper, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import DBAccessAPI from "../../apis/dbaccess";
import { RowActions } from "../../components/DataTables/TableBodyWrapper";
import { HeaderAction, RowAction } from "../../interfaces/Data";
import {
    DBDetail
} from "../../interfaces/DbAccess";
import { StatusLogType } from "../../interfaces/Deployments";
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector, useIsTabActive } from "../../store/hooks";
import { TableComponents, rowActionsFromStatus } from "../../utils/constants";
import { getActionsFromStatus, getChipColor } from "../../utils/deploymentUtils";
import { ApiError } from "../../utils/errorHandler";
import "./Dbaccess.css";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";

export const DBAccessDetailsPanel = (props: {
    dbaccessparam: DBDetail,
    handleClose: () => void
}) => {
    // console.log(props.gitlabparam.namespace);
    const defaultRowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.Deployments]);
    const specialPermissions = useAppSelector((state: RootState) => state.common.specialPermissions);
    const userData = useAppSelector((state: RootState) => state.common.userData);
    const reload: { randomNumber: number } = useAppSelector((state: RootState) => state.dbaccess.reload);
    const [dbaccessDetails, setDbaccessDetails] = useState<DBDetail | null>(null);
    const [actionClicked, setActionClicked] = useState<HeaderAction | RowAction | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [rowActions, setRowActions] = useState<RowAction[]>([]);
    const [rejectionComment, setRejectionComment] = useState<string>("");
    const isTabVisible = useIsTabActive();
    const dispatch = useAppDispatch();
    const { dbaccess, common } = actions;

    useEffect(() => {
        if (!(dbaccessDetails && defaultRowActions.length > 0 && userData.name && specialPermissions.length > 0)) {
            return;
        }

        const rowActionsFiltered = getActionsFromStatus({
            rowActions: defaultRowActions,
            row: dbaccessDetails,
            specialPermissions: specialPermissions,
            componentName: TableComponents.Deployments,
            personalData: userData
        })
        setRowActions(rowActionsFiltered)
    }, [defaultRowActions, dbaccessDetails, specialPermissions, userData])

    useEffect(() => {
        if (!isTabVisible) {
            return;
        }
        (async () => {
            const id = props.dbaccessparam._id;
            if (!id) {
                return;
            }

            const res = await DBAccessAPI.getDBaccessDetail(id);
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setDbaccessDetails(res.data);
        })();
    }, [reload, props.dbaccessparam._id, isTabVisible]);

    const renderLogs = () => {
        if (!dbaccessDetails?.statusLog) {
            return;
        }
        return <List>
            {dbaccessDetails.statusLog.map((log: any, index) => {
                if (log.type === StatusLogType.USER_ACTION) {
                    let byString = `by ${log.updatedBy}`;
                    return <ListItem key={index}>
                        <div>
                            <Chip sx={getChipColor(log.status)} label={log.status}></Chip> {byString} At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} {log.remarks ? `, Comment: ${log.remarks}` : ''}
                        </div>
                    </ListItem>
                } else {
                    return <ListItem key={index}>
                        <div>
                            {log.link ? <a href={log.link}><b>{log.remarks}</b></a> : <b>{log.remarks}</b>}  At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} <Chip sx={getChipColor(log.status)} label={log.status}></Chip>
                        </div>
                    </ListItem>
                }

            })}
        </List>
    };

    const handleConfirm = async () => {
        if (!dbaccessDetails) {
            return;
        }

        dispatch(common.setShowLoader(true));
        let res;
        switch (actionClicked) {
            case RowAction.APPROVE:
                res = await DBAccessAPI.bulkApprove({ ids: [dbaccessDetails._id.toString()] });
                break;
            case RowAction.REVIEW:
                res = await DBAccessAPI.bulkReview({ ids: [dbaccessDetails._id.toString()] });
                break;
            case RowAction.REJECT:
                res = await DBAccessAPI.bulkReject({ ids: [dbaccessDetails._id.toString()], comment: rejectionComment });
                break;
            case RowAction.DELETE_ROW:
                res = await DBAccessAPI.deleteDbAccess(dbaccessDetails);
                break;
        }
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
        }

        setActionClicked(null);
        dispatch(common.setShowLoader(false));
        setShowConfirmModal(false);
        dispatch(dbaccess.reloadData());
    }

    const handleActionClick = (action: RowAction) => {
        if (action === RowAction.EDIT_ROW && dbaccessDetails?._id) {
            const path = `${window.location}/${dbaccessDetails._id}?mode=edit`;
            const a = document.createElement("a");
            a.href = path;
            a.target = "_blank";
            a.click();
            a.remove();
            return;
        }

        setActionClicked(action);
        setShowConfirmModal(true);
    }

    const handleDetailsPageClick = () => {
        if (dbaccessDetails) {
            const path = `${window.location}/${dbaccessDetails._id}`;
            const a = document.createElement("a");
            a.href = path;
            a.target = "_blank";
            a.click();
            a.remove();
        }
    }

    const renderActions = () => {
        if (!dbaccessDetails) {
            return <></>
        }

        const statusActions = rowActionsFromStatus[dbaccessDetails.status];
        const filteredActions = statusActions.filter(action => {
            return action !== RowAction.CHECKBOX && rowActions.indexOf(action) > -1
        })

        return <RowActions
            sx={{ backgroundColor: "transparent!important" }}
            actions={filteredActions}
            handleAction={handleActionClick}
        ></RowActions>
    }

    const getValue = (field: string, value: any) => {
        return <span>{value}</span>
    }

    if (!dbaccessDetails) {
        return <Container>Resource Not Found</Container>
    }

    return <Paper className="deployment-details-panel" elevation={3} sx={{ overflowY: "scroll" }}>
        <Box className="ticket-panel-header">
            <div className="title" onClick={() => handleDetailsPageClick()}>{`DB Access Request ${dbaccessDetails._id}`}</div>
            <div className="icon-wrapper">
                <IconButton onClick={() => props.handleClose()} sx={{height: "20px", width: "20px"}}><Close sx={{color: "white"}}></Close></IconButton>
            </div>
        </Box>
        <Box sx={{ padding: "10px 0", ...getChipColor(dbaccessDetails.status) }}>{dbaccessDetails.status}</Box>
        <Container maxWidth={false} className="actions-container">
            {renderActions()}
        </Container>
        <Container maxWidth={false} sx={{ height: "70vh!important", overflowY: "scroll" }}>
            <Grid container spacing={0}>
                <Grid item xs={6}>
                    <List className="list">
                        <ListItem className="list-item">
                            <div className="field-name">Requested At:</div>
                            <div className="field-value">{getValue("date", `${new Date(dbaccessDetails.createdAt).toLocaleTimeString()}`)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested On:</div>
                            <div className="field-value">{getValue("date", `${new Date(dbaccessDetails.createdAt).toLocaleDateString()}`)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested By:</div>
                            <div className="field-value">{getValue("createdBy", dbaccessDetails.createdBy)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Environment Name:</div>
                            <div className="field-value">{getValue("deploymentType", dbaccessDetails?.envName)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Database Name:</div>
                            <div className="field-value">{getValue("roupName", dbaccessDetails?.dbName)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Description:</div>
                            <div className="field-value">{getValue("description", dbaccessDetails?.description)}</div>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <Typography>Logs</Typography>
                    {renderLogs()}
                </Grid>
            </Grid>
        </Container>
        <ConfirmDialog
            title='DB Access Updates'
            open={!!showConfirmModal}
            yesText='Yes'
            noText='No'
            handleNo={() => setShowConfirmModal(false)}
            handleYes={handleConfirm}
        >
            <span>{`Are you sure, you want to ${actionClicked}?`}</span>
            {actionClicked === 'Delete' ? <div style={{ padding: "0 20px" }}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e: any) => setRejectionComment(e.target.value)}
                />
            </div> : <></>}
        </ConfirmDialog>
    </Paper>
}