import { Backdrop, CircularProgress, Container } from "@mui/material";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthAPI } from "../../apis";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";

export const MSOAuth2 = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { common } = actions;
    let [searchParams] = useSearchParams();

    useEffect(() => {        
        (async () => {
            const code = searchParams.get("code");
            const state = searchParams.get("state");
            const sessionState = searchParams.get("session_state");
            if (!code) {
                navigate("/");
                return;
            }

            const res = await AuthAPI.MSLogin({
                code: code,
                state: state,
                session_state: sessionState
            })
    
            if (res instanceof ApiError) {
                dispatch(common.setSnackbar({children: res.message, severity: "error"}));
                navigate("/");
                return;
            }

            let breadcrumbs = JSON.parse(localStorage.getItem("breadcrumbs") || "[]");
            if (breadcrumbs.length === 0) {
                breadcrumbs = [{displayName: "Deployments", name: "deployments"}];
            }
            dispatch(common.setBreadCrumbs(breadcrumbs));
            navigate(`/dashboard/${breadcrumbs.map((crumb: any) => crumb.name).join("/")}`);                
        })()
    }, [])
    return <Container>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
            open={true}
            // onClick={handleClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </Container>
}