import { Add, Clear } from "@mui/icons-material";
import { Grid, IconButton } from "@mui/material";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { SchedulerAPI } from "../../apis";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { CustomTimeRangePicker } from "../../components/Wrapper/Inputs/CustomDatePicker";
import { FormFieldType, InputFormGrid } from "../../components/Wrapper/Inputs/InputFormGrid";
import { SchedulingEnvironment } from "../../interfaces/Common";
import { Day } from "../../interfaces/Scheduler";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { setIntervalTime } from "../../utils/dateUtils";
import { ApiError } from "../../utils/errorHandler";
import { onlyUnique } from "../../utils/objectUtils";
import "./ScheduleCalendar.css";

export const EditDefaultSchedulePage = (props: {
    handleClose: Function,
    showModal: boolean
}) => {
    const days = Object.values(Day);
    const [env, setEnv] = useState<SchedulingEnvironment>(SchedulingEnvironment.QA3);
    const [commonSchedules, setCommonSchedules] = useState<{ startTime: Date, stopTime: Date }[]>([
        {
            startTime: setIntervalTime(new Date(), 30),
            stopTime: setIntervalTime(new Date(), 30)
        }
    ]);
    const [selectedDays, setSelectedDays] = useState<Day[]>([])
    const [isStatic, setIsStatic] = useState<boolean>(false)
    const [schedules, setSchedules] = useState<{
        day: Day,
        index: number,
        startTime: Date, stopTime: Date
    }[]>([])
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common, scheduler } = actions;

    useEffect(() => {
        (async () => {
            const res = await SchedulerAPI.getDefaultSchedules({pageNumber: 1, limit: 1}, {env: env});
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            const scheduleRes = res.data.results[0];

            if (scheduleRes) {
                const schedulesArr = scheduleRes.schedule;
                const selectedDaysCopy = schedulesArr.map(obj => obj.day).filter(onlyUnique);
                setSelectedDays(selectedDaysCopy);
                setIsStatic(scheduleRes.isStatic);
                let map: any = {};
                let schedulesCopy: {
                    day: Day,
                    index: number,
                    startTime: Date, stopTime: Date
                }[] = []
                schedulesArr.forEach(obj => {
                    if (!map[obj.day] && map[obj.day] !== 0) {
                        map[obj.day] = -1;
                    }

                    map[obj.day] = map[obj.day] + 1;
                    console.log(map,'---maps');
                    
                    schedulesCopy.push({
                        day: obj.day,
                        index: map[obj.day],
                        startTime: obj.startTime,
                        stopTime: obj.stopTime
                    })
                })                
                setSchedules(schedulesCopy);
            }
        })()
    }, [env])

    const resetSchedules = (selectedDaysCopy: Day[], commonSchedulesCopy: {
        startTime: Date, stopTime: Date
    }[]) => {
        if (!(selectedDaysCopy.length > 0 && commonSchedulesCopy.length > 0)) {
            setSchedules([]);
            return;
        }

        let schedulesCopy: {
            day: Day,
            index: number,
            startTime: Date, stopTime: Date
        }[] = []
        for (let day of days) {
            if (!selectedDaysCopy.includes(day)) {
                continue;
            }

            for (let i = 0; i < commonSchedulesCopy.length; i++) {
                schedulesCopy.push({
                    day: day,
                    index: i,
                    startTime: commonSchedulesCopy[i].startTime,
                    stopTime: commonSchedulesCopy[i].stopTime
                })
            }
        }
        setSchedules(schedulesCopy);
    }

    const handleUpdateConfirm = async () => {
        const res = await SchedulerAPI.updateDefaultSchedule({
            env: env,
            schedule: schedules,
            isStatic: isStatic
        });

        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setShowConfirm(false);
        setEnv(SchedulingEnvironment.QA3);
        dispatch(common.setSnackbar({ children: "Successfully updated default schedule.", severity: "success" }));
        setSchedules([])
        props.handleClose();
        dispatch(scheduler.reloadDefaultScheduleData());
    }

    const handleClose = () => {
        props.handleClose()
    }

    const handleChangeCommonTime = (newValue: Date[] | null, index: number) => {
        if (!newValue) {
            return;
        }
        let commonCopy = cloneDeep(commonSchedules);
        commonCopy[index] = { startTime: newValue[0], stopTime: newValue[1] };
        setCommonSchedules(commonCopy);
        resetSchedules(selectedDays, commonCopy);
    }

    const handleRemoveCommon = (index: number) => {
        const copy = cloneDeep(commonSchedules);
        copy.splice(index, 1);
        setCommonSchedules(copy);
        resetSchedules(selectedDays, copy);
    }

    const handleAddNewCommon = () => {
        if (commonSchedules.length >= 3) {
            return;
        }
        let copy = [
            ...commonSchedules,
            {startTime: setIntervalTime(new Date(), 30), stopTime: setIntervalTime(new Date(), 30)}
        ]
        setCommonSchedules(copy)
        resetSchedules(selectedDays, copy);
    }

    const renderCommonRow = (index: number) => {
        return <Grid item flexDirection={"column"}>
            <div className="common-schedule">
                <CustomTimeRangePicker
                    fieldName={`Common - ${index+1}`}
                    name={`common-${index}`}
                    format={"HH:mm A"}
                    value={[commonSchedules[index].startTime, commonSchedules[index].stopTime]}
                    onChange={(newValue: Date[] | null) => {
                        handleChangeCommonTime(newValue, index);
                    }}
                ></CustomTimeRangePicker>
                {index > 0 && <div className="clear-icon">
                    <IconButton
                        sx={{height: "18px", width: "18px"}}
                        onClick={() => handleRemoveCommon(index)}
                    >
                            <Clear sx={{fontSize: "18px"}}></Clear>
                    </IconButton></div>}
            </div>
        </Grid>
    }

    const handleChangeDayTime = (newValue: Date[] | null, day: Day, index: number) => {        
        if (!newValue) {
            return;
        }
        const copy = cloneDeep(schedules);
        copy[index].startTime = newValue[0];
        copy[index].stopTime = newValue[1];
        setSchedules(copy);
    }

    const handleRemoveDayTime = (day: Day, scheduleIndex: number) => {
        const copy = cloneDeep(schedules);
        copy.splice(scheduleIndex, 1)
        setSchedules(copy);
    }

    const handleAddNewDayTime = (day: Day, index: number) => {
        const copy = cloneDeep(schedules);
        let scheduleIndex = copy.findIndex(obj => obj.day === day && obj.index === index-1);
        copy.splice(scheduleIndex+1, 0, {
            day: day,
            index: index,
            startTime: commonSchedules[index-1].startTime,
            stopTime: commonSchedules[index-1].stopTime
        })
        setSchedules(copy);
    }

    const handleSelectedDaysChange = (e: any) => {
        const { name, value } = e.target;
        if (name === "All") {
            let copy = selectedDays.length < days.length ? [...days] : [];
            setSelectedDays(copy);
            resetSchedules(copy, commonSchedules);
            return;
        }

        let selectedDaysCopy = [...selectedDays];
        let ind = selectedDaysCopy.indexOf(name);
        if (ind > -1 && !value) {
            selectedDaysCopy.splice(ind, 1);
            const schedulesCopy = cloneDeep(schedules);
            let dayIndex = schedulesCopy.findIndex(obj => obj.day === name);
            while (dayIndex > -1) {
                schedulesCopy.splice(dayIndex, 1);
                dayIndex = schedulesCopy.findIndex(obj => obj.day === name);
            }
            setSchedules(schedulesCopy);
        } else {
            selectedDaysCopy.push(name);
            const schedulesCopy = cloneDeep(schedules);
            for (let i = 0; i < commonSchedules.length; i++) {
                schedulesCopy.push({
                    day: name,
                    index: i,
                    startTime: commonSchedules[i].startTime,
                    stopTime: commonSchedules[i].stopTime
                })
            }
            setSchedules(schedulesCopy);
        }
        setSelectedDays(selectedDaysCopy);
    }

    const renderDayRow = (day: Day, index: number) => {
        const objIndex = schedules.findIndex(schedule => schedule.day === day && schedule.index === index);
        if (objIndex === -1) {
            return <></>
        }
        const obj = schedules[objIndex];

        return <Grid item flexDirection={"column"}>
            <div className="common-schedule">
                <CustomTimeRangePicker
                    fieldName={`${day} - ${index+1}`}
                    name={`${day}-${objIndex}`}
                    format={"HH:mm A"}
                    value={[obj.startTime, obj.stopTime]}
                    onChange={(newValue: Date[] | null) => {
                        handleChangeDayTime(newValue, day, objIndex);
                    }}
                ></CustomTimeRangePicker>
                {index > 0 && <div className="clear-icon">
                    <IconButton
                        sx={{height: "18px", width: "18px"}}
                        onClick={() => handleRemoveDayTime(day, objIndex)}
                    >
                            <Clear sx={{fontSize: "18px"}}></Clear>
                    </IconButton></div>}
            </div>
        </Grid>
    }

    return <ConfirmDialog
        open={!!props.showModal}
        title="Update Default Schedule"
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        yesText="Save"
        noText="Cancel"
    >
        <Grid container>
            <Grid container item columnGap={1} xs={3}>
                <InputFormGrid
                    formFields={[{
                        fieldName: "SchedulingEnvironment",
                        name: "env",
                        type: FormFieldType.CUSTOM_SELECT,
                        value: env,
                        options: Object.values(SchedulingEnvironment)
                    },{
                        fieldName: "Static Schedule",
                        name: "isStatic",
                        type: FormFieldType.CUSTOM_CHECKBOX,
                        value: isStatic,
                    }]}
                    onChange={(e: any) => {
                        const { name, value } = e.target;
                        if (name === "isStatic") {
                            setIsStatic(!isStatic);
                        } else {
                            setEnv(value)
                        }
                    }}
                    commonXs={12}
                ></InputFormGrid>
                <InputFormGrid
                    formFields={[
                        {
                            fieldName: "All",
                            name: "All",
                            type: FormFieldType.CUSTOM_CHECKBOX,
                            value: selectedDays.length === days.length
                        },
                        ...days.map(day => {
                            return {
                                fieldName: day,
                                name: day,
                                type: FormFieldType.CUSTOM_CHECKBOX,
                                value: selectedDays.includes(day)
                            }
                        })
                    ]}
                    onChange={handleSelectedDaysChange}
                    commonXs={12}
                ></InputFormGrid>
            </Grid>
            <Grid container item columnGap={1} xs={8} justifyContent={"flex-start"}>
                <Grid container item xs={12} columnGap={1}>
                    {commonSchedules.map((obj, index) => {
                        return renderCommonRow(index);
                    })}
                    {commonSchedules.length < 3 && <Grid item>
                        <IconButton onClick={() => handleAddNewCommon()}><Add></Add></IconButton>
                    </Grid>}
                </Grid>
                {days.filter(day => selectedDays.includes(day)).map(day => {
                    let fileredSchedules = schedules.filter(obj => obj.day === day)
                    return <Grid container item xs={12} columnGap={1}>
                        {fileredSchedules.map((obj, index) => {
                            return renderDayRow(day, index);
                        })}
                        {fileredSchedules.length < 3 && <Grid item>
                            <IconButton onClick={() => handleAddNewDayTime(day, fileredSchedules.length)}><Add></Add></IconButton>
                        </Grid>}
                    </Grid>
                })}
            </Grid>
        </Grid>
        <ConfirmDialog
            open={!!(showConfirm)}
            yesText="Yes"
            noText="No"
            handleNo={() => setShowConfirm(false)}
            handleYes={() => handleUpdateConfirm()}
            title="Confirmation"
        >
            {`Are you sure, you want to update the default schedule for ${env}?`}
        </ConfirmDialog>
    </ConfirmDialog>
}