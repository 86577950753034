import { CheckCircle, Close, Error, Info, Warning } from "@mui/icons-material";
import { Badge, Box, Divider, IconButton } from "@mui/material";
import { DeploymentStatus } from "../../interfaces/Deployments";
import { Notification } from '../../interfaces/Notifications';
import { JobStatus, ScheduleStatus } from "../../interfaces/Scheduler";
import { getLapsedTime } from "../../utils/dateUtils";
import { TriggerReason } from "../Scheduler/StartStopEnv";
import "./Notifications.css";

export const Notifications = (props: {
    notifications: Notification[],
    unread: boolean,
    totalCount: number,
    unreadCount: number,
    handleUnreadClick: (unread: boolean) => void,
    handleRead: (id: string) => void,
    handleRemove: (id: string, isRead: boolean) => void,
    handleLoadMore: () => void
}) => {
    const selectedSx = {
        paddingRight: "10px",
        color: "var(--sidebar-selected-bgc)",
        fontWeight: "600",
        cursor: "pointer",
        fontSize: "16px"
    }

    const actionSx = {
        paddingRight: "10px",
        color: "black",
        cursor: "pointer",
        fontSize: "14px"
    }

    return <Box className="notifications-container">
        <Box className="actions-container">
            <div className="action-item" key={"all"} onClick={() => props.handleUnreadClick(false)}>
                <Badge badgeContent={props.totalCount} color={!props.unread ? "primary" : "secondary"}>
                    <div style={props.unread ? actionSx : selectedSx}>All</div>
                </Badge>
            </div>
            <div className="action-item" key={"unread"} onClick={() => props.handleUnreadClick(true)}>
                <Badge badgeContent={props.unreadCount} color={props.unread ? "primary" : "secondary"}>
                    <div style={!props.unread ? actionSx : selectedSx}>Unread</div>
                </Badge>
            </div>
        </Box>
        <Divider></Divider>
        <div
            className="msgs-container"
            onScroll={(event: any) => {
                if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight) {
                    props.handleLoadMore()
                }
            }}
        >
            {props.notifications.map((notification: Notification, index: number) => {
                return <NotificationItem
                    notification={notification}
                    index={index}
                    handleRead={props.handleRead}
                    handleRemove={props.handleRemove}
                ></NotificationItem>
            })}
        </div>
    </Box>
}

export const NotificationItem = (props: {
    notification: Notification,
    index: number,
    handleRead: (id: string) => void,
    handleRemove: (id: string, isRead: boolean) => void,
}) => {

    const getIcon = (status: string) => {
        switch(status) {
            case DeploymentStatus.Completed:
            case JobStatus.Completed:
            case ScheduleStatus.Approved:
                    return <CheckCircle fontSize="small" color="success"></CheckCircle>
            case DeploymentStatus.Removed:
            case ScheduleStatus.Removed:
                return <Error fontSize="small" color="error"></Error>
            case DeploymentStatus.Failed:
            case JobStatus.Failed:
                return <Error fontSize="small" color="error"></Error>
            case DeploymentStatus.Rejected:
            case ScheduleStatus.Rejected:
                return <Warning fontSize="small" color="warning"></Warning>
            default:
                return <Info fontSize="small" color="info"></Info>
        }
    }

    if (["deployment", "scheduler"].indexOf(props.notification.msgType) > -1) {
        const str = props.notification.msgBody.msg.split(" ID_URL ");
        const status = props.notification.msgBody.data.status;
        return <div key={props.index} className={props.notification.isRead ? "notification-div read": "notification-div"}>
            <div className="notification-heading">
                {getIcon(status)}
                <div className="notification-title">{props.notification.msgBody.title}</div>
                <div className="time-text">{`${getLapsedTime(props.notification.createdAt)} ago`}</div>
                <IconButton onClick={() => props.handleRemove(props.notification.msgId, props.notification.isRead)}><Close fontSize="small"></Close></IconButton>
            </div>
            <div
                className="notification-msg"
                onClick={() => {
                    if (props.notification.isRead) {
                        return;
                    }
                    props.handleRead(props.notification.msgId)
                }}
            >{str[0]} <a href={props.notification.msgBody.data.url} ><strong>{props.notification.msgBody.data.id}</strong></a> {str[1]}</div>
        </div>
    } else {
        const status = props.notification.msgBody.data.jobStatus;
        const str = props.notification.msgBody.msg.split("Trigger Reason: ");
        return <div key={props.index} className={props.notification.isRead ? "notification-div read": "notification-div"}>
            <div className="notification-heading">
                {getIcon(status)}
                <div className="notification-title">{props.notification.msgBody.title}</div>
                <div className="time-text">{`${getLapsedTime(props.notification.createdAt)} ago`}</div>
                <IconButton onClick={() => props.handleRemove(props.notification.msgId, props.notification.isRead)}><Close fontSize="small"></Close></IconButton>
            </div>
            <div
                className="notification-msg"
                onClick={() => {
                    if (props.notification.isRead) {
                        return;
                    }
                    props.handleRead(props.notification.msgId)
                }}
            >{str[0]} Trigger Reason: <TriggerReason 
                reason={props.notification.msgBody.data.reason}
                createdBy={props.notification.msgBody.data.createdBy}></TriggerReason></div>
        </div>
    }
}

export const NotificationFlashMessage = (props: { notification: Notification}) => {
    if (props.notification.msgType === "deployment" || props.notification.msgType === "scheduler") {
        const str = props.notification.msgBody.msg.split(" ID_URL ");
        return <div className={props.notification.isRead ? "notification-div read": "notification-div"}>
            <div
                className="notification-msg"
            >{str[0]} <a href={props.notification.msgBody.data.url} ><strong>{props.notification.msgBody.data.id}</strong></a> {str[1]}</div>
        </div>
    } else {
        return <div className={props.notification.isRead ? "notification-div read": "notification-div"}>
        <div
            className="notification-msg"
            >{props.notification.msgBody.msg}</div>
        </div>
    }
}