import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Stack, Switch, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { ComparatorAPI } from "../../../apis";
import { FormFieldType, InputFormGrid } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { ComparisonData } from "../../../interfaces/Comparator";
import { actions } from "../../../store";
import { useAppDispatch } from "../../../store/hooks";
import { ApiError } from "../../../utils/errorHandler";
import "../Comparator.css";
import { PropertiesSnapshotComparator } from "./PropertiesSnapshots";
import { AllServicesSnapshotsComparator } from "./AllServicesSnapshotsComparator";
import { PropertiesDiff } from "./PropertiesDiff";

export const PropertiesComparator = () => {
    const [viewToggle, setViewToggle] = useState<string>("snapshots");
    return <Container maxWidth={false} className="property-comparator">
        <div className="toggle-wrapper">
            <div className="view_toggle">
                <span
                    onClick={() => setViewToggle("comparison")}
                    className={`${viewToggle === "comparison" && "activeToggle"}`}
                >
                    Comparison
                </span>
                <span
                    onClick={() => setViewToggle("snapshots")}
                    className={`${viewToggle === "snapshots" && "activeToggle"}`}
                >
                    Service Snapshots
                </span>
                <span
                    onClick={() => setViewToggle("environment")}
                    className={`${viewToggle === "environment" && "activeToggle"}`}
                >
                    Env Snapshots
                </span>
            </div>
        </div>
        {viewToggle === "comparison" && <DirectPropetiesComparator></DirectPropetiesComparator>}
        {viewToggle === "snapshots" && <PropertiesSnapshotComparator></PropertiesSnapshotComparator>}
        {viewToggle === "environment" && <AllServicesSnapshotsComparator></AllServicesSnapshotsComparator>}
    </Container>
}

export const DirectPropetiesComparator = () => {
    const [diffPayload, setDiffPayload] = useState<{
        serviceName: string,
        env1: string,
        env2: string
    }>({serviceName: "", env1: "", env2: ""});
    const [showDiff, setShowDiff] = useState<boolean>(false);
    const [envNames, setEnvNames] = useState<{ env1: string, env2: string }>({ env1: "", env2: "" });
    const [comparisonData, setComparisonData] = useState<ComparisonData>({
        commonKeyValues: {},
        commonKeyDifferentValues: {},
        uniqueKeysFile1: {},
        uniqueKeysFile2: {}
    });
    const [serviceNameOptions, setServiceNameOptions] = useState<string[]>([]);
    const [envOptions, setEnvOptions] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await ComparatorAPI.getPropertiesFilters("serviceName");
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setServiceNameOptions(res.data);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if (!diffPayload.serviceName) {
                return;
            }

            const res = await ComparatorAPI.getPropertiesFilters("env", {serviceName: [diffPayload.serviceName]});
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setEnvOptions(res.data);
        })()
    }, [diffPayload.serviceName])

    const disableSubmit = useMemo(() => {
        if (diffPayload.env1 && diffPayload.env2) {
            return false;
        }
        return true;
    }, [diffPayload])

    const handleSubmit = async () => {
        const res = await ComparatorAPI.getPropertiesComparison(diffPayload);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setComparisonData(res);
        setEnvNames({ ...envNames });
        setShowDiff(true);
    }

    return <Container maxWidth={false} className="property-comparator">
        <Grid container columnGap={1}>
            <InputFormGrid
                formFields={[{
                    name: "serviceName",
                    fieldName: "Service",
                    options: serviceNameOptions,
                    type: FormFieldType.COSTANTS_AUTOCOMPLETE,
                    value: diffPayload.serviceName,
                    sx: {width: "200px"}
                }, {
                    name: "env1",
                    fieldName: "Source Env",
                    options: envOptions,
                    type: FormFieldType.CUSTOM_SELECT,
                    value: diffPayload.env1,
                    sx: {width: "150px"}
                }, {
                    name: "env2",
                    fieldName: "Target Env",
                    options: envOptions,
                    type: FormFieldType.CUSTOM_SELECT,
                    value: diffPayload.env2,
                    sx: {width: "150px"}
                }]}
                onChange={(e: any) => {
                    const {name, value} = e.target;
                    setDiffPayload({ ...diffPayload, [name]: value });
                }}
            ></InputFormGrid>
            <Grid item sx={{ display: "flex", justifyContent: "center", alignItems: "flex-end", paddingBottom: "5px" }}>
                <Button className={disableSubmit ? "yes-button disabled" : "yes-button"} disabled={disableSubmit} onClick={handleSubmit}>Submit</Button>
            </Grid>
        </Grid>
        {showDiff && <PropertiesDiff
            comparisonData={comparisonData}
            diffPayload={diffPayload}
        ></PropertiesDiff>}
    </Container>
}