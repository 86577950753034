import { Container, IconButton, Stack, useMediaQuery } from "@mui/material"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { RootState, actions } from "../../store"
import "./Admin.css";
import { Person, VpnKey } from "@mui/icons-material"
import { ComponentName, SubComponentName } from "../../utils/constants";


export const Admin = () => {
    const breadCrumbs = useAppSelector((state: RootState) => state.common.breadCrumbs);
    const subComponents = useAppSelector((state: RootState) => state.common.subComponents[ComponentName.Admin]);
    const dispatch = useAppDispatch();
    const { common } = actions;
    const isLargeScreen = useMediaQuery('(min-width:1200px)');

    const handleLinkClick = (name: string) => {
        let displayName = name;
        switch (name) {
            case "roles":
                displayName = "Roles";
                break;
            case "permissions":
                displayName = "Permissions"
                break;
        }

        const breadCrumbsCopy = [...breadCrumbs];
        if (breadCrumbsCopy.length === 1) {
            breadCrumbsCopy.push({
                displayName: displayName,
                name: name
            });
        }

        if (breadCrumbsCopy[0].name === name) {
            return;
        }

        breadCrumbsCopy.pop();
        breadCrumbsCopy.push({
            displayName: displayName,
            name: name
        });
        dispatch(common.setBreadCrumbs(breadCrumbsCopy));
    }

    return <Container className="admin-menu">
        <Stack direction={isLargeScreen ? "row" : "column"} spacing={2}>
            {subComponents.includes(SubComponentName.Permissions)
                && <Link onClick={() => handleLinkClick("permissions")} to={"permissions"}>
                <div className="item">
                    <IconButton>
                        <VpnKey sx={{fontSize: "100px", color: "blue"}}/>
                    </IconButton>
                    <div className="title">Permissions</div>
                </div>
            </Link>}
            {subComponents.includes(SubComponentName.Roles) && <Link onClick={() => handleLinkClick("roles")} to={"roles"}>
                <div className="item">
                    <IconButton>
                        <Person sx={{fontSize: "100px", color: "blue"}}/>
                    </IconButton>
                    <div className="title">Roles</div>
                </div>
            </Link>}
        </Stack>
    </Container>
}