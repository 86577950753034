import { Button, Container, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AuthAPI } from "../../apis";
import MSLogo from "../../assets/logos/ms-symbollockup_signin_dark.svg";
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";
import { getMSLoginUrl } from "../../utils/msUtils";
import "./Auth.css";

export const Login = () => {
    const auth = useAppSelector((state: RootState) => state.common.auth);
    const { common } = actions;
    const dispatch = useAppDispatch();
    const [hasLoginFailed, setHasLoginFailed] = useState<boolean>(false);
    const [errMsg, setErrMsg] = useState<string>("");
 
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            const accessToken = localStorage.getItem("accessToken");
            const userId = localStorage.getItem("userId");
            if (!(accessToken && userId)) {
                return;
            }
    
            if (auth.unauthorizedError) {
                if (auth.refreshed) {
                    return;
                }
                const res = await AuthAPI.refresh();
                if (res instanceof ApiError) {
                    dispatch(common.setAuth({unauthorizedError: true, refreshed: true}));
                    return;
                }
                dispatch(common.setAuth({unauthorizedError: false, refreshed: false}));
            }

            let breadcrumbs = JSON.parse(localStorage.getItem("breadcrumbs") || "[]");
            if (breadcrumbs.length === 0) {
                breadcrumbs = [{displayName: "Deployments", name: "deployments"}];
            }
    
            dispatch(common.setBreadCrumbs(breadcrumbs));
            navigate(`/dashboard/${breadcrumbs.map((crumb: any) => crumb.name).join("/")}`);
            return;
        })()
    }, [auth])

    const handleMicrosoftLoginClick = async () => {
        const url = getMSLoginUrl();
        window.location.replace(url);
    }

    return <Container maxWidth={false} className="loginContainer">
        <Container className="leftGrid">
        </Container>
        <Container className="rightGrid">
            <Container className="wrapper">
                <Typography className="title">Hi There!</Typography>
                <Typography className="subtitle">Welcome to NslHub DevOps</Typography>
                {hasLoginFailed && <Typography className="error" sx={{color: "red!important"}}>{errMsg}</Typography>}
                <Button onClick={handleMicrosoftLoginClick}><MSLogo></MSLogo></Button>
            </Container>
        </Container>
    </Container>
}