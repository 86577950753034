import urls from "./urls";
import api from "./axiosInstance";
import { ApiDataRes, ListApiRes } from "../interfaces/Common";
import { CommonListRes } from "../interfaces/DbAccess";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import { AxiosResponse } from "axios";
import { ComparisonData, ReposRes } from "../interfaces/Comparator";
import { ImageRes, CommonRes } from "../interfaces/Comparator";
import { ApiMsgRes } from "../interfaces/Common";
import { DBDetail ,gitrepo, dbaccess} from "../interfaces/DbAccess";
export default class DBAccessAPI{

    public static async getenvNames(): Promise<ApiDataRes<string[]> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<string[]>> = await api.get(`${urls.dbaccessapi}/environments`);
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async getDBNames(params: { envName: string}): Promise<ApiDataRes<string[]> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<string[]>> = await api.get(`${urls.dbaccessapi}/dbnames`, {
                params: { envName: params.envName}});
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async createDBAccess(payload: dbaccess): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(urls.dbaccessapi, {
                ...payload
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async getDBaccessDetail(id: string): Promise<ApiDataRes<DBDetail> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<DBDetail>> = await api.get(`${urls.dbaccessapi}/${id}`);
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async getDBaccess(pagination: {
        limit: number,
        pageNumber: number
    }, filters: any): Promise<ListApiRes<DBDetail> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    if (Array.isArray(filters[key])) {
                        filtersObj[key] = filters[key].filter((filter: any) => filter !== ""); 
                    } else {
                        filtersObj[key] = filters[key]
                    }
                }
            }
            const response: AxiosResponse<ListApiRes<DBDetail>> = await api.get(urls.dbaccessapi, {
                params: {
                    ...pagination,
                    ...filtersObj
                }
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }
    public static async bulkApprove(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.dbaccessapi}/approve/${payload.ids}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async updateDBAccess(payload: dbaccess): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.dbaccessapi}/${payload._id}`, {
                envName: payload.envName,
                dbName: payload.dbName,
                description: payload.description,
                rdpID: payload.rdpID
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkReview(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.dbaccessapi}/review/${payload.ids}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async bulkReject(payload: {ids: string[],comment: string}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.dbaccessapi}/reject/${payload.ids}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async deleteDbAccess(payload: dbaccess): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.delete(`${urls.dbaccessapi}/${payload._id}`);
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }
    
    public static async bulkRemove(payload: {ids: string[],comment:string }): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.dbaccessapi}/remove/${payload.ids}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

}