import { AlertColor } from "@mui/material";

export interface Pagination {
    pageNumber: number;
    limit: number;
    currentResults: number,
    totalPages: number,
    totalResults: number
}

export interface ResultsCount {
    totalPages: number,
    totalResults: number,
    currentPage: number
}


export interface ApiResult {
    data: any[],
    totalResults: number,
    totalPages: number;
    currentPage: number;
    resultsPerPage: number;
    resultsInPage: number
}

export interface ListApiRes<T> {
    message: string;
    data: {
        totalResults: number;
        totalPages: number;
        limit: number;
        pageNumber: number;
        currentResults: number;
        results: T[]
    }
}

export interface ApiMsgRes {
    message: string;
}

export interface ApiDataRes<T> {
    message: string;
    data: T
}

export interface Role {
    role: string,
    permissions: string[],
    createdAt: Date,
    _id?: string
}

export enum ROLE {
    DEVELOPER = "developer",
    DEVOPS = "devops",
    ADMIN = "admin",
    REVIEWER = "reviewer",
    APPROVER = "approver",  
}

export enum ConfigName{
    EnvBranchMap="branchNameMap",
    ModuleServiceMap="moduleServiceMap",
    TeamsToken="teamsToken",
    SrcTargetMap="srcTargetMap",
    KafkaEndPointsMap="kafkaEndPointsMap",
    TaggingPipelinesMap="taggingPipelinesMap",
    DBAccessMap="dbAccessMap"
}

export enum Environment {
    PREMERGEDEV = "premerge-dev",
    PAAS3 = "paas3",
    NEBULA = "nebula",
    PREQA = "preqa",
    PREQA_DEV = "preqa-dev",
    QA3 = "qa3",
    QA3_DEV = "qa3-dev",
    QA_DEV = "qa-dev",
    CARNIVALSC = "carnivalsc",
    CARNIVALSC_DEV = "carnivalsc-dev",
    B2C_CARNIVALS = "b2c-carnivals",
    B2C_CARNIVALS_DEV = "b2c-carnivals-dev",
    PIONEER = "pioneer",
    PIONEER_DEV = "pioneer-dev",
    MAESTRO = "maestro",
    ALPHAPSR = "alpha-psr",
    JENKINS = "jenkins",
    NSL_LIBRARY="nsl-library",
    DEV_NSL_LIBRARY="dev-nsl-library"
}

export enum SchedulingEnvironment {
    CARNIVALSC = "carnivalsc",
    CARNIVALSC_DEV = "carnivalsc-dev",
    JENKINS = "jenkins",
    QA3_JENKINS = "qa3-jenkins",
    PAAS3 = "paas3",
    PIONEER = "pioneer",
    PIONEER_DEV = "pioneer-dev",
    PREMERGEDEV = "premerge-dev",
    QA3 = "qa3",
    QA3_DEV = "qa3-dev",
    QA_DEV = "qa-dev",
}

export enum ArgoEnvironment{
    PAAS3 = "paas3",
    PREQA = "preqa",
    QA3 = "qa3",
    QA3_DEV = "qa3-dev",
    QA_DEV = "qa3-dev",
    CARNIVALSC = "carnivalsc",
    CARNIVALSC_DEV = "carnivalsc-dev",
    CARNIVALSB = "carnivalsb",
    CARNIVALSB_DEV = "carnivalsb-dev",
    PIONEER = "pioneer",
    ALPHAPSR = "alpha-psr"
}

export enum ModuleName {
    CDUI="CDUI",
    TF="TF",
    BLOCKCHAIN="BLOCKCHAIN",
    TAAS="TAAS",
    PaaS="PAAS",
    DSD="DSD",
    HYPERLEDGER="HYPERLEDGER",
    STRINGER="STRINGER"
}

export type RowType<T> = T & {_id?: string}

export enum SortOrder {
    ASC=1,
    DSC=-1
}

export interface Breadcrumb {
    displayName: string,
    name: string,
}