import { AxiosResponse } from "axios";
import { ApiDataRes, ApiMsgRes, ListApiRes } from "../interfaces/Common";
import { AutomationTestsLog } from "../interfaces/Comparator";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import api from "./axiosInstance";
import urls from "./urls";

export default class TestsLogsAPI {
    public static async getAutomationTestsLogs(pagination: {
        limit: number,
        pageNumber: number
    }, filters: any): Promise<ListApiRes<AutomationTestsLog> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    filtersObj[key] = filters[key]
                }
            }

            const response: AxiosResponse<ListApiRes<AutomationTestsLog>> = await api.get(urls.testsLogs, {
                params: {
                    ...pagination,
                    ...filtersObj
                }
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async createAutomationTestsLog(payload: AutomationTestsLog): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(urls.testsLogs, {
                ...payload
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async deleteAutomationTestsLog(payload: AutomationTestsLog): Promise<ApiMsgRes | ApiError> {
        try {
            const response = await api.delete(`${urls.testsLogs}/${payload._id}`);
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkRemoveAutomationTestsLogs(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.testsLogs}/bulk`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async getFilters(filters: {
        searchText?: string,
        searchField: string
    }): Promise<ApiDataRes<string[]> | ApiError> {
        try {
            let filtersObj: any = {};
            if (filters.searchText) {
                filtersObj.searchText = filters.searchText;
            }

            const response = await api.get(`${urls.testsLogs}/filters/${filters.searchField}`, {
                params: {
                    ...filtersObj,
                    limit: 100,
                    pageNumber: 1
                }
            });
            return response.data;                
        } catch (error) {
            return AxiosErrorHandler(error);   
        }
    }
}