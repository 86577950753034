// src/types.ts
import { SortOrder } from "./Common";
import { ScheduleStatus } from "./Scheduler";
export interface gitrepo{
    _id?: string;
    repoName:string,
    projectSlug?: string;
    grouptype:boolean,
    description:string,
    namespace:string,
    createGroup: boolean;
    groupName:string,
    groupOwners?:string[];
    groupMaintainers?:string[];
    status: ScheduleStatus,
    createdBy?:string,
}
export interface Metadata{

    createdBy: string,
    reviewedBy?: string,
    approvedBy?: string,
    rejectedBy?: string,
    removedBy?: string,
    updatedBy: string[]
}

export interface GitLog {
    status: ScheduleStatus;
    date: string,
    updatedBy: string,
    remarks?: string,
}
export interface GitDetail extends gitrepo {
    _id: string,
    statusLog: GitLog[],
    metadata?: Metadata,
    createdAt: string
}
export interface CommonListRes<T> {
    message: string;
    data: {
        totalResults: number;
        totalPages: number;
        limit: number;
        pageNumber: number;
        currentResults: number;
        results: T[]
    }
}
export interface GitFilters {
    namespace?:string | string[],
    createdBy?: string | string[],
    status?: string | string[],
    action?: string | string[],
    actionBy?: string | string[],
    sortBy?: string,
    sortOrder?: SortOrder,
    approvedBy?: string | string[],
    rejectedBy?: string | string[],
    reviewedBy?: string | string[],
    raisedBefore?: string,
    raisedAfter?: string,
}
export enum GitRequestStatus {
    Raised = "Raised",
    Rejected = "Rejected",
    Removed = "Removed",
    Approved = "Approved",
}
export enum GroupOption {
    CREATEGROUP = "Create Group",
    CHOOSEEXISTINGGROUP = "Choose Existing Group",
}

export enum NamespaceOption{
    Fractal="fractal",
    NslInfraAutomation="nsl-infra-automation",
    T360="t360"
}
// export interface gitdetail extends gitrepo {
//     _id: string,
//     createdAt: string,
//     createdBy: string;
//     statusLogs: ScheduleLog[];
// }
