import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination, Role } from '../../interfaces/Common';

export interface RolesFilters {
    role?: string[],
    permissions?: string[]
}

export interface AdminState {
    roles: Role[],
    pagination: Pagination,
    filters: RolesFilters,
    selections: {[k:string]: boolean}
};


const getInitialState = (): AdminState => {
    const initialState: AdminState = {
        roles: [],
        pagination: {
            pageNumber: 1,
            limit: 10,
            currentResults: 0,
            totalPages: 0,
            totalResults: 0        
        },
        filters: {},
        selections: {}
    }

    return initialState;
}

const AdminReducer = {
    setRoles: (state: AdminState,
        action: PayloadAction<Role[]>) => {
            state.roles = action.payload;
    },
    setResultCounts: (state: AdminState, action: PayloadAction<{
        totalPages: number,
        currentResults: number,
        totalResults: number
    }>) => {
        state.pagination.currentResults = action.payload.currentResults;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalResults = action.payload.totalResults;
    },
    setPagination: (state: AdminState, action: PayloadAction<{limit: number, pageNumber: number}>) => {
        state.pagination.pageNumber = action.payload.pageNumber;
        state.pagination.limit = action.payload.limit;
    },
    setFilters: (state: AdminState, action: PayloadAction<RolesFilters>) => {
        state.filters = action.payload;
    },
    selectRow: (state: AdminState, action: PayloadAction<{_id: string}>) => {
        state.selections[action.payload._id] = true;
    },
    unselectRow: (state: AdminState, action: PayloadAction<{_id: string}>) => {
        delete state.selections[action.payload._id];
    },
    selectAll: (state: AdminState) => {
        for (const row of state.roles) {
            if (row._id) {
                state.selections[row._id] = true;
            }
        }
    },
    unselectAll: (state: AdminState) => {
        state.selections = {}
    },
}

export const adminSlice: Slice<AdminState, typeof AdminReducer> = createSlice({
    name: 'admin',
    initialState: getInitialState(),
    reducers: AdminReducer,
})