import urls from "./urls";
import api from "./axiosInstance";
import { ApiDataRes, ROLE } from "../interfaces/Common";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";

export default class MiscellaneousAPI {
    public static async getRole(): Promise<any> {
        const response = await api.get(urls.userRole);
        return response.data?.data || [];
    }

    public static async getUserProfile(): Promise<any> {
        const response = await api.get(urls.userData, { withCredentials: true });
        return response.data?.data || [];
    }

    public static async getUserFilter(filters: {
        searchText?: string,
        role?: ROLE | ROLE[],
        searchField?: string
    }): Promise<ApiDataRes<string[]> | ApiError> {
        try {
            let filtersObj: any = {};
            if (filters.searchText) {
                filtersObj.searchText = filters.searchText;
            }

            if (filters.searchField) {
                filtersObj.searchField = filters.searchField;
            }

            if (filters.role) {
                filtersObj.role = filters.role;
            }

            const response = await api.get(urls.userFilter, {
                params: {
                    ...filtersObj
                }
            });
            return response.data;                
        } catch (error) {
            return AxiosErrorHandler(error);   
        }
    }

    public static async getUserPermissions(filters: {
        searchText?: string,
    }): Promise<ApiDataRes<string[]> | ApiError> {
        try {
            let filtersObj: any = {};
            if (filters.searchText) {
                filtersObj.searchText = filters.searchText;
            }

            const response = await api.get(urls.userPermissions, {
                params: {
                    ...filtersObj
                }
            });
            return response.data;                
        } catch (error) {
            return AxiosErrorHandler(error);   
        }
    }
}