import { Axios, AxiosError } from "axios";

export class ApiError {
    public code: number;
    public message: string;
    constructor(code: number, message: string) {
        this.code = code;
        this.message = message;
    }
}

export const AxiosErrorHandler = (error: any, apiName?: string): ApiError => {
    if (error instanceof AxiosError) {
        if (error?.response?.data?.message) {
            return new ApiError(error?.response?.status || 500, error?.response?.data?.message || "Internal Server Error");
        }
        return new ApiError(error?.response?.status || 500, error?.response?.statusText || "Internal Server Error");
    }
    return new ApiError(500, "Server Error");
}