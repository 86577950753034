import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from 'axios';
import { ROLE } from '../../interfaces/Common';
import { ApiError } from '../../utils/errorHandler';

export interface AuthState {
    isAuthenticated: boolean;
    role: string[];
    userId: string;
    permissions: string[],
    isAuthError: boolean;
    errorMsg?: string;
    userData: {
        email: string,
        name: string
    }
};

const initialState: AuthState = {
    isAuthenticated: false,
    role: [ROLE.DEVELOPER],
    userId: localStorage.getItem("userId") || "",
    permissions: [],
    isAuthError: false,
    errorMsg: "",
    userData: {
        email: "",
        name: ""
    }
}

const authReducer = {
    setAccessData(state: AuthState, action: PayloadAction<{
        accessToken: string,
        userId: string,
        role: string[]
    }>) {
        localStorage.setItem("accessToken", action.payload.accessToken);
        localStorage.setItem("userId", action.payload.userId);
        localStorage.setItem("role", action.payload.role.toString());
        state.userId = action.payload.userId;
        state.role = action.payload.role;
        state.isAuthenticated = true;
        state.errorMsg = "";
        state.isAuthError = false;
    },
    setPermissions(state: AuthState, action: PayloadAction<{
        permissions: string[]
    }>) {
        state.permissions = action.payload.permissions;
    },
    setUserData(state: AuthState, action: PayloadAction<{
        name: string,
        email: string
    }>) {
        state.userData.name = action.payload.name;
        state.userData.email = action.payload.email;
    },
    setAuthStatus(state: AuthState, action: PayloadAction<boolean>) {
        state.isAuthenticated = action.payload;
        if (!state.isAuthenticated) {
            localStorage.removeItem("accessToken");
            localStorage.removeItem("userId");
            localStorage.removeItem("role");
            state.userId = "";
            state.role = [];
            state.permissions = [];
            state.isAuthenticated = false;
            state.errorMsg = "";
            state.isAuthError = false;    
        }
    },
    setAuthError(state: AuthState, action: PayloadAction<{error: string}>) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("role");
        state.userId = "";
        state.role = [];
        state.permissions = [];
        state.isAuthenticated = false;
        state.isAuthError = true;
        state.errorMsg = action.payload.error;
    }
}

export const authSlice: Slice<AuthState, typeof authReducer> = createSlice({
    name: 'authentication',
    initialState: initialState,
    reducers: authReducer
});