import { Day, ScheduleAggregation, ScheduleType } from "../interfaces/Scheduler";

export const getCurrentWeek = (dateObj: Date) => {
    let day = dateObj.getDay();
    const date = dateObj.getDate();
    let firstDay = new Date(dateObj.toDateString());
    let lastDay = new Date(dateObj.toDateString());
    if (day === 0) {
        firstDay = new Date((new Date(dateObj)).setDate(date-6));
    } else if (day === 1) {
        lastDay = new Date((new Date(dateObj)).setDate(date+6));
    } else {
        firstDay = new Date((new Date(dateObj)).setDate(date-day+1));
        lastDay = new Date((new Date(dateObj)).setDate(date-day+7));
    }

    return {
        firstDay,
        lastDay
    }
}

export const getDateStr = (date: Date) => {
	const dateObj = new Date(date);
	let dateVal:any = dateObj.getDate();
	dateVal = dateVal <= 9 ? `0${dateVal}` : dateVal;
	let monthVal:any = dateObj.getMonth()+1;
	monthVal = monthVal <= 9 ? `0${monthVal}` : monthVal;
	let yearVal:any = dateObj.getFullYear();
	return `${getDayName(dateObj.getDay())} ${dateVal}-${monthVal}-${yearVal}`;
};

export const getDateTimeStr = (date: Date) => {
	const dateObj = new Date(date);
	let dateVal:any = dateObj.getDate();
	dateVal = dateVal <= 9 ? `0${dateVal}` : dateVal;
	let monthVal:any = dateObj.getMonth()+1;
	monthVal = monthVal <= 9 ? `0${monthVal}` : monthVal;
	let yearVal:any = dateObj.getFullYear();
	let hourVal:any = dateObj.getHours();
	hourVal = hourVal <= 9 ? `0${hourVal}` : hourVal;
	let minVal:any = dateObj.getMinutes();
	minVal = minVal <= 9 ? `0${minVal}` : minVal;
	return `${dateVal}-${monthVal}-${yearVal} ${hourVal}:${minVal}`;
};

export const getTimeStr = (date: Date) => {
	const dateObj = new Date(date);
	let hourVal:any = dateObj.getHours();
	let am = "AM";
	if (hourVal > 11) {
		am = "PM";
	}
	hourVal = hourVal > 12 ? hourVal - 12 : hourVal;
	hourVal = hourVal <= 9 ? `0${hourVal}` : hourVal;
	let minVal:any = dateObj.getMinutes();
	minVal = minVal <= 9 ? `0${minVal}` : minVal;
	return `${hourVal}:${minVal} ${am}`;
};

export const get24TimeStr = (date: Date) => {
	const dateObj = new Date(date);
	let hourVal:any = dateObj.getHours();
	hourVal = hourVal <= 9 ? `0${hourVal}` : hourVal;
	let minVal:any = dateObj.getMinutes();
	minVal = minVal <= 9 ? `0${minVal}` : minVal;
	return `${hourVal}:${minVal}`;
};

export const setZeroTime = (date: Date) => {
	date.setHours(0);
	date.setMinutes(0);
	date.setSeconds(0);
	date.setMilliseconds(0);
    return date;
}

export const getTimeSlots = (): {startTime: Date, stopTime: Date, slot: string}[] => {
    const startDateTime = new Date();
    const stopDateTime = new Date();
    setZeroTime(startDateTime);
    setZeroTime(stopDateTime);
    stopDateTime.setMinutes(30);
    const timeSlots: {startTime: Date, stopTime: Date, slot: string}[] = [];
    while (!(stopDateTime.getHours() == 0 && stopDateTime.getMinutes() == 0)) {
        timeSlots.push({
            startTime: new Date(startDateTime),
            stopTime: new Date(stopDateTime),
            slot: `${stopDateTime.getHours() > 0 ? stopDateTime.getHours() : "00"}:${stopDateTime.getMinutes() > 0 ? stopDateTime.getMinutes() : "00"}`
        })
        startDateTime.setMinutes(startDateTime.getMinutes()+30);
        stopDateTime.setMinutes(stopDateTime.getMinutes()+30);
    }
    timeSlots.push({
        startTime: new Date(startDateTime),
        stopTime: new Date(stopDateTime),
        slot: `${stopDateTime.getHours() > 0 ? stopDateTime.getHours() : "00"}:${stopDateTime.getMinutes() > 0 ? stopDateTime.getMinutes() : "00"}`
    });
    return timeSlots;
}

export const getTimeSlotsInTimeRange = (scheduleObj: ScheduleAggregation): {
    slot: string, reason: string, createdBy: string, _id: string
}[] => {
    const startDateTime = new Date(scheduleObj.startTime);
    const stopDateTime = new Date(scheduleObj.startTime);
    
    stopDateTime.setMinutes(stopDateTime.getMinutes()+30);
    const timeSlots: {
        slot: string, reason: string, createdBy: string, _id: string
    }[] = [];

    while (stopDateTime < new Date(scheduleObj.stopTime)) {
        timeSlots.push({
            slot: `${stopDateTime.getHours() > 0 ? stopDateTime.getHours() : "00"}:${stopDateTime.getMinutes() > 0 ? stopDateTime.getMinutes() : "00"}`,
            reason: scheduleObj.reason,
            createdBy: scheduleObj.createdBy,
            _id: scheduleObj._id
        });
        startDateTime.setMinutes(startDateTime.getMinutes()+30);
        stopDateTime.setMinutes(stopDateTime.getMinutes()+30);
    }
    timeSlots.push({
        slot: `${stopDateTime.getHours() > 0 ? stopDateTime.getHours() : "00"}:${stopDateTime.getMinutes() > 0 ? stopDateTime.getMinutes() : "00"}`,
        reason: scheduleObj.reason,
        createdBy: scheduleObj.createdBy,
        _id: scheduleObj._id
    });
    return timeSlots;
}

export const getOverlapPercent = (slot: {startTime: Date, stopTime: Date}, schedule: {startTime: Date, stopTime: Date}) => {
    let a1 = slot.startTime.getTime();
    let a2 = slot.stopTime.getTime();
    let b1 = schedule.startTime.getTime();
    let b2 = schedule.stopTime.getTime();

    if (b1 >= a2 || b2 <= a1) {
        return 0
    }

    if (b1 <= a1 && a2 <= b2) {
        return 100;
    }

    return 0;
}

export const isValidDate = (d: any) => {
    return !isNaN(d) && d instanceof Date;
}

export const setIntervalTime = (date: Date, min: number) => {
    const minutes = date.getMinutes();
    const remainder = minutes%min;
    const quotient = Math.floor(minutes/min);
    if (remainder === 0) {
        date.setMinutes(quotient*min)
    } else {
        date.setMinutes((quotient+1)*min)
    }
    return date;
}

export const getBetweenDates = (startDate: Date, endDate: Date): Date[] => {
    const arr = [];
    const sdt = new Date(startDate);
    setZeroTime(sdt);
    const edt = new Date(endDate);
    setZeroTime(edt);
    while(sdt.getTime() <= edt.getTime()) {
        arr.push(new Date(sdt));
        sdt.setDate(sdt.getDate()+1);
    }
    return arr;
}

export const bumpDate = (date: Date, noOfDays: number) => {
    let newDate = new Date(date)
    newDate.setDate(newDate.getDate()+noOfDays);
    return newDate;
}

export const getNextWeek = (dateObj: Date) => {
    const {lastDay} = getCurrentWeek(dateObj);
    const nextDay = new Date(lastDay.setDate(lastDay.getDate()+1));
    return getCurrentWeek(nextDay);
}

export const getPreviousWeek = (dateObj: Date) => {
    const {firstDay} = getCurrentWeek(dateObj);
    const prevDay = new Date(firstDay.setDate(firstDay.getDate()-1));
    return getCurrentWeek(prevDay);
}

export const getDaysDiff = (date1: Date, date2: Date) => {
    const diffTime = date1.getTime() - date2.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

export const getDayName = (day: number) => {
    switch (day) {
        case 0:
            return Day.SUN;
        case 1:
            return Day.MON;
        case 2:
            return Day.TUE;
        case 3:
            return Day.WED;
        case 4:
            return Day.THU;
        case 5:
            return Day.FRI;
        default:
            return Day.SAT;
    }
}

export const getDayNumber = (day: Day) => {
    switch (day) {
        case Day.SUN:
            return 0;
        case Day.MON:
            return 1;
        case Day.TUE:
            return 2;
        case Day.WED:
            return 3;
        case Day.THU:
            return 4;
        case Day.FRI:
            return 5;
        default:
            return 6;
    }
}

export const getWrokingDayNumber = (day: Day) => {
    switch (day) {
        case Day.MON:
            return 0;
        case Day.TUE:
            return 1;
        case Day.WED:
            return 2;
        case Day.THU:
            return 3;
        case Day.FRI:
            return 4;
        case Day.SAT:
            return 5;
        case Day.SUN:
            return 6;
    }
}

export const getWrokingDay = (dayNumber: number) => {
    switch (dayNumber) {
        case 0:
            return Day.MON;
        case 1:
            return Day.TUE;
        case 2:
            return Day.WED;
        case 3:
            return Day.THU;
        case 4:
            return Day.FRI;
        case 5:
            return Day.SAT;
        default :
            return Day.SUN;
    }
}

export const copyTime = (date1: Date, date2: Date) => {
	date1.setHours(date2.getHours())
	date1.setMinutes(date2.getMinutes())
	date1.setSeconds(0)
	date1.setMilliseconds(0);
    return date1;
}

export const copyDate = (date1: Date, date2: Date) => {
	date1.setDate(date2.getDate())
	date1.setMonth(date2.getMonth())
	date1.setFullYear(date2.getFullYear());
    return date1;
}

export const getNextDay = (day: Day) => {
    switch (day) {
        case Day.MON:
            return Day.TUE;
        case Day.TUE:
            return Day.WED;
        case Day.WED:
            return Day.THU;
        case Day.THU:
            return Day.FRI;
        case Day.FRI:
            return Day.SAT;
        case Day.SAT:
            return Day.SUN;
        default:
            return Day.MON
    }
}

export const getScheduleDateString = (scheduleType: ScheduleType, day: Day) => {
    let dateObj = new Date();
    let currentDate = dateObj.getDate();
    let currentDay = dateObj.getDay();
    let dayNumber = getDayNumber(day);
    if ([ScheduleType.THIS_WEEK].indexOf(scheduleType) > -1) {
        if (currentDay === dayNumber) {
            return `${dateObj.getFullYear()}-${dateObj.getMonth()+1}-${dateObj.getDate()}`    
        }
        dateObj.setDate(currentDate-currentDay+dayNumber+(currentDay===0 ? -7 : 0));
        return `${dateObj.getFullYear()}-${dateObj.getMonth()+1}-${dateObj.getDate()}`;
    } else {
        dateObj.setDate(currentDate-currentDay+dayNumber+(currentDay === 0 ? dayNumber === 0? 7 : 0 : 7));
        return `${dateObj.getFullYear()}-${dateObj.getMonth()+1}-${dateObj.getDate()}`;
    }
}

export const getCurrentDayNumber = () => {
    const date = new Date();
    const dayNumber = date.getDay();
    const day = getDayName(dayNumber);
    return getWrokingDayNumber(day);
}

export const getWeekDays = () => {
    return [Day.MON, Day.TUE, Day.WED, Day.THU, Day.FRI];
}

export const getAllDays = () => {
    return [Day.MON, Day.TUE, Day.WED, Day.THU, Day.FRI, Day.SAT, Day.SUN];
}

export const getPrevHourStr = (hourStr: string) => {
    const [hour, min] = hourStr.split(":").map(val => parseInt(val));
    const prevStr = hour-1 > 9 ? `${hour-1}` : `0${hour-1}`;
    return `${prevStr}:00`
}

export const getNextHourStr = (hourStr: string) => {
    const [hour, min] = hourStr.split(":").map(val => parseInt(val));
    const nextStr = hour+1 > 9 ? `${hour+1}` : `0${hour+1}`;
    return `${nextStr}:00`
}

export const getTableDateString = (dateStr: string) => {
    const date = new Date(dateStr);
    return `${getDateStr(date)} ${getTimeStr(date)}`
}

export const getLapsedTime = (inputDate: Date) => {
    const date = new Date();
    const createdAt = new Date(inputDate);
    const secondsElapsed = Math.floor((date.getTime() - createdAt.getTime())/1000);

    if (secondsElapsed < 60) {
        return `${secondsElapsed} sec`;
    } else if (secondsElapsed >= 60 && secondsElapsed < 3600) {
        return `${Math.floor(secondsElapsed/60)} min`
    } else {
        return `${Math.floor(secondsElapsed/3600)} hr`
    }
}

export const getFormattedDate = (date: Date) => {
    const dateObj = new Date(date);
    let dateString = `${getDayName(date.getDay())} ${date.getDate()}-${date.getMonth()+1}-${date.getFullYear()}`;
    return dateString;
}

export const getHourMinString = (date: Date) => {
    const dateObj = new Date(date);
    return `${dateObj.getHours()}:${dateObj.getMinutes() > 9 ? dateObj.getMinutes() : `0${dateObj.getMinutes()}`}`;
}