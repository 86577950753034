import React from "react";
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { azureConfig } from "./config/azureConfig";

const rootElement = document.getElementById("root");

// const msalConfig = {
//   auth: azureConfig.auth,
// };

// const msalInstance = new PublicClientApplication(msalConfig);

// msalInstance.addEventCallback((event: EventMessage) => {
//   if (event.payload!= null && event.eventType === EventType.LOGIN_SUCCESS) {
//     console.log(event.payload, '--------------------payload success');
//     msalInstance.setActiveAccount(event.payload as AccountInfo);
//   }
// });

// msalInstance.addEventCallback((event: EventMessage) => {
//   if (event.payload!= null && event.eventType === EventType.LOGOUT_SUCCESS) {
//     console.log(event.payload);
//     msalInstance.setActiveAccount(null);
//   }
// });

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    // <MsalProvider instance={msalInstance}>
      <Provider store={store}>
        <App/>
      </Provider>
    // </MsalProvider>
  );  
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
