import urls from "./urls";
import api from "./axiosInstance";
import { ApiDataRes, ApiMsgRes, ListApiRes, ROLE, Role } from "../interfaces/Common";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import { AxiosResponse } from "axios";

export default class AdminAPI {
    public static async getUsersList(pagination: {
        limit: number,
        pageNumber: number
    }, filters: any): Promise<ListApiRes<any> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    filtersObj[key] = filters[key]
                }
            }
            const response = await api.get(urls.users, {
                params: {
                    ...pagination,
                    ...filtersObj
                }
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "getUsersList");
        }
    }

    public static async updateUserRole(payload: {
        userId: string, role: ROLE
    }): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.users}/${payload.userId}/role`, {
                ...payload
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "updateRole");
        }
    }

    public static async verifyUser(payload: {
        userId: string
    }): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.users}/${payload.userId}/verify`, {
                ...payload
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "verifyUser");
        }
    }

    public static async bulkVerifyUser(payload: {
        ids: string[]
    }): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(urls.bulkVerifyUsers, {
                ...payload
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "bulkVerifyUser");
        }
    }

    public static async bulkDBlockUsers(payload: {
        ids: string[]
    }): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(urls.bulkBlockUsers, {
                ...payload
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "bulkDBlockUsers");
        }
    }

    public static async getRolesList(filters: any): Promise<ApiDataRes<string[]> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    filtersObj[key] = filters[key]
                }
            }
            const response: AxiosResponse<ApiDataRes<string[]>> = await api.get(urls.roles, {
                params: {
                    ...filtersObj
                }
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "getUsersList");
        }
    }

    public static async getRoleData(role: string): Promise<ApiDataRes<Role> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<Role>> = await api.get(`${urls.roles}/${role}`);
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error, "getRoleData");
        }
    }

    public static async updateRole(payload: {
        role: string, permissions: string[]
    }): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.roles}/${payload.role}`, {
                ...payload
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "updateRole");
        }
    }

    public static async createRole(payload: {
        role: string, permissions: string[]
    }): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.post(`${urls.roles}`, {
                ...payload
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "createRole");
        }
    }

    public static async getPermissions(filters: any): Promise<ApiDataRes<string[]> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    filtersObj[key] = filters[key]
                }
            }
            const response: AxiosResponse<ApiDataRes<string[]>> = await api.get(urls.permissions, {
                params: {
                    ...filtersObj
                }
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "getUsersList");
        }
    }

    public static async updatePermissions(payload: {permissions: string[]}): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.put(urls.permissions, {
                ...payload
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error, "updatePermissions");
        }
    }
}