import { Alarm, CalendarMonth, Deck, PlayCircle, Schedule } from "@mui/icons-material"
import { Container, Grid, IconButton, Stack, useMediaQuery } from "@mui/material"
import { Link } from "react-router-dom"
import LambdaIcon from "../../assets/svgs/lambda.svg"
import { RootState, actions } from "../../store"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { ComponentName, SubComponentName } from "../../utils/constants"
import "./ScheduleRequests/Scheduler"

export const SchedulerMenu = () => {
    const subComponents: string[] = useAppSelector((state: RootState) => state.common.subComponents[ComponentName.Scheduler]);
    const breadCrumbs = useAppSelector((state: RootState) => state.common.breadCrumbs);
    const dispatch = useAppDispatch();
    const { common } = actions;
    const isLargeScreen = useMediaQuery('(min-width:1200px)');

    const handleLinkClick = (name: string) => {
        let displayName = name;
        switch (name) {
            case "scheduleLambdas":
                displayName = "Lambdas";
                break;
            case "requests":
                displayName = "Requests";
                break;
            case "calendar":
                displayName = "Calendar"
                break;
            case "default":
                displayName = "Default Schedule"
                break;
            case "startStop":
                displayName = "Start-Stop"
                break;
            case "holidays":
                displayName = "Holidays"
                break;
        }

        const breadCrumbsCopy = [...breadCrumbs];
        if (breadCrumbsCopy.length === 1) {
            breadCrumbsCopy.push({
                displayName: displayName,
                name: name
            });
        }

        if (breadCrumbsCopy[0].name === name) {
            return;
        }

        breadCrumbsCopy.pop();
        breadCrumbsCopy.push({
            displayName: displayName,
            name: name
        });
        dispatch(common.setBreadCrumbs(breadCrumbsCopy));
    }

    return <Container className="scheduler-menu">
        <Grid container columnGap={1} rowGap={1}>
            {subComponents.includes(SubComponentName.ScheduleLambdas)
                && <Grid item xs={3}><Link onClick={() => handleLinkClick("scheduleLambdas")} to={"scheduleLambdas"}>
                    <div className="item">
                        <IconButton>
                            <LambdaIcon />
                        </IconButton>
                        <div className="title">Schedule Lambdas</div>
                    </div>
                </Link>
                </Grid>}
            {subComponents.includes(SubComponentName.ScheduleRequests)
                && <Grid item xs={3}><Link onClick={() => handleLinkClick("requests")} to={"requests"}>
                    <div className="item">
                        <IconButton>
                            <Schedule sx={{ fontSize: "100px", color: "blue" }}></Schedule>
                        </IconButton>
                        <div className="title">Requests</div>
                    </div>
                </Link>
                </Grid>}
            {subComponents.includes(SubComponentName.ScheduleCalendar)
                && <Grid item xs={3}><Link onClick={() => handleLinkClick("calendar")} to={"calendar"}>
                    <div className="item">
                        <IconButton>
                            <CalendarMonth sx={{ fontSize: "100px", color: "blue" }} />
                        </IconButton>
                        <div className="title">Calendar</div>
                    </div>
                </Link>
                </Grid>}
            {subComponents.includes(SubComponentName.DefaultSchedules)
                && <Grid item xs={3}><Link onClick={() => handleLinkClick("default")} to={"default"}>
                    <div className="item">
                        <IconButton>
                            <Alarm sx={{ fontSize: "100px", color: "blue" }} />
                        </IconButton>
                        <div className="title">Default Schedule</div>
                    </div>
                </Link>
                </Grid>}
            {subComponents.includes(SubComponentName.StartStop)
                && <Grid item xs={3}><Link onClick={() => handleLinkClick("startStop")} to={"startStop"}>
                    <div className="item">
                        <IconButton>
                            <PlayCircle sx={{ fontSize: "100px", color: "blue" }}></PlayCircle>
                        </IconButton>
                        <div className="title">Start-Stop</div>
                    </div>
                </Link>
                </Grid>}
            {subComponents.includes(SubComponentName.Holidays)
                && <Grid item xs={3}><Link onClick={() => handleLinkClick("holidays")} to={"holidays"}>
                    <div className="item">
                        <IconButton>
                            <Deck sx={{ fontSize: "100px", color: "blue" }}></Deck>
                        </IconButton>
                        <div className="title">Holidays</div>
                    </div>
                </Link>
                </Grid>}
        </Grid>
    </Container>
}