import { Home } from "@mui/icons-material";
import { Breadcrumbs, Container, Link, Typography, useMediaQuery } from "@mui/material";
import { RootState } from "../../store";
import { useAppSelector } from "../../store/hooks";
import "./Navigation.css";
import { useNavigate } from "react-router-dom";

export const BreadCrumbs = () => {
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const breadCrumbs = useAppSelector((state: RootState) => state.common.breadCrumbs);
    const navigate = useNavigate();

    const handleClick = (path: string) => {
        navigate(path);
    }

    return <Container maxWidth={false} className="bread-crumbs-container">
        <Breadcrumbs aria-label="breadcrumb">
            {!isLargeScreen && <Link key="home" href={'/dashboard/home'}><Home></Home></Link>}
            {breadCrumbs.map((item, index) => {
                let href = '/dashboard';
                for (let i = 0; i <= index; i++) {
                    href += `/${breadCrumbs[i].name}`;
                }
                if (index === 0) {
                    return breadCrumbs.length > 1 ? <div key={index} color="inherit" onClick={() => handleClick(href)}>
                        {item.displayName}
                    </div>: <Typography key={index} color="text.primary">
                        {item.displayName}
                    </Typography>
                }
                return index < breadCrumbs.length-1 ? <div key={index} color="inherit" onClick={() => handleClick(href)}>
                    {item.displayName}
                </div>: <Typography key={index} color="text.primary">{item.displayName}</Typography>
            })}
        </Breadcrumbs>
    </Container>
}