import { Button, Checkbox, Chip, Container, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { ComparatorAPI } from "../../../apis";
import { InfoDialog } from "../../../components/Dialogs/InfoDialog";
import { FormFieldType, InputFormGrid } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { Pagination } from "../../../interfaces/Common";
import { AllEnvsSnapshot } from "../../../interfaces/Comparator";
import { RootState, actions } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getDateTimeStr } from "../../../utils/dateUtils";
import { ApiError } from "../../../utils/errorHandler";
import { RecordTopicsSnapshots } from "./RecordTopicsSnapshots";
import { Difference, Download, VideoCameraFront } from "@mui/icons-material";
import { TablePaginationWrapper } from "../../../components/DataTables/TablePaginationWrapper";
import { HeaderAction } from "../../../interfaces/Data";
import { SubComponentName } from "../../../utils/constants";

export const AllTopicsSnapshotComparator = () => {
    const headerActions = useAppSelector((state: RootState) => state.common.headerActions[SubComponentName.KafkaTopics]);
    const [diffPayload, setDiffPayload] = useState<{
        snapshot1: AllEnvsSnapshot | null,
        snapshot2: AllEnvsSnapshot | null
    }>({ snapshot1: null, snapshot2: null });
    const [comparisonData, setComparisonData] = useState<{
        env: string,
        diff: { common: string[], onlyInEnv1: string[], onlyInEnv2: string[] },
        snapshots: string[]
    }[]>([]);
    const [showViewDiff, setShowViewDiff] = useState<boolean>(false);
    const [showRecordModal, setShowRecordModal] = useState<boolean>(false);
    const [topicsFetched, setTopicFetched] = useState<boolean>(false);
    const isLargeScreen = useMediaQuery('(min-width:1400px)');
    const dispatch = useAppDispatch();
    const { common } = actions;

    const handleRecordClick = () => {
        setShowRecordModal(true);
    }

    const handleViewDiff = async () => {
        if (!(diffPayload.snapshot1 && diffPayload.snapshot2)) {
            return;
        }
        dispatch(common.setShowLoader(true));
        const res = await ComparatorAPI.getAllKafkaSnapshotsComparison({
            date1: diffPayload.snapshot1.date,
            date2: diffPayload.snapshot2.date
        })
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            dispatch(common.setShowLoader(false));
            return;
        }
        dispatch(common.setShowLoader(false));
        setShowViewDiff(true);
        setComparisonData(res.data);
    }

    const handleDownload = async () => {
        if (!(diffPayload.snapshot1 && diffPayload.snapshot2)) {
            return;
        }
        dispatch(common.setShowLoader(true));
        const res = await ComparatorAPI.downloadAllKafkaSnapshotsComparison({
            date1: diffPayload.snapshot1.date,
            date2: diffPayload.snapshot2.date
        })
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            dispatch(common.setShowLoader(false));
            return;
        }
        dispatch(common.setShowLoader(false));
        const href = URL.createObjectURL(res);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', 'TopicsComparison.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    const renderTable = (title: string, topicsList: string[]) => {
        return <TableContainer sx={{ height: "50vh", width: "100%", overflow: "auto" }}>
            <Table stickyHeader>
                <TableHead>
                    <TableCell colSpan={2}>{title}</TableCell>
                </TableHead>
                <TableBody>
                    {topicsList.map((val: string, ind: number) => {
                        return <TableRow>
                            <TableCell>{ind + 1}</TableCell>
                            <TableCell>{val}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    }

    return <Container maxWidth={false} sx={{padding: "10px!important"}} className="property-comparator">
        <Grid container rowGap={1}>
        {headerActions.includes(HeaderAction.ADD_NEW) && <IconButton
                className="record-button"
                sx={{height: "50px", width: "50px"}}
                onClick={handleRecordClick}
            >
                <VideoCameraFront sx={{color: "var(--sidebar-bgc)", fontSize: "40px"}}></VideoCameraFront>
            </IconButton>}
            <Grid container item columnGap={0.4}>
                <Grid container item xs={5.8} className="snapshots-table">
                    <AllEnvsSnapshotsTable
                        selected={diffPayload.snapshot1}
                        onSelect={(obj: AllEnvsSnapshot) => {
                            if (diffPayload.snapshot1 && diffPayload.snapshot1.date === obj.date) {
                                setDiffPayload({ ...diffPayload, snapshot1: null })
                            } else {
                                setDiffPayload({ ...diffPayload, snapshot1: obj })
                            }
                        }}
                        key={1}
                        index={1}
                    ></AllEnvsSnapshotsTable>
                </Grid>
                <Grid container item  xs={5.8} className="snapshots-table">
                    <AllEnvsSnapshotsTable
                        selected={diffPayload.snapshot2}
                        onSelect={(obj: AllEnvsSnapshot) => {
                            if (diffPayload.snapshot2 && diffPayload.snapshot2.date === obj.date) {
                                setDiffPayload({ ...diffPayload, snapshot2: null })
                            } else {
                                setDiffPayload({ ...diffPayload, snapshot2: obj })
                            }
                        }}
                        key={2}
                        index={2}
                    ></AllEnvsSnapshotsTable>
                </Grid>
            </Grid>
            {/* {diffPayload.snapshot1 && diffPayload.snapshot2 && <div style={{backgroundColor: "white"}} className="compare-button">
                <IconButton
                    sx={{height: "50px", width: "50px"}}
                    onClick={handleViewDiff}
                >
                    <Difference sx={{color: "var(--sidebar-bgc)", fontSize: "40px"}}></Difference>
                </IconButton>
                </div>} */}
            {diffPayload.snapshot1 && diffPayload.snapshot2 &&  <div style={{backgroundColor: "white"}} className="compare-button2"><IconButton
                sx={{height: "50px", width: "50px"}}
                onClick={handleDownload}
            >
                <Download sx={{color: "var(--sidebar-bgc)", fontSize: "40px"}}></Download>
            </IconButton></div>}
        </Grid>
        {showRecordModal && <RecordTopicsSnapshots
            showRecordModal={showRecordModal}
            setShowRecordModal={setShowRecordModal}
        ></RecordTopicsSnapshots>}
        {showViewDiff && <InfoDialog
            open={showViewDiff}
            title="Snapshots Comparison"
            noText="Close"
            handleNo={() => {
                setShowViewDiff(false);
                setComparisonData([])
            }}
            dialogSx={{ "& .MuiPaper-root": { width: "100vw" } }}
        >
            {diffPayload.snapshot1 && diffPayload.snapshot2 &&
                <Container maxWidth={false} sx={{ padding: 0 }}>
                    <div className="diff-title">
                        <div className="snapshot-name">
                            <span>{`SNAPSHOT - 1`}</span>
                            <span>{getDateTimeStr(diffPayload.snapshot1.date)}</span>
                            <span>{diffPayload.snapshot1.tags.map(str => <Chip label={str}></Chip>)}</span>
                        </div>
                        <div className="snapshot-name">
                            <span>{`SNAPSHOT - 2`}</span>
                            <span>{getDateTimeStr(diffPayload.snapshot2.date)}</span>
                            <span>{diffPayload.snapshot2.tags.map(str => <Chip label={str}></Chip>)}</span>
                        </div>
                    </div>
                    <div className="alldiff-container">
                        {comparisonData.map(obj => {
                            return <div className="diff-item">
                                <div className="title">{obj.env}</div>
                                <Grid container className="topics-diff-container">
                                    <Grid xs={4} className="diff-grid">{renderTable(`In Both (${obj.diff.common.length})`, obj.diff.common)}</Grid>
                                    <Grid xs={4} className="diff-grid">{renderTable(`Only In 1 (${obj.diff.onlyInEnv1.length})`, obj.diff.onlyInEnv1)}</Grid>
                                    <Grid xs={4} className="diff-grid">{renderTable(`Only In 2 (${obj.diff.onlyInEnv2.length})`, obj.diff.onlyInEnv2)}</Grid>
                                </Grid>
                            </div>
                        })}
                    </div>
                </Container>
            }
        </InfoDialog>}
    </Container>
}

export const AllEnvsSnapshotsTable = (props: {
    selected: AllEnvsSnapshot | null,
    onSelect: (selected: AllEnvsSnapshot) => void,
    index: number
}) => {
    const [filters, setFilters] = useState<{
        tags: string[],
        dates: Date[],
    }>({
        tags: [],
        dates: [],
    })
    const [pagination, setPagination] = useState<Pagination>({
        pageNumber: 1,
        limit: 10,
        currentResults: 0,
        totalPages: 0,
        totalResults: 0
    })
    const [tagsOptions, setTagsOptions] = useState<string[]>([]);
    const [tagsSearchText, setTagsSearchText] = useState<string>("");
    const [rows, setRows] = useState<AllEnvsSnapshot[]>([])
    const isLargeScreen = useMediaQuery('(min-width:1400px)');
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await ComparatorAPI.getTopicsFilters("tags", {
                pageNumber: 1, limit: 1000
            }, {searchText: tagsSearchText});
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setTagsOptions(res.data);
        })()
    }, [tagsSearchText])

    useEffect(() => {
        (async () => {
            dispatch(common.setShowLoader(true));
            const res = await ComparatorAPI.getAggregatedTopicsSnapshots({
                pageNumber: pagination.pageNumber,
                limit: pagination.limit
            }, {
                after: filters.dates ? filters.dates[0] : undefined,
                before: filters.dates ? filters.dates[1] : undefined,
                tags: filters.tags
            })
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                dispatch(common.setShowLoader(false));
                return;
            }
            setRows(res.data.results);
            setPagination({
                ...pagination,
                currentResults: res.data.currentResults,
                totalPages: res.data.totalPages,
                totalResults: res.data.totalResults
            })
            dispatch(common.setShowLoader(false));
        })()
    }, [filters, pagination.pageNumber, pagination.limit])

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }

    return <>
        <Grid container item className="snapshot-filters-wrapper">
            <InputFormGrid
                formFields={[{
                    name: "dates",
                    fieldName: "Date Range",
                    value: filters.dates,
                    type: FormFieldType.CUSTOM_DATERANGE_PICKER,
                }, {
                    name: "tags",
                    fieldName: "Tags",
                    value: filters.tags,
                    type: FormFieldType.CUSTOM_SEARCH_SELECT,
                    searchText: tagsSearchText,
                    muiltiple: true,
                    onSearchTextChange(e) {
                        setTagsSearchText(e);
                    },
                    options: tagsOptions
                }]}
                onChange={handleInputChange}
                commonXs={4}
                commonSx={{width: "180px"}}
            ></InputFormGrid>
        </Grid>
        <Grid item xs={12} className="snapshots-title">{`${props.index === 1 ? "Source" : "Target"} Snapshots`}</Grid>
        <Grid item xs={12}>
            <TableContainer sx={{ height: isLargeScreen ? "62vh" : "55vh", overflow: "auto" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Tags</TableCell>
                            <TableCell>Envs</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(obj => {
                            return <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={props.selected ? props.selected.date === obj.date : false}
                                        onClick={(e: any) => {
                                            props.onSelect(obj)
                                        }}
                                        sx={{
                                            color: "var(--sidebar-bgc)",
                                            height: "20px!important",
                                            "&.Mui-checked": {
                                                color: "var(--sidebar-bgc)",
                                            },
                                            '& .MuiSvgIcon-root': { fontSize: 20 },
                                        }}
                                    ></Checkbox>
                                </TableCell>
                                <TableCell>
                                    <div>{`${getDateTimeStr(obj.date)}`}</div>
                                </TableCell>
                                <TableCell>
                                    <div>{obj.tags.map(str => <Chip sx={{height: "20px"}} label={str}></Chip>)}</div>
                                </TableCell>
                                <TableCell>
                                    <div>{obj.envs.map(str => <Chip sx={{height: "20px"}} label={str}></Chip>)}</div>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePaginationWrapper
                limit={pagination.limit}
                pageNumber={pagination.pageNumber}
                currentResults={pagination.currentResults}
                totalResults={pagination.totalResults}
                totalPages={pagination.totalPages}
                onPageChange={(pageNumber: number) => {setPagination({...pagination,pageNumber: pageNumber})}}
                onLimitChange={(limit: number) => {setPagination({...pagination,limit: limit})}}
                width="45vw"
                hideRpp={!isLargeScreen}
            ></TablePaginationWrapper>
        </Grid>
    </>
}
