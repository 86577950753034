import { Checkbox, ListItemText, MenuItem, SxProps, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useDetectClickOutside } from 'react-detect-click-outside';
import "./Inputs.css";

export interface CustomSearchSelectProps {
    fieldName?: string,
    sideLabel?: boolean,
    searchText?: string,
    onSearchTextChange?: (e: string) => void,
    onChange: (e: any) => void,
    value: string[],
    options: string[],
    multiple?: boolean,
    sx?: SxProps,
    name: string
}

export const CustomSearchSelect = <T,>(props: CustomSearchSelectProps) => {
    let sx: any = props.sx;
    let width = sx ? sx.width : "200px";
    const [open, setOpen] = useState<boolean>(false);
    const ref = useDetectClickOutside({ onTriggered: () => setOpen(false) });
    const [pos, setPos] = useState<{
        left: number, right: number, top: number, bottom: number
    }>({
        left: 0, right: 0, top: 0, bottom: 0
    })
    const [localSearchText, setLocalSearchText] = useState<string>("")
    const [filteredOptions, setFilteredOptions] = useState<string[]>(props.options);

    useEffect(() => {
        if (ref.current) {
            setPos(calculateTopPosition(ref));
        }
    }, [ref])

    useEffect(() => {
        if (props.onSearchTextChange) {
            return;
        }
        setFilteredOptions(props.options.filter(val => {
            return val.toLowerCase().includes(localSearchText.toLocaleLowerCase())
        }));
    }, [props.options, localSearchText])

    const calculateTopPosition = (elementRef: any) => {
        const measures = elementRef.current.getBoundingClientRect();
        return {
            left: measures.x,
            right: measures.right,
            bottom: measures.bottom,
            top: measures.y+35,
        }
    };

    const handleSelect = (option: string) => {        
        if (props.multiple) {
            if (option === "Select All" && props.value.length === props.options.length) {
                props.onChange({
                    target: {
                        name: props.name,
                        value: []    
                    }
                })
            } else if (option === "Select All") {
                props.onChange({
                    target: {
                        name: props.name,
                        value: props.options   
                    }
                })
            } else {
                const valueCopy = [...props.value];
                const ind = valueCopy.indexOf(option);
                if (ind > -1) {
                    valueCopy.splice(ind, 1);
                } else {
                    valueCopy.push(option);
                }
                props.onChange({
                    target: {
                        name: props.name,
                        value: valueCopy    
                    }
                })    
            }
        } else {
            props.onChange({
                target: {
                    name: props.name,
                    value: option    
                }
            })
        }
        setLocalSearchText("")
    }

    return <div ref={ref} className={props.sideLabel ? "custom-input sidelabel" : "custom-input"} style={{ width: width }}>
        {props.fieldName && <div className="field-name"><span>{props.fieldName}</span></div>}
        <TextField
            size={"small"}
            value={props.onSearchTextChange ? props.searchText : localSearchText}
            onChange={(e: any) => {
                if (props.onSearchTextChange) {
                    props.onSearchTextChange(e.target.value);
                } else {
                    setLocalSearchText(e.target.value)
                }
            }}
            onFocus={() => {
                setOpen(true);
            }}
            placeholder={props.value ? (props.multiple ? props.value.join(","): `${props.value}`) : ""}
            autoComplete="off"
            inputProps={{ style: { height: "30px", padding: "5px 14px", boxSizing: "border-box" } }}
            sx={{ ...props.sx, width: (props.sideLabel ? width/2 : width), zIndex: 100 }}></TextField>
        {open && <div className="dropdown-container"
            id="dropdown-cont"
            style={{
                position: "fixed",
                top: pos.top + 30,
                left: pos.left,
                width: props.sideLabel ? width/2 : width
            }}
        >{props.multiple && <MenuItem value={"Select All"} onClick={() => handleSelect("Select All")}>
            <Checkbox
                checked={
                    (props.options && props.options.length > 0)
                    && props.value?.length === props.options?.length
                }
                style={{ color: "var(--sidebar-bgc)" }} />
            <ListItemText primary={"Select All"} />
        </MenuItem>}
            {(props.onSearchTextChange ? props.options : filteredOptions).map((option, index) => {
                let isChecked = false;
                if (Array.isArray(props.value)) {
                    isChecked = props.value.includes(option);
                } else {
                    isChecked = props.value === option;
                }
                return <MenuItem value={option} onClick={() => handleSelect(option)} key={index}>
                    <Checkbox checked={isChecked} style={{ color: "var(--sidebar-bgc)" }} />
                    <ListItemText primary={option} />
                </MenuItem>;
            })}</div>}
    </div>
}
