import { Container, Stack, useMediaQuery } from "@mui/material"
import { CustomIcon } from "../../components"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store/hooks"
import { RootState, actions } from "../../store"
import KafkaImage from "../../assets/images/kafka.png";
import ArgoCDImage from "../../assets/images/argocd.png"
import PropertiesImage from "../../assets/images/properties.png";
import AutomationTestingImage from "../../assets/images/automation-testing.jpg";
import "./Comparator.css";
import { ComponentName, SubComponentName } from "../../utils/constants"

export const Comparator = () => {
    const breadCrumbs = useAppSelector((state: RootState) => state.common.breadCrumbs);
    const subComponents = useAppSelector((state: RootState) => state.common.subComponents[ComponentName.Comparator]);
    const dispatch = useAppDispatch();
    const { common } = actions;
    const isLargeScreen = useMediaQuery('(min-width:1200px)');

    const handleLinkClick = (name: string) => {
        let displayName = name;
        switch (name) {
            case SubComponentName.NSLProperties:
                displayName = "NSL Properties";
                break;
            case SubComponentName.KafkaTopics:
                displayName = "Kafka Topics"
                break;
            case SubComponentName.ArgoImages:
                displayName = "Image Comparator"
                break;
        }

        const breadCrumbsCopy = [...breadCrumbs];
        if (breadCrumbsCopy.length === 1) {
            breadCrumbsCopy.push({
                displayName: displayName,
                name: name
            });
        }

        if (breadCrumbsCopy[0].name === name) {
            return;
        }

        breadCrumbsCopy.pop();
        breadCrumbsCopy.push({
            displayName: displayName,
            name: name
        });
        dispatch(common.setBreadCrumbs(breadCrumbsCopy));
    }

    return <Container className="comparator">
        <Stack direction={isLargeScreen ? "row" : "column"} spacing={2}>
            {subComponents.includes(SubComponentName.NSLProperties) && <Link onClick={() => handleLinkClick(SubComponentName.NSLProperties)} to={SubComponentName.NSLProperties}>
                <div className="item">
                    <img className="image" src={PropertiesImage}/>
                    <div className="title">NSL Proeprties</div>
                </div>
            </Link>}
            {subComponents.includes(SubComponentName.KafkaTopics) && <Link onClick={() => handleLinkClick(SubComponentName.KafkaTopics)} to={SubComponentName.KafkaTopics}>
                <div className="item">
                    <img className="image" src={KafkaImage}/>
                    <div className="title">Kafka Topics</div>
                </div>
            </Link>}
            {subComponents.includes(SubComponentName.ArgoImages) && <Link onClick={() => handleLinkClick(SubComponentName.ArgoImages)} to={SubComponentName.ArgoImages}>
                <div className="item">
                    <img className="image" src={ArgoCDImage}/>
                    <div className="title">Image Comparator</div>
                </div>
            </Link>}
            {subComponents.includes(SubComponentName.TestsLogs) && <Link onClick={() => handleLinkClick(SubComponentName.TestsLogs)} to={SubComponentName.TestsLogs}>
                <div className="item">
                    <img className="image" src={AutomationTestingImage}/>
                    <div className="title">Test Logs</div>
                </div>
            </Link>}
        </Stack>
    </Container>
}