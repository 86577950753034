import { Chip, IconButton, Paper, TableBody, TableCell, TableRow, useMediaQuery } from "@mui/material";
import "./Data.css";
import { RowAction } from "../../interfaces/Data";
import CustomIcon from "../Icons";
import { BlockSharp, CheckBox, CheckBoxOutlineBlank, Delete, Edit, Grading, Replay, Save, ThumbDown, ThumbUp, VerifiedUser, Visibility } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { DeploymentStatus } from "../../interfaces/Deployments";
import { SpecialPermissions, TableComponents, rowActionsFromStatus } from "../../utils/constants";
import { getActionsFromStatus, getChipColor } from "../../utils/deploymentUtils";
import { RowType } from "../../interfaces/Common";
import { Pipeline, User } from "../../interfaces/Accounts";
import { Day, RunType, ScheduleDate, ScheduleLambda, ScheduleTime } from "../../interfaces/Scheduler";
import { getDateStr, getTableDateString, getTimeStr } from "../../utils/dateUtils";
import { EditUserRole } from "../../containers/Accounts/EditUserRole";
import { UserAPI } from "../../apis";
import auth from "../../apis/auth";
import { useAppSelector } from "../../store/hooks";
import { RootState } from "../../store";
import { EditScheduleLambda } from "../../containers/Scheduler/ScheduleLambdas/EditScheduleLambda";

export const RowActions = (props: {
    isSelected?: boolean,
    actions: RowAction[],
    handleAction: (action: RowAction) => void,
    width?: string,
    sx?: any
}) => {
    let cellSx: any = {minWidth: props.width };
    if (props.isSelected) {
        cellSx["backgroundColor"] = "lightblue!important";
    }

    return <TableCell sx={{...cellSx, ...props.sx}} className="table-cell row-actions-cell">{props.actions.map((item, index) => {
        switch(item) {
            case RowAction.REJECT: 
                return <IconButton key={index} onClick={() => props.handleAction(item)}><ThumbDown sx={{fontSize: 16}}></ThumbDown></IconButton>
            case RowAction.APPROVE: 
                return <IconButton key={index} onClick={() => props.handleAction(item)}><ThumbUp sx={{fontSize: 16}}></ThumbUp></IconButton>
            case RowAction.EDIT_ROW: 
                return <IconButton key={index} onClick={() => props.handleAction(item)}><Edit sx={{fontSize: 16}}></Edit></IconButton>
            case RowAction.DELETE_ROW: 
                return <IconButton key={index} onClick={() => props.handleAction(item)}><Delete sx={{fontSize: 16}}></Delete></IconButton>
            case RowAction.SAVE_ROW: 
                return <IconButton key={index} onClick={() => props.handleAction(item)}><Save sx={{fontSize: 16}}></Save></IconButton>
            case RowAction.BLOCK: 
                return <IconButton key={index} onClick={() => props.handleAction(item)}><BlockSharp sx={{fontSize: 16}}></BlockSharp></IconButton>
            case RowAction.VERIFY: 
                return <IconButton key={index} onClick={() => props.handleAction(item)}><VerifiedUser sx={{fontSize: 16}}></VerifiedUser></IconButton>
            case RowAction.REVIEW: 
                return <IconButton key={index} onClick={() => props.handleAction(item)}><Grading sx={{fontSize: 16}}></Grading></IconButton>
            case RowAction.REBUILD: 
                return <IconButton key={index} onClick={() => props.handleAction(item)}><Replay sx={{fontSize: 16}}></Replay></IconButton>
            case RowAction.CHECKBOX:
                if (props.isSelected) {
                    return <IconButton key={index} onClick={() => props.handleAction(item)}><CheckBox sx={{fontSize: 16}}></CheckBox></IconButton>
                } else {
                    return <IconButton key={index} onClick={() => props.handleAction(item)}><CheckBoxOutlineBlank sx={{fontSize: 16}}></CheckBoxOutlineBlank></IconButton>
                }
            case RowAction.VIEW:
                return <IconButton key={index} onClick={() => props.handleAction(item)}><Visibility sx={{fontSize: 16}}></Visibility></IconButton>
            default:
                return <IconButton key={index} onClick={() => props.handleAction(item)}><CheckBoxOutlineBlank sx={{fontSize: 16}}></CheckBoxOutlineBlank></IconButton>
        }
    })}</TableCell>
}

export const TableBodyWrapper = <T,>(props: {
    componentName: TableComponents,
    rows: RowType<T>[],
    columns: {field: string, displayName: string, width?: string}[],
    actions?: RowAction[],
    handleAction?: (action: RowAction, row: any) => void
    selections?: {[key: string]: boolean},
    isStatusActions?: boolean,
    rowClickFn?: Function,
    rowDbClick?: Function,
    idClickFn?: Function,
    inlineEdit?: boolean
}) => {
    const specialPermissions = useAppSelector((state: RootState) => state.common.specialPermissions);
    const userData = useAppSelector((state: RootState) => state.common.userData);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [editRow, setEditRow] = useState<RowType<T>>();
    const isLargeScreen = useMediaQuery('(min-width:1200px)');

    const handleAction = (action: RowAction, row: RowType<T>) => {        
        if (action === RowAction.EDIT_ROW && props.inlineEdit) {
            setEditMode(true);
            setEditRow(row);
        } else if (props.handleAction) {
            props.handleAction(action, row);
        }
    }

    const handleEditCancel = () => {
        setEditMode(false);
    }

    const handleEditSave = () => {
        setEditMode(false);
    }

    const renderCell = (row: any, item: {field: string, displayName: string, width?: string}) => {
        let cellSx: any = {};
        if (props.selections && props.selections[row._id || ""]) {
            cellSx["backgroundColor"] = "lightblue!important";
        }

        if (item.width) {
            cellSx["width"] = `${item.width}!important`;
        }

        let val: any = `${row[item.field] || ""}`;

        if ([TableComponents.Deployments, TableComponents.ScheduleRequests].indexOf(props.componentName) > -1 && item.field === "status") {
            val = <Chip sx={getChipColor(row[item.field])} label={`${row[item.field]}`}></Chip>;
        }

        if (item.field === "_id") {
            return <TableCell key={item.field}
                sx={isLargeScreen ? {backgroundColor: 'white', ...cellSx, position: 'sticky', left: 132} : {backgroundColor: 'white',  ...cellSx,}}
                onClick={() => {
                    if (props.idClickFn) {
                        props.idClickFn(row);
                    }
                }}
            className="table-cell"><a>{`${row[item.field]}`}</a></TableCell>
        }

        if (item.field === "dates" && props.componentName === TableComponents.Holidays) {
            return <TableCell
                key={item.field}
                onClick={() => {
                    if (props.rowClickFn) {
                        props.rowClickFn(row)
                    }
                }}
                onDoubleClick={() => {
                    if (props.rowDbClick) {
                        props.rowDbClick(row)
                    }
                }}
                sx={{...cellSx, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}} className="table-cell">{row[item.field].map((obj: any) => {
                    return <span>{getDateStr(obj)}</span>
                })}</TableCell>
        }

        if (item.field === "s3logslink" && props.componentName === TableComponents.TestsLogs) {
            return <TableCell
                key={item.field}
                // onClick={() => {
                //     if (props.rowClickFn) {
                //         props.rowClickFn(row)
                //     }
                // }}
                // onDoubleClick={() => {
                //     if (props.rowDbClick) {
                //         props.rowDbClick(row)
                //     }
                // }}
                sx={cellSx} className="table-cell"><a href={val} target="_blank">{val}</a></TableCell>
        }

        if (props.componentName === TableComponents.ScheduleRequests && item.field === "schedules") {
            let selectedDates: Date[] = row.selectedDates || [];

            if (selectedDates.length === 0) {
                let value: ScheduleDate[] = row.schedule || {};
                let nonStop = row.runType === RunType.NON_STOP;
                let string = "";
                let startDay: Day = value[0]?.day;
                let endDay: Day = value[value.length-1]?.day;
                let startTime = value[0]?.startTime;
                let endTime = value[value.length-1]?.stopTime;
                if (nonStop) {
                    string = `${startDay} ${startTime} to ${endDay} ${endTime}`;
                } else {
                    string = `${value.map(val => val.day).join(", ")} ${startTime} to ${endTime}`;
                }
    
                return <TableCell
                    key={item.field}
                    onClick={() => {
                        if (props.rowClickFn) {
                            props.rowClickFn(row)
                        }
                    }}
                    onDoubleClick={() => {
                        if (props.rowDbClick) {
                            props.rowDbClick(row)
                        }
                    }}
                    sx={cellSx} className="table-cell">{string}</TableCell>    
            }

            let nonStop = row.runType === RunType.NON_STOP;
            let startDay: Date = selectedDates[0];
            let endDay: Date = selectedDates[selectedDates.length-1];
            let startTime = row.startTime;
            let endTime = row.stopTime;
            let elem: any = "";
            if (nonStop) {
                elem = <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <span>{`${getDateStr(startDay)} ${getTimeStr(startTime)}`}</span>
                    <span>To</span>
                    <span>{`${getDateStr(endDay)} ${getTimeStr(endTime)}`}</span>
                </div>;
            } else {
                elem = <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                    <span>{`${selectedDates.map(val => getDateStr(val)).join(", ")}`}</span>
                    <span>{`${getTimeStr(startTime)} to ${getTimeStr(endTime)}`}</span>
                </div>;
            }

            return <TableCell
                key={item.field}
                onClick={() => {
                    if (props.rowClickFn) {
                        props.rowClickFn(row)
                    }
                }}
                onDoubleClick={() => {
                    if (props.rowDbClick) {
                        props.rowDbClick(row)
                    }
                }}
                sx={cellSx} className="table-cell">{elem}</TableCell>
        }

        if (item.field === "createdAt") {
            val = getTableDateString(val);
        }

        return <TableCell
            key={item.field}
            onClick={() => {
                if (props.rowClickFn) {
                    props.rowClickFn(row)
                }
            }}
            onDoubleClick={() => {
                if (props.rowDbClick) {
                    props.rowDbClick(row)
                }
            }}
            sx={cellSx} className="table-cell">{val}</TableCell>
    }

    const getActionsWidth = () => {
        switch (props.componentName) {
            case TableComponents.Deployments:
                return "100px";
            default:
                return "100px";
        }
    }

    return <TableBody className="table-body">{props.rows.map((row, index) => {
        let rowId = row._id || "";
        let editRowId = editRow ? editRow._id : "";
        if (editMode && rowId === editRowId && props.inlineEdit) {
            switch(props.componentName) {
                case TableComponents.Accounts:
                    const user: User = row as unknown as User;
                    return <EditUserRole
                        handleCancel={handleEditCancel}
                        handleSave={handleEditSave}
                        row={user} ></EditUserRole>
                case TableComponents.ScheduleLambdas:
                    const lambdaObj: ScheduleLambda = row as unknown as ScheduleLambda;
                    return <EditScheduleLambda
                        handleCancel={handleEditCancel}
                        handleSave={handleEditSave}
                        row={lambdaObj} ></EditScheduleLambda>
            }
        } else {
            const rowSx: any = {}
            if (props.rowClickFn) {
                rowSx[":hover"] = {
                    cursor: "pointer"
                }
            }

            return <TableRow sx={rowSx} key={index}>
                {props.actions && props.actions.length > 0 && props.handleAction && 
                    <RowActions
                        width={getActionsWidth()}
                        isSelected={props.selections && props.selections[rowId]}
                        actions={props.isStatusActions ? getActionsFromStatus({
                            row: row,
                            rowActions: props.actions || [],
                            personalData: userData,
                            specialPermissions: specialPermissions,
                            componentName: props.componentName
                        }): props.actions}
                        handleAction={(action: RowAction) => {
                            handleAction(action, row);
                        }}></RowActions>}
                {props.columns.map((item) => {
                    return renderCell(row, item);
                })}
            </TableRow>
        }
    })}</TableBody>
}