import { Container, useMediaQuery } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import SidebarContainer from "../Navigation/SidebarContainer";
import "./MainContainer.css";
import { Deployments } from "../Deployments/Deployments";
import { HeaderBar } from "./HeaderBar";
import { BreadCrumbs } from "../Navigation/BreadCrumbs";
import { Comparator } from "../Comparator/Comparator";
import { PropertiesComparator } from "../Comparator/NSLProperties/PropertiesComparator";
import { Admin } from "../Admin/Admin";
import { TopicsComparator } from "../Comparator/KafkaTopics/TopicsComparator";
import { ImageComparator } from "../Comparator/ImageComparator"
import { Configs } from "../Configs/Configs";
import { DeploymentDetailsPage } from "../Deployments/DeploymentDetailsPage";
import { Scheduler } from "../Scheduler/ScheduleRequests/Scheduler";
import { ScheduleDetailsPage } from "../Scheduler/ScheduleRequests/ScheduleDetailsPage";
import { SchedulerMenu } from "../Scheduler/SchedulerMenu";
import { SchedulerCalendar } from "../ScheduleCalendar/ScheduleCalendar";
import { DefaultSchedulePage } from "../ScheduleCalendar/DefaultSchedulePage";
import { StartStopEnv } from "../Scheduler/StartStopEnv";
import { Accounts } from "../Accounts/Accounts";
import { Roles } from "../Admin/Roles";
import { Permissions } from "../Admin/Permissions";
import { Gitlab } from "../gitlab/Gitlab";
import { GitDetailsPage } from "../gitlab/GitlabDetailsPage";
import { DBAccess } from "../Dbaccess/Dbaccess";
import { DBAccessDetailsPage } from "../Dbaccess/DbAccessDetailsPage";
import { ScheduleLambdas } from "../Scheduler/ScheduleLambdas/ScheduleLambdas";
import { Holidays } from "../Scheduler/Holidays/Holidays";
import { AutomationTestsLogs } from "../Comparator/TestsLogs/TestsLogs";
import { BuildCycle } from "../BuildCycle/BuildCycle";
import { BuildCycleDetailsPage } from "../BuildCycle/BuildCycleDetailsPage";

export const MainContainer = () => {
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    return <Container maxWidth={false} className="main-container">
        <HeaderBar></HeaderBar>
        <Container className="nav-content-container" sx={isLargeScreen ? {} : {'& .MuiContainer-root': {width: "100%!important"}}} maxWidth={false}>
            {isLargeScreen && <SidebarContainer></SidebarContainer>}
            <Container maxWidth={false} className="content-container">
                <BreadCrumbs></BreadCrumbs>
                <Routes>
                    {!isLargeScreen && <Route path={'home'} element={<SidebarContainer></SidebarContainer>}></Route>}
                    <Route path={'admin'} element={<Admin></Admin>}/>
                    <Route path={'admin/roles'} element={<Roles></Roles>}/>
                    <Route path={'admin/permissions'} element={<Permissions></Permissions>}/>
                    <Route path={'accounts'} element={<Accounts></Accounts>}/>
                    <Route path={'configs'} element={<Configs></Configs>}/>
                    <Route path={`deployments`} element={<Deployments></Deployments>}/>
                    <Route path={`deployments/:deploymentId`} element={<DeploymentDetailsPage></DeploymentDetailsPage>}/>
                    <Route path={`scheduler`} element={<SchedulerMenu></SchedulerMenu>}/>
                    <Route path={`scheduler/default`} element={<DefaultSchedulePage></DefaultSchedulePage>}/>
                    <Route path={`scheduler/requests`} element={<Scheduler></Scheduler>}/>
                    <Route path={`buildcycle`} element={<BuildCycle></BuildCycle>}/>
                    <Route path={`buildcycle/:buildcycleid`} element={<BuildCycleDetailsPage></BuildCycleDetailsPage>}/>
                    <Route path={`gitrepos`} element={<Gitlab></Gitlab>}/>
                    <Route path={`gitrepos/:gitlabdetailsid`} element={<GitDetailsPage ></GitDetailsPage>}/>
                    <Route path={`dbaccess`} element={<DBAccess ></DBAccess>}/>
                    <Route path={`dbaccess/:dbaccessid`} element={<DBAccessDetailsPage ></DBAccessDetailsPage>}/>
                    <Route path={`scheduler/startStop`} element={<StartStopEnv></StartStopEnv>}/>
                    <Route path={`scheduler/scheduleLambdas`} element={<ScheduleLambdas></ScheduleLambdas>}/>
                    <Route path={`scheduler/holidays`} element={<Holidays></Holidays>}/>
                    <Route path={`scheduler/calendar`} element={<SchedulerCalendar></SchedulerCalendar>}/>
                    <Route path={`scheduler/requests/:scheduleId`} element={<ScheduleDetailsPage></ScheduleDetailsPage>}/>
                    <Route path={`comparator`} element={<Comparator></Comparator>}/>
                    <Route path={`comparator/propertiescompare`} element={<PropertiesComparator></PropertiesComparator>}/>
                    <Route path={`comparator/imagescompare`} element={<ImageComparator></ImageComparator>}/>
                    <Route path={`comparator/topicscompare`} element={<TopicsComparator></TopicsComparator>}/>
                    <Route path={`comparator/testslogs`} element={<AutomationTestsLogs></AutomationTestsLogs>}/>
                </Routes>
            </Container>
        </Container>
    </Container>
}