import { AxiosResponse } from "axios";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import urls from "./urls";
import api from "./axiosInstance";
import { ApiDataRes, ApiMsgRes, ConfigName, Environment, ListApiRes, ModuleName } from "../interfaces/Common";
import { ConfigReq, DeploymentDetail } from "../interfaces/Deployments";

export default class ConfigsAPI {

  public static async getBranchNames(): Promise<ApiDataRes<{[key in Environment]?: string}> | ApiError> {
      try {
          const response: AxiosResponse<ApiDataRes<{[key in Environment]?: string}>> = await api.get(urls.branchNamesMap);
          return response.data || {};                
      } catch (error) {
          return AxiosErrorHandler(error);
      }
  }

  public static async getModuleServiceMap(): Promise<ApiDataRes<{[key in ModuleName]?: string[]}> | ApiError> {
    try {
        const response: AxiosResponse<ApiDataRes<{[key in ModuleName]?: string[]}>> = await api.get(urls.moduleServiceMap);
        return response.data || {};                
    } catch (error) {
        return AxiosErrorHandler(error);
        
    }
  }

  public static async getKafkaEndpointMap(): Promise<ApiDataRes<{[key in Environment]?: string}> | ApiError> {
    try {
        const response: AxiosResponse<ApiDataRes<{[key in Environment]?: string}>> = await api.get(urls.kafkaEndPointMap);
        return response.data || {};                
    } catch (error) {
        return AxiosErrorHandler(error);
    }
  }

  public static async getTaggingPipelines(): Promise<ApiDataRes<{[key in ModuleName]?: string[]}> | ApiError> {
    try {
        const response: AxiosResponse<ApiDataRes<{[key in ModuleName]?: string[]}>> = await api.get(urls.taggingPipelines);
        return response.data || {};                
    } catch (error) {
        return AxiosErrorHandler(error);
    }
  }

  public static async getSrcTargetMap(): Promise<ApiDataRes<{[key in Environment]?: Environment[]}> | ApiError> {
    try {
        const response: AxiosResponse<ApiDataRes<{[key in Environment]?: Environment[]}>> = await api.get(urls.srcTargetMap);
        return response.data || {};                
    } catch (error) {
        return AxiosErrorHandler(error);
    }
}

  public static async updateBranchNames(data: {[key in Environment]?: string}): Promise<ApiMsgRes | ApiError> {
    try {
        const payload: ConfigReq = {
          name: ConfigName.EnvBranchMap,
          data: data
        }
        const response: AxiosResponse<ApiMsgRes> = await api.put(urls.branchNamesMap, payload);
        return response.data;                
    } catch (error) {
        return AxiosErrorHandler(error);
        
    }
  }

  public static async updateModuleServiceMap(data: {[key in ModuleName]?: string[]}): Promise<ApiMsgRes | ApiError> {
    try {
        const payload: ConfigReq = {
          name: "moduleServiceMap",
          data: data
        }
          const response: AxiosResponse<ApiMsgRes> = await api.put(urls.moduleServiceMap, payload);
          return response.data;                
    } catch (error) {
        return AxiosErrorHandler(error);  
    }
  }

  public static async updateKafkaEndpointMap(data: {[key in Environment]?: string}): Promise<ApiMsgRes | ApiError> {
    try {
        const payload: ConfigReq = {
          name: ConfigName.KafkaEndPointsMap,
          data: data
        }
        const response: AxiosResponse<ApiMsgRes> = await api.put(urls.kafkaEndPointMap, payload);
        return response.data;                
    } catch (error) {
        return AxiosErrorHandler(error);
    }
  }

  public static async updateSrcTargetMap(data: {[key in Environment]?: Environment[]}): Promise<ApiMsgRes | ApiError> {
    try {
        const payload: ConfigReq = {
          name: ConfigName.SrcTargetMap,
          data: data
        }
        const response: AxiosResponse<ApiMsgRes> = await api.put(urls.srcTargetMap, payload);
        return response.data;                
    } catch (error) {
        return AxiosErrorHandler(error);
        
    }
  }

  public static async updateTaggingPipelines(data: {[key in ModuleName]?: string[]}): Promise<ApiMsgRes | ApiError> {
    try {
        const payload: ConfigReq = {
          name: ConfigName.TaggingPipelinesMap,
          data: data
        }
        const response: AxiosResponse<ApiMsgRes> = await api.put(urls.taggingPipelines, payload);
        return response.data;                
    } catch (error) {
        return AxiosErrorHandler(error);
        
    }
  }

  public static async getModules(): Promise<ApiDataRes<string[]> | ApiError> {
    try {
        const response: AxiosResponse<ApiDataRes<string[]>> = await api.get(`${urls.configs}/modules`);
        return response.data || [];                
    } catch (error) {
        return AxiosErrorHandler(error);
        
    }
  }

  public static async getModuleServices(params: { module: string | string[]}): Promise<ApiDataRes<string[]> | ApiError> {
    try {
        const response: AxiosResponse<ApiDataRes<string[]>> = await api.get(`${urls.configs}/services`, {
            params: { moduleName: params.module}});
        return response.data || [];
    } catch (error) {
        return AxiosErrorHandler(error);
    }
  }

  public static async getDbAccess(): Promise<ApiDataRes<{[key in Environment]?: string[]}> | ApiError> {
    try {
        const response: AxiosResponse<ApiDataRes<{[key in Environment]?: string[]}>> = await api.get(urls.dbAccess);
        return response.data || {};                
    } catch (error) {
        return AxiosErrorHandler(error);
    }
  }

  public static async updateDbAccess(data: {[key in Environment]?: string[]}): Promise<ApiMsgRes | ApiError> {
    try {
        const payload: ConfigReq = {
          name: ConfigName.DBAccessMap,
          data: data
        }
        const response: AxiosResponse<ApiMsgRes> = await api.put(urls.dbAccess, payload);
        return response.data;                
    } catch (error) {
        return AxiosErrorHandler(error);
        
    }
  }




}
