import { Grid } from "@mui/material";
import { useState } from "react";
import { ComparatorAPI } from "../../../apis";
import { ConfirmDialog } from "../../../components/Dialogs/ConfirmDialog";
import { InputFormGrid, FormFieldType } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { Environment } from "../../../interfaces/Common";
import { actions } from "../../../store";
import { useAppDispatch } from "../../../store/hooks";
import { ApiError } from "../../../utils/errorHandler";

export const RecordTopicsSnapshots = ({
    showRecordModal,
    setShowRecordModal,
}: {
    showRecordModal: boolean,
    setShowRecordModal: (show: boolean) => void,
}) => {
    const envs = Object.values(Environment);
    const [snapshotPayload, setSnapshotPayload] = useState<{
        envs: Environment[],
        lastOverRide: boolean,
        tags: string[]
    }>({ envs: [], lastOverRide: false, tags: [] });
    const [showRecordConfirm, setShowRecordConfirm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common } = actions;

    const handleRecordSnapshots = () => {
        setShowRecordConfirm(true);
    }

    const handleRecordConfirm = async () => {
        dispatch(common.setShowLoader(true));
        const res = await ComparatorAPI.createTopicsSnapshots(snapshotPayload);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            dispatch(common.setShowLoader(false));
            return;
        }
        dispatch(common.setShowLoader(false));
        setSnapshotPayload({ envs: [], lastOverRide: false, tags: [] });
        setShowRecordConfirm(false);
        setShowRecordModal(false);
    }

    return <ConfirmDialog
        open={showRecordModal}
        title="Record Snapshots"
        yesText="Record"
        noText="Cancel"
        handleYes={handleRecordSnapshots}
        handleNo={() => setShowRecordModal(false)}
        disableYes={snapshotPayload.envs.length === 0}
    >
        <Grid container rowGap={1}>
            <Grid container item xs={12}>
                <InputFormGrid
                    formFields={[
                        {
                            fieldName: "All",
                            name: "All",
                            type: FormFieldType.CUSTOM_CHECKBOX,
                            value: snapshotPayload.envs.length === envs.length
                        },
                        ...envs.map(env => {
                            return {
                                fieldName: env,
                                name: env,
                                type: FormFieldType.CUSTOM_CHECKBOX,
                                value: snapshotPayload.envs.includes(env)
                            }
                        })
                    ]}
                    onChange={(e: any) => {
                        const { name, value } = e.target;
                        if (name === "All") {
                            setSnapshotPayload(snapshotPayload.envs.length < envs.length ? {
                                ...snapshotPayload,
                                envs: [...envs]
                            } : {
                                ...snapshotPayload,
                                envs: []
                            });
                            return;
                        }

                        let selectedEnvsCopy = [...snapshotPayload.envs];
                        let ind = selectedEnvsCopy.indexOf(name);
                        if (ind > -1 && !value) {
                            selectedEnvsCopy.splice(ind, 1);
                        } else {
                            selectedEnvsCopy.push(name);
                        }
                        setSnapshotPayload({ ...snapshotPayload, envs: selectedEnvsCopy });
                    }}
                ></InputFormGrid>
            </Grid>
            <Grid container item xs={12}>
                <InputFormGrid
                    formFields={[{
                        name: "tags",
                        value: snapshotPayload.tags.join(","),
                        fieldName: "Tags"
                    }]}
                    onChange={(e: any) => {
                        const { name, value } = e.target;
                        setSnapshotPayload({
                            ...snapshotPayload,
                            tags: value.split(",")
                        })
                    }}
                ></InputFormGrid>
            </Grid>
        </Grid>
        {showRecordConfirm && <ConfirmDialog
            open={showRecordConfirm}
            title="Confirmation"
            yesText="Yes"
            noText="No"
            handleYes={handleRecordConfirm}
            handleNo={() => setShowRecordConfirm(false)}
        >
            {`Are you sure you want to record the kafka topics snapshots for the selected envs?`}
        </ConfirmDialog>}
    </ConfirmDialog>
}