import { Cancel, Save } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogTitle, IconButton, TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { AdminAPI } from "../../apis";
import { Dropdown } from "../../components/Filters/Dropdown";
import { User } from "../../interfaces/Accounts";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";
import "./Accounts.css";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";

export const EditUserRole = (props: {
    handleCancel: () => void,
    handleSave: () => void,
    row: User
}) => {
    const [userObj, setUserObj] = useState<User>({
        _id: props.row._id,
        name: props.row.name,
        email: props.row.email,
        role: props.row.role,
        isVerified: props.row.isVerified,
    })
    const [roleOptions, setRoleOptions] = useState<string[]>([]);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { accounts, common } = actions;

    useEffect(() => {
        (async () => {
            const res = await AdminAPI.getRolesList({});
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setRoleOptions(res.data);
        })()
    }, [])

    const handleCreateConfirm = async () => {
        dispatch(common.setShowLoader(true));
        const res = await AdminAPI.updateUserRole({
            userId: props.row._id,
            role: userObj.role
        });

        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            dispatch(common.setShowLoader(false));
            return;
        }

        setShowConfirm(false);
        setUserObj({
            _id: props.row._id,
            name: props.row.name,
            email: props.row.email,
            role: props.row.role,
            isVerified: props.row.isVerified,
        });
        dispatch(accounts.reloadData());
        props.handleSave();
        dispatch(common.setShowLoader(false));
    }

    useEffect(() => {
        if (userObj.role) {
            setCanSubmit(true);
        }
    }, [userObj])

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (value === "") {
            // If the user selects an empty value, clear the source environment field
            setUserObj({
                ...userObj,
                [name]: "",
            });
        } else {
            setUserObj({
                ...userObj,
                [name]: value,
            });
        }
    };

    const handleCancelClick = () => {
        props.handleCancel();
    }

    return <><TableRow>
        <TableCell className="action-icons-container">
            <IconButton disabled={!canSubmit} onClick={() => setShowConfirm(true)}>
                <Save sx={{ color: "green", fontSize: 16, opacity: canSubmit ? 1 : 0.5 }}></Save>
            </IconButton>
            <IconButton><Cancel sx={{ color: "red", fontSize: 16 }} onClick={handleCancelClick}></Cancel></IconButton>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            {userObj.name}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            {userObj.email}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <Dropdown
                multiple
                id="role-select"
                value={userObj.role}
                name="role"
                label="Role"
                onChange={handleInputChange}
                options={roleOptions}
            ></Dropdown>
        </TableCell>
    </TableRow>
        <ConfirmDialog
            open={showConfirm}
            yesText="Yes"
            noText="No"
            handleNo={() => setShowConfirm(false)}
            handleYes={() => handleCreateConfirm()}
            title="User Role Update Confirmation"
        ><span>{`Are you sure, you want to update the role to ${userObj.role}?`}</span></ConfirmDialog>
    </>
}