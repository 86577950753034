import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { Pagination } from '../../interfaces/Common';
import { DBDetail, DBFilters } from '../../interfaces/DbAccess';

export interface DBState {
    dbdetails: DBDetail[],
    pagination: Pagination,
    filters: DBFilters,
    selections: {[k:string]: boolean},
    reload: {
        randomNumber: number
    }
};


const getInitialState = (): DBState => {
    const initialState: DBState = {
        dbdetails: [],
        pagination: {
            pageNumber: 1,
            limit: 10,
            currentResults: 0,
            totalPages: 0,
            totalResults: 0        
        },
        filters: {},
        selections: {},
        reload: {
            randomNumber: 0
        }
    }

    return initialState;
}

const DBAccessReducer = {
    setDBaccess: (state: DBState,
        action: PayloadAction<DBDetail[]>) => {
            state.dbdetails = action.payload;
    },
    setResultCounts: (state: DBState, action: PayloadAction<{
        totalPages: number,
        currentResults: number,
        totalResults: number
    }>) => {
        state.pagination.currentResults = action.payload.currentResults;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalResults = action.payload.totalResults;
    },
    setPagination: (state: DBState, action: PayloadAction<{limit: number, pageNumber: number}>) => {
        state.pagination.pageNumber = action.payload.pageNumber;
        state.pagination.limit = action.payload.limit;
    },
    reloadData: (state: DBState) => {
        state.reload.randomNumber = Math.random();
    },
    setFilters: (state: DBState, action: PayloadAction<DBFilters>) => {
        state.filters = action.payload;
    },
    selectRow: (state: DBState, action: PayloadAction<{_id: string}>) => {
        state.selections[action.payload._id] = true;
    },
    setSelections: (state: DBState, action: PayloadAction<{[k:string]: boolean}>) => {
        state.selections = action.payload;
    },
    unselectRow: (state: DBState, action: PayloadAction<{_id: string}>) => {
        delete state.selections[action.payload._id];
    },
    selectAll: (state: DBState) => {
        for (const row of state.dbdetails) {
            if (row._id) {
                state.selections[row._id] = true;
            }
        }
    },
    unselectAll: (state: DBState) => {
        state.selections = {}
    },
}

export const dbSlice: Slice<DBState, typeof DBAccessReducer> = createSlice({
    name: 'dbaccess',
    initialState: getInitialState(),
    reducers: DBAccessReducer,
})