// src/types.ts

import { Environment, SortOrder } from "./Common";

export interface Deployment {
    _id?: string;
    module: string,
    services: string[];
    sourceEnvironment?: Environment;
    targetEnvironment: Environment;
    branchName?: string;
    tickets: string[];
    deploymentType: DeploymentType;
    status: DeploymentStatus;
    createdBy?: string;
    tag?: string;
}

export enum StatusLogType {
    USER_ACTION = "User Action",
    DEPLOYMENT = "Deployment"
}

export interface Metadata{
    tag?: string,
    createdBy: string,
    reviewedBy?: string,
    approvedBy?: string,
    rejectedBy?: string,
    removedBy?: string,
    updatedBy: string[]
}

export interface DeploymentDetail extends Deployment {
    _id: string,
    statusLog: {
        status: DeploymentStatus;
        type: StatusLogType;
        date: string,
        updatedBy: string,
        pipeline?: string,
        url?: string,
        remarks?: string,
    }[],
    metadata?: Metadata,
    createdAt: string
}

export interface DeploymentFilters {
    module?: string | string[],
    createdBy?: string | string[],
    deploymentType?: string | string[],
    services?: string[] | string[],
    sourceEnvironment?: string | string[],
    targetEnvironment?: string | string[],
    status?: string | string[],
    tickets?: string | string[],
    action?: string | string[],
    actionBy?: string | string[],
    sortBy?: string,
    sortOrder?: SortOrder,
    approvedBy?: string | string[],
    rejectedBy?: string | string[],
    reviewedBy?: string | string[],
    raisedBefore?: string,
    raisedAfter?: string,
}

export enum DeploymentStatus {
    Raised = "Raised",
    Updated = "Updated",
    Rejected = "Rejected",
    Removed = "Removed",
    Reviewed = "Reviewed",
    InProgress = "InProgress",
    Completed = "Completed",
    Failed = "Failed",
    Rebuild = "Rebuild",
    Approved = "Approved"
}

export enum DeploymentType {
    BUGBUILD = "Bug Build",
    BUGPROMOTION = "Bug Promotion",
    BUILDPROMOTION = "Build Promotion",
    BUILD = "Build",
}

export interface ConfigObj {
    branchNameMaps?: { [key in Environment]?: string },
    modulesServicesMap?: { [k: string]: string[] }
}

export interface ConfigReq {
    name: string,
    data: any
}
