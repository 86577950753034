import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { Deployment, DeploymentDetail, DeploymentFilters } from '../../interfaces/Deployments';
import { Pagination } from '../../interfaces/Common';

export interface DeploymentsState {
    deployments: DeploymentDetail[],
    pagination: Pagination,
    filters: DeploymentFilters,
    selections: {[k:string]: boolean},
    reload: {
        randomNumber: number
    }
};


const getInitialState = (): DeploymentsState => {
    const initialState: DeploymentsState = {
        deployments: [],
        pagination: {
            pageNumber: 1,
            limit: 10,
            currentResults: 0,
            totalPages: 0,
            totalResults: 0        
        },
        filters: {},
        selections: {},
        reload: {
            randomNumber: 0
        }
    }

    return initialState;
}

const deploymentsReducer = {
    setDeployments: (state: DeploymentsState,
        action: PayloadAction<DeploymentDetail[]>) => {
            state.deployments = action.payload;
    },
    setResultCounts: (state: DeploymentsState, action: PayloadAction<{
        totalPages: number,
        currentResults: number,
        totalResults: number
    }>) => {
        state.pagination.currentResults = action.payload.currentResults;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalResults = action.payload.totalResults;
    },
    setPagination: (state: DeploymentsState, action: PayloadAction<{limit: number, pageNumber: number}>) => {
        state.pagination.pageNumber = action.payload.pageNumber;
        state.pagination.limit = action.payload.limit;
    },
    reloadData: (state: DeploymentsState) => {
        state.reload.randomNumber = Math.random();
    },
    setFilters: (state: DeploymentsState, action: PayloadAction<DeploymentFilters>) => {
        state.filters = action.payload;
    },
    selectRow: (state: DeploymentsState, action: PayloadAction<{_id: string}>) => {
        state.selections[action.payload._id] = true;
    },
    setSelections: (state: DeploymentsState, action: PayloadAction<{[k:string]: boolean}>) => {
        state.selections = action.payload;
    },
    unselectRow: (state: DeploymentsState, action: PayloadAction<{_id: string}>) => {
        delete state.selections[action.payload._id];
    },
    selectAll: (state: DeploymentsState) => {
        for (const row of state.deployments) {
            if (row._id) {
                state.selections[row._id] = true;
            }
        }
    },
    unselectAll: (state: DeploymentsState) => {
        state.selections = {}
    },
}

export const deploymentsSlice: Slice<DeploymentsState, typeof deploymentsReducer> = createSlice({
    name: 'deployments',
    initialState: getInitialState(),
    reducers: deploymentsReducer,
})