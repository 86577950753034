import { Alert, AlertProps, Backdrop, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, List, ListItem, MenuItem, Snackbar, Stack, TextareaAutosize, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import DBAccessAPI from "../../apis/dbaccess";
import { Select, TextField } from "../../components/Wrapper";
import { Pagination } from "../../interfaces/Common";
import { dbaccess } from "../../interfaces/DbAccess";
import { ScheduleStatus } from "../../interfaces/Scheduler";
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { ApiError } from "../../utils/errorHandler";
import "./Dbaccess.css";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { InputFormGrid, FormFieldType } from "../../components/Wrapper/Inputs/InputFormGrid";
import { DeploymentType } from "../../interfaces/Deployments";
export const CreateDbPage = (props: {
    handleClose: Function,
    showModal: boolean,
    // handleReload: Function
}) => {
    const [dbaccessObj, setdbaccessObj] = useState<dbaccess>({
        envName: "",
        dbName: "",
        rdpID: "",
        description: "",
        status: ScheduleStatus.Raised
    });
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [envOptions, setenvOptions] = useState<string[]>([]);
    const [databaseOptions, setdatabaseOptions] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        if (dbaccessObj.envName && dbaccessObj.dbName && dbaccessObj.rdpID && dbaccessObj.description) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }

    }, [dbaccessObj])

    useEffect(() => {
        (async () => {
            const res = await DBAccessAPI.getenvNames();
            if (res instanceof ApiError) {
                dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
                return;
            }
            setenvOptions(res.data);
        })()
    }, [])

    useEffect(() => {
        (async () => {
            if (dbaccessObj.envName) {
                const res = await DBAccessAPI.getDBNames({ envName: dbaccessObj.envName });
                if (res instanceof ApiError) {
                    dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
                    return;
                }

                setdatabaseOptions(res.data);
                setdbaccessObj({
                    ...dbaccessObj,
                    dbName: ""
                })
            } else {
                setdatabaseOptions([]);
            }
        })()
    }, [dbaccessObj.envName])


    const handleCreateConfirm = async () => {
        dispatch(common.setShowLoader(true));
        const res = await DBAccessAPI.createDBAccess(dbaccessObj);
        if (res instanceof ApiError) {
            dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
            return;
        }
        setShowConfirm(false);
        setdbaccessObj({
            envName: "",
            dbName: "",
            rdpID: "",
            description: "",
            status: ScheduleStatus.Raised
        });

        dispatch(common.setSnackbar({ children: "Successfully raised a new DB Accesss request.", severity: "success" }))
        props.handleClose();
        dispatch(common.setShowLoader(false));
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (value === "") {
            // If the user selects an empty value, clear the source environment field
            setdbaccessObj({
                ...dbaccessObj,
                [name]: "", // Make sure to update the specific field with an empty value
            });
        } else {
            setdbaccessObj({
                ...dbaccessObj,
                [name]: value,
            });
        }
    };

    const handleClose = () => {
        props.handleClose()
    }

    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }

    return <ConfirmDialog
        yesText="Create"
        noText="Cancel"
        title="Create DB Access Request"
        open={props.showModal}
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        disableYes={!canSubmit}
    >
        <Container maxWidth={false} className="create-deployment-list">
            <Grid container justifyContent={"center"} rowGap={1} alignItems={"center"}>
                <InputFormGrid
                    formFields={[{
                        fieldName: "Environment",
                        name: "envName",
                        options: envOptions,
                        type: FormFieldType.CUSTOM_SELECT,
                        value: dbaccessObj.envName
                    }, {
                        fieldName: "Database",
                        name: "dbName",
                        options: databaseOptions,
                        type: FormFieldType.CUSTOM_SELECT,
                        value: dbaccessObj.dbName,
                    }, {
                        fieldName: "RDP IP",
                        name: "rdpID",
                        value: dbaccessObj.rdpID
                    }, {
                        fieldName: "Description",
                        name: "description",
                        value: dbaccessObj.description
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
            </Grid>
            {!showConfirm ? <></> : <ConfirmDialog
                open={showConfirm}
                yesText="Yes"
                noText="No"
                title="Confirmation"
                handleNo={() => setShowConfirm(false)}
                handleYes={() => handleCreateConfirm()}
            >
                {`Are you sure, you want to create a db access request for ${dbaccessObj.dbName} on the env ${dbaccessObj.envName}?`}
            </ConfirmDialog>}
        </Container>
    </ConfirmDialog>
}