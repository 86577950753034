import { useEffect, useState } from 'react';
import { Container, Alert, AlertProps, Snackbar, FormControl, MenuItem, Box, Chip, IconButton, Button, Dialog, DialogActions, DialogTitle, TableCell, TableRow, Table, TableBody, TableHead, Backdrop, CircularProgress } from '@mui/material';
import { AdminAPI } from '../../apis';
import { Role } from '../../interfaces/Common';
import "./Admin.css";
import { ApiError } from '../../utils/errorHandler';
import { InputLabel, Select, TextField } from '../../components/Wrapper';
import { Add, AddCard, Cancel, ClearAll, Remove, Save, SettingsBackupRestore } from '@mui/icons-material';
import { getPermissionsMap, getPermissionsPayload } from './Permissions';
import { useAppDispatch } from '../../store/hooks';
import { actions } from '../../store';


export const Roles = () => {
    const [roleData, setRoleData] = useState<Role | null>(null);
    const [allPermissionsMap, setAllPermissionsMap] = useState<{[k: string]: string[]}>({});
    const [permissionsMap, setPermissionsMap] = useState<{[k: string]: string[]}>({});
    const [roleOptions, setRoleOptions] = useState<string[]>([]);
    const [role, setRole] = useState<string | null>(null);
    const [reset, setReset] = useState<number>(0);
    const [showSave, setShowSave] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [addMode, setAddMode] = useState<boolean>(false);
    
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
      (async () => {
        dispatch(common.setShowLoader(true));
        const res = await AdminAPI.getRolesList({});
        if (res instanceof ApiError) {
          dispatch(common.processApiError(res));
          return;
        }
        setRoleOptions(res.data);
        setRole(res.data[0]);

        const res2 = await AdminAPI.getPermissions({});
        if (res2 instanceof ApiError) {
          dispatch(common.processApiError(res2));
          return;
        }
        setAllPermissionsMap(getPermissionsMap(res2.data));
        dispatch(common.setShowLoader(false));
      })()
    }, [])

    useEffect(() => {
      if (!role) {
        return;
      }
      dispatch(common.setShowLoader(true));

      (async () => {
        const res = await AdminAPI.getRoleData(role);
        if (res instanceof ApiError) {
          dispatch(common.processApiError(res));
          return;
        }
        setRoleData(res.data);
      })()
      dispatch(common.setShowLoader(false));
    }, [role, reset])

    useEffect(() => {
      if (!roleData) {
        return;
      }

      setPermissionsMap(getPermissionsMap(roleData.permissions));
    }, [roleData])

    

    const handleCateggoryAdd = (category: string) => {
        setPermissionsMap({
          [category]: [],
          ...permissionsMap
        });
        setShowSave(true);      
    }

    const handleCategoryDelete = (key: string) => {
      let permissionsMapCopy = {...permissionsMap};
      delete permissionsMapCopy[key];
      setPermissionsMap(permissionsMapCopy);
      setShowSave(true);
    }

    const handlePermissionAdd = (key: string, permission: string) => {
        let permissionsMapCopy = {...permissionsMap};
        let actions = [...permissionsMapCopy[key]];
        actions.push(permission);
        permissionsMapCopy[key] = actions;
        setPermissionsMap(permissionsMapCopy);
        setShowSave(true);
    }

    const handlePermissionDelete = (key: string, permission: string) => {
      let permissionsMapCopy = {...permissionsMap};
      let actions = [...permissionsMapCopy[key]];
      let index = actions.indexOf(permission);
      if (index > -1) {
        actions.splice(index, 1);
      }
      permissionsMapCopy[key] = actions;
      setPermissionsMap(permissionsMapCopy);
      setShowSave(true);
    }

    const handleSaveClick = () => {
      setShowConfirm(true);
    }


    const handleAddClick = async () => {
      const res = await AdminAPI.getPermissions({});
      if (res instanceof ApiError) {
        dispatch(common.processApiError(res));
        return;
      }

      if (roleData) {
        setRoleData({...roleData, role: "", permissions: res.data})
      }
      setAddMode(true);
    }

    const handleConfirm = async () => {
      dispatch(common.setShowLoader(true));

      if (addMode && roleData) {
        const payload = getPermissionsPayload(permissionsMap);
        const res = await AdminAPI.createRole({role: roleData.role, permissions: payload.permissions});
        if (res instanceof ApiError) {
          dispatch(common.processApiError(res));
          dispatch(common.setShowLoader(false));
          return;
        }
        dispatch(common.setSnackbar({children: res.message, severity: "success"}));
        setShowConfirm(false);
        handleCancel();
        dispatch(common.setShowLoader(false));
        return;
      }

      if (!role) {
        return;
      }

      const payload = getPermissionsPayload(permissionsMap);
      const res = await AdminAPI.updateRole({role: role, ...payload});

      if (res instanceof ApiError) {
        dispatch(common.processApiError(res));
        dispatch(common.setShowLoader(false));
        return;
      }

      dispatch(common.setSnackbar({children: res.message, severity: "success"}));
      setShowConfirm(false);
      setShowSave(false);
      dispatch(common.setShowLoader(false));
    }

    const handleReset = () => {
      setReset(Math.random());
    }

    const handleEmptyPermissions = () => {
      setPermissionsMap({})
      setShowSave(true);
    }

    const handleCancel = () => {
      setAddMode(false);
      handleReset();
    }

    const renderPermissions = () => {
      if (permissionsMap) {
        let permissions: any[] = [];
        for (let key of Object.keys(permissionsMap)) {
          permissions.push(<CategoryItem
            category={key}
            permissions={permissionsMap[key]}
            allPermissions={allPermissionsMap[key] || []}
            handleDeletePermission={handlePermissionDelete}
            handleDeleteCategory={handleCategoryDelete}
            handleAddPermission={handlePermissionAdd}
          ></CategoryItem>)
        }
        return permissions;
      } else {
        return []
      }
    }

    const renderCategoriesDropdown = () => {
      let categories = Object.keys(permissionsMap);
      let allCategories = Object.keys(allPermissionsMap);
      let options = allCategories.filter(category => categories.indexOf(category) === -1);
      return options.length > 0 ? <Box className='category-container' sx={{justifyContent: "flex-start!important"}}>
        <Box className='category' sx={{borderRight: "none!important"}}>
        <Select id="role-select" name="role" variant="outlined"
            onChange={(e: any) => {
              if (e.target.value) {
                handleCateggoryAdd(e.target.value)
              }
            }}
        >
            {options.map((item) => {
                return <MenuItem value={item}>{item}</MenuItem>
            })}
        </Select>
        </Box>
      </Box> : <></>
    }

    return (<Container maxWidth={false} className='roles-container'>
      {!addMode && <Container maxWidth={false} className="filters-container">
          <FormControl size="small" sx={{ width: 150 }}>
              <InputLabel id="role-select-label">Role</InputLabel>
              <Select
                  labelId="role-select-label"
                  id="role-select"
                  value={role}
                  name="role"
                  label="Role"
                  onChange={(e: any) => {
                    setRole(e.target.value);
                  }}
                  size='small'
                  variant="outlined"
              >
                  {roleOptions.map((item) => {
                      return <MenuItem value={item}>{item}</MenuItem>
                  })}
              </Select>
          </FormControl>
      </Container>}
      <Container className='data-container'>
        <Box className='heading-container'>
          <Box>{addMode ? 
            <TextField label="New Role" size='small' onChange={(e: any) => {
                if (roleData) {
                  setRoleData({...roleData, role: e.target.value});
                }
              }}></TextField>
          : `${role} Permissions`}</Box>
          <Box className='buttons-wrapper'>
            {!addMode && <IconButton onClick={handleAddClick}><AddCard color="primary"></AddCard></IconButton>}
            {addMode && <IconButton onClick={handleCancel}><Cancel color="error"></Cancel></IconButton>}
            <IconButton onClick={handleSaveClick} disabled={!showSave}><Save color={showSave ? "primary": "disabled"}></Save></IconButton>
            <IconButton onClick={handleEmptyPermissions}><ClearAll color="primary"></ClearAll></IconButton>
            <IconButton onClick={() => {
              if (addMode) {
                handleAddClick();
              } else {
                handleReset();
              }
            }}>
              <SettingsBackupRestore color="primary"></SettingsBackupRestore>
            </IconButton>
          </Box>
        </Box>
        <Box className='perms-container'>
          {renderCategoriesDropdown()}
          <Table>        
            <TableHead>
              <TableRow>
                <TableCell sx={{textAlign: "center", borderRight: "1px solid rgba(224, 224, 224, 1)"}}>Category</TableCell>
                <TableCell>Permissions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {renderPermissions()}
            </TableBody>
          </Table>
        </Box>
      </Container>
      <Dialog
          maxWidth="xs"
          open={!!(showConfirm)}
      >
          <DialogTitle>{!addMode ?
            `Are you sure, you want to update the role permissions for the ${role}?`
            : `Are you sure, you want to create the role ${roleData?.role}?`}</DialogTitle>
          <DialogActions>
              <Button onClick={() => setShowConfirm(false)}>
                  No
              </Button>
              <Button onClick={handleConfirm}>Yes</Button>
          </DialogActions>
      </Dialog>
  </Container>
  );
}

export const CategoryItem = (props: {
  category: string,
  permissions: string[],
  allPermissions: string[],
  handleDeletePermission: (category: string, permission: string) => void,
  handleDeleteCategory: (category: string) => void,
  handleAddPermission: (category: string, permission: string) => void,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [options, setOptions] = useState<string[]>([]);

  useEffect(() => {
    if (props.permissions.length === 0) {
      setOptions(props.allPermissions);
      return;
    }

    const permissionsLeft = props.allPermissions.filter(permission => {
      return props.permissions.indexOf(permission) === -1
    });
    setOptions(permissionsLeft);
  }, [props.allPermissions, props.permissions])

  return <TableRow>
  <TableCell sx={{textAlign: "center", borderRight: "1px solid rgba(224, 224, 224, 1)"}}>
      <Chip
        onDelete={() => props.handleDeleteCategory(props.category)}
        color='primary'
        label={props.category}
      ></Chip>
    </TableCell>
    <TableCell>
        {props.permissions.map((perm, index) => {
          return <Chip
            sx={{margin: "2px"}}
            label={perm}
            key={index}
            onDelete={() => props.handleDeletePermission(props.category, perm)}
          ></Chip>
        })}
        {options.length > 0 && (showDropdown ? 
          <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2px"}}>
            <Select id="role-select" name="role" variant="outlined"
              onChange={(e: any) => {
                if (e.target.value) {
                  props.handleAddPermission(props.category, e.target.value)
                }
              }}
          >
              {options.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>
              })}
          </Select>
          <IconButton onClick={() => setShowDropdown(false)}><Remove></Remove></IconButton>
        </Box> : <IconButton onClick={() => setShowDropdown(true)}><Add></Add></IconButton>)}
    </TableCell>
  </TableRow>
}