import { Checkbox, Grid, ListItemText, MenuItem, SxProps } from "@mui/material";
import { CustomTextArea, CustomTextField } from "./CutomTextField";
import { CustomSelect } from "./CustomSelect";
import { ConstantsAutoComplete } from "./ConstantsAutoComplete";
import { CustomCheckbox } from "./CustomCheckBox";
import { CustomRange } from "./CustomRange";
import { CustomAutoComplete } from "./CustomAutoComplete";
import { CustomRangeDatePicker } from "./CustomDateRangePicker";
import { CustomDatePicker, CustomTimePicker, CustomTimeRangePicker } from "./CustomDatePicker";
import { useEffect, useState } from "react";
import { CustomSearchSelect } from "./CustomSearchSelect";

export enum FormFieldType {
	CUSTOM_TEXTFIELD = "CustomTextField",
	CUSTOM_SELECT = "CustomSelect",
	CUSTOM_DATEPICKER = "CustomDatePicker",
	CUSTOM_MULTI_DATEPICKER = "CustomMultiDatePicker",
	CUSTOM_DATERANGE_PICKER = "CustomDateRangePicker",
	CUSTOM_TIMEPICKER = "CustomTimePicker",
	CUSTOM_TIMERANGEPICKER = "CustomTimeRangePicker",
	CUSTOM_CHECKBOX = "CustomCheckbox",
	COSTANTS_AUTOCOMPLETE = "StatesAutComplete",
	CUSTOM_AUTOCOMPLETE = "CustomAutComplete",
	CUSTOM_SEARCH_SELECT = "CustomSearchSelect",
	CUSTOM_RANGE = "CustomRange",
	CUSTOM_FILEINPUT = "CustomFileInput",
	CUSTOM_TEXT_AREA = "CustomTextArea"
}

export interface FormField {
	type?: FormFieldType,
	textType?: string,
	name: string,
	fieldName?: string,
	value: any,
	value1?: any,
	value2?: any,
	required?: boolean,
	helperText?: string,
	muiltiple?: boolean,
	options?: string[],
	format?: string,
	disabled?: boolean,
	minDate?: Date,
	maxDate?: Date
	minTime?: string,
	maxTime?: string,
	maxLength?: number,
	maxValue?: number,
	minValue?: number,
	minRows?: number,
	searchText?: string,
	onSearchTextChange?: (e: any) => void,
	sideLabel?: boolean,
	sx?: SxProps
}

const defaultLabelNames: { [k: string]: string } = {
	year: "Year",
	from: "From",
	to: "To",
	branch: "Branch",
	startDate: "Start Date",
	endDate: "End Date",
};

export const InputFormGrid = (props: {
	formFields: FormField[],
	onChange: (e: any, name: string, index?: number) => void,
	commonSx?: SxProps,
	commonXs?: number
}) => {
	return <>{props.formFields.map((formField, index) => {
		const fieldType = formField.type || FormFieldType.CUSTOM_TEXTFIELD;
		switch (fieldType) {
			case FormFieldType.CUSTOM_DATERANGE_PICKER:
				return <Grid item key={index} xs={props.commonXs}><CustomRangeDatePicker
					id={formField.name}
					name={formField.name}
					sx={formField.sx || props.commonSx}
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					value={formField.value}
					onChange={(e: any) => props.onChange(e, formField.name)}
					required={formField.required}
					maxDate={formField.maxDate ? new Date(formField.maxDate) : undefined}
					minDate={formField.minDate ? new Date(formField.minDate) : undefined}
					maxLength={formField.maxLength}
				></CustomRangeDatePicker></Grid>;
			case FormFieldType.CUSTOM_RANGE:
				return <Grid item key={index} xs={props.commonXs}><CustomRange
					id={formField.name}
					name={formField.name}
					type={formField.textType || "text"}
					sx={formField.sx || props.commonSx}
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					value={formField.value}
					onChange={(e: any) => props.onChange(e, formField.name)}
					required={formField.required}
					helperText={formField.helperText}
					disabled={formField.disabled}
				></CustomRange></Grid>;
			case FormFieldType.CUSTOM_TIMEPICKER:
				return <Grid item key={index} xs={props.commonXs}><CustomTimePicker
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					name={formField.name}
					format={formField.format || "HH:mm A"}
					value={formField.value}
					sx={formField.sx || props.commonSx}
					disabled={formField.disabled}
					onChange={(newValue: Date | null) => {
						if (!newValue) {
							return;
						}
						props.onChange({
							target: {
								name: formField.name,
								value: newValue
							}
						}, formField.name);
					}}
					maxDate={formField.maxTime ? new Date(formField.maxTime) : undefined}
					minDate={formField.minTime ? new Date(formField.minTime) : undefined}
				></CustomTimePicker></Grid>;
			case FormFieldType.CUSTOM_TIMERANGEPICKER:
				return <Grid item key={index} xs={props.commonXs}><CustomTimeRangePicker
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					name={formField.name}
					format={formField.format || "HH:mm A"}
					value={formField.value}
					sx={formField.sx || props.commonSx}
					disabled={formField.disabled}
					onChange={(newValue: Date[] | null) => {
						if (!newValue) {
							return;
						}
						props.onChange({
							target: {
								name: formField.name,
								value: newValue
							}
						}, formField.name);
					}}
					maxDate={formField.maxTime ? new Date(formField.maxTime) : undefined}
					minDate={formField.minTime ? new Date(formField.minTime) : undefined}
				></CustomTimeRangePicker></Grid>;
			case FormFieldType.CUSTOM_DATEPICKER:
				return <Grid item key={index} xs={props.commonXs}><CustomDatePicker
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					name={formField.name}
					disabled={formField.disabled}
					value={formField.value}
					onChange={(newValue: any) => {
						if (!newValue) {
							return;
						}
						props.onChange({
							target: {
								name: formField.name,
								value: newValue
							}
						}, formField.name);
					}}
					multiple={false}
					minDate={formField.minDate ? new Date(formField.minDate) : undefined}
					maxDate={formField.maxDate ? new Date(formField.maxDate) : undefined}
					format={formField.format || "DD-MM-YYYY"}
					sx={formField.sx || props.commonSx}
				></CustomDatePicker></Grid>;
			case FormFieldType.CUSTOM_MULTI_DATEPICKER:
				return <Grid item key={index} xs={props.commonXs}><CustomDatePicker
					value={formField.value}
					fieldName={formField.fieldName}
					required={formField.required}
					onChange={(newVals: Date[] | Date) => {
						if (Array.isArray(newVals)) {
							props.onChange({
								target: {
									name: formField.name,
									value: newVals
								}
							}, formField.name)
						}
					}}
					sx={formField.sx || props.commonSx}
					multiple={true}
					minDate={formField.minDate ? new Date(formField.minDate) : undefined}
					maxDate={formField.maxDate ? new Date(formField.maxDate) : undefined}
					format={formField.format || "DD-MM-YYYY"}
					name={formField.name}
				></CustomDatePicker></Grid>;
			case FormFieldType.CUSTOM_SELECT:
				return <Grid item key={index} xs={props.commonXs}><CustomSelect
					id={formField.name}
					name={formField.name}
					sx={formField.sx || props.commonSx}
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					multiple={formField.muiltiple}
					value={formField.value}
					disabled={formField.disabled}
					renderValue={(selected) => Array.isArray(selected) ? selected.join(",") : selected}
					onChange={(e: any) => {
						const values = e.target.value;
						if (formField.muiltiple && values[values.length - 1] === "Select All") {
							props.onChange({
								target: {
									name: formField.name,
									value: formField.value.length === formField.options?.length ? [] : formField.options || []
								}
							}, formField.name);
							return;
						}
						props.onChange(e, formField.name);
					}}
					required={formField.required}
					sideLabel={formField.sideLabel}
				>
					{formField.muiltiple && <MenuItem value={"Select All"} key="selectAll">
						<Checkbox
							checked={
								(formField.options && formField.options.length > 0)
								&& formField.value?.length === formField.options?.length
							}
							style={{ color: "var(--sidebar-bgc)" }} />
						<ListItemText primary={"Select All"} />
					</MenuItem>}
					{formField.options?.map((option, index) => {
						let isChecked = false;
						if (Array.isArray(formField.value)) {
							isChecked = formField.value.includes(option);
						} else {
							isChecked = formField.value === option;
						}
						return <MenuItem value={option} key={index}>
							<Checkbox checked={isChecked} style={{ color: "var(--sidebar-bgc)" }} />
							<ListItemText primary={option} />
						</MenuItem>;
					})}
				</CustomSelect></Grid>;
			case FormFieldType.CUSTOM_CHECKBOX:
				return <Grid item key={index} xs={props.commonXs}>
					<CustomCheckbox
						name={formField.name}
						value={formField.value}
						fieldName={formField.fieldName || defaultLabelNames[formField.name]}
						onChange={(e: any) => props.onChange(e, formField.name)}
						sx={formField.sx || props.commonSx}
					></CustomCheckbox>
				</Grid>;
			case FormFieldType.COSTANTS_AUTOCOMPLETE:
				return <Grid item key={index} xs={props.commonXs}><ConstantsAutoComplete
					name={formField.name}
					value={formField.value}
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					handleChange={(e: any) => props.onChange(e, formField.name)}
					sx={formField.sx || props.commonSx}
					options={formField.options || []}
					multiple={formField.muiltiple}
				></ConstantsAutoComplete>
				</Grid>;
			case FormFieldType.CUSTOM_SEARCH_SELECT:
				return <Grid item key={index} xs={props.commonXs}>
					<CustomSearchSelect
						name={formField.name}
						sx={formField.sx || props.commonSx}
						fieldName={formField.fieldName || defaultLabelNames[formField.name]}
						multiple={formField.muiltiple}
						value={formField.value}
						searchText={formField.searchText}
						onSearchTextChange={formField.onSearchTextChange}
						onChange={(e: any) => {
							props.onChange(e, formField.name);
						}}
						options={formField.options || []}
						sideLabel={formField.sideLabel}
					></CustomSearchSelect>
				</Grid>;
			case FormFieldType.CUSTOM_AUTOCOMPLETE:
				return <Grid item key={index} xs={props.commonXs}><CustomAutoComplete
					name={formField.name}
					value={formField.value}
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					handleChange={(e: any) => props.onChange(e, formField.name)}
					sx={formField.sx || props.commonSx}
					options={formField.options || []}
					searchText={formField.searchText}
					onSearchTextChange={(e: any) => {
						if (formField.onSearchTextChange) {
							formField.onSearchTextChange(e);
						}
					}}
					multiple={formField.muiltiple}
					disabled={formField.disabled}
				></CustomAutoComplete>
				</Grid>;
			case FormFieldType.CUSTOM_FILEINPUT:
				return <Grid item key={index} xs={props.commonXs}><CustomTextField
					id={formField.name}
					name={formField.name}
					type={"file"}
					sx={formField.sx || props.commonSx}
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					// value={formField.value}
					onChange={(e: any) => props.onChange(e, formField.name)}
					required={formField.required}
					helperText={formField.helperText}
					disabled={formField.disabled}
					minRows={formField.minRows}
				/>
				</Grid>;
			case FormFieldType.CUSTOM_TEXT_AREA:
				return <Grid item key={index} xs={props.commonXs}><CustomTextArea
					id={formField.name}
					name={formField.name}
					type={formField.textType || "text"}
					sx={formField.sx || props.commonSx}
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					value={formField.value}
					onChange={(e: any) => props.onChange(e, formField.name)}
					required={formField.required}
					helperText={formField.helperText}
					disabled={formField.disabled}
					minRows={formField.minRows}
				/></Grid>;
			default:
				return <Grid item key={index} xs={props.commonXs}><CustomTextField
					id={formField.name}
					name={formField.name}
					type={formField.textType || "text"}
					sx={formField.sx || props.commonSx}
					fieldName={formField.fieldName || defaultLabelNames[formField.name]}
					value={formField.value}
					onChange={(e: any) => props.onChange(e, formField.name)}
					required={formField.required}
					helperText={formField.helperText}
					disabled={formField.disabled}
					minRows={formField.minRows}
				/></Grid>;
		}
	})}</>;
};