import urls from "./urls";
import api from "./axiosInstance";
import { ApiDataRes, ListApiRes } from "../interfaces/Common";
import { CommonListRes } from "../interfaces/Gitlab";
import { ApiError, AxiosErrorHandler } from "../utils/errorHandler";
import { AxiosResponse } from "axios";
import { ComparisonData, ReposRes } from "../interfaces/Comparator";
import { ImageRes, CommonRes } from "../interfaces/Comparator";
import { ApiMsgRes } from "../interfaces/Common";
import { GitDetail ,gitrepo} from "../interfaces/Gitlab";
export default class GitlabAPI{
    public static async getUsers(pagination: {
        limit: number,
        pageNumber: number
    }, searchText: string): Promise<any | ApiError> {
        try {
            const filtersObj: any = {};
            
            const response: AxiosResponse<CommonListRes<string>> = await api.get(`${urls.gitlabapi}/search/users`, {
                params: {
                    ...pagination,
                    searchText
                }
            });
            const fg=response.data;
    
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }
    public static async getGroups(pagination: {
        limit: number,
        pageNumber: number
    }, searchText: string): Promise<any | ApiError> {
        try {
            const filtersObj: any = {};
            
            const response: AxiosResponse<CommonListRes<string>> = await api.get(`${urls.gitlabapi}/search/groups`, {
                params: {
                    ...pagination,
                    searchText
                }
            });
            const fg=response.data;
    
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }

    public static async createRepo(payload: gitrepo): Promise<ApiMsgRes | ApiError> {
        try {
            const response: AxiosResponse<ApiMsgRes> = await api.post(urls.gitlabapi, {
                ...payload
            });
            return response.data || [];
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async getRepoDetail(id: string): Promise<ApiDataRes<GitDetail> | ApiError> {
        try {
            const response: AxiosResponse<ApiDataRes<GitDetail>> = await api.get(`${urls.gitlabapi}/${id}`);
            return response.data;
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }
    public static async getRepos(pagination: {
        limit: number,
        pageNumber: number
    }, filters: any): Promise<ListApiRes<GitDetail> | ApiError> {
        try {
            const filtersObj: any = {};
            for (let key in filters) {
                if (filters[key] !== undefined && filters[key] !== null && filters[key] !== "") {
                    if (Array.isArray(filters[key])) {
                        filtersObj[key] = filters[key].filter((filter: any) => filter !== ""); 
                    } else {
                        filtersObj[key] = filters[key]
                    }
                }
            }
            const response: AxiosResponse<ListApiRes<GitDetail>> = await api.get(urls.gitlabapi, {
                params: {
                    ...pagination,
                    ...filtersObj
                }
            });
            return response.data || [];                
        } catch (error) {
            return AxiosErrorHandler(error);
            
        }
    }
    public static async bulkApprove(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.gitlabapi}/approve/${payload.ids}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async updateRepo(payload: gitrepo): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.gitlabapi}/${payload._id}`, {
                repoName: payload.repoName,
                projectSlug: payload.projectSlug,
                description: payload.description,
                namespace: payload.namespace,
                createGroup: payload.createGroup,
                groupName:payload.groupName,
                groupOwners:payload.groupOwners,
                groupMaintainers:payload.groupMaintainers
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async bulkReview(payload: {ids: string[]}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.gitlabapi}/review/${payload.ids}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);            
        }
    }

    public static async bulkReject(payload: {ids: string[],comment: string}): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.gitlabapi}/reject/${payload.ids}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

    public static async deleteGitrepo(payload: gitrepo): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.delete(`${urls.gitlabapi}/${payload._id}`);
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }
    
    public static async bulkRemove(payload: {ids: string[],comment:string }): Promise<ListApiRes<any> | ApiError> {
        try {
            const response = await api.put(`${urls.gitlabapi}/remove/${payload.ids}`, {
                ...payload
            });
            return response.data || {};                
        } catch (error) {
            return AxiosErrorHandler(error);
        }
    }

}
