import { Environment, SchedulingEnvironment, SortOrder } from "./Common";

export enum Day {
    MON = "Mon",
    TUE = "Tue",
    WED = "Wed",
    THU = "Thu",
    FRI = "Fri",
    SAT = "Sat",
    SUN = "Sun"
}

export enum ScheduleStatus {
    Raised = "Raised",
    Rejected = "Rejected",
    Removed = "Removed",
    Approved = "Approved",
}

export enum RepoStatus {
    Raised = "Raised",
    Rejected = "Rejected",
    Removed = "Removed",
    Approved = "Approved",
}

export interface ScheduleLog {
    status: ScheduleStatus;
    date: string,
    updatedBy: string,
    remarks?: string,
}

export enum ScheduleType {
    THIS_WEEK = "This Week",
    NEXT_WEEK = "Next Week",
}

export enum RunType {
    NON_STOP = "Non Stop",
    // START_STOP="Start Stop",
    PERIODIC = "Periodic"
}

export interface ScheduleRequest {
    _id?: string;
    reason: string;
    env: SchedulingEnvironment;
    status: ScheduleStatus;
    runType: RunType;
    selectedDates: Date[];
    schedules: { startTime: Date, stopTime: Date }[];
}

export interface CreateScheduleReq {
    env: SchedulingEnvironment;
    status: ScheduleStatus;
    runType: RunType;
    selectedDates: Date[];
    startTime: Date;
    stopTime: Date;
    reason: string;
}

export interface ScheduleDetail extends ScheduleRequest {
    _id: string,
    createdAt: string,
    createdBy: string;
    statusLogs: ScheduleLog[];
}

export interface SchedulerFilters {
    env?: SchedulingEnvironment[],
    scheduleType?: ScheduleType[],
    runType?: RunType[],
    status?: ScheduleStatus | ScheduleStatus[],
    sortBy?: string,
    sortOrder?: SortOrder,
    createdBy?: string | string[],
    approvedBy?: string | string[],
    rejectedBy?: string | string[],
    raisedBefore?: string,
    raisedAfter?: string,
}

// export interface ScheduleObj {
//     startTime: string;
//     stopTime: string;
//     date: Date;
// }

export interface DaySchedule {
    schedules: {
        startTime: string;
        stopTime: string;
    }[]
    day: Day
}

export interface ScheduleDate extends ScheduleTime {
    date: string;
}

export interface ScheduleTime {
    startTime: any;
    stopTime: any;
    day: Day;
}

export interface ScheduleAggregation {
    env: SchedulingEnvironment,
    reason: string,
    startTime: Date,
    stopTime: Date,
    day: Day,
    status: string,
    createdAt: Date,
    createdBy: string,
    _id: string,
}

export interface DefaultSchedule {
    env: SchedulingEnvironment;
    schedule: ScheduleTime[];
    isStatic: boolean
}

export enum RunStatus {
    ON = "On",
    OFF = "Off"
}

export enum JobStatus {
    Failed = "Failed",
    Completed = "Completed",
    InProgress = "InProgress"
}

export interface RunLog {
    id?: string;
    env: Environment;
    status: RunStatus;
    jobStatus: JobStatus;
    createdAt: Date;
    updatedAt: Date | null;
    createdBy: string;
    reason: string;
}

export interface ScheduleLambda {
    _id: string,
    id: string,
    lambdaName: string,
    envs: string[],
    triggerType: RunStatus
}

export interface ScheduleLambdaFilters {
    envs?: string[],
    triggerType?: RunStatus[],
    sortBy?: string,
    sortOrder?: SortOrder,
}

export enum HolidayType {
    ONE_DAY = "One Day",
    MULTIPLE_DAYS = "Mulitple Days",
    RANGE = "Range"
}

export interface Holiday {
    _id?: string,
    name: string,
    dates: Date[],
    envs?: Environment[]
}

export interface HolidayFilters {
    name?: string[],
    envs?: SchedulingEnvironment[]
}