import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Checkbox, SxProps, TextField } from "@mui/material";
import "./Inputs.css";
import { useCallback } from "react";

export interface ConstantsAutoCompleteProps {
    handleChange: (e: any) => void,
    name: string,
    value: any,
    sx?: SxProps,
    fieldName?: string,
    options: string[],
	multiple?: boolean
}

export const ConstantsAutoComplete = (props: ConstantsAutoCompleteProps) => {
	const sx: any = props.sx;
	const width = sx ? sx.width : "200px";

	const getStyle = useCallback(() => {
		if (props.multiple) {
			return {
				width: width,
				'& .MuiAutocomplete-inputRoot': {
					height: `${40*((props.value || []).length)+30}px`,
					padding: "0px",
					boxSizing: "border-box"
				},
				'& .MuiAutocomplete-inputRoot .MuiOutlinedInput-input': {
					height: `30px`,
					padding: "5px 14px",
					boxSizing: "border-box"
				},
				'& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment': {
					height: `30px`,
				},
				'& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment button': {
					height: `30px`,
				}
			}
		} else {
			return {
				width: width,
				'& .MuiAutocomplete-inputRoot': {
					height: "30px",
					padding: "0px",
					boxSizing: "border-box"
				},
				'& .MuiAutocomplete-inputRoot .MuiOutlinedInput-input': {
					height: "30px",
					padding: "5px 14px",
					boxSizing: "border-box"
				},
				'& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment': {
					height: "30px",
				},
				'& .MuiAutocomplete-inputRoot .MuiAutocomplete-endAdornment button': {
					height: "30px",
				}
			}
		}
	}, [props.value])

	return <div className="custom-input" style={{width: width}}>
		<div className="field-name"><span>{props.fieldName}</span></div><Autocomplete
			id="combo-box-states"
			onChange={(e: any, newValue: string | null) => {
				props.handleChange({
					target: {
						name: props.name,
						value: newValue
					}
				});
			}}
			multiple={props.multiple}
			renderOption={(props, option, { selected }) => (
				<li {...props}>
					<Checkbox
						icon={<CheckBoxOutlineBlank></CheckBoxOutlineBlank>}
						checkedIcon={<CheckBox></CheckBox>}
						style={{ marginRight: 8, color: "var(--sidebar-bgc)" }}
						checked={selected}
					/>
					{option}
				</li>
			)}
			disableCloseOnSelect={props.multiple}
			options={props.options}
			disableClearable={false}
			value={props.value}
			sx={{...getStyle()}}
			renderInput={(params) => <TextField {...params}
			/>}
		></Autocomplete></div>;
};
