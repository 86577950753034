export enum RowAction {
    DELETE_ROW="Delete",
    EDIT_ROW="Update",
    APPROVE="Approve",
    REJECT="Reject",
    SAVE_ROW="Save",
    CHECKBOX="Checkbox",
    VIEW="View",
    BLOCK="Block",
    VERIFY="Verify",
    REVIEW="Review",
    REBUILD="Rebuild"
}

export enum HeaderAction {
    MULTI_DELETE="DeleteAll",
    SELECT_ALL="SelectAll",
    REVIEW="ReviewAll",
    UNSELECT="UnselectAll",
    APPROVE="ApproveAll",
    REJECT="RejectAll",
    REMOVE="DeleteAll",
    REBUILD="RebuildAll",
    ADD_NEW="Create",
    VERIFY="VerifyAll",
    BLOCK="BlockAll",
    DOWNLOAD_SELECTED="DownloadAll"
}