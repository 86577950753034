import { Paper, Table, TableContainer } from "@mui/material";
import { useState } from "react";
import { Pagination, RowType, SortOrder } from "../../interfaces/Common";
import { HeaderAction, RowAction } from "../../interfaces/Data";
import { TableComponents } from "../../utils/constants";
import "./Data.css";
import { TableBodyWrapper } from "./TableBodyWrapper";
import { HeaderActions, TableHeaderWrapper } from "./TableHeaderWrapper";
import { TablePaginationWrapper } from "./TablePaginationWrapper";

export const TableWrapper = <T,>(props: {
    componentName: TableComponents,
    columns: {field: string, displayName: string, width?: string}[],
    rows: RowType<T>[],
    selections: {[k:string]: boolean},
    headerActions: HeaderAction[],
    handleHeaderAction: (action: HeaderAction) => void,
    handleLimitChange: (limit: number) => void,
    handlePageChange: (pageNumber: number) => void,
    pagination: Pagination,
    rowActions: RowAction[],
    handleRowAction: (action: RowAction, row: RowType<T>) => void,
    handleSort?: (field: string, order: SortOrder) => void,
    sorting?: {field?: string, order?: SortOrder},
    isStatusActions?: boolean,
    rowClickFn?: (row: RowType<T>) => void,
    rowDbClickFn?: (row: RowType<T>) => void,
    idClickFn?: (row: RowType<T>) => void,
    inlineEdit?: boolean,
    inlineAdd?: boolean,
}) => {

    const [shouldRenderNewRow, setShouldRenderNewRow] = useState<boolean>(false);

    const handleHeaderAction = (action: HeaderAction) => {
        if (action === HeaderAction.ADD_NEW) {
            setShouldRenderNewRow(true);
        }
        props.handleHeaderAction(action);
    }

    const renderNewRow = () => {
        switch(props.componentName) {
            default:
                return <></>
        }
    }

    return <Paper className='table-container'>
        <HeaderActions
            actions={(props.headerActions || []).map(headerAction => {
                if (headerAction === HeaderAction.SELECT_ALL) {
                    return Object.keys(props.selections).length === props.rows.length ? HeaderAction.UNSELECT : HeaderAction.SELECT_ALL
                }
                return headerAction;
            })}
            handleAction={handleHeaderAction}
        ></HeaderActions>
        <TableContainer className='sub-table'>
            <Table stickyHeader aria-label="simple table" >
                <TableHeaderWrapper
                    componentName={props.componentName}
                    columns={props.columns}
                    showActions={(props.headerActions || []).length>0}
                    handleSort={props.handleSort}
                    sorting={props.sorting}
                ></TableHeaderWrapper>
                {props.inlineAdd && shouldRenderNewRow && renderNewRow()}
                <TableBodyWrapper
                    rows={props.rows}
                    columns={props.columns}
                    componentName={props.componentName}
                    actions={props.rowActions || []}
                    selections={props.selections}
                    handleAction={props.handleRowAction}
                    isStatusActions={props.isStatusActions}
                    rowClickFn={props.rowClickFn}
                    rowDbClick={props.rowDbClickFn}
                    inlineEdit={props.inlineEdit}
                    idClickFn={props.idClickFn}
                ></TableBodyWrapper>
            </Table>
        </TableContainer>
        <TablePaginationWrapper
            pageNumber={props.pagination.pageNumber}
            limit={props.pagination.limit}
            onLimitChange={props.handleLimitChange}
            onPageChange={props.handlePageChange}
            totalResults={props.pagination.totalResults}
            currentResults={props.pagination.currentResults}
            totalPages={props.pagination.totalPages}
            showText={true}
            showLimit={true}
        ></TablePaginationWrapper>
    </Paper>
}