import { StandardTextFieldProps, TextField, TextFieldProps } from "@mui/material";
import "./Inputs.css";

export interface CustomTextFieldProps<T> extends StandardTextFieldProps {
    fieldName: string,
	noField?: boolean
}

export const CustomTextField = <T,>(props: CustomTextFieldProps<T>) => {
	const sx: any = props.sx;
	const width = sx ? sx.width : "200px";
	return <div className="custom-input" style={{width: width}}>
		{!props.noField && <div className="field-name"><span>{props.fieldName}{props.required ? "*": ""}</span></div>}
		<TextField
			size={props.size || "small"}
			{...props}
			inputProps={{style: {height: "30px", padding: "5px 14px", boxSizing: "border-box"}}}
			sx={{...props.sx, width: width }}></TextField>
	</div>;
};

export const CustomTextArea = <T,>(props: CustomTextFieldProps<T>) => {
	const sx: any = props.sx;
	const width = sx ? sx.width : "400px";
	return <div className="custom-input" style={{width: width}}>
		{!props.noField && <div className="field-name"><span>{props.fieldName}{props.required ? "*": ""}</span></div>}
		<textarea
			style = {{
				height: "60px", width: width, fontSize: "14px", padding: "5px 14px", boxSizing: "border-box",
				borderColor: "var(--sidebar-bgc) !important",
				fontFamily: "Poppins"
			}}
			name={props.name}
			value={`${props.value}` || ""}
			onChange={(e: any) => {
				if (props.onChange) {
					props.onChange(e);
				}
			}}
		></textarea>
	</div>;
};