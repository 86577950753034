import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { SchedulerAPI } from "../../../apis";
import { ConfirmDialog } from "../../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { SchedulingEnvironment } from "../../../interfaces/Common";
import { CreateScheduleReq, DefaultSchedule, RunType, ScheduleStatus, ScheduleTime } from "../../../interfaces/Scheduler";
import { actions } from "../../../store";
import { useAppDispatch } from "../../../store/hooks";
import { bumpDate, get24TimeStr, getBetweenDates, getCurrentWeek, getDateStr, getDayName, getNextWeek, getTimeStr, setIntervalTime, setZeroTime } from "../../../utils/dateUtils";
import { ApiError } from "../../../utils/errorHandler";
import "./ScheduleRequests.css";

export const CreateSchedulePage = (props: {
    handleClose: Function,
    showModal: boolean
}) => {
    const date = new Date();
    const currentWeek = getCurrentWeek(date);
    const nextWeek = getNextWeek(date);
    const [scheduleObj, setScheduleObj] = useState<CreateScheduleReq>({
        env: SchedulingEnvironment.QA3,
        reason: "",
        runType: RunType.PERIODIC,
        selectedDates: [],
        startTime: setIntervalTime(new Date(), 30),
        stopTime: setIntervalTime(new Date(), 30),
        status: ScheduleStatus.Raised
    });
    const [dateRanges, setDateRanges] = useState<Date[]>([new Date(), new Date()]);

    const [defaultSchedule, setDefaultSchedule] = useState<DefaultSchedule | null>(null);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common, scheduler } = actions;

    const envOptions = [
        SchedulingEnvironment.PREMERGEDEV,
        SchedulingEnvironment.PAAS3,
        SchedulingEnvironment.QA3,
        SchedulingEnvironment.QA3_DEV,
        SchedulingEnvironment.CARNIVALSC,
        SchedulingEnvironment.CARNIVALSC_DEV,
        SchedulingEnvironment.PIONEER,
        SchedulingEnvironment.JENKINS,
        SchedulingEnvironment.QA3_JENKINS,
    ]

    useEffect(() => {
        if (scheduleObj.reason && scheduleObj.selectedDates.length > 0) {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    }, [scheduleObj])

    useEffect(() => {
        (async () => {
            if (!scheduleObj.env) {
                return;
            }
            const res = await SchedulerAPI.getDefaultSchedules({pageNumber: 1, limit: 1}, {env: scheduleObj.env});
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            const scheduleRes = res.data.results[0];
            setDefaultSchedule(scheduleRes);
        })()
    }, [scheduleObj.env])


    const handleCreateConfirm = async () => {
        dispatch(common.setShowLoader(true));
        const res = await SchedulerAPI.createSchedule(scheduleObj);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setShowConfirm(false);
        setScheduleObj({
            env: SchedulingEnvironment.QA3,
            reason: "",
            runType: RunType.NON_STOP,
            selectedDates: [],
            startTime: new Date(),
            stopTime: new Date(),
            status: ScheduleStatus.Raised
        });
        dispatch(common.setSnackbar({ children: "Successfully raised a deployment request.", severity: "success" }));
        props.handleClose();
        dispatch(common.setShowLoader(false));
        dispatch(scheduler.reloadData());
    }


    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setScheduleObj({ ...scheduleObj, [name]: value })
    };

    const renderScheduleTime = (index: number) => {
        if (scheduleObj.runType === RunType.PERIODIC) {
            return <TableCell>{`${getTimeStr(scheduleObj.startTime)}-${getTimeStr(scheduleObj.stopTime)}`}</TableCell>
        } else {
            if (scheduleObj.selectedDates.length === 1) {
                return <TableCell>{`${getTimeStr(scheduleObj.startTime)}-${getTimeStr(scheduleObj.stopTime)}`}</TableCell>
            }
            if (index === 0) {
                return <TableCell>{`${getTimeStr(scheduleObj.startTime)} to End Of Day`}</TableCell>
            }
            if (index === scheduleObj.selectedDates.length-1) {
                return <TableCell>{`Start Of Day to ${getTimeStr(scheduleObj.stopTime)}`}</TableCell>
            }

            return <TableCell>All Day</TableCell>
        }

    }
    const getSchedule = (objs: ScheduleTime[]) => {
        return <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
            {objs.map(obj => {
                let startStr = get24TimeStr(new Date(obj.startTime));
                let stopStr = get24TimeStr(new Date(obj.stopTime));
                if (startStr === "00:00" && stopStr === "00:00") {
                    return <span>All Day</span>
                }
                if (stopStr === "00:00") {
                    stopStr = "24:00"
                }
                return <span>{`${startStr}-${stopStr}`}</span>
            })}            
        </div>
    }

    const renderScheduleTable = () => {
        if (!defaultSchedule) {
            return <></>;
        }

        return <TableContainer className="schedule-table">
            <Table stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Requested Schedule</TableCell>
                        <TableCell>Default Schedule</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {scheduleObj.selectedDates.map((obj, ind) => {
                        let day = getDayName(obj.getDay());
                        let defaultObjs = defaultSchedule.schedule.filter(obj2 => obj2.day === day);
                        return <TableRow>
                            <TableCell>{getDateStr(obj)}</TableCell>
                            {renderScheduleTime(ind)}
                            <TableCell>{defaultObjs.length > 0 ? getSchedule(defaultObjs): ""}</TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    }

    return <ConfirmDialog
        open={props.showModal}
        title="Create Schedule Request"
        yesText="Create"
        noText="Cancel"
        handleNo={() => props.handleClose()}
        handleYes={() => setShowConfirm(true)}
        disableYes={!canSubmit}
        sx={{ height: "60vh" }}
    >
        <Grid container item columnGap={2}>
            <Grid container rowGap={2} xs={5} justifyContent={"center"} sx={{height: "55vh"}}>
                <InputFormGrid
                    formFields={[{
                        fieldName: "SchedulingEnvironment",
                        name: "env",
                        type: FormFieldType.CUSTOM_SELECT,
                        options: envOptions,
                        value: scheduleObj.env
                    }, {
                        fieldName: "Run Type",
                        name: "runType",
                        type: FormFieldType.CUSTOM_SELECT,
                        options: Object.values(RunType),
                        value: scheduleObj.runType
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
                {scheduleObj.runType === RunType.PERIODIC ?
                    <InputFormGrid
                        formFields={[{
                            fieldName: "Select Dates",
                            name: "selectedDates",
                            type: FormFieldType.CUSTOM_MULTI_DATEPICKER,
                            value: scheduleObj.selectedDates,
                            minDate: setZeroTime(new Date()),
                            maxDate: bumpDate(setZeroTime(new Date()), 15)
                        }]}
                        onChange={handleInputChange}
                        commonXs={12}
                        ></InputFormGrid> :
                    <InputFormGrid
                        formFields={[{
                            fieldName: "Select Date Range",
                            name: "selectedDates",
                            type: FormFieldType.CUSTOM_DATERANGE_PICKER,
                            value: dateRanges,
                            minDate: setZeroTime(new Date()),
                            maxDate: bumpDate(setZeroTime(new Date()), 15)
                        }]}
                        onChange={(e: any) => {
                            const { name, value } = e.target;
                            setDateRanges(value);
                            let datesArr = getBetweenDates(value[0], value[1]);
                            setScheduleObj({
                                ...scheduleObj,
                                selectedDates: datesArr
                            })
                        }}
                        commonXs={12}
                ></InputFormGrid>}
                <InputFormGrid
                    formFields={[{
                        fieldName: "Start Time",
                        name: "startTime",
                        type: FormFieldType.CUSTOM_TIMEPICKER,
                        value: scheduleObj.startTime
                    }, {
                        fieldName: "Stop Time",
                        name: "stopTime",
                        type: FormFieldType.CUSTOM_TIMEPICKER,
                        value: scheduleObj.stopTime
                    }, {
                        fieldName: "Reason",
                        name: "reason",
                        value: scheduleObj.reason,
                        type: FormFieldType.CUSTOM_TEXT_AREA
                    }]}
                    onChange={handleInputChange}
                    commonSx={{width: "200px"}}
                    commonXs={12}
                ></InputFormGrid>
            </Grid>
            {scheduleObj.selectedDates.length > 0 && <Grid xs={5}>
                {renderScheduleTable()}
            </Grid>}
        </Grid>
        <ConfirmDialog
            open={showConfirm}
            title="Schedule Request Confirmation"
            yesText="Yes"
            noText="No"
            handleNo={() => setShowConfirm(false)}
            handleYes={() => handleCreateConfirm()}
        >
            <div>
                <span>{`Are you sure, you want to create a schedule request for ${scheduleObj.env}?`}</span>
                <div>
                    <span>Schedule Details</span>
                    {scheduleObj.runType === RunType.NON_STOP ?
                        <div>
                            <div>{`${getTimeStr(scheduleObj.startTime)} ${getDateStr(scheduleObj.selectedDates[0])}`}</div>
                            <div>To</div>
                            <div>{`${getTimeStr(scheduleObj.stopTime)} ${getDateStr(scheduleObj.selectedDates[scheduleObj.selectedDates.length - 1])}`}</div>
                        </div> :
                        <div>
                            <div>{`${getTimeStr(scheduleObj.startTime)} to ${getTimeStr(scheduleObj.stopTime)}`}</div>
                            <div>On</div>
                            <div>{scheduleObj.selectedDates.map(obj => getDateStr(obj)).join(", ")}</div>
                        </div>}
                </div>
            </div>
        </ConfirmDialog>
    </ConfirmDialog>
}