import { Button, FormControl, Container, TextField, TableCell, TableContainer, TableHead, TableRow, TableBody, DialogActions, DialogTitle, Table } from "@mui/material"
import { useEffect, useState } from "react";
import React from 'react';
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../components/Wrapper/Inputs/InputFormGrid";
import { Environment } from "../../interfaces/Common";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";
import { BuildCycleDetail } from "../../interfaces/Buildcycle";
import BuildCycleAPI from "../../apis/buildcycle";
import "./BuildCycle.css";

export const EditBRC = (props: {
    handleClose: Function,
    showModal: boolean,
    deploymentObj: BuildCycleDetail
    // handleReload: Function
}) => {
    const [deploymentObj, setDeploymentObj] = useState<BuildCycleDetail>({
        _id: props.deploymentObj._id,
        statusLog: props.deploymentObj.statusLog,
        cyclename: props.deploymentObj.cyclename,
        metadata: props.deploymentObj.metadata,
        createdAt: props.deploymentObj.createdAt,
        buildcycle: props.deploymentObj.buildcycle,
        createdBy: props.deploymentObj.createdAt
    });

    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common } = actions;

    const handleCreateConfirm = async () => {
        setDeploymentObj({
            ...deploymentObj,

        });

        console.log(deploymentObj);
        dispatch(common.setShowLoader(true));
        const res = await BuildCycleAPI.updateBuildCycle(deploymentObj);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setShowConfirm(false);

        dispatch(common.setSnackbar({ children: "Successfully updated Build Cycle Release", severity: "success" }));
        props.handleClose();
        dispatch(common.setShowLoader(false));
    }

    const handleChange = (e: any, index: number, type: string) => {
        const { value } = e.target;
        setDeploymentObj(prevState => ({
            ...prevState,
            buildcycle: prevState.buildcycle.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        [type]: value
                    };
                }
                return item;
            })
        }));
    };

    const handleColumnChange = (e: any, index: number, key: string, type: string) => {
        const { value } = e.target;
        setDeploymentObj(prevState => ({
            ...prevState,
            buildcycle: prevState.buildcycle.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        [type]: {
                            ...item[type], // Preserve other properties in the nested object
                            [key]: value // Update the specific key within the nested object
                        }
                    };
                }
                return item;
            })
        }));
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (value === "") {
            // If the user selects an empty value, clear the source environment field
            setDeploymentObj({
                ...deploymentObj,
                [name]: name === "tickets" ? [] : "",
            });
        } else {

            setDeploymentObj({
                ...deploymentObj,
                [name]: name === "env" ? value : value,

            });
        }
    };
    const handleClose = () => {
        props.handleClose()
    }

    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }

    return <ConfirmDialog
        yesText="Save"
        noText="Cancel"
        title="Update Build Cycle Release"
        open={props.showModal}
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        disableYes={false}
    >
        <Container className="deployment-details-content" maxWidth={false} >

            <Container maxWidth={false} className="schedule-container">
                <TableContainer sx={{ width: "90vw", height: "80vh", overflow: "auto" }} >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <th>Build Cycle</th>
                                <th>Environment</th>
                                <th>In-time</th>
                                <th>Out-time</th>
                                <th>Restricted Check-in Time</th>
                                <th>SAAS Migration Start Time</th>
                                {deploymentObj.buildcycle.length > 0 &&
                                    Object.keys(deploymentObj.buildcycle[0].promotions).map((key) => (
                                        <th key={key}>{key}</th>
                                    ))}
                                {deploymentObj.buildcycle.length > 0 &&
                                    Object.keys(deploymentObj.buildcycle[0].Qualitycheckpoints).map((key) => (
                                        <th key={key}>{key}</th>
                                    ))}
                                {/* <th>Actions</th> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {deploymentObj.buildcycle.map((envCycle, index) => (
                                <TableRow key={index} >
                                    
                                    <TableCell>
                                        <InputFormGrid
                                            formFields={[{
                                                // fieldName: "Select Dates",
                                                name: "cyclename",
                                                type: FormFieldType.CUSTOM_TEXTFIELD,
                                                value: deploymentObj.cyclename,
                                                disabled: true,
                                            }]}
                                            onChange={(e) => handleChange(e, index, "cyclename")}
                                            commonXs={12}
                                        ></InputFormGrid>
                                    </TableCell>

                                    <TableCell>
                                        <InputFormGrid
                                            formFields={[{
                                                // fieldName: "Select Dates",
                                                name: "intime",
                                                type: FormFieldType.CUSTOM_TEXTFIELD,
                                                value: envCycle.env,
                                                disabled: true,
                                            }]}
                                            onChange={(e) => handleChange(e, index, "env")}
                                            commonXs={12}
                                        ></InputFormGrid>
                                    </TableCell>

                                    <TableCell>
                                        <InputFormGrid
                                            formFields={[{
                                                // fieldName: "Select Dates",
                                                name: "intime",
                                                type: FormFieldType.CUSTOM_DATEPICKER,
                                                value: envCycle.intime,
                                            }]}
                                            onChange={(e) => handleChange(e, index, "intime")}
                                            commonXs={12}
                                        ></InputFormGrid>
                                    </TableCell>

                                    <TableCell>
                                        <InputFormGrid
                                            formFields={[{
                                                // fieldName: "Select Dates",
                                                name: "outtime",
                                                type: FormFieldType.CUSTOM_DATEPICKER,
                                                value: envCycle.outtime,
                                            }]}
                                            onChange={(e) => handleChange(e, index, "outtime")}
                                            commonXs={12}
                                        ></InputFormGrid>
                                    </TableCell>

                                    <TableCell>
                                        <InputFormGrid
                                            formFields={[{
                                                // fieldName: "Select Dates",
                                                name: "restrictedCheckintime",
                                                type: FormFieldType.CUSTOM_DATEPICKER,
                                                value: envCycle.restrictedCheckintime,
                                            }]}
                                            onChange={(e) => handleChange(e, index, "restrictedCheckintime")}
                                            commonXs={12}
                                        ></InputFormGrid>
                                    </TableCell>

                                    <TableCell>
                                        <InputFormGrid
                                            formFields={[{
                                                // fieldName: "Select Dates",
                                                name: "saasMigrationstartTime",
                                                type: FormFieldType.CUSTOM_DATEPICKER,
                                                value: envCycle.saasMigrationstartTime,
                                            }]}
                                            onChange={(e) => handleChange(e, index, "saasMigrationstartTime")}
                                            commonXs={12}
                                        ></InputFormGrid>
                                    </TableCell>

                                    {Object.keys(envCycle.promotions).map((key) => (
                                        <React.Fragment key={key}>
                                            <TableCell>
                                                <InputFormGrid
                                                    formFields={[{
                                                        name: key,
                                                        type: FormFieldType.CUSTOM_DATEPICKER,
                                                        value: envCycle.promotions[key],
                                                    }]}
                                                    onChange={(e) => handleColumnChange(e, index, key, "promotions")}
                                                    commonXs={12}
                                                />
                                            </TableCell>
                                        </React.Fragment>
                                    ))}

                                    {Object.keys(envCycle.Qualitycheckpoints).map((key) => (
                                        <React.Fragment key={key}>
                                            <TableCell>
                                                <InputFormGrid
                                                    formFields={[{
                                                        name: key,
                                                        type: FormFieldType.CUSTOM_DATEPICKER,
                                                        value: envCycle.Qualitycheckpoints[key],
                                                    }]}
                                                    onChange={(e) => handleColumnChange(e, index, key, "Qualitycheckpoints")}
                                                    commonXs={12}
                                                />
                                            </TableCell>
                                        </React.Fragment>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </Container>


            {<ConfirmDialog
                open={showConfirm}
                yesText="Yes"
                noText="No"
                title="Confirmation"
                handleNo={() => setShowConfirm(false)}
                handleYes={() => handleCreateConfirm()}
            >
                {`Are you sure, you want to save the changes to the ${deploymentObj.cyclename}?`}
            </ConfirmDialog>}
        </Container>
    </ConfirmDialog>
}