import { Menu } from "@mui/icons-material";
import { Container, FormControl, IconButton, Popover, Stack, useMediaQuery } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useState } from "react";
import { UserFilter } from "../../components/Filters/DataFilters";
import { Dropdown } from "../../components/Filters/Dropdown";
import { TextField } from "../../components/Wrapper";
import { ROLE } from "../../interfaces/Common";
import { GitFilters, GitRequestStatus, NamespaceOption } from "../../interfaces/Gitlab";
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { AuthState } from "../../store/reducers/authReducer";
import "./Gitlab.css";


export const GitFilter = () => {
    // const filters: DeploymentFilters = useAppSelector((state: RootState) => state.deployments.filters);
    const filters: GitFilters = useAppSelector((state: RootState) => state.gitlab.filters);
    
    const authState: AuthState = useAppSelector((state: RootState) => state.auth);
    const roles = {
        approvedBy: [ROLE.APPROVER],
        reviewedBy: [ROLE.REVIEWER, ROLE.APPROVER],
        raisedBy: [ROLE.DEVELOPER],
        rejectedBy: [ROLE.REVIEWER, ROLE.APPROVER]
    }
    
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const open = Boolean(anchorEl);
    const menuId = open ? 'simple-popover' : undefined;
    const isLargeScreen = useMediaQuery('(min-width:1200px)');

    const dispatch = useAppDispatch();
    // const { deployments } = actions;
    const { gitlab } = actions;
  
    

    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (value === "") {
            dispatch(gitlab.setFilters({
                ...filters,
                [name]: ""
            }))
        } else {
            dispatch(gitlab.setFilters({
                ...filters,
                [name]: value
            }))
        }
    };

    const handleDateTimeChange = (newVal: Dayjs | null, name: string) => {
        if (!newVal) {
            dispatch(gitlab.setFilters({
                ...filters,
                [name]: ""
            }))
        } else {
            let date: Date = newVal.toDate();
            dispatch(gitlab.setFilters({
                ...filters,
                [name]: `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()} ${name === "raisedBefore" ? "0:00": "23:59"}:`
            }))
        }
    };

    const datePickersx = {
        '& .MuiInputLabel-root': {
            fontSize: '10px'
        },
        '& .MuiOutlinedInput-root': {
            fontSize: '10px',
            width: '150px'
        }
    }

    const getDateValue = (val: string | undefined) => {
        if (!val) {
            return null;
        }
        let date = dayjs(val);

        return date;
    }

    const renderRaisedByFilter = useCallback(() => {
        if (authState.role.indexOf(ROLE.DEVELOPER) > -1) {
            return <></>
        }

        return <UserFilter
            key={"raisedBy"}
            id="raisedby-select"
            multiple
            value={filters.createdBy}
            label="Raised By"
            name="createdBy"
            onChange={handleInputChange}
            role={roles.raisedBy}
        ></UserFilter>

    }, [filters.createdBy, authState.role]);

    const renderApprovedByFilter = useCallback(() => {
        return <UserFilter
            key={"approvedBy"}
            id="approved-select"
            multiple
            value={filters.approvedBy}
            label="Approved By"
            name="approvedBy"
            onChange={handleInputChange}
            role={roles.approvedBy}
        ></UserFilter>
    }, [filters.approvedBy])

    const renderReviewedByFilter = useCallback(() => {
        return <UserFilter
            key={"reviewedBy"}
            id="reviewed-select"
            multiple
            value={filters.reviewedBy}
            label="Reviewed By"
            name="reviewedBy"
            onChange={handleInputChange}
            role={roles.reviewedBy}
        ></UserFilter>
    }, [filters.reviewedBy])

    const renderRejectedByFilter = useCallback(() => {
        return <UserFilter
            key={"rejectedBy"}
            id="rejected-select"
            multiple
            value={filters.rejectedBy}
            label="Rejected By"
            name="rejectedBy"
            onChange={handleInputChange}
            role={roles.rejectedBy}
        ></UserFilter>
    }, [filters.approvedBy])

    
    return <Container maxWidth={false} className="filters-container">
        <Stack direction="row" spacing={2}>
            
            <Dropdown
                key={"deploymentType"}
                multiple
                id="type-select"
                value={filters.namespace}
                options={Object.values(NamespaceOption)}
                name="namespace"
                label="Namespace"
                onChange={handleInputChange}
            ></Dropdown>
            
            <Dropdown
                id="status-simple-select"
                multiple
                key={"status"}
                value={filters.status}
                label="Status"
                name="status"
                onChange={handleInputChange}
                options={Object.values(GitRequestStatus)}
            ></Dropdown>
            
            <FormControl sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <IconButton onClick={handleMenuClick} sx={{ padding: 0 }}><Menu fontSize="small"></Menu></IconButton>
            </FormControl>
            <Popover
                id={menuId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Container maxWidth={false} className="deployment-filters-container2">
                    <Stack direction="row" spacing={2}>
                        {renderRaisedByFilter()}
                        {renderApprovedByFilter()}
                        {renderReviewedByFilter()}
                        {renderRejectedByFilter()}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                views={["year", "month", "day"]}
                                onChange={(newVal: Dayjs | null) => {
                                    handleDateTimeChange(newVal, "raisedBefore");
                                }}
                                componentsProps={{
                                    actionBar: {
                                        actions: ['clear'],
                                    },
                                }}
                                InputAdornmentProps={{
                                    position: "end",
                                    sx: { fontSize: 10 }
                                }}
                                label="Raised Before"
                                inputFormat="DD-MM-YYYY 00:00"
                                value={getDateValue(filters.raisedBefore)}
                                renderInput={(props) => {
                                    return <TextField
                                        label="Raised Before"
                                        variant="outlined"
                                        size="small"
                                        sx={datePickersx}
                                        {...props}></TextField>
                                }}
                            ></DatePicker>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                views={["year", "month", "day"]}
                                onChange={(newVal: Dayjs | null) => {
                                    handleDateTimeChange(newVal, "raisedAfter");
                                }}
                                componentsProps={{
                                    actionBar: {
                                        actions: ['clear'],
                                    },
                                }}
                                InputAdornmentProps={{
                                    position: "end",
                                    sx: { fontSize: 10 }
                                }}
                                label="Raised After"
                                inputFormat="DD-MM-YYYY 23:59"
                                value={getDateValue(filters.raisedAfter)}
                                renderInput={(props) => {
                                    return <TextField
                                        label="Raised After"
                                        variant="outlined"
                                        size="small"
                                        sx={datePickersx}
                                        {...props}></TextField>
                                }}
                            ></DatePicker>
                        </LocalizationProvider>
                    </Stack>
                </Container>
            </Popover>
        </Stack>
    </Container>
}
