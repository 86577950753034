import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { UserAPI } from "../../apis";
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";

export const ProtectedRoute = (props: any) => {
    const navigate = useNavigate();
    const auth = useAppSelector((state: RootState) => state.common.auth);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const accessToken = localStorage.getItem("accessToken");
            const userId = localStorage.getItem("userId");
            if (!(accessToken && userId)) {
                navigate(`/`);
                return;
            }
    
            if (auth.unauthorizedError) {
                navigate(`/`);
                return;
            }
    
            const res = await UserAPI.getUserPermissions({});
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            const res2 = await UserAPI.getUserProfile();
            if (res2 instanceof ApiError) {
                dispatch(common.processApiError(res2));
                return;
            }

            dispatch(common.setPermissions({permissions: res.data}));
            dispatch(common.setUserData(res2));
            const breadcrumbs = JSON.parse(localStorage.getItem("breadcrumbs") || "[]")
            dispatch(common.setBreadCrumbs(breadcrumbs));
        })()
    }, [auth])

    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    );
}