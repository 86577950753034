import { Container, TextField, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { TableWrapper } from '../../components/DataTables/TableWrapper';
import { Pagination, SortOrder } from '../../interfaces/Common';
import { HeaderAction, RowAction } from '../../interfaces/Data';
import { DeploymentFilters } from '../../interfaces/Deployments';
import { RootState, actions } from '../../store';
import { useAppDispatch, useAppSelector, useIsTabActive } from '../../store/hooks';
import { TableComponents } from '../../utils/constants';
import { getNewHeaderActions } from '../../utils/deploymentUtils';
import { ApiError } from '../../utils/errorHandler';
// import { ConfirmDialog } from './ConfirmDialog';
import { CreateBuildCyclePage } from './CreateBuildCyclePage';
import "./BuildCycle.css";
import { BuildCycleDetail } from '../../interfaces/Buildcycle';
import BuildCycleAPI from '../../apis/buildcycle';
import { newCycle } from '../../interfaces/Buildcycle';

export const BuildCycle = () => {
  const columns = [
    { field: '_id', displayName: 'ID', width: "50px", isSortField: true },
    { field: 'cyclename', displayName: 'BRC', width: "150px", isSortField: true },
    { field: 'createdBy', displayName: 'Created By', width: "100px", isSortField: true },
    { field: 'createdAt', displayName: 'Created On', width: "100px", isSortField: true }
  ];

  const rowActions: RowAction[] = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.BuildCycle]);
  const rows: BuildCycleDetail[] = useAppSelector((state: RootState) => state.buildcycle.buildcycle);
  const selections: { [k: string]: boolean } = useAppSelector((state: RootState) => state.buildcycle.selections);
  const filters: DeploymentFilters = useAppSelector((state: RootState) => state.buildcycle.filters);
  const pagination: Pagination = useAppSelector((state: RootState) => state.buildcycle.pagination);

  const [reload, setreload] = useState<{ randomNumber: number }>({ randomNumber: 0 });
  const isTabVisible = useIsTabActive();
  const dispatch = useAppDispatch();
  const { buildcycle, common } = actions;
  const [showCreate, setShowCreate] = useState<boolean>(false);
  const [canCreate, setCanCreate] = useState<boolean>(false);

  useEffect(() => {
    if (rowActions.length === 0) {
      return;
    }
    setCanCreate(rowActions.indexOf(RowAction.EDIT_ROW) > -1);
  }, [rowActions])

  useEffect(() => {
    (async () => {
      if (!isTabVisible) {
        return;
      }

      const res = await BuildCycleAPI.getBuildCycleList({
        limit: pagination.limit,
        pageNumber: pagination.pageNumber
      }, { ...filters });

      console.log(res);
      if (res instanceof ApiError) {
        return;
      }

      if (res && res.data) {
        dispatch(buildcycle.setBuildcycle(res.data.results));
        dispatch(buildcycle.setResultCounts({
          totalPages: res.data.totalPages,
          totalResults: res.data.totalResults,
          currentResults: res.data.currentResults
        }))
      }
    })()
  }, [pagination.limit, pagination.pageNumber, filters, reload, isTabVisible]);

  const handleSort = (field: string, order: SortOrder) => {
    dispatch(buildcycle.setFilters({ ...filters, sortBy: field, sortOrder: order }));
  }

  const handleRowDoubleClick = (row: newCycle) => {
    const path = `${window.location}/${row._id}`;
    const a = document.createElement("a");
    a.href = path;
    a.target = "_blank";
    a.click();
    a.remove();
  }

  return (
    <Container maxWidth={false} className='table-view'>
      {(canCreate) && <Container maxWidth={false} className="button-container">
        {canCreate && <Button variant="contained" onClick={() => setShowCreate(true)}>Create BRC</Button>}
        {showCreate && <CreateBuildCyclePage showModal={showCreate} handleClose={() => { setShowCreate(false); setreload((prevState) => ({ randomNumber: prevState.randomNumber + 1 })); }}></CreateBuildCyclePage>}
      </Container>}

      <TableWrapper<BuildCycleDetail>
        componentName={TableComponents.BuildCycle}
        columns={columns}
        rows={rows}
        selections={selections}
        // headerActions={headerActions}
        // handleHeaderAction={handleHeaderAction}
        handleLimitChange={(limit: number) => dispatch(buildcycle.setPagination({ pageNumber: pagination.pageNumber, limit: limit }))}
        handlePageChange={(pageNumber: number) => dispatch(buildcycle.setPagination({ limit: pagination.limit, pageNumber: pageNumber }))}
        pagination={pagination}
        // rowActions={rowActions}
        // handleRowAction={handleRowAction}
        // rowClickFn={handleRowClick}
        rowDbClickFn={handleRowDoubleClick}
        // idClickFn={handleIdClick}
        isStatusActions={true}
        handleSort={handleSort}
        sorting={{ field: filters.sortBy, order: filters.sortOrder }}
      ></TableWrapper>

    </Container>
  );
}
