import { Alert, Backdrop, Chip, CircularProgress, Container, Grid, List, ListItem, Snackbar, TextField, Typography, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import { createSearchParams, useNavigate } from "react-router-dom"
import { HeaderAction, RowAction } from "../../interfaces/Data"
import { StatusLogType } from "../../interfaces/Deployments"
import { RootState, actions } from "../../store"
import { useAppDispatch, useAppSelector, useIsTabActive } from "../../store/hooks"
import { getActionsFromStatus, getChipColor } from "../../utils/deploymentUtils"
import { ApiError } from "../../utils/errorHandler"
import { EditRepo } from "./EditRepo"
import "./Gitlab.css"

import GitlabAPI from "../../apis/gitlab"
import { RowActions } from "../../components/DataTables/TableBodyWrapper"
import { GitDetail } from "../../interfaces/Gitlab"
import { TableComponents, rowActionsFromStatus } from "../../utils/constants"
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog"

export const GitDetailsPage = () => {
    const defaultRowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.Deployments]);
    const specialPermissions = useAppSelector((state: RootState) => state.common.specialPermissions);
    const userData = useAppSelector((state: RootState) => state.common.userData);
    const reload: { randomNumber: number } = useAppSelector((state: RootState) => state.deployments.reload);
    const [gitlabDetails, setGitlabDetails] = useState<GitDetail | null>(null);
    const [actionClicked, setActionClicked] = useState<HeaderAction | RowAction | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [rowActions, setRowActions] = useState<RowAction[]>([]);
    const [rejectionComment, setRejectionComment] = useState<string>("");
    const [editMode, setEditMode] = useState<boolean>(false);
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const isTabVisible = useIsTabActive();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { gitlab, common } = actions;

    useEffect(() => {
        if (!(gitlabDetails && defaultRowActions.length > 0 && userData.name && specialPermissions.length > 0)) {
            return;
        }

        const rowActionsFiltered = getActionsFromStatus({
            rowActions: defaultRowActions,
            row: gitlabDetails,
            specialPermissions: specialPermissions,
            componentName: TableComponents.Deployments,
            personalData: userData
        })
        setRowActions(rowActionsFiltered)
    }, [defaultRowActions, gitlabDetails, specialPermissions, userData])

    useEffect(() => {
        if (!isTabVisible) {
            return;
        }
        dispatch(common.setShowLoader(true));
        (async () => {
            const splits = window.location.pathname.split("/");
            const id = splits[splits.length - 1];
            const res = await GitlabAPI.getRepoDetail(id);
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            const queryParams = new URLSearchParams(window.location.search);
            const mode = queryParams.get("mode");
            setGitlabDetails(res.data);
        })();
        dispatch(common.setShowLoader(false));
    }, [reload, isTabVisible]);



    const renderLogs = () => {
        return <List>
            <ListItem><div style={{ fontWeight: 600 }}>Logs</div></ListItem>
            {gitlabDetails?.statusLog.map((log: any, index) => {
                if (log.type === StatusLogType.USER_ACTION) {
                    let byString = `by ${log.updatedBy}`;
                    return <ListItem key={index}>
                        <div>
                            <Chip sx={getChipColor(log.status)} label={log.status}></Chip> {byString} At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} {log.remarks ? `, Comment: ${log.remarks}` : ''}
                        </div>
                    </ListItem>
                } else {
                    return <ListItem key={index}>
                        <div>
                            {log.link ? <a href={log.link}><b>{log.remarks}</b></a> : <b>{log.remarks}</b>}  At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} <Chip sx={getChipColor(log.status)} label={log.status}></Chip>
                        </div>
                    </ListItem>
                }

            })}
        </List>
    };

    const handleConfirm = async () => {
        dispatch(common.setShowLoader(true));
        if (!gitlabDetails) {
            return;
        }

        let res;
        switch (actionClicked) {
            case RowAction.APPROVE:
                res = await GitlabAPI.bulkApprove({ ids: [gitlabDetails._id.toString()] });
                break;
            case RowAction.REVIEW:
                res = await GitlabAPI.bulkReview({ ids: [gitlabDetails._id.toString()] });
                break;
            case RowAction.REJECT:
                res = await GitlabAPI.bulkReject({ ids: [gitlabDetails._id.toString()], comment: rejectionComment });
                break;
            case RowAction.DELETE_ROW:
                res = await GitlabAPI.deleteGitrepo(gitlabDetails);
                break;
        }
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
        }

        setActionClicked(null);
        dispatch(common.setShowLoader(false));
        setShowConfirmModal(false);
        dispatch(gitlab.reloadData());
    }

    const handleActionClick = (action: HeaderAction | RowAction) => {
        if (action === RowAction.EDIT_ROW) {
            setEditMode(true);
            return;
        }
        setActionClicked(action);
        setShowConfirmModal(true);
    }

    const renderActions = () => {
        if (!gitlabDetails) {
            return <></>
        }

        const statusActions = rowActionsFromStatus[gitlabDetails.status];
        const filteredActions = statusActions.filter(action => {
            return action !== RowAction.CHECKBOX && rowActions.indexOf(action) > -1
        })

        return <RowActions
            sx={{ backgroundColor: "transparent!important" }}
            actions={filteredActions}
            handleAction={handleActionClick}
        ></RowActions>
    }

    if (!gitlabDetails) {
        return <Container>Resource Not Found</Container>
    }

    if (editMode) {
        return <EditRepo
            row={gitlabDetails}
            handleCancel={() => {
                const path = window.location.pathname;
                navigate({ pathname: path, search: createSearchParams({}).toString() });
                setEditMode(false)
            }}
            handleSave={() => {
                const path = window.location.pathname;
                navigate({ pathname: path, search: createSearchParams({}).toString() });
                window.location.reload();
                setEditMode(false);
            }}
        ></EditRepo>
    }

    return <Container className="deployment-details-content" maxWidth={false} sx={{ overflowY: "scroll" }}>
        <Container maxWidth={false} className="actions-container">
            {renderActions()}
        </Container>
        <Container maxWidth={false} sx={{ padding: 0, margin: 0, paddingRight: '2px' }}>
            <Grid container spacing={0}>
                <Grid item xs={isLargeScreen ? 6 : 12}>
                    <List className="list">
                        <ListItem className="list-item">
                            <div className="field-name">Request ID:</div>
                            <div className="field-value"><Chip label={gitlabDetails._id}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested At:</div>
                            <div className="field-value"><Chip label={`${new Date(gitlabDetails.createdAt).toDateString()} ${new Date(gitlabDetails.createdAt).toLocaleTimeString()}`}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested By:</div>
                            <div className="field-value"><Chip label={gitlabDetails.createdBy}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Status:</div>
                            <div className="field-value"><Chip label={gitlabDetails.status} sx={getChipColor(gitlabDetails.status)}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Project Name:</div>
                            <div className="field-value"><Chip label={gitlabDetails.repoName}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Namespace:</div>
                            <div className="field-value"><Chip label={gitlabDetails.namespace}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Group Name:</div>
                            <div className="field-value"><Chip label={gitlabDetails.groupName}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Owners:</div>
                            <div className="field-value"><Chip label={(gitlabDetails.groupOwners)?.join(', ')}></Chip></div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Maintainers:</div>
                            <div className="field-value"><Chip label={(gitlabDetails.groupMaintainers)?.join(', ')}></Chip></div>
                        </ListItem>

                    </List>
                </Grid>
                <Grid item xs={isLargeScreen ? 6 : 12} sx={{ height: "80vh!important", overflowY: "auto" }}>
                    <Typography>Logs</Typography>
                    {renderLogs()}
                </Grid>
            </Grid>
        </Container>
        <ConfirmDialog
            open={!!showConfirmModal}
            title='Confirm Repository Updates'
            yesText='Confirm'
            noText='Cancel'
            handleNo={() => setShowConfirmModal(false)}
            handleYes={handleConfirm}
        >
            <span>{`Are you sure, you want to ${actionClicked}?`}</span>
            {actionClicked === 'Delete' ? <div style={{ padding: "0 20px" }}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e: any) => setRejectionComment(e.target.value)}
                />
            </div> : <></>}
        </ConfirmDialog>
    </Container>
}