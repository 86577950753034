import { SxProps } from "@mui/material";
import { useState } from "react"
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import "./Inputs.css";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

export const CustomDatePicker = (props: {
    value: Date | Date[],
    onChange: (dates: Date[] | Date) => void,
    fieldName?: string,
    sx?: SxProps,
    required?: boolean,
    multiple?: boolean,
    name?: string,
    disabled?: boolean,
    format?: string,
    minDate?: Date,
    maxDate?: Date
}) => {
    const sx: any = props.sx;
    const width = sx ? sx.width : "200px";
    return <div className="custom-input" style={{ width: width }}>
        {props.fieldName && <div className="field-name"><span>{props.fieldName}{props.required ? "*" : ""}</span></div>}
        <DatePicker
            style={{width: width}}
            multiple={props.multiple}
            disabled={props.disabled}
            value={props.value}
            format={props.format || "DD-MM-YYYY"}
            shadow
            plugins={[
                <DatePanel sort="date" />
            ]}
            onChange={(newVals: DateObject[] | DateObject | null) => {
                console.log(newVals,'----newVals');
                        

                if (Array.isArray(newVals)) {
                    props.onChange(newVals.map(obj => obj.toDate()))
                } else if (newVals === null && props.multiple) {
                    props.onChange([])
                } else if (newVals instanceof DateObject) {
                    props.onChange(newVals.toDate())
                }
            }}
            minDate={props.minDate}
            maxDate={props.maxDate}
            calendarPosition="top-left"
            // inputClass="multi-datepicker-input"
        />
    </div>;
}

export const CustomTimePicker = (props: {
    value: Date,
    onChange: (dates: Date) => void,
    fieldName?: string,
    sx?: SxProps,
    required?: boolean,
    disabled?: boolean,
    name: string,
    format?: string,
    minDate?: Date,
    maxDate?: Date
}) => {
	const sx: any = props.sx;
	const width = sx ? sx.width : "200px";
    
	return <div className="custom-input" style={{width: width}}>
		<div className="field-name"><span>{props.fieldName}</span></div>
        <DatePicker
            calendarPosition="top-left"
            style={{width: width}}
            disableDayPicker
            format="HH:mm"
            minDate={props.minDate}
            maxDate={props.maxDate}
            plugins={[
                <TimePicker
                    hideSeconds
                    mStep={30}
                />
            ]}
            disabled={props.disabled}
            value={props.value}
            onChange={(newVals: DateObject | null) => {
                if (newVals instanceof DateObject) {
                    const date = new Date();
                    date.setHours(newVals.hour);
                    date.setMinutes(newVals.minute);
                    date.setSeconds(0);
                    date.setMilliseconds(0);
                    props.onChange(date)
                }
            }}
        />
	</div>;
};

export const CustomTimeRangePicker = (props: {
    value: Date[],
    onChange: (dates: Date[]) => void,
    fieldName?: string,
    sx?: SxProps,
    required?: boolean,
    disabled?: boolean,
    name: string,
    format?: string,
    minDate?: Date,
    maxDate?: Date
}) => {
	const sx: any = props.sx;
	const width = sx ? sx.width : "200px";
    
	return <div className="custom-input" style={{width: width}}>
		<div className="field-name"><span>{props.fieldName}</span></div>
        <div style={{display:"flex", justifyContent: "center", alignItems: "center"}}>
            <DatePicker
                style={{width: "100px"}}
                disableDayPicker
                format="HH:mm"
                minDate={props.minDate}
                maxDate={props.maxDate}
                plugins={[
                    <TimePicker
                        hideSeconds
                        mStep={30}
                    />,
                ]}
                disabled={props.disabled}
                value={props.value[0]}
                onChange={(newVals: DateObject | null) => {
                    if (newVals) {
                        props.onChange([newVals.toDate(), props.value[1]]);
                    }
                }}
            />
            <DatePicker
                style={{width: "100px"}}
                disableDayPicker
                format="HH:mm"
                minDate={props.minDate}
                maxDate={props.maxDate}
                plugins={[
                    <TimePicker
                        hideSeconds
                        mStep={30}
                    />,
                ]}
                disabled={props.disabled}
                value={props.value[1]}
                onChange={(newVals: DateObject | null) => {
                    if (newVals) {
                        props.onChange([props.value[0], newVals.toDate()]);
                    }
                }}
            />
        </div>
	</div>;
};