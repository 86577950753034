import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TextField } from '../Wrapper';
import { type } from 'os';
import { Badge } from '@mui/material';

const icon = <CheckBoxOutlineBlankIcon sx={{fontSize: 12}}/>;
const checkedIcon = <CheckBoxIcon sx={{fontSize: 12}}/>;

export type DropdownProps = {
    name: string;
    label: string;
    value: string | string[] | undefined;
    options: string[];
    placeholder?: string;
    id?: string;
    multiple?: boolean;
    onChange: (e: any) => void;
    onSearchTextChange?: (e: any) => void
}


export const Dropdown = (props: DropdownProps) => {
  const placeholder = React.useMemo(() => {
    if (!props.value) {
      return props.placeholder;
    }
    if (Array.isArray(props.value)) {
      return props.value.join(", ");
    }
    return props.value;
  },[props.value]);

  const sx= {
    '& .MuiAutocomplete-input': {
      minWidth: "100px!important",  
    },
    '& .MuiAutocomplete-input::placeholder': {
      color: 'black!important'
    }
  }
  return (
    <Badge badgeContent={Array.isArray(props.value) ? props.value.length : null} color='primary'>
      <Autocomplete
        openOnFocus
        options={props.options}
        disableCloseOnSelect
        renderTags={() => <></>}
        getOptionLabel={(option: string) => {
          return option;
        }}
        multiple={props.multiple}
        value={props.value}
        renderOption={(props, option, { selected }) => {
          return (
          <li {...props} style={{padding: 0, margin: 0, fontSize: 12}}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ paddingLeft: 4, paddingRight: 4 }}
              checked={selected}
            />
            {option}
          </li>
        )}}
        onChange={(event: React.SyntheticEvent<Element, Event>, newValue: string | string[] | null) => {
          props.onChange({target: {name: props.name, value: newValue}});
        }}
        style={{ width: 150 }}
        renderInput={(params) => {
          if (props.onSearchTextChange) {
            return <TextField {...params}
              variant="outlined"
              size='small'
              name={props.name}
              label={props.label}
              onChange={props.onSearchTextChange}
              placeholder={props.placeholder} />
          }
          return <TextField {...params}
            sx={sx}
            variant="outlined"
            size='small'
            name={props.name}
            label={props.label}
            placeholder={placeholder} />
        }}
      />
    </Badge>
  );
}