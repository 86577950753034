import { Route, BrowserRouter, Routes } from 'react-router-dom';
import './App.css';
import { Login } from './containers/Auth/Login';
import { ProtectedRoute } from './containers/Auth/ProtectedRoute';
import { MainContainer } from './containers/MainContainer/MainContainer';
import { MSOAuth2 } from './containers/Auth/MSOAuth2';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { Alert, AlertProps, Backdrop, CircularProgress, Snackbar } from '@mui/material';
import { useState } from 'react';
import { RootState, actions } from './store';

const App = () => {
  const snackbar = useAppSelector((state: RootState) => state.common.snackbar);
  const showLoader = useAppSelector((state: RootState) => state.common.showModals.loader);
  const dispatch = useAppDispatch();
  const { common } = actions;

  const handleCloseSnackbar = () => {
    dispatch(common.setSnackbar(null));
  };

  return <BrowserRouter>
    <div className="App">
      <Routes>
          <Route  path="/" element={<Login></Login>}/>
          <Route  path="/msal" element={<MSOAuth2></MSOAuth2>}/>
          <Route  path="/dashboard/*" element={<ProtectedRoute><MainContainer></MainContainer></ProtectedRoute>}/>
        </Routes>
    </div>
    {!!snackbar && (
        <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    {showLoader && <Backdrop
			sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 10000 }}
			open={true}
		>
			<CircularProgress color="inherit" />
		</Backdrop>}
  </BrowserRouter>
}
export default App;
