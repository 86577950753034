import { Chip, Container, Grid, List, ListItem, TextField, useMediaQuery } from "@mui/material"
import { useEffect, useState } from "react"
import { SchedulerAPI } from "../../../apis"
import { RowActions } from "../../../components/DataTables/TableBodyWrapper"
import { ConfirmDialog } from "../../../components/Dialogs/ConfirmDialog"
import { HeaderAction, RowAction } from "../../../interfaces/Data"
import { ScheduleDetail } from "../../../interfaces/Scheduler"
import { RootState, actions } from "../../../store"
import { useAppDispatch, useAppSelector, useIsTabActive } from "../../../store/hooks"
import { TableComponents, rowActionsFromStatus } from "../../../utils/constants"
import { getDateStr, getTimeStr } from "../../../utils/dateUtils"
import { getActionsFromStatus, getChipColor } from "../../../utils/deploymentUtils"
import { ApiError } from "../../../utils/errorHandler"
import "./ScheduleRequests.css"

export const ScheduleDetailsPage = () => {
    const reload: {randomNumber: number} = useAppSelector((state: RootState) => state.deployments.reload);
    const defaultRowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.ScheduleRequests]);
    const userData = useAppSelector((state: RootState) => state.common.userData);
    const specialPermissions = useAppSelector((state: RootState) => state.common.specialPermissions);
    const [scheduleDetails, setScheduleDetails] = useState<ScheduleDetail | null>(null);
    const [actionClicked, setActionClicked] = useState<HeaderAction | RowAction | null>(null);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [rowActions, setRowActions] = useState<RowAction[]>([]);
    const [rejectionComment, setRejectionComment] = useState<string>("");
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const isTabVisible = useIsTabActive();
    const dispatch = useAppDispatch();
    const { scheduler, common } = actions;

    useEffect(() => {
        if (!(scheduleDetails && defaultRowActions.length > 0 && userData.name && specialPermissions.length > 0)) {
            return;
        }

        const rowActionsFiltered = getActionsFromStatus({
            rowActions: defaultRowActions,
            row: scheduleDetails,
            specialPermissions: specialPermissions,
            componentName: TableComponents.Deployments,
            personalData: userData
        })
        setRowActions(rowActionsFiltered)
    }, [defaultRowActions, scheduleDetails, specialPermissions])


    useEffect(() => {
        if (!isTabVisible) {
            return;
        }
        dispatch(common.setShowLoader(true));
        (async () => {
            const splits = window.location.pathname.split("/");
            const id = splits[splits.length - 1];
            const res = await SchedulerAPI.getScheduleDetail(id);
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setScheduleDetails(res.data);
        })();
        dispatch(common.setShowLoader(false));
    }, [reload, isTabVisible]);

    

    const handleConfirm = async () => {
        if (!scheduleDetails) {
            return;
        }

        dispatch(common.setShowLoader(true));
        let res;
        switch (actionClicked) {
            case RowAction.APPROVE:
                res = await SchedulerAPI.bulkApprove({ ids: [scheduleDetails._id.toString()] });
                break;
            case RowAction.REVIEW:
                res = await SchedulerAPI.bulkReview({ ids: [scheduleDetails._id.toString()] });
                break;
            case RowAction.REJECT:
                res = await SchedulerAPI.bulkReject({ ids: [scheduleDetails._id.toString()], comment: rejectionComment });
                break;
            case RowAction.DELETE_ROW:
                res = await SchedulerAPI.deleteSchedule(scheduleDetails);
                break;
        }
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
        }
        setActionClicked(null);
        dispatch(common.setShowLoader(false));
        setShowConfirmModal(false);
        dispatch(scheduler.reloadData());
    }

    const handleActionClick = (action: HeaderAction | RowAction) => {
        setActionClicked(action);
        setShowConfirmModal(true);
    }

    const renderActions = () => {
        if (!scheduleDetails) {
            return <></>
        }

        const statusActions = rowActionsFromStatus[scheduleDetails.status];
        const filteredActions = statusActions.filter(action => {
            return action !== RowAction.CHECKBOX && rowActions.indexOf(action) > -1
        })
        
        return <RowActions
            sx={{backgroundColor: "transparent!important"}}
            actions={filteredActions}
            handleAction={handleActionClick}
        ></RowActions>
    }

    const getValue = (field: string, value: any) => {
        return <span>{value}</span>
    }

    if (!scheduleDetails) {
        return <Container>Resource Not Found</Container>
    }

    const renderSchedules = () => {
        let schedules: any[] = [<ListItem className="list-item">
                <div className="field-name" style={{justifyContent: "flex-start"}}>Schedules</div>
            </ListItem>];
        let scheduleObjs: {startTime: Date, stopTime: Date}[] = scheduleDetails.schedules || [];
        scheduleObjs.forEach((obj, index) => {
            schedules.push(<ListItem className="list-item" key={index}>
                    <div className="field-name">{`${getDateStr(obj.startTime)}: `}</div>
                    <div className="field-value">{`${getTimeStr(obj.startTime)}-${getTimeStr(obj.stopTime)}`}</div>
            </ListItem>)        
        })
        return <List className="list">
            {schedules}
        </List>
    }

    const renderLogs = () => {
        return <List>
            <ListItem><div style={{fontWeight: 600}}>Logs</div></ListItem>
        {scheduleDetails?.statusLogs.map((log, index) => {
                let byString = `by ${log.updatedBy}`;
                return <ListItem key={index}>
                        <div>
                            <Chip sx={getChipColor(log.status)} label={log.status}></Chip> {byString} At {new Date(log.date).toLocaleTimeString()} on {new Date(log.date).toLocaleDateString()} {log.remarks ? `, Comment: ${log.remarks}` : ''}
                        </div>
                </ListItem>
            })}
        </List>
    };

    return <Container className="details-content" maxWidth={false} sx={{overflowY: "scroll"}}>
        <Container maxWidth={false} className="actions-container">
            {renderActions()}
        </Container>
        <Container maxWidth={false}>
            <Grid container spacing={0}>
                <Grid item xs={isLargeScreen ? 6 : 12}>
                    <List className="list">
                        <ListItem className="list-item">
                            <div className="field-name">Requested At:</div>
                            <div className="field-value">{getValue("date", `${new Date(scheduleDetails.createdAt).toLocaleTimeString()}`)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested On:</div>
                            <div className="field-value">{getValue("date", `${new Date(scheduleDetails.createdAt).toLocaleDateString()}`)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Requested By:</div>
                            <div className="field-value">{getValue("createdBy", scheduleDetails.createdBy)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Environment</div>
                            <div className="field-value">{getValue("env", scheduleDetails.env)}</div>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={isLargeScreen ? 6 : 12}>
                    <List className="list">
                        <ListItem className="list-item">
                            <div className="field-name">Run Type:</div>
                            <div className="field-value">{getValue("runType", scheduleDetails.runType)}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Selected Days:</div>
                            <div className="field-value">{getValue("selectedDates", scheduleDetails.selectedDates.map(obj => getDateStr(obj)).join(", "))}</div>
                        </ListItem>
                        <ListItem className="list-item">
                            <div className="field-name">Reason:</div>
                            <div className="field-value">{scheduleDetails.reason}</div>
                        </ListItem>
                    </List>
                </Grid>
                <Grid item xs={isLargeScreen ? 6 : 12}>
                    {renderSchedules()}
                </Grid>
                <Grid item xs={12}>
                    {renderLogs()}
                </Grid>
            </Grid>
        </Container>
        <ConfirmDialog
            open={showConfirmModal}
            handleNo={() => setShowConfirmModal(false)}
            handleYes={handleConfirm}
            title='Schedule Updated Confirmation'
        >
            {actionClicked === HeaderAction.REJECT ? <div style={{ padding: "0 20px" }}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e) => setRejectionComment(e.target.value)}
                />
            </div> : <>{`Are you sure, you want to ${actionClicked}?`}</>}
        </ConfirmDialog>
    </Container>
}