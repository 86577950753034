import { Container, Grid, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import GitlabAPI from "../../apis/gitlab";
import { ConfirmDialog } from "../../components/Dialogs/ConfirmDialog";
import { FormFieldType, InputFormGrid } from "../../components/Wrapper/Inputs/InputFormGrid";
import { NamespaceOption, gitrepo } from "../../interfaces/Gitlab";
import { ScheduleStatus } from "../../interfaces/Scheduler";
import { actions } from "../../store";
import { useAppDispatch } from '../../store/hooks';
import { ApiError } from "../../utils/errorHandler";
import "./Gitlab.css";
export const CreateRepoPage = (props: {
    handleClose: Function,
    showModal: boolean,
    // handleReload: Function
}) => {
    const [repoObj, setRepoObj] = useState<gitrepo>({
        repoName: "",
        projectSlug: "",
        grouptype: false,
        description: "",
        namespace: "",
        groupName: "",
        createGroup: false,
        groupOwners: [],
        groupMaintainers: [],
        status: ScheduleStatus.Raised
    });
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [groupsname, setGroupsname] = useState<string[]>([]);
    const [maintainersname, setMaintainersname] = useState<string[]>([]);
    const [ownersname, setOwnersname] = useState<string[]>([]);
    const [type, setType] = useState('');
    const [isGroupSelected, setIsGroupSelected] = useState(false);
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const [pageNumber, setPageNumber] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchText, setSearchText] = useState('');
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response
                if (!isGroupSelected) {
                    response = await GitlabAPI.getGroups({
                        limit: 10,
                        pageNumber: 1,
                    }, searchText);
                    setGroupsname(response.data.results);
                }
                else {
                    response = await GitlabAPI.getUsers({
                        limit: 10,
                        pageNumber: 1,
                    }, searchText);
                }

                const data = response.data;
                if (type == "groupOwners" || isGroupSelected) {
                    setOwnersname(data.results);
                }
                if (type == "groupMaintainers" || isGroupSelected) {
                    setMaintainersname(data.results);
                }


                console.log(data.results);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchData();
    }, [pageNumber, limit, searchText, type, isGroupSelected]);

    useEffect(() => {
        if (isGroupSelected) {
            setRepoObj({
                ...repoObj,
                createGroup: true,
            });
        }
        else {
            setRepoObj({
                ...repoObj,
                createGroup: false,
            });
        }
    }, [repoObj])

    useEffect(() => {
        setRepoObj({
            ...repoObj,
            groupName: "",
        });

    }, [isGroupSelected])

    useEffect(() => {
        setRepoObj({
            ...repoObj,
            groupName: "",
            groupMaintainers: [],
            groupOwners: []
        });

    }, [!isGroupSelected])

    useEffect(() => {

        if ((repoObj.repoName && repoObj.description && repoObj.namespace && repoObj.groupName && !repoObj.createGroup) || (repoObj.createGroup && repoObj.groupName)) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }

    }, [repoObj])



    const handleCreateConfirm = async () => {
        dispatch(common.setShowLoader(true));
        const res = await GitlabAPI.createRepo(repoObj);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setShowConfirm(false);

        dispatch(common.setSnackbar({ children: "Successfully raised a new repository request.", severity: "success" }))
        props.handleClose();
        dispatch(common.setShowLoader(false));
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (value === "") {
            // If the user selects an empty value, clear the source environment field
            setRepoObj({
                ...repoObj,
                [name]: "",  // Make sure to update the specific field with an empty value
            });
        }
        else {
            setRepoObj({
                ...repoObj,
                [name]: value,

            });

        }
    };
    const handleSearchTextChange = (identifier: any, event: any, value: any) => {
        setType(identifier);
        setSearchText(value);
        setPageNumber(1);
    };
    const handleClose = () => {
        props.handleClose()
    }


    const handleRadioChange = () => {
        setIsGroupSelected(!isGroupSelected); // Toggle the selected state
        repoObj.grouptype = isGroupSelected
    };
    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }


    return <ConfirmDialog
        yesText="Create"
        noText="Cancel"
        title="Create DB Access Request"
        open={props.showModal}
        handleNo={handleClose}
        handleYes={() => setShowConfirm(true)}
        disableYes={!canSubmit}
    >
        <Container maxWidth={false} className="create-deployment-list">
            <Grid container justifyContent={"center"} rowGap={1} alignItems={"center"}>
                <InputFormGrid
                    formFields={[{
                        fieldName: "Project Name",
                        name: "repoName",
                        value: repoObj.repoName
                    }, {
                        fieldName: "Namespace",
                        name: "namespace",
                        options: Object.values(NamespaceOption),
                        type: FormFieldType.CUSTOM_SELECT,
                        value: repoObj.namespace,
                    }, {
                        fieldName: "Project Slug",
                        name: "projectSlug",
                        value: repoObj.projectSlug
                    }, {
                        fieldName: "Description",
                        name: "description",
                        value: repoObj.description
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>
                <InputFormGrid
                    formFields={[{
                        fieldName: "Create New Group",
                        name: "isGroupSelected",
                        value: isGroupSelected,
                        type: FormFieldType.CUSTOM_CHECKBOX
                    }]}
                    onChange={(e: any) => {
                        handleRadioChange();
                    }}
                    commonXs={12}
                ></InputFormGrid>
                {isGroupSelected ? <InputFormGrid
                    formFields={[{
                        fieldName: "New Group Name",
                        name: "groupName",
                        value: repoObj.groupName
                    }, {
                        fieldName: "Owners",
                        name: "groupOwners",
                        options: ownersname,
                        type: FormFieldType.CUSTOM_SELECT,
                        value: repoObj.groupOwners,
                    }, {
                        fieldName: "Maintainers",
                        name: "groupMaintainers",
                        options: maintainersname,
                        type: FormFieldType.CUSTOM_SELECT,
                        value: repoObj.groupMaintainers,
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>: <InputFormGrid
                    formFields={[{
                        fieldName: "Group Name",
                        name: "groupName",
                        value: repoObj.groupName,
                        options: groupsname,
                        type: FormFieldType.CUSTOM_SELECT
                    }]}
                    onChange={handleInputChange}
                    commonXs={12}
                ></InputFormGrid>}
            </Grid>
            {!showConfirm ? <></> : <ConfirmDialog
                open={showConfirm}
                yesText="Yes"
                noText="No"
                title="Confirmation"
                handleNo={() => setShowConfirm(false)}
                handleYes={() => handleCreateConfirm()}
            >
                {`Are you sure, you want to create a git repo access request for ${repoObj.repoName}?`}
            </ConfirmDialog>}
        </Container>
    </ConfirmDialog>
}