import { HeaderAction, RowAction } from "../interfaces/Data";
import { DeploymentStatus } from "../interfaces/Deployments";
import { ScheduleStatus } from "../interfaces/Scheduler";
import { rowActionsFromStatus, SpecialPermissions } from "./constants";
import { uppercaseStart } from "./objectUtils";

export const getChipColor = (status: DeploymentStatus | ScheduleStatus, colorNames?: boolean) => {
    switch (status) {
        case DeploymentStatus.Completed:
            return { backgroundColor: "#2e7d32", color: "white" }
        case DeploymentStatus.Approved:
            return { backgroundColor: "#0288d1", color: "white" }
        case DeploymentStatus.Reviewed:
            return { backgroundColor: "#9c27b0", color: "white" }
        case DeploymentStatus.Failed:
            return { backgroundColor: "#d32f2f", color: "white" }
        case DeploymentStatus.InProgress:
            return { backgroundColor: "#1976d2", color: "white" }
        case DeploymentStatus.Raised:
            return { backgroundColor: "#00000014", color: "black" }
        case DeploymentStatus.Rejected:
            return { backgroundColor: "#ed6c02", color: "white" }
        case DeploymentStatus.Removed:
            return { backgroundColor: "#d32f2f", color: "white" }
        default:
            return { backgroundColor: "#00000014", color: "black" }
    }
}

export const getNewHeaderActions = (selectedStatuses: any[], defaultHeaderActions: HeaderAction[]) => {
    const newHeaderActions: HeaderAction[] = [];

    for (let i = 0; i < defaultHeaderActions.length; i++) {
        switch (defaultHeaderActions[i]) {
            case HeaderAction.APPROVE:
            case HeaderAction.REJECT:
                let isValid = true;
                for (let j = 0; j < selectedStatuses.length; j++) {
                    if ([DeploymentStatus.Raised, DeploymentStatus.Reviewed,
                        DeploymentStatus.Rebuild, DeploymentStatus.Updated].indexOf(selectedStatuses[j]) === -1) {
                        isValid = false;
                        break;
                    }
                }
                if (isValid) {
                    newHeaderActions.push(defaultHeaderActions[i]);
                }
                break;
            case HeaderAction.MULTI_DELETE:
                let isValid2 = true;
                for (let j = 0; j < selectedStatuses.length; j++) {
                    if ([DeploymentStatus.Raised, DeploymentStatus.Failed].indexOf(selectedStatuses[j]) === -1) {
                        isValid2 = false;
                        break;
                    }
                }
                if (isValid2) {
                    newHeaderActions.push(defaultHeaderActions[i]);
                }
                break;
            case HeaderAction.REVIEW:
                let isValid3 = true;
                for (let j = 0; j < selectedStatuses.length; j++) {
                    if ([DeploymentStatus.Raised, DeploymentStatus.Updated].indexOf(selectedStatuses[j]) === -1) {
                        isValid3 = false;
                        break;
                    }
                }
                if (isValid3) {
                    newHeaderActions.push(defaultHeaderActions[i]);
                }
                break;
            case HeaderAction.REBUILD:
                let isValid4 = true;
                for (let j = 0; j < selectedStatuses.length; j++) {
                    if ([DeploymentStatus.Failed].indexOf(selectedStatuses[j]) === -1) {
                        isValid4 = false;
                        break;
                    }
                }
                if (isValid4) {
                    newHeaderActions.push(defaultHeaderActions[i]);
                }
                break;
            default:
                newHeaderActions.push(defaultHeaderActions[i])
        }
    }
    return newHeaderActions;
}

export const getActionsFromStatus = <T,>({
    rowActions,
    row,
    personalData,
    componentName,
    specialPermissions
}:{
    rowActions: RowAction[],
    row: T,
    personalData: {name: string},
    componentName: string,
    specialPermissions: string[]
}) => {        
    return rowActions.filter(action => {
        const status = row["status" as keyof T];
        const id = row["_id" as keyof T];
        const createdBy = row["createdBy" as keyof T];
        let avialbaleRowActions = rowActionsFromStatus[status as keyof typeof DeploymentStatus] || [];
        if (avialbaleRowActions.length === 0) {
            return false;
        }
        
        avialbaleRowActions = avialbaleRowActions.filter(action => {
            if (action !== RowAction.EDIT_ROW && action !== RowAction.REBUILD && action !== RowAction.DELETE_ROW || createdBy === personalData.name) {                
                return true;
            }

            let permAction = "";
            switch(action) {
                case RowAction.EDIT_ROW:
                    permAction = "UpdateAll";
                    break;
                case RowAction.DELETE_ROW:
                    permAction = "DeleteAll";
                    break;
                case RowAction.REBUILD:
                    permAction = "RebuildAll";
                    break;
            }

            let permission = `${uppercaseStart(componentName)}.${permAction}`;                         
            return specialPermissions.indexOf(permission) > -1;
        })
        return avialbaleRowActions.indexOf(action) > -1;    
    })
}
