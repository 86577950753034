import { Container, Box, Typography, Tabs, Tab, AlertProps, Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import { BranchesMap } from "./EnvBranchMap";
import { ModuleServiceMap } from "./ModuleServicesMap";
import { KafkaEndpointsMap } from "./KafkaEndpointsMap";
import { TaggingPipelineMap } from "./TaggingPipelinesMap";
import { SrcTargetMap } from "./SrcTargetMap";
import { DBAccessMap } from "./DBAccessMap";
import "./Configs.css";
import { RootState } from "../../store";
import { useAppSelector } from "../../store/hooks";
import { ComponentName, SubComponentName } from "../../utils/constants";

export const Configs = () => {
    const subComponents = useAppSelector((state: RootState) => state.common.subComponents[ComponentName.Configs]);
    const [tab, setTab] = useState(0);
    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    function tabProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return <Container className="admin-configs">
        <Container>
            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example">
                        {subComponents.includes(SubComponentName.Branches) && <Tab label="Branches" {...tabProps(0)} />}
                        {subComponents.includes(SubComponentName.Services) && <Tab label="Services"  {...tabProps(1)} />}
                        {subComponents.includes(SubComponentName.KafkaEP) && <Tab label="Kafka Endpoints"  {...tabProps(2)} />}
                        {subComponents.includes(SubComponentName.TaggingPipelines) && <Tab label="Tagging Pipelines"  {...tabProps(3)} />}
                        {subComponents.includes(SubComponentName.EnvSourceTarget) && <Tab label="Env Source Target"  {...tabProps(4)} />}
                        {subComponents.includes(SubComponentName.DatabaseSGs) && <Tab label="DataBase SGs"  {...tabProps(5)} />}
                    </Tabs>
                </Box>
                <CustomTabPanel value={tab} index={0}>
                    <BranchesMap></BranchesMap>
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={1}>
                    <ModuleServiceMap></ModuleServiceMap>
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={2}>
                    <KafkaEndpointsMap></KafkaEndpointsMap>
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={3}>
                    <TaggingPipelineMap></TaggingPipelineMap>
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={4}>
                    <SrcTargetMap></SrcTargetMap>
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={5}>
                    <DBAccessMap></DBAccessMap>
                </CustomTabPanel>
            </Box>
        </Container>
    </Container>
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
