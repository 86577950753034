import { Cancel, Save } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogTitle, IconButton, TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { RunStatus, ScheduleLambda } from "../../../interfaces/Scheduler";
import { AdminAPI, SchedulerAPI } from "../../../apis";
import { Dropdown } from "../../../components/Filters/Dropdown";
import { actions } from "../../../store";
import { useAppDispatch } from "../../../store/hooks";
import { ApiError } from "../../../utils/errorHandler";
import { Environment } from "../../../interfaces/Common";
import { ConfirmDialog } from "../../../components/Dialogs/ConfirmDialog";

export const EditScheduleLambda = (props: {
    handleCancel: () => void,
    handleSave: () => void,
    row: ScheduleLambda
}) => {
    const [lambdaObj, setLambdaObj] = useState<ScheduleLambda>({
        _id: props.row._id,
        id: props.row.id,
        lambdaName: props.row.lambdaName,
        envs: props.row.envs,
        triggerType: props.row.triggerType
    })
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { lambdas, common } = actions;

    const envOptions = [
        Environment.PREMERGEDEV,
        Environment.PAAS3,
        Environment.PREQA,
        Environment.PREQA_DEV,
        Environment.QA3,
        Environment.QA3_DEV,
        Environment.CARNIVALSC,
        Environment.CARNIVALSC_DEV,
        Environment.PIONEER,
        Environment.PIONEER_DEV,
        Environment.MAESTRO,
    ]

    useEffect(() => {
        if (lambdaObj.lambdaName && lambdaObj.envs.length > 0) {
            setCanSubmit(true)
        } else {
            setCanSubmit(false)
        }
    }, [lambdaObj])

    const handleUpdateConfirm = async () => {
        dispatch(common.setShowLoader(true));
        const res = await SchedulerAPI.updateScheduleLambda(lambdaObj);
        if (res instanceof ApiError) {
            dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
            return;
        }
        setShowConfirm(false);
        setLambdaObj({
            _id: "",
            id: "",
            envs: [],
            triggerType: RunStatus.ON,
            lambdaName: "",
        });
        dispatch(common.setSnackbar({ children: res.message, severity: "success" }));
        props.handleCancel();
        dispatch(common.setShowLoader(false));
        dispatch(lambdas.reloadData());
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setLambdaObj({
            ...lambdaObj,
            [name]: value,
        });
    };

    const handleCancelClick = () => {
        props.handleCancel();
    }

    return <><TableRow>
        <TableCell className="action-icons-container">
            <IconButton disabled={!canSubmit} onClick={() => setShowConfirm(true)}>
                <Save sx={{ color: "green", fontSize: 16, opacity: canSubmit ? 1 : 0.5 }}></Save>
            </IconButton>
            <IconButton><Cancel sx={{ color: "red", fontSize: 16 }} onClick={handleCancelClick}></Cancel></IconButton>
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            {lambdaObj.lambdaName}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            {lambdaObj.triggerType}
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <Dropdown
                multiple
                id="envs-select"
                value={lambdaObj.envs}
                name="envs"
                label="Envs"
                onChange={handleInputChange}
                options={envOptions}
            ></Dropdown>
        </TableCell>
    </TableRow>
        {!showConfirm ? <></> : <ConfirmDialog
            open={showConfirm}
            yesText="Yes"
            noText="No"
            title="Confirmation"
            handleNo={() => setShowConfirm(false)}
            handleYes={() => handleUpdateConfirm()}
        >
            {`Are you sure, you want to update ${lambdaObj.lambdaName} for the envs ${lambdaObj.envs.join(", ")}?`}
        </ConfirmDialog>}
    </>
}