import { configureStore } from '@reduxjs/toolkit';
import { authSlice } from './reducers/authReducer';
import { commonSlice } from './reducers/commonReducer';
import { deploymentsSlice } from './reducers/deploymentsReducer';
import { adminSlice } from './reducers/adminReducer';
import { schedulerSlice } from './reducers/schedulerReducer';
import { accountsSlice } from './reducers/accountsReducer';
import { gitSlice } from './reducers/gitReducer';
import { dbSlice } from './reducers/dbReducers';
import { scheduleLambdasSlice } from './reducers/scheduleLambdasReducer';
import { permissionsSlice } from './reducers/permissionsReducer';
import { holidaysSlice } from './reducers/holidaysReducer';
import { testsLogsSlice } from './reducers/testsLogsReducer';
import { buildcycleSlice } from './reducers/buildcycleReducers';
const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        common: commonSlice.reducer,
        deployments: deploymentsSlice.reducer,
        admin: adminSlice.reducer,
        accounts: accountsSlice.reducer,
        scheduler: schedulerSlice.reducer,
        gitlab: gitSlice.reducer,
        dbaccess: dbSlice.reducer,
        lambdas: scheduleLambdasSlice.reducer,
        permissions: permissionsSlice.reducer,
        holidays: holidaysSlice.reducer,
        testsLogs: testsLogsSlice.reducer,
        buildcycle: buildcycleSlice.reducer
    },
})

export const actions = {
    auth: authSlice.actions,
    common: commonSlice.actions,
    deployments: deploymentsSlice.actions,
    admin: adminSlice.actions,
    accounts: accountsSlice.actions,
    scheduler: schedulerSlice.actions,
    gitlab: gitSlice.actions,
    dbaccess: dbSlice.actions,
    lambdas: scheduleLambdasSlice.actions,
    permissions: permissionsSlice.actions,
    holidays: holidaysSlice.actions,
    testsLogs: testsLogsSlice.actions,
    buildcycle: buildcycleSlice.actions
}

export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch