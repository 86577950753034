import { Container, Stack } from "@mui/material";
import { AutomationTestsFilters } from "../../../interfaces/Comparator";
import { RootState, actions } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import "./TestsLogs.css";
import { LogsFilter } from "../../../components/Filters/DataFilters";


export const AutomationTestsLogsFilterGrid = () => {
    const filters: AutomationTestsFilters = useAppSelector((state: RootState) => state.testsLogs.filters);
    const dispatch = useAppDispatch();
    const { testsLogs } = actions;

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        dispatch(testsLogs.setFilters({
            ...filters,
            [name]: value
        }))
    };

    return <Container maxWidth={false} className="filters-container">
        <Stack direction="row" spacing={2}>
            <LogsFilter
                key={"serviceName"}
                multiple
                id="serviceName-select"
                value={filters.ServiceName || []}
                name="ServiceName"
                label="Service Name"
                onChange={handleInputChange}
                searchField="ServiceName"
            ></LogsFilter>
            <LogsFilter
                key={"buildId"}
                multiple
                id="buildId-select"
                value={filters.buildId || []}
                name="buildId"
                label="Build Id"
                onChange={handleInputChange}
                searchField="buildId"
            ></LogsFilter>
            <LogsFilter
                key={"type"}
                multiple
                id="type-select"
                value={filters.type || []}
                name="type"
                label="Type"
                onChange={handleInputChange}
                searchField="type"
            ></LogsFilter>
            <LogsFilter
                key={"status"}
                multiple
                id="status-select"
                value={filters.status || []}
                name="status"
                label="Status"
                onChange={handleInputChange}
                searchField="status"
            ></LogsFilter>
            <LogsFilter
                key={"commonbranch"}
                multiple
                id="commonbranch-select"
                value={filters.commonbranch || []}
                name="commonbranch"
                label="Common Branch"
                onChange={handleInputChange}
                searchField="commonbranch"
            ></LogsFilter>
            <LogsFilter
                key={"MR_ID"}
                multiple
                id="MR_ID-select"
                value={filters.MR_ID || []}
                name="MR_ID"
                label="MR ID"
                onChange={handleInputChange}
                searchField="MR_ID"
            ></LogsFilter>
            <LogsFilter
                key={"FailedStage"}
                multiple
                id="FailedStage-select"
                value={filters.FailedStage || []}
                name="FailedStage"
                label="Failed Stage"
                onChange={handleInputChange}
                searchField="FailedStage"
            ></LogsFilter>
        </Stack>
    </Container>
}
