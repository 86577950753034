import { Container, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
// import { GitDetail, GitFilters, gitrepo } from '../../interfaces/Gitlab';
import { TableWrapper } from '../../components/DataTables/TableWrapper';
import { Pagination, SortOrder } from '../../interfaces/Common';
import { HeaderAction, RowAction } from '../../interfaces/Data';
import { ScheduleStatus } from '../../interfaces/Scheduler';
import { RootState, actions } from '../../store';
import { useAppDispatch, useAppSelector, useIsTabActive } from '../../store/hooks';
import { TableComponents } from '../../utils/constants';
import { ApiError } from '../../utils/errorHandler';
import { CreateDbPage } from './CreateDbPage';
import { DBAccessDetailsPanel } from './DbAccessDetailsPanel';
import { DBFilter } from './DbAccessFilters';
import "./Dbaccess.css";
// import GitlabAPI from '../../apis/gitlab';
import DBAccessAPI from '../../apis/dbaccess';
import { ConfirmDialog } from '../../components/Dialogs/ConfirmDialog';
import { DBDetail, DBFilters } from '../../interfaces/DbAccess';

export const DBAccess = () => {
  const columns = [
    { field: '_id', displayName: 'ID', width: "50px", isSortField: true },
    { field: 'status', displayName: 'Status', width: "150px", isSortField: true },
    { field: 'envName', displayName: 'Environment', width: "150px", isSortField: true },
    { field: 'dbName', displayName: 'DataBase', width: "100px", isSortField: true },
    { field: 'rdpID', displayName: 'RDP IP', width: "100px", isSortField: true },
    { field: 'createdBy', displayName: 'Raised By', width: "100px", isSortField: true },
    { field: 'createdAt', displayName: 'Raised On', width: "100px", isSortField: true }
  ];

  const rows: DBDetail[] = useAppSelector((state: RootState) => state.dbaccess.dbdetails);
  const selections: { [k: string]: boolean } = useAppSelector((state: RootState) => state.dbaccess.selections);
  const filters:DBFilters = useAppSelector((state: RootState) => state.dbaccess.filters);
  const pagination: Pagination = useAppSelector((state: RootState) => state.dbaccess.pagination);
  // const reload: {randomNumber: number} = useAppSelector((state: RootState) => state.dbaccess.reload);
  const permissions = useAppSelector((state: RootState) => state.auth.permissions);
  const [showPane, setShowPane] = useState<boolean>(false);
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [actionClicked, setActionClicked] = useState<RowAction | HeaderAction | null>(null);
  const [rowClicked, setRowClicked] = useState<DBDetail | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const rowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.DBAccess]);
  const defaultHeaderActions = useAppSelector((state: RootState) => state.common.headerActions[TableComponents.DBAccess]);
  const [headerActions, setHeaderActions] = useState<HeaderAction[]>([]);
  const [comment, setComment] = useState<string>("");
  const isTabVisible = useIsTabActive();
  const dispatch = useAppDispatch();
  const { dbaccess, common } = actions;
  const [reload, setreload] = useState<{ randomNumber: number }>({ randomNumber: 0 });

  useEffect(() => {
    if (!isTabVisible) {
      return;
    }
    (async () => {
      const res = await DBAccessAPI.getDBaccess({
        limit: pagination.limit,
        pageNumber: pagination.pageNumber
      }, {
        ...filters
      });
      
      if (res instanceof ApiError) {
        dispatch(common.processApiError(res));
        return;
      }
      if (res && res.data) {
        dispatch(dbaccess.setDBaccess(res.data.results));
        dispatch(dbaccess.setResultCounts({
          totalPages: res.data.totalPages,
          totalResults: res.data.totalResults,
          currentResults: res.data.currentResults
        }))
      }
    })()
  }, [pagination.limit, pagination.pageNumber, filters, reload.randomNumber, isTabVisible]);

  useEffect(() => {
    if (defaultHeaderActions.length === 0) {
      return;
    }

    const ids = Object.keys(selections);
    if (ids.length === 0) {
      setHeaderActions(defaultHeaderActions);
      return;
    }
    
    const selectedStatuses = rows.filter(row => ids.indexOf(row._id.toString()) > -1).map(row => row.status);    
    const newHeaderActions: HeaderAction[] = [];

    for (let i = 0; i < defaultHeaderActions.length; i++) {
      switch (defaultHeaderActions[i]) {
        case HeaderAction.APPROVE:
        case HeaderAction.REJECT:
        case HeaderAction.MULTI_DELETE:
          let isValid = true;
          for (let i = 0; i < selectedStatuses.length; i++) {
            if ([ScheduleStatus.Raised].indexOf(selectedStatuses[i]) === -1) {
              isValid = false;
              break;
            }
          }
          if (isValid) {
            newHeaderActions.push(defaultHeaderActions[i]);
          }
          break;
        default:
          newHeaderActions.push(defaultHeaderActions[i])
      }
    }
    setHeaderActions(newHeaderActions);
  }, [selections, defaultHeaderActions]);

  const handleConfirm = async () => {
    dispatch(common.setShowLoader(true));
    let res;
    switch (actionClicked) {
      case HeaderAction.APPROVE:
        if (Object.keys(selections).length > 0) {
          res = await DBAccessAPI.bulkApprove({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.APPROVE:
        if (rowClicked?._id) {
          res = await DBAccessAPI.bulkApprove({ ids: [`${rowClicked?._id}`] });
        }
        break;
      case HeaderAction.REVIEW:
        if (Object.keys(selections).length > 0) {
          res = await DBAccessAPI.bulkReview({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.REVIEW:
        if (rowClicked?._id) {
          res = await DBAccessAPI.bulkReview({ ids: [`${rowClicked?._id}`] });
        }
        break;
      case HeaderAction.REJECT:
        if (Object.keys(selections).length > 0) {
          console.log("comment is", comment)
          res = await DBAccessAPI.bulkReject({ ids: Object.keys(selections), comment: comment });
        }
        break;
      case RowAction.REJECT:
        if (rowClicked?._id) {
          res = await DBAccessAPI.bulkReject({ ids: [`${rowClicked?._id}`] , comment: comment});
        }
        break;
  
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          res = await DBAccessAPI.bulkRemove({ ids: Object.keys(selections) , comment:comment });
        }
        break;
      case RowAction.DELETE_ROW:
        if (rowClicked?._id) {
          res = await DBAccessAPI.bulkRemove({ ids: [`${rowClicked?._id}`],comment: comment});
        }
        break;
    }
    if (res instanceof ApiError) {
      dispatch(common.processApiError(res));
    }

    setActionClicked(null);
    setRowClicked(null);
    dispatch(common.setShowLoader(false));
    setShowConfirm(false);
    setreload((prevState) => ({ randomNumber: prevState.randomNumber + 1 }));
  }

  const handleSort = (field: string, order: SortOrder) => {
    dispatch(dbaccess.setFilters({...filters, sortBy: field, sortOrder: order}));
  }

  const handleHeaderAction = async (action: HeaderAction) => {
    setActionClicked(action);
    switch (action) {
      case HeaderAction.APPROVE:
      case HeaderAction.REVIEW:
      case HeaderAction.REJECT:
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          setShowConfirm(true)
        }
        break;
      case HeaderAction.ADD_NEW:
        // setRenderNewRow(true);
        setShowCreateForm(true);
        break;
      case HeaderAction.SELECT_ALL:
        dispatch(dbaccess.selectAll())
        break;
      case HeaderAction.UNSELECT:
        dispatch(dbaccess.unselectAll())
        break;
      case HeaderAction.DOWNLOAD_SELECTED:
          setShowConfirm(true);
          break;
      default:
        console.log(action);

    }
  }

  const handleRowAction = async (action: RowAction, row: DBDetail) => {
    setActionClicked(action);
    switch (action) {
      case RowAction.DELETE_ROW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.APPROVE:
          setShowConfirm(true);
          setRowClicked(row);
          break;
      case RowAction.REJECT:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.REVIEW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.CHECKBOX:
        if (row._id && selections[row._id]) {
          dispatch(dbaccess.unselectRow({ _id: row._id }));
        } else if (row._id) {
          dispatch(dbaccess.selectRow({ _id: row._id }));
        }
        break;
      case RowAction.EDIT_ROW:
        const ind2 = rows.findIndex(rowObj => row._id === rowObj._id);
        if (ind2 > -1) {
          const path = `${window.location}/${row._id}?mode=edit`;
          const a = document.createElement("a");
          a.href = path;
          a.target = "_blank";
          a.click();
          a.remove();
        }
        break;
      default:
        console.log(action);
    }
  }

  const handleRowClick = (row: DBDetail) => {
    if (row._id && selections[row._id]) {
      dispatch(dbaccess.unselectRow({_id: row._id}));
    } else if (row._id) {
      dispatch(dbaccess.selectRow({_id: row._id}));
    }
  }

  const handleIdClick = (row: DBDetail) => {
    setShowPane(true);
    setRowClicked(row);
  }

  const handleRowDoubleClick = (row: DBDetail) => {
    const path = `${window.location}/${row._id}`;
    const a = document.createElement("a");
    a.href = path;
    a.target = "_blank";
    a.click();
    a.remove();
  }

  return (
    <Container maxWidth={false} className='deployments-list-container'>
      <DBFilter></DBFilter>
      <Container maxWidth={false} className='deployments-table-wrapper'>
        <TableWrapper<DBDetail>
            componentName={TableComponents.DBAccess}
            columns={columns}
            rows={rows}
            selections={selections}
            headerActions={headerActions}
            handleHeaderAction={handleHeaderAction}
            handleLimitChange={(limit: number) => dispatch(dbaccess.setPagination({ pageNumber: pagination.pageNumber, limit: limit}))}
            handlePageChange={(pageNumber: number) => dispatch(dbaccess.setPagination({ limit: pagination.limit, pageNumber: pageNumber}))}
            pagination={pagination}
            rowActions={rowActions}
            handleRowAction={handleRowAction}
            rowClickFn={handleRowClick}
            rowDbClickFn={handleRowDoubleClick}
            idClickFn={handleIdClick}
            isStatusActions={true}
            handleSort={handleSort}
            sorting={{field: filters.sortBy, order: filters.sortOrder }}
        ></TableWrapper>
      </Container>
      <ConfirmDialog
        title='DB Access Updates'
        open={!!showConfirm}
        yesText='Yes'
        noText='No'
        handleNo={() => setShowConfirm(false)}
        handleYes={handleConfirm}
        >
          <span>{`Are you sure, you want to ${actionClicked}?`}</span>
            {actionClicked === 'Delete' ? <div style={{padding: "0 20px"}}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e: any) => setComment(e.target.value)}
                />    
            </div>: <></>}
        </ConfirmDialog>
        {rowClicked && showPane && <DBAccessDetailsPanel
          dbaccessparam={rowClicked}
          handleClose={() => {
            setRowClicked(null);
            setShowPane(false);
          }}
        ></DBAccessDetailsPanel>}
      { showCreateForm && <CreateDbPage showModal={showCreateForm} handleClose={() => { setShowCreateForm(false); setreload((prevState) => ({ randomNumber: prevState.randomNumber + 1 }));}}></CreateDbPage>}
    </Container>
  );
}