import { StandardTextFieldProps, TextField } from "@mui/material";
import "./Inputs.css";

export interface CustomRangeProps<T> extends StandardTextFieldProps {
    fieldName: string,
    value: any[],
}

export const CustomRange = <T,>(props: CustomRangeProps<T>) => {
	const sx: any = props.sx;
	const width = sx ? sx.width : "200px";
	return <div className="custom-input" style={{width: width}}>
		<div className="field-name"><span>{props.fieldName}{props.required ? "*": ""}</span></div>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row"}}>
    		<TextField
                size={props.size || "small"}
                {...props}
                name={props.name}
                value={props.value ? props.value[0] : undefined}
                sx={{...props.sx, width: width/2}}
                inputProps={{style: {height: "30px", padding: "5px 14px", boxSizing: "border-box"}}}
                type="number"
                onChange={(e: any) => {
                    let {name, value} = e.target;
                    if (props.onChange) {
                        props.onChange({
                            ...e,
                            target: {
                                ...e.target,
                                name: name,
                                value: [value ? parseFloat(value): undefined, props.value[1]]
                            }
                        })    
                    }
                }}
            >
            </TextField>
    		<TextField
                size={props.size || "small"}
                {...props}
                value={props.value ? props.value[1] : undefined}
                name={props.name}
                type="number"
                sx={{...props.sx, width: width/2}}
                inputProps={{style: {height: "30px", padding: "5px 14px", boxSizing: "border-box"}}}
                onChange={(e: any) => {
                    let {name, value} = e.target;
                    if (props.onChange) {
                        props.onChange({
                            ...e,
                            target: {
                                ...e.target,
                                name: name,
                                value: [props.value[0], value ? parseFloat(value): undefined]
                            }
                        })    
                    }
                }}
          ></TextField>
        </div>
	</div>;
};