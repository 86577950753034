import { Difference } from "@mui/icons-material";
import { Box, Checkbox, Chip, Container, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { ComparatorAPI } from "../../../apis";
import { TablePaginationWrapper } from "../../../components/DataTables/TablePaginationWrapper";
import { InfoDialog } from "../../../components/Dialogs/InfoDialog";
import { FormFieldType, InputFormGrid } from "../../../components/Wrapper/Inputs/InputFormGrid";
import { Environment, Pagination } from "../../../interfaces/Common";
import { ComparisonData, PropertiesSnapshotShortData, TopicsSnapshot } from "../../../interfaces/Comparator";
import { actions } from "../../../store";
import { useAppDispatch } from "../../../store/hooks";
import { getDateTimeStr } from "../../../utils/dateUtils";
import { ApiError } from "../../../utils/errorHandler";
import { PropertiesDiff } from "./PropertiesDiff";

export const PropertiesSnapshotComparator = () => {
    const [diffPayload, setDiffPayload] = useState<{
        snapshot1: PropertiesSnapshotShortData | null,
        snapshot2: PropertiesSnapshotShortData | null
    }>({ snapshot1: null, snapshot2: null });
    const [comparisonData, setComparisonData] = useState<ComparisonData>({
        commonKeyValues: {},
        commonKeyDifferentValues: {},
        uniqueKeysFile1: {},
        uniqueKeysFile2: {},
    });
    const [showViewDiff, setShowViewDiff] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { common } = actions;

    const handleViewDiff = async () => {
        if (!(diffPayload.snapshot1 && diffPayload.snapshot2)) {
            return;
        }
        dispatch(common.setShowLoader(true));
        const res = await ComparatorAPI.getPropertiesSnapshotsComparison({
            id1: `${diffPayload.snapshot1?._id}`,
            id2: `${diffPayload.snapshot2?._id}`
        })
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            dispatch(common.setShowLoader(false));
            return;
        }
        dispatch(common.setShowLoader(false));
        setShowViewDiff(true);
        setComparisonData(res.data);
    }

    return <Container maxWidth={false} className="property-comparator">
        <Grid container>
            <Grid container item columnGap={0.4}>
                <Grid container item xs={5.8} className="snapshots-table">
                    <PropertiesSnapshotsTable
                        selected={diffPayload.snapshot1}
                        onSelect={(obj: PropertiesSnapshotShortData) => {
                            if (diffPayload.snapshot1 && diffPayload.snapshot1._id === obj._id) {
                                setDiffPayload({ ...diffPayload, snapshot1: null })
                            } else {
                                setDiffPayload({ ...diffPayload, snapshot1: obj })
                            }
                        }}
                        key={1}
                        index={1}
                    ></PropertiesSnapshotsTable>
                </Grid>
                <Grid container item xs={5.8} className="snapshots-table">
                    <PropertiesSnapshotsTable
                        selected={diffPayload.snapshot2}
                        onSelect={(obj: PropertiesSnapshotShortData) => {
                            if (diffPayload.snapshot2 && diffPayload.snapshot2._id === obj._id) {
                                setDiffPayload({ ...diffPayload, snapshot2: null })
                            } else {
                                setDiffPayload({ ...diffPayload, snapshot2: obj })
                            }
                        }}
                        key={2}
                        index={2}
                    ></PropertiesSnapshotsTable>
                </Grid>
                {diffPayload.snapshot1 && diffPayload.snapshot2 && <div style={{backgroundColor: "white"}} className="compare-button"><IconButton
                    sx={{ height: "50px", width: "50px" }}
                    onClick={handleViewDiff}
                >
                    <Difference sx={{ color: "var(--sidebar-bgc)", fontSize: "40px" }}></Difference>
                </IconButton></div>}
            </Grid>
        </Grid>
        {showViewDiff && <InfoDialog
            open={showViewDiff}
            title="Snapshots Comparison"
            noText="Close"
            handleNo={() => {
                setShowViewDiff(false);
                setComparisonData({
                    commonKeyValues: {},
                    commonKeyDifferentValues: {},
                    uniqueKeysFile1: {},
                    uniqueKeysFile2: {},
                })
            }}
        >
            {diffPayload.snapshot1 && diffPayload.snapshot2 && <Container maxWidth={false} className="diff-container">
                <Box sx={{ width: '100%' }}>
                    <Box>
                        <div className="snapshot-name">{`Source - SNAPSHOT-${diffPayload.snapshot1._id}: ${diffPayload.snapshot1.path}-${getDateTimeStr(diffPayload.snapshot1.date)}`}</div>
                        <div className="snapshot-name">{`Target - SNAPSHOT-${diffPayload.snapshot2._id}: ${diffPayload.snapshot2.path}-${getDateTimeStr(diffPayload.snapshot2.date)}`}</div>
                    </Box>
                    <PropertiesDiff
                        comparisonData={comparisonData}
                        diffPayload={{env1: "1", env2: "2"}}
                    ></PropertiesDiff>
                </Box>
            </Container>}
        </InfoDialog>}
    </Container>
}

export const PropertiesSnapshotsTable = (props: {
    selected: PropertiesSnapshotShortData | null,
    onSelect: (selected: PropertiesSnapshotShortData) => void,
    index: number
}) => {
    const [filters, setFilters] = useState<{
        env: string,
        serviceName: string[]
        tags: string[],
        dates: Date[],
    }>({
        env: Environment.QA3,
        serviceName: [],
        tags: [],
        dates: [],
    })
    const [pagination, setPagination] = useState<Pagination>({
        pageNumber: 1,
        limit: 10,
        currentResults: 0,
        totalPages: 0,
        totalResults: 0
    })
    const isLargeScreen = useMediaQuery('(min-width:1400px)');
    const [tagsOptions, setTagsOptions] = useState<string[]>([]);
    const [tagsSearchText, setTagsSearchText] = useState<string>("");
    const [rows, setRows] = useState<PropertiesSnapshotShortData[]>([])
    const [rowData, setRowData] = useState<TopicsSnapshot | null>(null);
    const [showTopics, setShowTopics] = useState<boolean>(false);
    const [serviceNameOptions, setServiceNameOptions] = useState<string[]>([]);
    const [envOptions, setEnvOptions] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await ComparatorAPI.getPropertiesFilters("tags", { searchText: tagsSearchText });
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }

            setTagsOptions(res.data);
        })()
    }, [tagsSearchText])

    useEffect(() => {
        (async () => {
            const res = await ComparatorAPI.getPropertiesFilters("serviceName");
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setServiceNameOptions(res.data);

            const res2 = await ComparatorAPI.getPropertiesFilters("env");
            if (res2 instanceof ApiError) {
                dispatch(common.processApiError(res2));
                return;
            }
            setEnvOptions(res2.data);

        })()
    }, [])


    useEffect(() => {
        (async () => {
            dispatch(common.setShowLoader(true));
            const res = await ComparatorAPI.getPropertiesSnapshots({
                pageNumber: pagination.pageNumber,
                limit: pagination.limit
            }, {
                env: filters.env,
                serviceName: filters.serviceName,
                after: filters.dates ? filters.dates[0] : undefined,
                before: filters.dates ? filters.dates[1] : undefined,
                tags: filters.tags
            })
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                dispatch(common.setShowLoader(false));
                return;
            }
            setRows(res.data.results);
            setPagination({
                ...pagination,
                currentResults: res.data.currentResults,
                totalPages: res.data.totalPages,
                totalResults: res.data.totalResults
            })
            dispatch(common.setShowLoader(false));
        })()
    }, [filters, pagination.pageNumber, pagination.limit])

    const handleRowClick = async (obj: PropertiesSnapshotShortData) => {
        const res = await ComparatorAPI.getTopicsSnapshotDetail(`${obj._id}`);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            return;
        }
        setRowData(res.data);
        setShowTopics(true);
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    }

    return <>
        <Grid container item className="snapshot-filters-wrapper">
            <InputFormGrid
                formFields={[{
                    name: "env",
                    fieldName: "Environment",
                    value: filters.env,
                    type: FormFieldType.CUSTOM_SELECT,
                    options: envOptions,
                }, {
                    name: "serviceName",
                    fieldName: "Service",
                    value: filters.serviceName,
                    type: FormFieldType.CUSTOM_SEARCH_SELECT,
                    options: serviceNameOptions,
                    muiltiple: true
                }, {
                    name: "dates",
                    fieldName: "Date Range",
                    value: filters.dates,
                    type: FormFieldType.CUSTOM_DATERANGE_PICKER,
                }, {
                    name: "tags",
                    fieldName: "Tags",
                    value: filters.tags,
                    type: FormFieldType.CUSTOM_SEARCH_SELECT,
                    searchText: tagsSearchText,
                    muiltiple: true,
                    onSearchTextChange(e) {
                        setTagsSearchText(e);
                    },
                    options: tagsOptions
                }]}
                onChange={handleInputChange}
                commonXs={3}
                commonSx={{ width: "120px" }}
            ></InputFormGrid>
        </Grid>
        <Grid item xs={12} className="snapshots-title">{`${props.index === 1 ? "Source" : "Target"} Snapshots of Environment ${filters.env.toUpperCase()}`}</Grid>
        <Grid item xs={12}>
            <TableContainer sx={{ height: isLargeScreen ? "62vh": "55vh", overflow: "auto" }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Service Name</TableCell>
                            <TableCell>Tags</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map(obj => {
                            return <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={props.selected ? props.selected._id === obj._id : false}
                                        onClick={(e: any) => {
                                            props.onSelect(obj)
                                        }}
                                        sx={{
                                            color: "var(--sidebar-bgc)",
                                            height: "20px!important",
                                            "&.Mui-checked": {
                                                color: "var(--sidebar-bgc)",
                                            },
                                            '& .MuiSvgIcon-root': { fontSize: 20 },
                                        }}
                                    ></Checkbox>
                                </TableCell>
                                <TableCell>
                                    <div onClick={() => handleRowClick(obj)}>{`${getDateTimeStr(obj.date)}`}</div>
                                </TableCell>
                                <TableCell>
                                    <div>{obj.serviceName}</div>
                                </TableCell>
                                <TableCell>
                                    <div>{obj.tags.map(str => <Chip sx={{height: "20px"}} label={str}></Chip>)}</div>
                                </TableCell>
                            </TableRow>
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePaginationWrapper
                limit={pagination.limit}
                pageNumber={pagination.pageNumber}
                currentResults={pagination.currentResults}
                totalResults={pagination.totalResults}
                totalPages={pagination.totalPages}
                onPageChange={(pageNumber: number) => { setPagination({ ...pagination, pageNumber: pageNumber }) }}
                onLimitChange={(limit: number) => { setPagination({ ...pagination, limit: limit }) }}
                width="45vw"
                showLimit={true}
                hideRpp={!isLargeScreen}
            ></TablePaginationWrapper>
        </Grid>
        {rowData && showTopics && <InfoDialog
            open={showTopics}
            noText="Close"
            handleNo={() => {
                setShowTopics(false);
                setRowData(null);
            }}
            title={`Kafka Topics for ${rowData?.env} on ${rowData?.date}`}
        >
            <div className="topics-container">
                {rowData.topics.map((topic, index) => {
                    return <div className="topic-div" key={index}>{`${index + 1}. ${topic}`}</div>
                })}
            </div>
        </InfoDialog>}
    </>
}
