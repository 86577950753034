import { Container, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AdminAPI } from "../../apis";
import { UserFilter } from "../../components/Filters/DataFilters";
import { Dropdown } from "../../components/Filters/Dropdown";
import { UserFilters } from "../../interfaces/Accounts";
import { ROLE } from "../../interfaces/Common";
import { RootState, actions } from "../../store";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";
import "./Accounts.css";

export const AccountsFilters = () => {
    const filters: UserFilters = useAppSelector((state: RootState) => state.accounts.filters);
    const roles = Object.values(ROLE);
    const [roleOptions, setRoleOptions] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { accounts, common } = actions;

    useEffect(() => {
        (async () => {
            const res = await AdminAPI.getRolesList({});
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
              return;
            }
            setRoleOptions(res.data);
        })()
    }, [])

    
    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (value === "") {
            dispatch(accounts.setFilters({
                ...filters,
                [name]: ""
            }))
        } else {
            dispatch(accounts.setFilters({
                ...filters,
                [name]: value
            }))
        }
    };

    const renderNameFilter = useCallback(() => {
        return <UserFilter
            key={"name"}
            id="name-select"
            multiple
            value={filters.name}
            label="Name"
            name="name"
            onChange={handleInputChange}
            role={roles}
        ></UserFilter>
    }, [filters.name]);

    const renderEmailFilter = useCallback(() => {
        return <UserFilter
            key={"email"}
            id="email-select"
            multiple
            value={filters.email}
            label="email"
            name="email"
            onChange={handleInputChange}
            searchField="email"
        ></UserFilter>
    }, [filters.email]);

    return <Container maxWidth={false} className="filters-container">
        <Stack direction="row" spacing={2}>
            {renderNameFilter()}
            {renderEmailFilter()}
            <Dropdown
                multiple
                id="role-select"
                value={filters.role}
                name="role"
                label="Role"
                onChange={handleInputChange}
                options={roleOptions}
            ></Dropdown>
        </Stack>
    </Container>
}