import { createSlice } from '@reduxjs/toolkit'
import type { Slice, PayloadAction } from "@reduxjs/toolkit";
import { Deployment, DeploymentDetail, DeploymentFilters } from '../../interfaces/Deployments';
import { Pagination } from '../../interfaces/Common';
import { BuildCycleDetail } from '../../interfaces/Buildcycle';

export interface BuildCycleState {
    buildcycle: BuildCycleDetail[],
    pagination: Pagination,
    filters: DeploymentFilters,
    selections: {[k:string]: boolean},
    reload: {
        randomNumber: number
    },
    reloadDefault: {
        randomNumber: number
    },
};


const getInitialState = (): BuildCycleState => {
    const initialState: BuildCycleState = {
        buildcycle: [],
        pagination: {
            pageNumber: 1,
            limit: 10,
            currentResults: 0,
            totalPages: 0,
            totalResults: 0        
        },
        filters: {},
        selections: {},
        reload: {
            randomNumber: 0
        },
        reloadDefault: {
            randomNumber: 0
        }
    }

    return initialState;
}

const buildCycleReducer = {
    setBuildcycle: (state: BuildCycleState,
        action: PayloadAction<BuildCycleDetail[]>) => {
            state.buildcycle = action.payload;
    },
    setResultCounts: (state: BuildCycleState, action: PayloadAction<{
        totalPages: number,
        currentResults: number,
        totalResults: number
    }>) => {
        state.pagination.currentResults = action.payload.currentResults;
        state.pagination.totalPages = action.payload.totalPages;
        state.pagination.totalResults = action.payload.totalResults;
    },
    setPagination: (state: BuildCycleState, action: PayloadAction<{limit: number, pageNumber: number}>) => {
        state.pagination.pageNumber = action.payload.pageNumber;
        state.pagination.limit = action.payload.limit;
    },
    reloadData: (state: BuildCycleState) => {
        state.reload.randomNumber = Math.random();
    },
    setFilters: (state: BuildCycleState, action: PayloadAction<DeploymentFilters>) => {
        state.filters = action.payload;
    },
    selectRow: (state: BuildCycleState, action: PayloadAction<{_id: string}>) => {
        state.selections[action.payload._id] = true;
    },
    setSelections: (state: BuildCycleState, action: PayloadAction<{[k:string]: boolean}>) => {
        state.selections = action.payload;
    },
    unselectRow: (state: BuildCycleState, action: PayloadAction<{_id: string}>) => {
        delete state.selections[action.payload._id];
    },
    reloadDefaultBuildCycleData: (state: BuildCycleState) => {
        state.reloadDefault.randomNumber = Math.random();
    },
    selectAll: (state: BuildCycleState) => {
        for (const row of state.buildcycle) {
            if (row._id) {
                state.selections[row._id] = true;
            }
        }
    },
    unselectAll: (state: BuildCycleState) => {
        state.selections = {}
    },
}

export const buildcycleSlice: Slice<BuildCycleState, typeof buildCycleReducer> = createSlice({
    name: 'buildcycle',
    initialState: getInitialState(),
    reducers: buildCycleReducer,
})