import { Button, Container, FormControl, MenuItem, Stack, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import { ComparatorAPI } from "../../apis";
import ConfigsAPI from "../../apis/config";
import { InputLabel, Select } from "../../components/Wrapper";
import { ImageRes } from "../../interfaces/Comparator";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
import { ApiError } from "../../utils/errorHandler";
import "./Comparator.css";

export const ImageComparator = () => {
    const [payload, setPayload] = useState<{ modulename: string, servicename: string }>({
        modulename: "",
        servicename: "",
    })
    const [moduleOptions, setModuleOptions] = useState<string[]>([]);
    const [disableSubmit, setDisableSubmit] = useState<boolean>(true);
    const [imageResponse, setImageResponse] = useState<ImageRes[]>([]);
    const [imageFetched, setimageFetched] = useState<boolean>(false);
    const isLargeScreen = useMediaQuery('(min-width:1200px)');
    const [servicesOptions, setServicesOptions] = useState<string[]>([]);
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        (async () => {
            const res = await ConfigsAPI.getModules();
            if (res instanceof ApiError) {
                dispatch(common.processApiError(res));
                return;
            }
            setModuleOptions(res.data);
        })()
    }, [])
    useEffect(() => {
        (async () => {
            if (payload.modulename) {
                const res = await ConfigsAPI.getModuleServices({ module: payload.modulename });
                if (res instanceof ApiError) {
                    dispatch(common.processApiError(res));
                    return;
                }

                setServicesOptions(res.data);

            } else {
                setServicesOptions([]);
            }
        })()
    }, [payload.modulename])
    useEffect(() => {
        if (imageFetched) {
            setimageFetched(false);
        }
    }, [payload])

    useEffect(() => {
        (async () => {
            if (payload.servicename != "" && payload.modulename != "")
                setDisableSubmit(false);
        })()
    }, [payload])

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        if (value === "") {
            setPayload({
                ...payload,
                [name]: ""
            })
        } else {
            setPayload({
                ...payload,
                [name]: value
            })
        }
    }

    

    const handleSubmit = async () => {
        dispatch(common.setShowLoader(true));
        const res = await ComparatorAPI.getImages(payload);
        if (res instanceof ApiError) {
            dispatch(common.processApiError(res));
            dispatch(common.setShowLoader(false));
            return;
        }
        setImageResponse(res.data);
        setimageFetched(true);
        dispatch(common.setShowLoader(false));
        setDisableSubmit(true);
    }

    return <Container className="image-comparator">
        <Container>
            <Stack direction={isLargeScreen ? "row" : "column"} spacing={2}>
                <FormControl size="small" sx={{ maxWidth: 180 }}>
                    <InputLabel id="module-name">Module Name</InputLabel>
                    <Select
                        labelId="module-name"
                        id="module-name-select"
                        value={payload.modulename}
                        label="Module Name"
                        onChange={handleInputChange}
                        name="modulename"
                    >
                        {moduleOptions.map((value) => {
                            return <MenuItem value={value}>{value}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <FormControl size="small" sx={{ maxWidth: 180 }}>
                    <InputLabel id="service-name">Service Name</InputLabel>
                    <Select
                        labelId="service-name-label"
                        id="service-name-select"
                        value={payload.servicename}
                        label="Service Name"
                        name="servicename"
                        onChange={handleInputChange}
                    >
                        {servicesOptions.map((value) => {
                            return <MenuItem value={value}>{value}</MenuItem>
                        })}
                    </Select>
                </FormControl>
                <Button variant="contained" disabled={disableSubmit} type="submit" onClick={handleSubmit}>Submit</Button>
            </Stack>
        </Container>
        {imageFetched && <Container maxWidth={false} className="image-container">
            <table className="image-table">
                <thead>
                    <tr>
                        <th>Environment</th>
                        <th>Image</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody >
                    {imageResponse.map((item, index) => {
                        return (
                            <tr key={index}>
                                <td>{item.env}</td>
                                <td>{item.image}</td>
                                <td>{item.status}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </Container>}
    </Container>
}