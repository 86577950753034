import { Container, Stack, FormControl, Button, Box, IconButton, AlertProps, Alert, Snackbar, Dialog, DialogActions, DialogTitle, Backdrop, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { UserAPI } from "../../apis";
import { ApiError } from "../../utils/errorHandler";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { DataObjectSharp, EditSharp, ListAlt } from "@mui/icons-material";
import ConfigsAPI from "../../apis/config";
import { ModuleName, Environment } from "../../interfaces/Common";
import "./Configs.css";
import { RootState } from "../../store";
import { useAppSelector } from "../../store/hooks";
import { SubComponentName } from "../../utils/constants";
import { RowAction } from "../../interfaces/Data";

export const DBAccessMap = () => {
    const [canEdit, setCanEdit] = useState<boolean>(false);
    const permissions = useAppSelector((state: RootState) => state.common.rowActions[SubComponentName.DatabaseSGs]);
    const [dbAccessMap, setDbAccessMap] = useState<{[key in Environment]?: string[]}>({});
    const [editString, setEditString] = useState<string>("");
    const [showLoader, setShowLoader] = useState<boolean>(false);
    const columns: GridColDef[] = [
        { field: 'envName', headerName: 'Environment', minWidth: 200 },
        {
            field: 'dbName',
            headerName: "DataBase",
            minWidth: 200,
            editable: false
        },
    ];
    const [snackbar, setSnackbar] = useState<Pick<
        AlertProps,
        'children' | 'severity'
    > | null>(null);
    const [viewMode, setViewMode] = useState<string>("Json");
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSave, setCanSave] = useState<boolean>(false);

    useEffect(() => {
        (async () => {
            setShowLoader(true);
            const res = await ConfigsAPI.getDbAccess();
            if (res instanceof ApiError) {
                setSnackbar({children: res.message, severity: "error"});
                setShowLoader(false);
                return;
            }
            setDbAccessMap(res.data);
            setEditString(JSON.stringify(res.data, null, 2));
            setShowLoader(false);
        })()
    }, []);

    useEffect(() => {
        setCanEdit(permissions.includes(RowAction.EDIT_ROW));
    }, [permissions])

    useEffect(() => {
        try {
            let json = JSON.parse(editString);
            setCanSave(true);
        } catch (error) {
            setCanSave(false);
        }
    }, [editString]);

    const handleCloseSnackbar = () => setSnackbar(null);

    const handleSave = async () => {
        const val = JSON.parse(editString);
        let res = await ConfigsAPI.updateDbAccess(val);
        if (res instanceof ApiError) {
            setSnackbar({children: res.message, severity: "error"});
            return;
        }
        setDbAccessMap(val);
        setSnackbar({children: res.message, severity: "success"});
        setViewMode("Json");
    }
    
    const renderTable = () => {
        const rows = Object.keys(dbAccessMap).map((key: string) => {
            return {
                id: key,
                envName: key,
                dbName: dbAccessMap[key as Environment],
            }
        })
        return (
            <Box sx={{ height: "60vh" }}>
              <DataGrid
                rows={rows}
                columns={columns}
              />
            </Box>
          );    
    }

    const handleChange = (newVal: string) => {
        setEditString(newVal);
    }

    const renderJson = () => {
        return <textarea 
            onChange={(e: any) => {
                handleChange(e.target.value);
            }}
            disabled={viewMode !== "Edit"}
            spellCheck={false}
            style={{width: "50vw", height: "50vh"}} value={editString}></textarea>
    }

    return <Container>
        <Stack direction={"row"} spacing={2}>
            {canEdit && <IconButton color={viewMode === "Edit" ? "primary": "default"} onClick={() => setViewMode("Edit")}><EditSharp></EditSharp></IconButton>}
            <IconButton color={viewMode === "Json" ? "primary": "default"} onClick={() => setViewMode("Json")}><DataObjectSharp></DataObjectSharp></IconButton>
            <IconButton color={viewMode === "List" ? "primary": "default"} onClick={() => setViewMode("List")}><ListAlt></ListAlt></IconButton>
        </Stack>
        <Container>{viewMode === "List"? renderTable(): renderJson()}</Container>
        {viewMode === "Edit" && <FormControl sx={{width: "50vw"}}><Button variant="contained" disabled={!canSave} onClick={() => handleSave()}>Save</Button></FormControl>}
        {!!snackbar && (
            <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
                <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
        )}
        {showLoader && <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
            open={true}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
        </Backdrop>}
        <Dialog
            maxWidth="xs"
            open={!!(showConfirm)}
        >
            <DialogTitle>{`Are you sure, you want to update?`}</DialogTitle>
            <DialogActions>
                <Button onClick={() => setShowConfirm(false)}>
                    No
                </Button>
                <Button onClick={() => handleSave()}>Yes</Button>
            </DialogActions>
            {showLoader && <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 100 }}
                open={true}
                    // onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>}
        </Dialog>
    </Container>
}