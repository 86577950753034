import { Autocomplete, Button, Container, Dialog, DialogActions, DialogTitle, List, ListItem, MenuItem, Stack, TextareaAutosize } from "@mui/material";
import { useEffect, useState } from "react";
import GitlabAPI from "../../apis/gitlab";
import { Select, TextField } from "../../components/Wrapper";
import { NamespaceOption, gitrepo } from "../../interfaces/Gitlab";
import { actions } from "../../store";
import { useAppDispatch } from "../../store/hooks";
export const EditRepo = (props: {
    handleCancel: () => void,
    handleSave: () => void,
    row: gitrepo
}) => {
    const [repoObj, setRepoObj] = useState<gitrepo>({
        _id: props.row._id,
        repoName: props.row.repoName,
        projectSlug: props.row.projectSlug,
        description: props.row.description,
        createGroup: props.row.createGroup,
        groupName: props.row.groupName,
        namespace: props.row.namespace,
        status: props.row.status,
        groupMaintainers: props.row.groupMaintainers,
        groupOwners: props.row.groupOwners,
        grouptype: props.row.createGroup

    })
    const [showConfirm, setShowConfirm] = useState<boolean>(false);
    const [canSubmit, setCanSubmit] = useState<boolean>(false);
    const [groupsname, setGroupsname] = useState<string[]>([]);
    const [maintainersname, setMaintainersname] = useState<string[]>([]);
    const [ownersname, setOwnersname] = useState<string[]>([]);
    const [type, setType] = useState('');
    const [isGroupSelected, setIsGroupSelected] = useState(props.row.createGroup);
    const [pageNumber, setPageNumber] = useState(1);
    const [limit, setLimit] = useState(10);
    const [searchText, setSearchText] = useState('');
    const dispatch = useAppDispatch();
    const { common } = actions;

    useEffect(() => {
        const fetchData = async () => {
            try {
                let response
                if (type == "groups" || !isGroupSelected) {
                    response = await GitlabAPI.getGroups({
                        limit: 10,
                        pageNumber: 1,
                    }, searchText);
                    setGroupsname(response.data.results);
                }
                else {
                    response = await GitlabAPI.getUsers({
                        limit: 10,
                        pageNumber: 1,
                    }, searchText);
                }

                const data = response.data;
                if (type == "groupOwners" || isGroupSelected) {
                    setOwnersname(data.results);
                }
                if (type == "groupMaintainers" || isGroupSelected) {
                    setMaintainersname(data.results);
                }


                console.log(data.results);
            } catch (error) {
                console.error('Error fetching groups:', error);
            }
        };

        fetchData();
    }, [pageNumber, limit, searchText, type, isGroupSelected]);

    useEffect(() => {
        if (isGroupSelected) {
            setRepoObj({
                ...repoObj,
                createGroup: true,
                grouptype: true
            });
        }
        else {
            setRepoObj({
                ...repoObj,
                createGroup: false,
                grouptype: false
            });
        }
    }, [repoObj])

    useEffect(() => {

        setRepoObj({
            ...repoObj,
            groupName: "",
        });

    }, [isGroupSelected])
    useEffect(() => {

        setRepoObj({
            ...repoObj,
            groupName: "",
            groupMaintainers: [],
            groupOwners: []
        });

    }, [!isGroupSelected])

    useEffect(() => {

        if ((repoObj.repoName && repoObj.description && repoObj.namespace && repoObj.groupName && !repoObj.createGroup) || (repoObj.createGroup && repoObj.groupName)) {
            setCanSubmit(true);
        } else {
            setCanSubmit(false);
        }

    }, [repoObj])



    const handleCreateConfirm = async () => {
        dispatch(common.setShowLoader(true));
        await GitlabAPI.updateRepo(repoObj);
        setShowConfirm(false);
        setRepoObj({
            _id: props.row._id,
            repoName: props.row.repoName,
            description: props.row.description,
            createGroup: props.row.createGroup,
            groupName: props.row.groupName,
            namespace: props.row.namespace,
            status: props.row.status,
            groupMaintainers: props.row.groupMaintainers,
            groupOwners: props.row.groupOwners,
            grouptype: props.row.grouptype
        });
        dispatch(common.setShowLoader(false));
        props.handleSave();
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;

        if (value === "") {
            // If the user selects an empty value, clear the source environment field
            setRepoObj({
                ...repoObj,
                [name]: "",  // Make sure to update the specific field with an empty value
            });
        }
        else {
            setRepoObj({
                ...repoObj,
                [name]: value,

            });

        }
    };
    const handleSearchTextChange = (identifier: any, event: any, value: any) => {
        setType(identifier);
        setSearchText(value);
        setPageNumber(1);
    };



    const handleRadioChange = () => {
        setIsGroupSelected(!isGroupSelected); // Toggle the selected state

    };
    const mobileViewDialogSx = {
        padding: 0,
        margin: 0,
        '& .MuiDialog-paperWidthXl': {
            margin: 0
        }
    }

    return <Container maxWidth={false} className="edit-deployment">
        <Stack direction={"row"} spacing={2}>
            <Container maxWidth={false}>
                <List className="list">
                    <ListItem className="list-item" key="Projects">
                        <div className="field-name">project Name:</div>
                        <div className="field-value">
                            <TextField value={repoObj.repoName} onChange={handleInputChange} name="repoName" variant="outlined" size="small"></TextField>
                        </div>
                    </ListItem>
                    <ListItem className="list-item" key="namespace">
                        <div className="field-name">Namespace:</div>
                        <div className="field-value">
                            <Select
                                sx={{ minWidth: "200px" }}
                                id="namespace"
                                value={repoObj.namespace}
                                name="namespace"
                                onChange={handleInputChange}
                                required
                            >
                                {Object.keys(NamespaceOption).map((key) => {
                                    const value = NamespaceOption[key as keyof typeof NamespaceOption];
                                    return <MenuItem value={value}>{value}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </ListItem>
                    <ListItem className="list-item" key="projectSlug">
                        <div className="field-name">projectSlug:</div>
                        <div className="field-value">
                            <TextField value={repoObj.projectSlug} onChange={handleInputChange} name="projectSlug" variant="outlined" size="small"></TextField>
                        </div>
                    </ListItem>
                    <ListItem className="list-item" key="discription">
                        <div className="field-name">Discription:</div>
                        <div className="field-value">
                            <TextareaAutosize value={repoObj.description} onChange={handleInputChange} name="description" style={{ minHeight: "50px", minWidth: "200px" }} />
                        </div>
                    </ListItem>
                    <ListItem className="list-item" key="group">
                        <div className="field-name">Groups:</div>
                        <div className="field-value">
                            <input
                                type="checkbox"
                                name="grouptype"
                                checked={isGroupSelected}
                                onChange={handleRadioChange}
                            />

                        </div>
                    </ListItem>
                    {isGroupSelected &&
                        <ListItem className="list-item" key="newgroupName">
                            <div className="field-name">New Group Name:</div>
                            <div className="field-value">
                                <TextField value={repoObj.groupName} onChange={handleInputChange} name="groupName" variant="outlined" size="small"></TextField>
                            </div>
                        </ListItem>}
                    {isGroupSelected &&
                        <ListItem className="list-item" key="groupOwners">
                            <div className="field-name">Owners:</div>
                            <div className="field-value">
                                <Autocomplete
                                    value={repoObj.groupOwners || []}
                                    multiple={true}

                                    onInputChange={(event, value) =>
                                        handleSearchTextChange('groupOwners', event, value)
                                    }
                                    options={ownersname}
                                    disableCloseOnSelect
                                    onChange={(e: any, newVal: any[]) => {
                                        setRepoObj({ ...repoObj, "groupOwners": newVal })
                                    }}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Search" variant="outlined" size="small" />
                                    )}
                                    style={{ width: 300 }} // Adjust the width as needed
                                />

                            </div>

                        </ListItem>}
                    {isGroupSelected &&
                        <ListItem className="list-item" key="groupMaintainers">
                            <div className="field-name">Maintainers:</div>
                            <div className="field-value">
                                <Autocomplete
                                    value={repoObj.groupMaintainers || []}
                                    multiple={true}

                                    onInputChange={(event, value) =>
                                        handleSearchTextChange('groupMaintainers', event, value)
                                    }
                                    options={maintainersname}
                                    disableCloseOnSelect
                                    onChange={(e: any, newVal: any[]) => {
                                        setRepoObj({ ...repoObj, "groupMaintainers": newVal })
                                    }}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField {...params} label="Search" variant="outlined" size="small" />
                                    )}
                                    style={{ width: 300 }} // Adjust the width as needed
                                />
                            </div>
                        </ListItem>}
                    {!isGroupSelected &&
                        <ListItem className="list-item" key="GroupName">
                            <div className="field-name">Group Name:</div>
                            <div className="field-value">
                                <Autocomplete
                                    value={repoObj.groupName}


                                    onInputChange={(event, value) =>
                                        handleSearchTextChange('groups', event, value)
                                    }

                                    options={groupsname}

                                    onChange={(e: any, newVal: any) => {
                                        setRepoObj({ ...repoObj, "groupName": newVal })
                                    }}
                                    getOptionLabel={(option) => option}
                                    renderInput={(params) => (
                                        <TextField  {...params} label="Search" variant="outlined" size="small" />
                                    )}
                                    style={{ width: 300 }} // Adjust the width as needed
                                />
                            </div>
                        </ListItem>}

                </List>
                <Button disabled={!canSubmit} onClick={() => setShowConfirm(true)} fullWidth variant="contained">Update</Button>
            </Container>
        </Stack>
        <Dialog
            maxWidth="xs"
            open={!!(showConfirm)}
        >
            <DialogTitle>{`Are you sure, you want to update the Groupname ${repoObj.groupName}?`}</DialogTitle>
            <DialogActions>
                <Button onClick={() => setShowConfirm(false)}>
                    No
                </Button>
                <Button onClick={handleCreateConfirm}>Yes</Button>
            </DialogActions>
        </Dialog>
    </Container>
}