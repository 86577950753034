import { Container, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { SchedulerAPI } from '../../../apis';
import { TableWrapper } from '../../../components/DataTables/TableWrapper';
import { ConfirmDialog } from '../../../components/Dialogs/ConfirmDialog';
import { Pagination, SortOrder } from '../../../interfaces/Common';
import { HeaderAction, RowAction } from '../../../interfaces/Data';
import { ScheduleLambda, ScheduleLambdaFilters } from '../../../interfaces/Scheduler';
import { RootState, actions } from '../../../store';
import { useAppDispatch, useAppSelector, useIsTabActive } from '../../../store/hooks';
import { TableComponents } from '../../../utils/constants';
import { ApiError } from '../../../utils/errorHandler';
import { CreateScheduleLambda } from './CreateScheduleLambda';
import "./ScheduleLambdas.css";
import { ScheduleLambdasFilterGrid } from './ScheduleLambdasFilterGrid';


export const ScheduleLambdas = () => {
  const columns = [
    { field: 'lambdaName', displayName: 'Lambda Name', width: "40%", isSortField: true },
    { field: 'triggerType', displayName: 'Lambda Type', width: "15%", isSortField: true },
    { field: 'envs', displayName: 'Envs', width: "30%", isSortField: true },
  ];
  const rows: ScheduleLambda[] = useAppSelector((state: RootState) => state.lambdas.scheduleLambdas);
  const headerActions = useAppSelector((state: RootState) => state.common.headerActions[TableComponents.ScheduleLambdas]);
  const rowActions = useAppSelector((state: RootState) => state.common.rowActions[TableComponents.ScheduleLambdas]);
  const selections: { [k: string]: boolean } = useAppSelector((state: RootState) => state.lambdas.selections);
  const filters: ScheduleLambdaFilters = useAppSelector((state: RootState) => state.lambdas.filters);
  const pagination: Pagination = useAppSelector((state: RootState) => state.lambdas.pagination);
  const reload: {randomNumber: number} = useAppSelector((state: RootState) => state.lambdas.reload);
  const [showCreateForm, setShowCreateForm] = useState<boolean>(false);
  const [actionClicked, setActionClicked] = useState<RowAction | HeaderAction | null>(null);
  const [rowClicked, setRowClicked] = useState<ScheduleLambda | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  
  const [comment, setComment] = useState<string>("");
  const isTabVisible = useIsTabActive();
  const dispatch = useAppDispatch();
  const { lambdas, common } = actions;

  useEffect(() => {
    if (!isTabVisible) {
      return;
    }

    (async () => {
      const res = await SchedulerAPI.getScheduleLambdas({
        pageNumber: pagination.pageNumber,
        limit: pagination.limit
      },{...filters});

      if (res instanceof ApiError) {
        dispatch(common.setSnackbar({children: res.message, severity: "error"}));
        return;
      }

      dispatch(lambdas.setLambdas(res.data.results));
      dispatch(lambdas.setResultCounts({
        totalPages: res.data.totalPages,
        totalResults: res.data.totalResults,
        currentResults: res.data.currentResults
      }))
    })()
  }, [pagination.limit, pagination.pageNumber, filters, reload.randomNumber, isTabVisible]);

  const handleConfirm = async () => {
    setShowConfirm(true);
    let res;
    switch (actionClicked) {
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          res = await SchedulerAPI.bulkRemoveScheduleLambdas({ ids: Object.keys(selections) });
        }
        break;
      case RowAction.DELETE_ROW:
        if (rowClicked) {
          res = await SchedulerAPI.bulkRemoveScheduleLambdas({ids: [rowClicked._id]});
        }
        break;
    }

    if (res instanceof ApiError) {
      dispatch(common.setSnackbar({ children: res.message, severity: "error" }));
    }

    setActionClicked(null);
    setRowClicked(null);
    setShowConfirm(false);
    setShowConfirm(false);
  }

  const handleHeaderAction = async (action: HeaderAction) => {
    setActionClicked(action);
    switch (action) {
      case HeaderAction.MULTI_DELETE:
        if (Object.keys(selections).length > 0) {
          setShowConfirm(true)
        }
        break;
      case HeaderAction.ADD_NEW:
        // setRenderNewRow(true);
        setShowCreateForm(true);
        break;
      case HeaderAction.SELECT_ALL:
        dispatch(lambdas.selectAll())
        break;
      case HeaderAction.UNSELECT:
        dispatch(lambdas.unselectAll())
        break;
      default:
        console.log(action);

    }
  }

  const handleRowAction = async (action: RowAction, row: ScheduleLambda) => {
    setActionClicked(action);
    switch (action) {
      case RowAction.DELETE_ROW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.APPROVE:
          setShowConfirm(true);
          setRowClicked(row);
          break;
      case RowAction.REJECT:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.REVIEW:
        setShowConfirm(true);
        setRowClicked(row);
        break;
      case RowAction.CHECKBOX:
        if (row._id && selections[row._id]) {
          dispatch(lambdas.unselectRow({ _id: row._id }));
        } else if (row._id) {
          dispatch(lambdas.selectRow({ _id: row._id }));
        }
        break;
      case RowAction.EDIT_ROW:
        const ind2 = rows.findIndex(rowObj => row._id === rowObj._id);
        if (ind2 > -1) {
          const path = `${window.location}/${row._id}?mode=edit`;
          const a = document.createElement("a");
          a.href = path;
          a.target = "_blank";
          a.click();
          a.remove();
        }
        break;
      default:
        console.log(action);
    }
  }

  const handleSort = (field: string, order: SortOrder) => {
    dispatch(lambdas.setFilters({...filters, sortBy: field, sortOrder: order}));
  }

  const handleRowClick = (row: ScheduleLambda) => {
    if (row._id && selections[row._id]) {
      dispatch(lambdas.unselectRow({_id: row._id}));
    } else if (row._id) {
      dispatch(lambdas.selectRow({_id: row._id}));
    }
  }

  return (
    <Container maxWidth={false} className='table-view'>
      <ScheduleLambdasFilterGrid></ScheduleLambdasFilterGrid>
      <TableWrapper<ScheduleLambda>
          componentName={TableComponents.ScheduleLambdas}
          columns={columns}
          rows={rows}
          selections={selections}
          headerActions={headerActions}
          handleHeaderAction={handleHeaderAction}
          handleLimitChange={(limit: number) => dispatch(lambdas.setPagination({ pageNumber: pagination.pageNumber, limit: limit}))}
          handlePageChange={(pageNumber: number) => dispatch(lambdas.setPagination({ limit: pagination.limit, pageNumber: pageNumber}))}
          pagination={pagination}
          rowActions={rowActions}
          handleRowAction={handleRowAction}
          rowClickFn={handleRowClick}
          isStatusActions={false}
          handleSort={handleSort}
          sorting={{field: filters.sortBy, order: filters.sortOrder }}
          inlineEdit={true}
      ></TableWrapper>
      <ConfirmDialog
            open={showConfirm}
            handleNo={() => setShowConfirm(false)}
            handleYes={handleConfirm}
            title='Schedule Updated Confirmation'
        >
            {actionClicked === HeaderAction.REJECT ? <div style={{ padding: "0 20px" }}>
                <TextField
                    autoFocus
                    id="comment"
                    label="comments"
                    type="string"
                    fullWidth
                    variant="standard"
                    placeholder="Please add your reason for rejection"
                    onChange={(e) => setComment(e.target.value)}
                />
            </div> : <>{`Are you sure, you want to ${actionClicked}?`}</>}
        </ConfirmDialog>
      {showCreateForm && <CreateScheduleLambda showModal={showCreateForm} handleClose={() => setShowCreateForm(false)}></CreateScheduleLambda>}
    </Container>
  );
}
